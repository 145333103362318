import React, { Component } from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";
import {Link} from "react-router-dom";

class Sidebar extends Component {

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        //const { user } = this.props.auth;
        return (
            <>
                   <button className="navbar-toggler nav_btn collapsed" type="button" data-toggle="collapse" data-target="#navbarText">
            <span className="icon-bar"><i className="las la-times"></i></span>
        </button>
        <div className="nav_content collapse navbar-collapse" id="navbarText">
         <ul className="navbar-nav  line">
          <li className="nav-item">
                <a href="/dashboard" className="nav-link"><i className="las la-tachometer-alt"></i>Dashboard</a>
                    
                </li>
                <li className="nav-item">
                <a href="/contest/live" className="nav-link"><i className="las la-trophy"></i>Contest</a>
                    
                </li>
                <li className="nav-item">
                <a href="/profile-user" className="nav-link"><i className="las la-user"></i>Profile</a>
                
                </li>
                <li className="nav-item">
                <a href="/kyc" className="nav-link"><i className="las la-user-check"></i>KYC</a>
                    
                </li>
                <li className="nav-item">
                <a href="/wallet" className="nav-link"><i className="las la-wallet"></i>Wallet</a>
                   
                </li>
				
				
				<li className="nav-item">
					<a href="/bankdetail" className="nav-link"><i className="las la-university"></i>Bank Details</a>
                </li>
                
                <li className="nav-item">
                <a href="/add-money" className="nav-link"><i className="las la-hand-holding-usd"></i>Add Money</a>
                   
                </li>
                {/* <li className="nav-item">
                <Link to="/exchange" className="nav-link"><i className="las la-hand-holding-usd"></i> Exchange</Link>
                   
                </li> */}
                <li className="nav-item">
                <a href="/help-center" className="nav-link"><i className="las la-headset"></i>Submit Ticket</a>
                   
                </li>
                <li className="nav-item">
                <a href="/help-history" className="nav-link"><i className="las la-history"></i>Ticket History</a>
                   
                </li>
                <li className="nav-item">
                    <a className="nav-link " href="#" onClick={this.onLogoutClick}><i className="las la-sign-out-alt"></i>Logout</a> 
                </li>
            </ul>
          
        </div>
          </> 
        );
    }
}

Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Sidebar);
