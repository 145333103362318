import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Moment from 'moment';
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
import ReactGA from 'react-ga';
const baseUrl = myConstList.baseUrl;
class HelpHistory extends Component {

    constructor(props) {
        super(props);
       
       
        this.columns = [
            {
                key: "_id",
                text: "Id",
                classNameName: "upcase",
                align: "left",
                sortable: true
            },
            {
                key: "subject",
                text: "Subject",
                classNameName: "upcase",
                align: "left",
                sortable: true,
            },
            {
                key: "status",
                text: "Status",
                classNameName: "upcase",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                 
                        <Fragment>
                            {record.status=='Resolved'?<span to={"/chat-room/"+ record.id} className="resolve_class">{record.status}</span>: <span to={"/chat-room/"+ record.id} className="panding_class">{record.status}</span>}                           
                        </Fragment>
                    );
                }
               
            },
            {
                key: "date",
                text: "Date",
                classNameName: "upcase",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                       
                        <Fragment>                            
                            {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                classNameName: "upcase",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                       
                        <Fragment>
                            
                            <Link to={"/chat-room/"+ record.id} className="btn btn_man">Chat</Link>
                          
                            
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "HelpHistory",
            no_data_text: 'No user found!',
           
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: false,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

      

        this.state = {
            modalIsOpen:false,
            currentRecord: {
                id: ''
             }

        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize('UA-218461585-1');
        ReactGA.pageview('/contest');
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl+"api/helpHistory-data",{_id:user.id})
            .then(res => {
                this.setState({records: res.data.data})
                
            })
            .catch()
    }


    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    
    render() {
        return (
            <>
            <Sidebar/>
            <Navbar urlPath={false} symbol={false}/>
                <div className="container container2">

<div className="in_page">
<div className="crypt-market-status2 mt-2 p-2 p-sm-3">
<h5 className="w_hadding mb22">History</h5>
<div className="table-striped ">
<div className="ccrool_table">
 
 <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />

 </div>
 </div>
 </div>


</div>

</div>
            <Footer />
            </>
        );
    }

}

HelpHistory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(HelpHistory);
