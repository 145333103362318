import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateKyc } from "../../../actions/updatePersionalDataKycActions";
import { withRouter } from "react-router-dom";
import {toast,Toaster } from 'react-hot-toast';
import $ from 'jquery';
class ProfileUpdateChangePassowrd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            password: '',
            opassword: '',
            password2: '',
            message:'',
            showResults : false,
            errors: {
                message:""
            }
        };
        console.log(this.state.id);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
             })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.prodata !== undefined
            && nextProps.prodata.pro !== undefined
            && nextProps.prodata.pro.data !== undefined
            && nextProps.prodata.pro.data.message !== undefined
            && nextProps.prodata.pro.data.status && nextProps.prodata.pro.data.messageStatus=='3') {
                
                this.setState({
                    message: nextProps.prodata.pro.data.message, 
                    password: "",
                    password2: "",
                    opassword: "",
                    errors: {
                        password: "",
                        opassword: "",
                        password2: "",
                        message:"",
                    }                   
                });            
        }else if (nextProps.prodata !== undefined
            && nextProps.prodata.pro !== undefined
            && nextProps.prodata.pro.data !== undefined){
            this.setState({
                errors: nextProps.prodata.pro.data
            });
        }
    }
        onChange = e => {
        this.setState({
            message: "",
            errors: {
                password: "",
                message: "",
                opassword: "",
                password2: ""
            }
        })
      
        if (e.target.id === 'user-update-password') {

            const strongRegexSubmit = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            var testSubmit = strongRegexSubmit.test(e.target.value);
     
            if (!testSubmit) {         
                this.setState({ errors: {password: "Min 8 characters(Including 1 Upper case, 1 Numeric value, 1 Special symbol)"}}); 
                
            }

            this.setState({ password: e.target.value });
        }
          if (e.target.id === 'user-update-opassword') {
            this.setState({ opassword: e.target.value });
        }
        if (e.target.id === 'user-update-password2') {
            this.setState({ password2: e.target.value });
        }

        this.setState({
            showResults: this.state.name===null ? false : true
        })
        

    };

    onUserUpdate = e => {
       
        e.preventDefault();


        const strongRegexSubmit = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		var testSubmit = strongRegexSubmit.test(this.state.password);
 
		if (!testSubmit) {         
			this.setState({ errors: {password: "Min 8 characters(Including 1 Upper case, 1 Numeric value, 1 Special symbol)"}}); 
			return false;
		}

        

        const newUser = {
            _id: this.state.id,
            type: "password",
            password: this.state.password,
            opassword: this.state.opassword,
            password2: this.state.password2

        };
        this.props.updateKyc(newUser);
    
    };
    
   
    render() {
        const { errors } = this.state;
        return (
            <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                <div className="white_box">
                    <h6>Change password </h6>
                    <span className="text-success">{this.state.message}</span>
                    <span className="text-danger">{this.state.errors.message}</span>
                    <div className="row">
                        <input
                            onChange={this.onChange}
                            value={this.state.id}
                            id="user-update-id"
                            type="text"
                            className="d-none" />
                            <div className="form-group col-md-12">
                            <label>Old Password<span className="text-danger"> *</span></label>
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.opassword}
                                id="user-update-opassword"
                                type="password"
                                placeholder="Old Password"
                                
                            />
                            <span className="text-danger">{errors.opassword}</span>
                        </div>
                            <div className="form-group col-md-6">
                            <label>New Password<span className="text-danger"> *</span></label>
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.password}
                                id="user-update-password"
                                type="password"
                                placeholder="New Password"
                                
                            />
                            <span className="text-danger">{errors.password}</span>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Confrim Password<span className="text-danger"> *</span></label>
                            <input
                                       
                                onChange={this.onChange}
                                value={this.state.password2}
                                id="user-update-password2"
                                type="password"
                                placeholder="Confrim Password"
                                className="form-control"
                            />
                             <span className="text-danger">{errors.password2}</span>

                        </div>
                        <div className="form-group col-md-12" style={{ display: (this.state.showResults ? 'block' : 'none') }}>
                            <button className="btn btn_man ">Save</button>
                        </div>
                    </div>
                </div>
            </form>
 )
    }
}

ProfileUpdateChangePassowrd.propTypes = {
    updateKyc: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    prodata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    prodata: state.prodata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateKyc }
)(withRouter(ProfileUpdateChangePassowrd));
