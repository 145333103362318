import React from 'react'

const Loading = (props) => (
    <div class="">
        <div class="loader_man">
            <div class="loader">
                
            </div>
        </div>
    </div>
)

export default Loading;