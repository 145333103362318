import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter  } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import {toast,Toaster } from 'react-hot-toast';
import $ from 'jquery';
import axios from 'axios';
import { isMobile, isDesktop, isAndroid, isIOS, browserName, browserVersion, osName, osVersion } from "react-device-detect";
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class Login extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            email: "",
            password: "",
            type:"password",
            showOtpBox:false,
            readonly:false,
            otp:"",
            encrypted_otp:"",
            encrypted_mobile:"",
            encrypted_countryCode:"",
            submitBtnDisable:false,
            errors: {
           },
         };
    }
    getGeoInfo(){
        fetch("https://api.ipdata.co/?api-key=f9ba88a917827fb3c3103a318590155f3bc976cabefdb00c65e93ceb")
        .then(response => response.json())
        .then(response => {
            console.log(response);
        this.setState({
            calling_code: response.calling_code,
            country_flag: response.flag,
        });
        this.setState({ countryCode: response.calling_code })
        }).catch((error) => {})
    }
	
	getUserIp = ()=> {
        
        axios.get('https://geolocation-db.com/json/').then((response) => {
			let data = response.data;
			this.setState({ip:data.IPv4 ? data.IPv4 : ""
						});
		}).catch((error) => {
			console.log(error);
		});
	}


	DeviceName = () => {
		if (isMobile) { 
			this.setState({ device: "Mobile" });
		}
		else if (isAndroid) {
			this.setState({ device: "Android" });
		}
		else if (isIOS) {
			this.setState({ device: "IOS" });
		}
		else if (isDesktop) {
			this.setState({ device: "Desktop" })
		}
	}

    componentWillReceiveProps(nextProps) { 
        if (nextProps.auth.isAuthenticated) {
            if(window.location.pathname != '/privacy-policy' && window.location.pathname != '/term-and-condition' && window.location.pathname != '/faq' && window.location.pathname != '/about-us' && window.location.pathname != '/contact-us' && this.props.history){
                this.props.history.push("/dashboard");
            }
            
            this.setState({
                errors: {},
                email:"",
                password:''
            }); 
        }else{     
           
            // this.setState({errors:{email:nextProps.auth.reg.data.email,password:nextProps.auth.reg.data.password,password2:nextProps.auth.reg.data.password2}});
        }

        if (nextProps.errors) {
            if(nextProps.errors.message !="" && nextProps.errors.message != undefined){
                setTimeout(function() {
                    toast.error( nextProps.errors.message,toastStyles);
                    nextProps.errors.message = "";
                }, 500);
                
            }else if(nextProps.errors.password1 !="" && nextProps.errors.password1 != undefined){
                toast.error(nextProps.errors.password1,toastStyles);
            }
            
            this.setState({
                errors: nextProps.errors,
                submitBtnDisable:false
             });
         
        }
        if (nextProps.auth.reg != undefined
            && nextProps.auth.reg.data != undefined
            && nextProps.auth.reg.data.message != undefined
            && nextProps.auth.reg.data.status) {
                if(nextProps.auth.reg.data.data!=undefined && nextProps.auth.reg.data.data.encrypted_otp != undefined){
                    
                    this.setState({
                        encrypted_otp:nextProps.auth.reg.data.data.encrypted_otp,
                        encrypted_mobile:nextProps.auth.reg.data.data.encrypted_mobile,
                        encrypted_countryCode:nextProps.auth.reg.data.data.encrypted_countryCode,
                        showOtpBox:true,
                        readonly:true,
                        submitBtnDisable:false,
                        submitBtnText:"Verify OTP"
                    })
                }
                else {
                    this.setState({
                        message: nextProps.auth.reg.data.message,
                        name:"",
                        mobile:"",
                        referralBy:"",
                        encrypted_otp:"",
                        encrypted_mobile:"",
                        encrypted_countryCode:"",
                        showOtpBox:false,
                        readonly:false,
                        submitBtnDisable:false,
                        submitBtnText:"Log in"
                    });
                }
        }
        
    }
    componentDidMount() {
		this.getUserIp();
		this.DeviceName();
        this.getGeoInfo();
        // if (this.props.auth.isAuthenticated) {
        //     this.props.history.push("/dashboard");
        // }
    };
    onChange = e => {
        this.setState({
        errors: {
          
        }
    });
        this.setState({ [e.target.id]: e.target.value });
        if (e.target.id === 'user-update-countryCode') {
            this.setState({ countryCode: e.target.value });
        }
        if (e.target.id === 'user-update-mobile') {
            this.setState({ mobile: e.target.value });
        }

    };
    hideShowModal(){ 
        $("#loginmodal").removeClass("show");
    }
        handleOnBlurEmail(event) {
       
        this.setState({
            errors: {
                   email1:""
                   
            }
         });
      }
      handleOnBlurPassword(event) {
        this.setState({
              errors: {
                password1:""
            },
         });
      }
    onSubmit = e => {
        toast.dismiss();
        e.preventDefault();
        this.setState({submitBtnDisable:true});
        const userData = {
            ip : this.state.ip,
			deviceName:this.state.device,
			browserName:browserName,
			osName:osName,
            countryCode: this.state.countryCode,
            mobile: this.state.mobile,
            otp:this.state.otp,
            encrypted_otp:this.state.encrypted_otp,
            encrypted_mobile:this.state.encrypted_mobile,
            encrypted_countryCode:this.state.encrypted_countryCode
		};
        this.props.loginUser(userData);
    };
    eyeFucntion= e =>{
       if(this.state.type=="password"){
        this.setState({type:"text"});
       }else{
        this.setState({type:"password"});
       }
    }
   
    render() {
        const { errors } = this.state;
        return (
        <>
            <div className=" tab-pane active pt-3" id="SignIn">
            <form noValidate onSubmit={this.onSubmit} >
            <div className="form-group row">        
                                <div className="col-3 col-lg-3 col-md-3">
                            {/* <label className="fw-500">Country Code</label> */}
                                    <div className="country_flag_man_box">
                                        <span class="country_flag"><img className="w100" src={this.state.country_flag}></img></span>
                                    
                                    
                                    
                                        <input onChange={this.onChange}
                                            value={this.state.calling_code}
                                            placeholder={this.state.calling_code}
                                            id="user-update-countryCode"
                                            type="text" className="form-control country_code pr-0"/>
                                    </div>
                                    <span className="text-danger">{errors.countryCode}</span>
                                </div>
                                <div className="col-9 pl-0">
                            
                                    <input onChange={this.onChange} placeholder="Mobile number"
                                        id="user-update-mobile"
                                        type="number" className="form-control"
                                        value={this.state.mobile}
                                        readOnly={this.state.readonly}
                                        pattern="[0-9]*"
                                    />
                                    <span className="text-danger">{errors.mobile}</span>
                                    <span className="text-danger">{errors.message}</span>
                                </div>
                            </div>
  
                            <div className="form-group"  style={{ display: (this.state.showOtpBox ? 'block' : 'none') }}>
                                <input onChange={this.onChange} value={this.state.otp}  className="form-control" autoComplete="off" placeholder="Enter OTP" id="otp" type="text" error={errors.otp}/>
                            </div>


   {/*<div className="form-group ">
    <a href="#" className="" data-target="#pwdModal" data-toggle="modal" onClick={() => this.hideShowModal()}>Forgot Password</a>

        </div> */}
  <div className="form-group">
    <button className="btn w100 btn_man" disabled={this.state.submitBtnDisable}>Log in</button>
  </div> 
  </form>
          
  </div>
         </>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Login);
