import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requery } from "../../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditor } from 'ckeditor4-react';
import 'react-toastify/dist/ReactToastify.css';
class MarketActivities extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            id: this.props.id,
            description: '',
            message: '',
            username: user.name,
            showResults: false,
            showTeaxtArea: true,
            errors: {},
        };

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                records: nextProps.record
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {

            this.setState({
                message: nextProps.auth.user.data.message
            });
        }
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    showChatHtml = () => {
       

        const html = []
        // this.state.records.map(function (value, i) {
           
            html.push(
                <>
                    <tr>
                        <td className="align-middle"><i className="las la-star stsr5 mr-1"></i>
                            CNS</td>
                        <td><span className="pr-2">0.00004356</span></td>
                        <td className="tr"><b className="crypt-down">-5.4%</b> </td>
                    </tr>
                </>
            );
        // })
        return html;
    }
    onChange = e => {
        if (e.target.id === 'user-update-subject') {
            this.setState({ subject: e.target.value });
        }

    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUserUpdate = e => {
        const { user } = this.props.auth;
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
            user_id: user.id,
            description: this.state.description


        };
        this.props.requery(newUser);
    };

    render() {
        const { errors } = this.state;
        const { user } = this.props.auth;
        console.log("local");
        console.log(this.state.records);
        return (
            <div className="crypt-market-status mt-2 ">
            <div>
                <h6 className="rgb100 m-0 p-2">Market Activities</h6>
                <table className="table border_none mb-0">
                    <tbody className="crypt-table-hover">
                        <tr>
                            <td className="">
                                <span className="rgb100">ETC</span> BTC<br /> 17:25:07
                            </td>
                            <td className="tr">
                                <span className="crypt-up">13.00%</span> <br />In 5 min
                            </td>
                          </tr>
                        <tr>
                            <td className="">
                                <span className="rgb100">ETC</span> BTC<br /> 17:25:07
                            </td>
                            <td className="tr">
                                <span className="crypt-down">13.00%</span> <br />In 5 min
                            </td>
                          </tr>
                        <tr>
                            <td className="">
                                <span className="rgb100">ETC</span> BTC<br /> 17:25:07
                            </td>
                            <td className="tr">
                                <span className="crypt-up">13.00%</span> <br />In 5 min
                            </td>
                          </tr>
                        <tr>
                            <td className="">
                                <span className="rgb100">ETC</span> BTC<br /> 17:25:07
                            </td>
                            <td className="tr">
                                <span className="crypt-down">13.00%</span> <br />In 5 min
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
 )
    }
}

MarketActivities.propTypes = {
    requery: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { requery }
)(withRouter(MarketActivities));
