import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Leaderboard from "../partials/exchange/Leaderboard"; 
import Participant from "../partials/exchange/Participant";
import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
import ReactGA from 'react-ga';
import moment from 'moment';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
const config = {
    headers: {
        Authorization: 'Bearer ' + `${localStorage.getItem("jwtToken")}`,
    },
};
class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            records: [],
            leaderboard: [],
            participant: [],
            contestLogo: "",
            contestName: "",
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            entryFee: "",
            virtualFund: "",
            winningAmount: "",
            contestStatus: "",
            levels: [],
            description: '',
            heading: '',
            about: '',
            notes: '',
            pair_id: "",
            numOfParticipation: "",
            contestId: this.props.match.params.id,
            currentStatus: "",
            currentStatusLive:"",
            pairRealName: "",
            max_particepate: "",
            playNow:"",
            checked: false,
            bonusper: 0,
            bonus: 0,            
            contestType:"",
            contestTypePast:"",
            cashWalletChecked:false,
            winningWalletChecked:false,
            rewardWalletChecked:false,
            cashWalletDeduct:0,
            winningWalletDeduct:0,
            rewardWalletDeduct:0,
            participateBtnDisable:false
        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize('UA-218461585-1');
        ReactGA.pageview('/trading-contest');
        this.getData()        
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }
    participatedata(id, entryFee, virtualFund, max_particepate) {
        this.setState({ id: id, entryFee: entryFee, virtualFund: virtualFund, max_particepate: max_particepate });


    }
    playNow(contestId){
        axios
            .post(baseUrl + "api/updatePlayNowCondition", { contest_id: contestId},config)
            .catch();
    }
    participate(id, entryFee, virtualFund, max_particepate, checked) {
        this.setState({participateBtnDisable:true});
        const { user } = this.props.auth;
        var sendData = { contest_id: id,
            entryFee: entryFee, 
            virtualFund: virtualFund, 
            max_particepate: max_particepate, 
            cashWalletChecked: this.state.cashWalletChecked,
            winningWalletChecked: this.state.winningWalletChecked,
            rewardWalletChecked: this.state.rewardWalletChecked   
           }
        axios
        .post(baseUrl + "api/participateCondition", sendData ,config)
            .then(res => {
                this.setState({participateBtnDisable:false});
                if (res.data.status) {
                    this.setState({
                        status: "active",
                        show:false
                    });
                    toast.success(res.data.message, toastStyles)
                    setTimeout(function () {
                        window.location.reload();
                    }, 2000);
                }else{
                    toast.error(res.data.message, toastStyles)
                } 
            })
            .catch();
    }
    handleRewardWalletCheck = e => {
        // var bonusper = this.state.entryFee * 50 / 100;
		// bonusper = (bonusper>this.state.bonus)  ? this.state.bonus : bonusper;
        // var totalde = this.state.entryFee - bonusper;

        // if (this.state.rewardWalletChecked == false) { 
        //     this.setState({ checkBox: true, bonusper: bonusper, totalde: totalde }); 
        // } else {

        //     this.setState({ checkBox: false, bonusper: 0, totalde: 0 });
        // }
        this.setState({ rewardWalletChecked: !this.state.rewardWalletChecked });
        this.calculateIt(this.state.cashWalletChecked,this.state.winningWalletChecked,!this.state.rewardWalletChecked);
    }

    handleCashWalletCheck = e => {
        // var bonusper = this.state.entryFee * 50 / 100;
		// bonusper = (bonusper>this.state.bonus)  ? this.state.bonus : bonusper;
        // var totalde = this.state.entryFee - bonusper;

        // if (this.state.rewardWalletChecked == false) { 
        //     this.setState({ checkBox: true, bonusper: bonusper, totalde: totalde }); 
        // } else {

        //     this.setState({ checkBox: false, bonusper: 0, totalde: 0 });
        // }
        this.setState({ cashWalletChecked: !this.state.cashWalletChecked });
        this.calculateIt(!this.state.cashWalletChecked,this.state.winningWalletChecked,this.state.rewardWalletChecked);
    }

    handleWinningWalletCheck = e => {
        // var bonusper = this.state.entryFee * 50 / 100;
		// bonusper = (bonusper>this.state.bonus)  ? this.state.bonus : bonusper;
        // var totalde = this.state.entryFee - bonusper;

        // if (this.state.rewardWalletChecked == false) { 
        //     this.setState({ checkBox: true, bonusper: bonusper, totalde: totalde }); 
        // } else {

        //     this.setState({ checkBox: false, bonusper: 0, totalde: 0 });
        // }
        this.setState({ winningWalletChecked: !this.state.winningWalletChecked });
        this.calculateIt(this.state.cashWalletChecked,!this.state.winningWalletChecked,this.state.rewardWalletChecked);
    }

    calculateIt=(cashCheck,winningCheck,rewardCheck)=>{
        // console.log("cashWalletChecked",cashCheck);
        // console.log("winningWalletChecked",winningCheck);
        // console.log("rewardWalletChecked",rewardCheck);
        var remainingAmount = this.state.entryFee;
        var rewardWalletDeduct = 0;
        var winningWalletDeduct = 0;
        var cashWalletDeduct = 0;

        if(rewardCheck){
            var rewardWalletDeduct = remainingAmount * 50 / 100;
            rewardWalletDeduct = (rewardWalletDeduct>this.state.bonus)  ? this.state.bonus : rewardWalletDeduct;
            var remainingAmount = remainingAmount - rewardWalletDeduct; 
        }

        if(winningCheck){
            var winningWalletDeduct = remainingAmount;
            winningWalletDeduct = (parseInt(winningWalletDeduct) > parseInt(this.state.winningWalletBalance))  ? this.state.winningWalletBalance : winningWalletDeduct;            
            var remainingAmount = remainingAmount - winningWalletDeduct; 
        }

        if(cashCheck){
            var cashWalletDeduct = remainingAmount;
            cashWalletDeduct = (cashWalletDeduct>this.state.cashWalletBalance)  ? this.state.cashWalletBalance : cashWalletDeduct;
            var remainingAmount = remainingAmount - cashWalletDeduct; 
        }

        // console.log("rewardWalletDeduct",rewardWalletDeduct);
        // console.log("winningWalletDeduct",winningWalletDeduct);
        // console.log("cashWalletDeduct",cashWalletDeduct);
        
        this.setState({
            rewardWalletDeduct:rewardWalletDeduct,
            winningWalletDeduct:winningWalletDeduct,
            cashWalletDeduct:cashWalletDeduct,
        })

    }

    getData() {
        console.log("contestByID-data");
        var currentTime = moment().utcOffset('+05:30').format('HH:mm');
        var endTime = moment().utcOffset('+05:30').add(1, 'minutes').format('HH:mm');
        axios
            .post(baseUrl + "api/contestByID-data", { _id: this.props.match.params.id,currentTime: currentTime,endTime:endTime }, config)
            .then(res => {
                if(res.data.data.item){
                    var arrData = res.data.data.item;
                    let arr = [];
                    if(arrData.winningAmount !== 0){
                        console.log("winningAmount========",arrData.levels);
                        arr = arrData.levels.split(',');
                        console.log("arr========",arr);
                    }
                    let cleanText = arrData.description.replace(`/</?[^>]+(>|$)/g`, "");
                    console.log("arr==",arr);
                    console.log("arrData.winningAmount",arrData.winningAmount);
                    this.setState({
                        records: res.data.data,
                        contestLogo: arrData.contestLogo,
                        contestName: arrData.contestName,
                        startDate: arrData.startDate,
                        startTime: arrData.startTime,
                        endDate: arrData.endDate,
                        endTime: arrData.endTime,
                        entryFee: arrData.entryFee,
                        virtualFund: arrData.virtualFund,
                        winningAmount: arrData.winningAmount,
                        contestStatus: arrData.contestStatus,
                        description: arrData.description,
                        heading: arrData.heading,
                        about: arrData.about,
                        notes: arrData.notes,
                        levels: arr,
                        numOfParticipation: arrData.numOfParticipation,
                        currentStatus:arrData.currentStatus,
                        max_particepate: arrData.max_particepate,
                        contestTypePast:arrData.pastContestStatus
                    })
                }                
            })
            .catch()
        axios
            .post(baseUrl + "api/profile-data", {},config)
            .then(res => {
                this.setState({ bonus: res.data.data.bonus,winningWalletBalance: res.data.data.winningamount,cashWalletBalance: res.data.data.balance })
            })
            .catch()
        axios
            .post(baseUrl + "api/liveContestById", { currentTime: currentTime,endTime:endTime,contestId: this.props.match.params.id }, config)
            .then(res => {
                if(res.data.data[0]){
                    this.setState({
                        currentStatusLive: res.data.data[0].currentStatus,
                        pairRealName: res.data.data[0].pairRealName,
                        playNow: res.data.data[0].playNow[0],
                        contestType:"live"                        
                    })
                }
            })
            .catch()
        // axios
        //     .post(baseUrl + "api/leaderboard-winner-data", { contestId: this.props.match.params.id }, config)
        //     .then(res => {
        //         this.setState({ leaderboard: res.data.data })
        //     })
        //     .catch()
        axios
            .post(baseUrl + "api/leaderboard-data", { contestId: this.props.match.params.id }, config)
            .then(res => {
                this.setState({ participant: res.data.data,leaderboard: res.data.data })
            })
            .catch()
            
    }

    showHtml = () => {
        const html = [];
        let collectThis = this;
        if(this.state.levels.length>0){
            collectThis.state.levels.map(function (value, i) {
                if(value !=0){
                    html.push(
                        <>
                            <tr>
                                <th>{i + 1}</th>
                                <th className="tr">{value}</th>
                            </tr>
                        </>
                    );
                }                
            })
        }        
        return html;
    }


    render() {
        return (<>
		  
            <Navbar urlPath={false} symbol={false} />
            <Sidebar />

            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Contest Rules</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body rules_list" dangerouslySetInnerHTML={{__html: this.state.description}}>
                           
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="in_page">
                <div className="container-fluid">
                    <div className="crypt-market-status2  ">
                        <div className="row sm-gutters row_d p-3">
                            <div className="col-lg-6 col_d mt-2 pr-lg-5 ">
                              <div className="row ">
							  <div className="col-6">
							    <h5 className="ttc">{this.state.contestName}</h5>
							  </div>
							  <div className="col-6 tr mb-4">
							     <a className="view_dital" href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal">Contest Rules</a>
							  </div>
							  
							  <div className="col-5 pt-1">
							     <b>Prize Fund</b>
							  </div>
							  <div className="col-7 tr mb-2">
							     <h5 className="ine_hadding">{this.state.winningAmount} INR</h5>
							  </div>
							  
							  <div className="col-5 pt-1">
							     <b>Entry Fee</b>
							  </div>
							  <div className="col-7 tr mb-2">
							     <h5 className="ine_hadding">{this.state.entryFee} INR</h5>
							  </div>
							  </div>

							 <div className=" tr">	
							
							{!this.state.contestTypePast && this.state.contestType =="" && 
							    <div className="  play_btn_flef ">
                                    {this.state.currentStatus == "ACTIVE" ?
                                        this.state.playNow== 'yes'? <Link className="play-now btn btn_man" to={"/exchange/" + this.state.contestId+"/"+this.state.pairRealName}><i className="las la-trophy"></i>Going On</Link>:<span className="alert alert-success">Already Participated</span>
                                        : this.state.numOfParticipation!= this.state.max_particepate ? <button type="button" className="btn btn_man p_button"  onClick={() => this.participatedata(this.state._id,this.state.entryFee,this.state.virtualFund,this.state.max_particepate)} data-toggle="modal" data-target="#participateModal" >Participate Now </button> : <span className="alert alert-danger">Participation limit exceeded</span>}    
                                </div>
                            } 
                            {!this.state.contestTypePast && this.state.contestType =="live" && 
                                <div className="  play_btn_flef ">
                                    {this.state.currentStatusLive == "ACTIVE" ?
                                        this.state.playNow== 'yes'? <Link className="play-now btn btn_man" to={"/exchange/" + this.state.contestId+"/"+this.state.pairRealName}><i className="las la-trophy"></i>Going On</Link>:<Link className="play-now btn btn_man" to={"/exchange/" + this.state.contestId+"/"+this.state.pairRealName} onClick={() => this.playNow(this.state.contestId)}><i className="las la-trophy"></i>Play Now</Link>
                                        : this.state.numOfParticipation!= this.state.max_particepate ? <button type="button" className="btn btn_man p_button"  onClick={() => this.participatedata(this.state._id,this.state.entryFee,this.state.virtualFund,this.state.max_particepate)} data-toggle="modal" data-target="#participateModal" >Participate Now </button> : <span className="alert alert-danger">Participation limit exceeded</span>}    
                                </div>
                            }
                            </div>
                            </div>
							
                           
                            <div className="col-lg-6 col_d ">
                                <div className="lilogo">
                                    <ul>
                                        <li className="tc"> <img src="/img/banner-logo.png" alt="Logo" className="c_logo" /></li>
                                        <li className="border_li"> {this.state.winningAmount} INR<br />PRIZE POOL</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-2">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="crypt-market-status2 mt-4 tc_box">
                                <div className="pt-3 pl-3 pr-3">
                                    <div className="row">
                                        <div className="col-6 mb-2">
                                            <b className="d_hadding" >
                                                Participants
                                            </b>
                                        </div>
										<div className="col-6 mb-2 tr">
                                           <div className="d_value2" >
                                                    {this.state.numOfParticipation}
                                                </div>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <b className="d_hadding" >
                                                Start
                                            </b>
                                        </div>
										<div className="col-6 mb-2 tr">
                                                <div className="d_value2" >
                                                    {this.state.startDate} {this.state.startTime}
                                                </div>
                                         
                                        </div>
                                        <div className="col-6 ">
                                            <b className="d_hadding" >
                                                End
                                            </b>
                                        </div> 
										<div className="col-6 mb-2 tr">
                                                <div className="d_value2" >
                                                    {this.state.endDate} {this.state.endTime}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3">
                            
                                    <table className="table mb-0 winners_table2">
                                        <tr>
                                            <th className="th">Rank</th>
                                            <th className="tr th"> Winning Amount</th>
                                        </tr>
                                    </table>
									    <div className="winners_table">
									<table className="table ">
                                        {this.showHtml()}
                                    </table>
                                </div>
                                </div>
                                {/*<div className="pl-2 pl-md-3 pr-2 pr-md-3 pb-3">
                                    <h5>About</h5>
                                    <p> {this.state.about}
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-8 mt-3 mt-sm-0">
                            <div className="crypt-market-status2 mt-4 p-2 p-md-3">
								  <ul className="nav contatcs-tab pb-3" id="crypt-tab">
									<li className="nav-item">
									  <a className="active" data-toggle="tab" href="#Leaderboard">Leaderboard</a>
									</li>
									<li className="nav-item">
									  <a data-toggle="tab" href="#Leaderboard2">Participants</a>
									</li>
								  </ul>
								  <div class="tab-content">
								<div id="Leaderboard" class="tab-pane active">
								  <Leaderboard leaderboard={this.state.leaderboard} currentStatusLive={this.state.currentStatusLive} contestType={this.state.contestType} contestTypePast = {this.state.contestTypePast}/>
								</div>
								<div id="Leaderboard2" class="tab-pane fade">
								   <Participant participant={this.state.participant} contestType={this.state.contestType} contestTypePast = {this.state.contestTypePast}/>
								</div>
							  </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade " id="participateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content mx400">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Select an option to pay - ₹ {this.state.entryFee}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">


                            <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleCashWalletCheck} defaultChecked={this.state.checked} />    
                                <label className="form-check-label" for="flexCheckChecked">
                                <span className="amountshow">
                                Cash Wallet({this.state.cashWalletBalance}):</span>₹ {this.state.cashWalletDeduct}
                                </label>
                            </div>
                            
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleWinningWalletCheck} defaultChecked={this.state.checked} />
                                <label className="form-check-label" for="flexCheckChecked">
                                <span className="amountshow">
                                 Winning Wallet({this.state.winningWalletBalance}):</span>₹ {this.state.winningWalletDeduct}
                                   
                                </label>
                            </div>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleRewardWalletCheck} defaultChecked={this.state.checked} />
                                <label className="form-check-label" for="flexCheckChecked">
                                <span className="amountshow">
                                 Reward Wallet({this.state.bonus}):</span>₹ {this.state.rewardWalletDeduct}
                                    <br /><span className="amountshow">Total to participate:</span>₹  {this.state.entryFee}
                                    <br /><span className="text-danger">Note: You can use maximum 50% of entry fees from the reward wallet</span>
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-border" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn_man" onClick={() => this.participate(this.state.contestId, this.state.entryFee, this.state.virtualFund, this.state.max_particepate, this.state.checked)}>Participate</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Toaster />
        </>
        );
    }
}
Users.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});
export default connect(
    mapStateToProps
)(Users);
