import axios from "axios";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
export const addWithdrawalRe = (userData, history) => dispatch => {
    axios
        .post(baseUrl+"api/withdral-request-add", userData,configToken)
        .then(res =>
            dispatch({
                type: "TR_ADD",
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: "GET_ERRORS",
            payload: err.response.data
        })
    );
};



