import React from 'react'
import BigNumber from 'bignumber.js'

const Ticker = (props) => (
    
    <div className="box-kline-para-basic-right">
    <ul>
        <li>
            <h4 className="ng-binding">Last Price</h4>
            <strong className="ng-binding"><span className={new BigNumber(props.c).gte(new BigNumber(props.lastc)) ? 'text-success' : 'text-danger'}>{new BigNumber(props.c).toFormat(null,1)}</span></strong>
             {/* <strong className="transMoney ng-binding ng-scope">$0.941434</strong> */}
        </li>
        <li>
            <h4 className="ng-binding">Change</h4>
            <strong><span className={props.p < 0 ? 'text-danger' : 'text-success'}>{`${new BigNumber(props.p).toFormat(null,1)} (${new BigNumber(props.P).toFormat(2,1)}%)`}</span></strong>
            {/* <strong className="changeRate ng-binding ng-scope green">+0.16%</strong> */}
        </li>
        <li>
            <h4 className="ng-binding">24h-High</h4>
            <strong className="ng-binding">{new BigNumber(props.h).toFormat(null,1)}</strong>
        </li>
        <li>
            <h4 className="ng-binding">24h-Low</h4>
            <strong className="ng-binding">{new BigNumber(props.l).toFormat(null,1)}</strong>
        </li>
        <li>
            <h4 className="ng-binding">24h Volume</h4>
            <strong className="ng-binding">{new BigNumber(props.q).toFormat(2,1)} {props.s.length === 7 ? props.s.slice(-4) : props.s.slice(-3)}</strong>
        </li>
    </ul>
    <div className="cls"></div>
</div>

   
)

export default Ticker;