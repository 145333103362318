import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";

import {onMessageListener} from "./firebaseInit";
import Notifications from "./components/notification/Notifications";
import ReactNotifications from "./components/notification/ReactNotifications";

import Contest from "./components/pages/Contest";
import Profile from "./components/pages/Profile";
import Kyc from "./components/pages/Kyc";
import HelpCenter from "./components/pages/HelpCenter";
import HelpHistory from "./components/pages/HelpHistory";
import AddMoney from "./components/pages/AddMoney";
import AddMoneyMobile from "./components/pages/AddMoneyMobile";
import Exchange from "./components/pages/Exchange";
import MobileExchange from "./components/pages/MobileExchange";
import Wallet from "./components/pages/Wallet";
import WalletBonus from "./components/pages/WalletBonus";
import ChatRoom from "./components/pages/ChatRoom";
import Withdral from "./components/pages/Withdral";
import transactionHistory from "./components/pages/transactionHistory";
import tradingContest from "./components/pages/tradingContest";
import WithdrawalHistory from "./components/pages/WithdrawalHistory";
import WithdrawalHistoryTable from "./components/pages/WithdrawalHistoryTable";
import WalletHistoryTable from "./components/pages/WalletHistoryTable";
import ResetPassword from "./components/auth/ResetPassword";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import TempLogin from "./components/auth/TempLogin";
import ComingSoon from "./components/pages/ComingSoon";
import BankDetail from "./components/pages/BankDetail";
// 
import privacypolicy from "./components/pages/privacypolicy";
import faq from "./components/pages/faq";
import contactus from "./components/pages/contactus";
import aboutus from "./components/pages/aboutus";
import Media from "./components/pages/Media";
import cancellation from "./components/pages/cancellation";
import profitLossMobile from "./components/pages/mobileProfitLoss";
// 
import Games from "./components/pages/Games";
import UpDown from "./components/pages/UpDown";
import UpDownFree from "./components/pages/UpDownFree";
import React, { Component,useState} from 'react';
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

if (localStorage.jwtToken) {
    const token = localStorage.jwtToken;
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = "/";
    }
}
if(localStorage.jwtToken) {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }

function App(){
    const setShow = useState(false);
    const [notification, setNotification]=useState({title:"",body:""});
        onMessageListener()
        .then((payload) => {
              setShow(true);
            setNotification({
                title: "title",
                body: "body of meassage",
            });
            console.log(payload);
        }).catch((err) => console.log("failed: ", err));

        return (
            <Provider store={store}>
                <Router>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            
                            {/*  */}
                            <Route exact path="/games" component={Games} />
                            <Route exact path="/up-down" component={UpDown} />
                            <Route exact path="/up-down-free" component={UpDownFree} />
                            <Route exact path="/privacy-policy" component={privacypolicy} />
                            <Route exact path="/faq" component={faq} />
                            <Route exact path="/contact-us" component={contactus} />
                            <Route exact path="/about-us" component={aboutus} />
                            <Route exact path="/media" component={Media} />
                            <Route exact path="/term-and-condition" component={cancellation} />
                            <Route exact path="/mobile-exchange/:id/:symbol" component={MobileExchange} />
                            <Route exact path="/add-money-mobile" component={AddMoneyMobile} />
                            <Route exact path="/profit-loss-mobile/:symbol" component={profitLossMobile} />
                            {/*  */}
                            <Route exact path="/login/:_id" component={Login} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/temp-login" component={TempLogin} />
                            <Route exact path="/password-reset/:userId/:token" component={ResetPassword} />
                            <PrivateRoute exact path="/dashboard" component={Dashboard} />
                            <PrivateRoute exact path="/contest" component={Contest} />
                            <PrivateRoute exact path="/contest/:type" component={Contest} />
                            <PrivateRoute exact path="/profile-user" component={Profile} />
                            <PrivateRoute exact path="/kyc" component={Kyc} />
                            <PrivateRoute exact path="/help-center" component={HelpCenter} />
                            <PrivateRoute exact path="/help-history" component={HelpHistory} />
                            <PrivateRoute exact path="/add-money" component={AddMoney} />
                            <PrivateRoute exact path="/wallet" component={Wallet} />
                            <PrivateRoute exact path="/walletbonus" component={WalletBonus} />
                            <PrivateRoute exact path="/exchange/:id/:symbol" component={Exchange} />
                            
                            <PrivateRoute exact path="/chat-room/:id" component={ChatRoom} />
                            <PrivateRoute exact path="/trading-contest/:id/" component={tradingContest} />
                            <PrivateRoute exact path="/withdrawal-request" component={Withdral} />
                            <PrivateRoute exact path="/withdrawalHistory" component={WithdrawalHistory} />
                            <PrivateRoute exact path="/withdrawalHistoryTable" component={WithdrawalHistoryTable} />
                            <PrivateRoute exact path="/transaction-history" component={transactionHistory} />
                            <PrivateRoute exact path="/transaction-history-table" component={transactionHistory} />
                            <PrivateRoute exact path="/WalletHistoryTable" component={WalletHistoryTable} />
                            <PrivateRoute exact path="/bankdetail" component={BankDetail} />
                            
                            <Route exact path="/referral/:refer" component={Login} />
                            <Route exact path="*" component={NotFound} />
                            {/* <Route exact path="/:refer" component={Home} /> */}
                        </Switch>
                </Router>
            </Provider>
            /* <div className="App">
                { show ?(<ReactNotifications title={notification.title} body={notification.body}/>):(<></>)}
                <Notifications />
                <Fader text="Hello react"></Fader>
            </div> */
        );
}

export default App;
