import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";

const Games = () => {
  console.log("hiiii");
  return (
    <div>
      <Navbar urlPath={true} symbol={false} /> 
      <Sidebar />
      <div className="in_page">
	  
	   <div className="container">
	      <div className="row">
	       <div className="col-md-4 mb-4">
	          <a href="dashboard" className="crypt-market-status2 p-0">
	          <img
                  src="img/ttg_game.jpg"
                  alt="TTG Banner"
                  className="img-fluid"
                />
	          </a>
	       </div>
		   <div className="col-md-4 mb-4">
       <a href="up-down" className="crypt-market-status2 p-0">
	          <img
                  src="img/up_down.jpg"
                  alt="TTG Banner"
                  className="img-fluid"  
                />
	          </a>
	       </div>
		   <div className="col-md-4 mb-4">
       <a href="#" className="crypt-market-status2 p-0">
	          <img
                  src="img/coin11.jpg"
                  alt="TTG Banner"
                  className="img-fluid"
                />
	          </a>
	       </div>
	  
	     </div>

	  
        <div className="crypt-market-status2 mt-5 ">
          <div id="myCarousel" className="carousel slide" data-ride="carousel">
            <ul className="carousel-indicators">
              <li data-target="#myCarousel" data-slide-to={0} className="" />
              <li
                data-target="#myCarousel"
                data-slide-to={1}
                className="active"
              />
              <li data-target="#myCarousel" data-slide-to={2} className="" />
              <li data-target="#myCarousel" data-slide-to={3} className="" />
              <li data-target="#myCarousel" data-slide-to={4} className="" />
            </ul>
            <div className="carousel-inner">
              <div className="carousel-item">
                <img
                  src="http://192.168.1.80:5000//bunner/bunner-1699001057457.png"
                  alt="TTG Banner"
                  className="img-fluid"
                />
              </div>
              <div className="carousel-item active">
                <img
                  src="http://192.168.1.80:5000//bunner/bunner-1699001018440.png"
                  alt="TTG Banner"
                  className="img-fluid"
                />
              </div>
              <div className="carousel-item">
                <img
                  src="http://192.168.1.80:5000//bunner/bunner-1698999108532.png"
                  alt="TTG Banner"
                  className="img-fluid"
                />
              </div>
              <div className="carousel-item">
                <img
                  src="http://192.168.1.80:5000//bunner/bunner-1698999101183.png"
                  alt="TTG Banner"
                  className="img-fluid"
                />
              </div>
              <div className="carousel-item">
                <img
                  src="http://192.168.1.80:5000//bunner/bunner-1699011514977.png"
                  alt="TTG Banner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Games;