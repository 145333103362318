import React from 'react'
import BigNumber from 'bignumber.js'

const OrderRow = (props) => (
    <tr>
                        <td className={`${props.class}`}>{new BigNumber(props.ba[0]).toFormat(null,1)}</td>
                        <td>{new BigNumber(props.ba[1]).toFormat(null,1)}</td>
                        <td>{new BigNumber((props.ba[0] * props.ba[1])).toFormat(null,1)}</td>
                    </tr>
      
)

const ProfitLoseTable = (props) => {
    let bids = [];
    let asks = [];
   
    let numRowsBid = Math.min(10, props.bids.length);
    let numRowsAsk = Math.min(10, props.asks.length);
    let maxBid = BigNumber.maximum(props.bids.map(bid => bid[0])).toFormat()
    let minAsk = BigNumber.minimum(props.asks.map(ask => ask[0])).toFormat()
    let minBid = new BigNumber(maxBid).minus(BigNumber.minimum(props.bids.map(bid => bid[0]))).toFormat()
    let maxAsk = new BigNumber(minAsk).minus(BigNumber.maximum(props.asks.map(ask => ask[0]))).toFormat()
    for (var b = 0; b < numRowsBid; b++) {
        bids.push(
            <OrderRow i={b} ba={props.bids[b]} diff={maxBid} max={minBid} class="crypt-up" />         
        )
    }
    for (var a = 0; a < numRowsAsk; a++) {
        asks.unshift(
            <OrderRow i={a} ba={props.asks[a]} diff={minAsk} max={maxAsk} class="crypt-down" />         
        )
    }
    return (
        <>
        <div className="col-md-4 col_d">
        <div className="crypt-market-status mt-2">
            <table className="table border_none">
                <thead>
                    <tr>
                        <th scope="col">Price</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <tbody>
                 
                   {bids}
                </tbody>
            </table>
            <h6 className="text-center t2t pt-2"> {isNaN(props.c)?0:new BigNumber(props.c).toFormat(null,1)}</h6>
            <table className="table border_none mb-0">
                <tbody>
               
                {asks} 
                   

                </tbody>
            </table>
        </div>
    </div>
      
        </>
    )
}

export default ProfitLoseTable;
