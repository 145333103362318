import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { subscriberUser } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Header from "../../components/pages/Header";
import Register from "../../components/auth/Register";
import SubscribeUser from "../../components/auth/SubscribeUser";
import ForgetPassword from "../../components/auth/ForgetPassword";
import Footer from "../../components/pages/Footer";
import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Scroll from 'react-scroll';
import $ from 'jquery';
import {Modal,Button } from 'react-bootstrap';


const Link = Scroll.Link;

const baseUrl = myConstList.baseUrl;
class Home extends Component {
    constructor() {
        super();
        this.state = {         
            balance:'',
           errors: {},
           showLogin:true
        };
		
		this.button = React.createRef();
		this.signupTabBtn = React.createRef();
     
    }

    componentDidMount() {
        if(this.props.match.params._id != undefined){
            axios
                .post(baseUrl+"api/email-verification", {userId: this.props.match.params._id})
                .then(res => {     
                        toast( res.data.message);
                this.props.history.push("/");
            })
            .catch(error=>{
                // alert(error);
            });
            
        }
        
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
		// if(this.props.match.params.refer!==undefined){
		// 	// this.button.current.click();
		// 	this.signupTabBtn.current.click();			
		// }
		
    };

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.auth.isAuthenticated) {
           window.location = "/dashboard";
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
    }
    onSubmit = e => {
        e.preventDefault();	
            const newUser = {
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile
        };
        let res = this.props.subscriberUser(newUser);        
    }

render() {
        const { errors } = this.state;
        return (
            <>	
            <div className="">
                <div >
                    <nav className="navbar navbar-expand-lg home_nav">
                        <div className="container ">
                        {/* <!-- logo --> */}
                        <a className="navbar-brand" href="/">
                            <img src="/img/logo.png" alt="header-Logo" className="logo logo1"/>
							<img src="./img/logo_mobile.svg" alt="header-Logo" className="logo logo2"/>
						</a>

                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarText">
                            <span className="icon-bar"><i className="las la-times"></i></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav  line ml-auto">
                            <li className="nav-item">
                            <Link className="nav-link" to="about-ttg" spy={true} smooth={true} duration={500}
                            >   About
                            </Link>  
                            </li>                            
                            <li className="nav-item">
                                <Link className="nav-link" to="how-to-play" spy={true} smooth={true} duration={500}>How To Play
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="contest" spy={true} smooth={true} duration={500}>Contest
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="media" spy={true} smooth={true} duration={500}>Media
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="faq" spy={true} smooth={true} duration={500}
                                >FAQ
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/contact-us" >Contact
                                </a>
                            </li>

                            </ul>
                             
                        </div>
                        <div className="right_menu ">
                            <li className="nav-item">
                            {/* <button data-toggle="modal" data-target="#loginmodal" className="btn" ref={this.button}  id="main_btn">Login/Sign Up</button> */}
                            </li>
                        </div>
                        </div>
                    </nav>


                    <div class="login_bg" >
                  <div class="container">
                     <div class="row ">
                        <div class="col-md-5 mr-auto ml-auto">
                        <div class="bg-white p-md-4 p-2 logi_signup_box">
						<h5 className="login_hadding text-center mb-0">Let's Get Started</h5>
                        {/*  <ul className="nav nav-tabs">
                            <li className="nav-item" style={{width:'100%'}}>
                                <a className="nav-link active" data-toggle="tab" href="#SignUp" id="signuptab">Let's Get Started</a>
                            </li>
                          <li className="nav-item">
                                <a className="nav-link" ref={this.signupTabBtn} data-toggle="tab" href="#SignUp" id="signuptab">Sign Up</a>
                            </li>
                           </ul>*/}
                        <div className="tab-content pt-4">
                            <Register />
                        </div>
                        </div>
                        </div>
                        </div>
                       
                     </div>
               </div>
              
            
					
					
					
                    <Footer />
{/*  */}
                </div>
            </div>
            <ForgetPassword />
            <Toaster />
</>
        );
    }
}

Home.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Home);
