import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import ForgetPassword from "../../components/auth/ForgetPassword";
import Footer from "../../components/pages/Footer";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Scroll from 'react-scroll';
const baseUrl = myConstList.baseUrl;
const Link = Scroll.Link;
class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName:'',
           errors: {}
        };
     
    }

    componentDidMount() {               
        if (this.props.auth.isAuthenticated) {
            this.setState({userName:this.props.auth.user.name});
        } 
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }        
    }

render() {
        const { errors } = this.state;
        return (
            <> 
			<MetaTags>
                <title>PR & Media | Trade The Games</title>
                <meta name="description" content="Want to know information about us? Read our publish article and visit our media partner website." />
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://www.tradethegames.com/media" />	
	<meta name="facebook-domain-verification" content="ih4k1e3ay891tcx2z9uelbtn60zdsr"/>
	<meta property="og:title" content="PR & Media | Trade The Games"/>
	<meta property="og:site_name" content="Trade the Games"/>
    <meta property="og:url" content="https://www.tradethegames.com/"/>
	<meta property="og:description" content="Want to know information about us? Read our publish article and visit our media partner website."/>
	<meta property="og:type" content="article"/>
	<meta property="og:image" content="https://www.tradethegames.com/img/ttg-logo.jpg"/>
    <meta name="twitter:card" content="summary_large_image"/>
	<meta name="twitter:site" content="@tradethegames"/>
	<meta name="twitter:title" content="PR & Media | Trade The Games"/>
	<meta name="twitter:description" content="Want to know information about us? Read our publish article and visit our media partner website."/>
    <meta name="twitter:image" content=" https://www.tradethegames.com/img/ttg-logo.jpg"/>
            </MetaTags> 
			
            <div className="">
                <div >
                    <nav className="navbar navbar-expand-lg home_nav">
                        <div className="container ">
                        <a className="navbar-brand" href="/">
                            <img src="./img/logo.png" alt="header-Logo" className="logo logo1"/>
							<img src="./img/logo_mobile.svg" alt="header-Logo" className="logo logo2"/>
						</a>

                       
                     <div className="right_menu ">
                            <li className="nav-item">
                                {this.state.userName !=='' &&
                                <a href = "/profile-user" className="man_color fw-500" >{this.state.userName}
                                </a>
                                
                                }
                                {/* {this.state.userName ==='' &&
                                <a className="btn" ref={this.button}  data-toggle="modal" data-target="#loginmodal" id="main_btn">Login/Sign Up</a>
                                } */}

                            
                            </li>
                        </div>
                        </div>
                    </nav>
                    <div className="modal fade" id="loginmodal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body login_modal"> <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>

                                <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#SignIn" id="signintab">Sign In</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#SignUp" id="signuptab">Sign Up</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-4">
                                        <Login />
                                        <Register />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner_banner text-center" >
					    <img src="./img/about_banner.jpg" alt="about_banner"/>
						 <div>
                        <h1>Media</h1>
                       </div>
                    </div>
                    <div className="p60 how" >
             <div class="container">      
<div className="row">
 <div className="col-md-4 col-sm-6 mb-3">
  <div  className="media_box">
    <div className="media_box_img">
     <img src="img/media/6.jpg" alt="lp" className="img-fluid"/>
    </div>
	
  </div>
 </div>
   <div className="col-md-4 col-sm-6 mb-3">
  <div  className="media_box">
    <div className="media_box_img">
     <img src="img/media/3.jpg" alt="lp" className="img-fluid"/>
    </div>
	
  </div>
 </div>
 <div className="col-md-4 col-sm-6 mb-3">
  <div className="media_box">
    <div className="media_box_img">
     <img src="img/media/4.jpg" alt="lp" className="img-fluid"/>
    </div>
	
  </div>
 </div>
 <div className="col-md-4 col-sm-6 mb-3">
  <div  className="media_box">
    <div className="media_box_img">
     <img src="img/media/5.jpg" alt="lp" className="img-fluid"/>
    </div>
	
  </div>
 </div> 

  <div className="col-md-4 col-sm-6 mb-3">
  <div  className="media_box">
    <div className="media_box_img">
     <img src="img/media/2.jpg" alt="lp" className="img-fluid"/>
    </div>
	
  </div>
 </div>
  <div className="col-md-4 col-sm-6 mb-3">
  <div  className="media_box">
    <div className="media_box_img">
     <img src="img/media/1.jpg" alt="lp" className="img-fluid"/>
    </div>
	
 </div></div>

 

 </div>

</div>
				   </div>
                   
                    <Footer />

                    
                </div>
            </div>
            <ForgetPassword />
  
</>
        );
    }
}

Media.propTypes = {
    errors: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps
)(Media);
