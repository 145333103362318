import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING,
    USER_ADD
} from "./types";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;

export const registerUser = (userData, history) => dispatch => {
    axios
        .post(baseUrl+"api/user-registor", userData)
        .then(res => {
            // dispatch({
            //     type: "RESISTER",
            //     payload: res,
            // })

            if(res.data.status === true && res.data.data === undefined){
                const { token } = res.data;
                localStorage.setItem("jwtToken", token);
                setAuthToken(token);
                const decoded = jwt_decode(token);
                dispatch(setCurrentUser(decoded));                
            }
            else if(res.data.data != undefined &&  res.data.data.encrypted_otp!==undefined){
                dispatch({
                    type: "RESISTER",
                    payload: res
                })                 
            }
            else if(res.data.status===false){
                dispatch({
                    type: "RESISTER",
                    payload: res
                })
            }
            else{ 
                
                dispatch({
                    type: GET_ERRORS,
                    payload: res.data
                })
            }
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const registerEmailUser = (userData, history) => dispatch => {
    console.log("data===email");
    axios
        .post(baseUrl+"api/user-registor-email", userData)
        .then(res => {
            // dispatch({
            //     type: "RESISTER",
            //     payload: res,
            // })

            if(res.data.status === true && res.data.data === undefined){
                const { token } = res.data;
                localStorage.setItem("jwtToken", token);
                setAuthToken(token);
                const decoded = jwt_decode(token);
                dispatch(setCurrentUser(decoded));                
            }
            else if(res.data.data != undefined &&  res.data.data.encrypted_otp!==undefined){
                dispatch({
                    type: "RESISTER",
                    payload: res
                })                 
            }
            else if(res.data.status===false){
                dispatch({
                    type: "RESISTER",
                    payload: res
                })
            }
            else{ 
                
                dispatch({
                    type: GET_ERRORS,
                    payload: res.data
                })
            }
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const subscriberUser = (userData, history) => dispatch => {
    axios
        .post(baseUrl+"api/user-subscriber", userData)
        .then(res => {
            if(res.data.status === true){    
                dispatch({
                    type: "RESISTER",
                    payload: res
                })            
            }else{ 
                dispatch({
                    type: GET_ERRORS,
                    payload: res.data
                })
            }
        })        
};
export const resetPassword = userData => dispatch => {
    
    axios
        .post(baseUrl+"api/reset-password", userData)
        .then(res => 
            dispatch({
                type: "FORGET",
                payload: res,
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const forgetPassword = (userData, history) => dispatch => {
    axios
        .post(baseUrl+"api/forget-Password", userData)
        .then(res => 
            dispatch({
                type: 'FORGET',
                payload: res,
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const loginUser = (userData, history)=> dispatch => {
    
    axios
        .post(baseUrl+"api/login",  userData)
        .then(res => {
            if(res.data.status === true && res.data.data === undefined){
                const { token } = res.data;
                localStorage.setItem("jwtToken", token);
                setAuthToken(token);
                const decoded = jwt_decode(token);
                dispatch(setCurrentUser(decoded));                
            }
            else if(res.data.data != undefined &&  res.data.data.encrypted_otp!==undefined){
                dispatch({
                    type: "RESISTER",
                    payload: res
                })                 
            }
            else{ 
                
                dispatch({
                    type: GET_ERRORS,
                    payload: res.data
                })
            }
        })
};
export const TempUserLogin = (userData, history)=> dispatch => {
    
    axios
        .post(baseUrl+"api/temp-user-login",  userData)
        .then(res => {
            if(res.data.status === true && res.data.data === undefined){
                const { token } = res.data;
                localStorage.setItem("jwtToken", token);
                setAuthToken(token);
                const decoded = jwt_decode(token);
                dispatch(setCurrentUser(decoded));                
            }
            else if(res.data.data != undefined &&  res.data.data.encrypted_otp!==undefined){    
                dispatch({
                    type: "RESISTER",
                    payload: res
                })                 
            }
            else{ 
                dispatch({
                    type: GET_ERRORS,
                    payload: res.data
                })
            }
        })
};

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

export const logoutUser = () => dispatch => {
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};
