import axios from "axios";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
export const addContest = (userData, history) => dispatch => {
    axios
        .post(baseUrl+"api/contest-add", userData)
        .then(res =>
            dispatch({
                type: 'CONTEST_ADD',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};


export const updateContest = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/contest-update", userData)
        .then(res =>
            dispatch({
                type: 'CONTEST_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
