import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from 'jquery';
import BuyOrder from "./BuyOrder";
import SellOrder from "./SellOrder";
import {toast,Toaster } from 'react-hot-toast';
import axios from "axios";
import * as myConstList from '../../../BaseUrl';
import jwt_decode from "jwt-decode";
import setAuthToken from "../../../utils/setAuthToken";
import { setCurrentUser} from "../../../../src/actions/authActions";
const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }

const baseUrl = myConstList.baseUrl;
class OrderSection extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            errors: {},
            usdt:this.props.virtualFund,
            CurrencyValue:this.props.getCurrencyValueData,
            currentprice:this.props.currentprice,
            message:""
        };
}
    componentWillReceiveProps(nextProps) {
        if (nextProps.getCurrencyValueData) {
            if(nextProps.getCurrencyValueData){
                this.setState({
                    CurrencyValue: nextProps.getCurrencyValueData                
                });
            }
        }else{
            this.setState({
                CurrencyValue: 0                
            });
        }

        if (nextProps.record) {
            this.setState({
                records: nextProps.record,
                currentprice: nextProps.currentprice,                
            });
        }
        if (nextProps.currentprice) {
            this.setState({
     
                currentprice: nextProps.currentprice,
            });
        }
        if (nextProps.virtualFund !=undefined) {
            this.setState({     
                usdt: nextProps.virtualFund,
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if(nextProps.errors.message){
            toast.error( nextProps.errors.message, toastStyles);
            nextProps.errors.message ="";
        }
        if (nextProps.orderdata !== undefined
            && nextProps.orderdata.order !== undefined
            && nextProps.orderdata.order.data !== undefined
            && nextProps.orderdata.order.data.message !== undefined
            && nextProps.orderdata.order.data.status) {
            
            this.setState({
               // message:nextProps.orderdata.order.data.message,
                btotal:"",
                bamount:"",
                stopLoss:""
            });
        }      
    }
    componentDidMount() {
        /*setInterval(() => {
            this.getData2();
        }, 4000);*/
        //this.getCurrencyValue();
    }

    // getCurrencyValue=()=>{
    //     const { user } = this.props.auth;
    //     axios
    //             .post(baseUrl+"api/getCurrencyValue", {user_id: user.id, contestId: this.props.match.params.id, symbol: this.props.match.params.symbol },config)
    //             .then(res => {
    //                 console.log("aaaaa",res);
    //                 if (res.data[0]) {
    //                     this.setState({ CurrencyValue: res.data[0].totalcurrency })
    //                     var classThis = this;
    //                     setTimeout(function() { classThis.getCurrencyValue(); },1000);
    //                 }
    //             })
    //             .catch()
    // }
getData2(){
    /*const { user } = this.props.auth;
    axios
            .post(baseUrl+"api/getCurrencyValue", {user_id: user.id, contestId: this.props.match.params.id, symbol: this.props.match.params.symbol },config)
            .then(res => {
                if (res.data[0]) {
                    this.setState({ CurrencyValue: res.data[0].totalcurrency })
                }
            })
            .catch()*/
            // axios
            // .post(baseUrl+"api/participateById", { contest_id: this.props.match.params.id, user_id: user.id })
            // .then(res => {
            //     this.setState({ usdt: res.data.virtualFund })
            // })
            // .catch()
}

    render() {
        if(this.state.currentprice){
           var cprice =this.state.currentprice;
        }
        const { errors } = this.state;
        const { user } = this.props.auth;

        return (
            <div className="crypt-market-status mt-2">
            <div className="dashbord_hadding">
            Limit  <span style={{marginLeft:"20Px"}} className="text-info text-center">{this.state.message}</span>
			</div>

                <div className="crypt-boxed-area">
                    <div className="row no-gutters">
                        <BuyOrder usdt={this.state.usdt} currentprice={cprice} />
                        <SellOrder CurrencyValue={this.state.CurrencyValue} currentprice={cprice} />
                    </div>
                </div>
                <Toaster />
            </div>
        )
    }
}

OrderSection.propTypes = {
    auth: PropTypes.object.isRequired,
    orderdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    orderdata: state.orderdata,
    errors: state.errors
});
export default connect(
    mapStateToProps,

)(withRouter(OrderSection));
