import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {Link} from "react-router-dom";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons/faUserAlt";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../../src/utils/setAuthToken";
import { setCurrentUser} from "../../../src/actions/authActions";
import axios from "axios";
import Moment from 'moment';
import Countdown from 'react-countdown';
import * as myConstList from '../../BaseUrl';
import MetaTags from 'react-meta-tags';
import socketIOClient from "socket.io-client";
import ReactGA from 'react-ga';


const jwtToken = localStorage.getItem("jwtToken");
const baseUrl = myConstList.baseUrl;
const baseUrlSocket= myConstList.baseUrlSocket;
const socket = socketIOClient(baseUrlSocket);

const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
     liveContestStartRecords:'',
     liveContestEndRecords:'',
     balance:0,
     upcomingTotal:0,
     pastTotal:0,
     liveTotal:0,
     winningamount:0,
     kycCompleteStatus:'',
     loginHostory:[],
     errors: {},
     endDays: 0,
     endHours: 0,
     endMinutes: 0,
     endSeconds: 0,
     startDays: 0,
     startHours: 0,
     startMinutes: 0,
     startSeconds: 0,
     banner:[],
     timerFlagLive:false,
     timerFlagUpcoming:false,
     intervalNameLocalLive:"",
     intervalNameLocalUpcomming:"",
     isPlaying: false,
     liveIntervalName: '',
     upcomingIntervalName: '',
     serverLiveTime: '',
     serverUpcomingTime: '',
     };
     
    //  clearInterval(intervalIds);
    // window.location.reload();
}


componentDidMount() {    
    ReactGA.initialize('UA-218461585-1');
	ReactGA.pageview('/dashboard');
    if (this.props.auth.isAuthenticated) {
        this.props.history.push("/dashboard");        
    }

    
    // console.log("socket==",socket);
    this.setState({ socketCollect: socket });
    var collectThis = this;

    // client-side
    var data = {'token':jwtToken};
    socket.emit("totalContestData",data); 
    socket.emit('userConnected',{'token':jwtToken,'userId':this.props.auth.user.id});  
    socket.emit('loginHistoryList',{'token':jwtToken,'userId':this.props.auth.user.id});  
    socket.emit('upcomingRecordDashboard',data);
    socket.emit('liveRecordDashboard',data);  
    socket.emit('getserverTime');  
    socket.off("timeOutLiveContest")
    socket.on("timeOutLiveContest", function (apiRespData) {
        // console.log("timeOutContest===");
        socket.emit("totalContestData",data); 
        socket.emit("liveRecordDashboard",data); 
        socket.emit("upcomingRecordDashboard",data); 
        if(collectThis.state.liveIntervalName !=''){
            clearInterval(collectThis.state.liveIntervalName);
        }
        // collectThis.liveContestDataEnd();
    })
    socket.off("timeOutupcomingContest")
    socket.on("timeOutupcomingContest", function (apiRespData) {
        // console.log("timeOutContest===");
        socket.emit("totalContestData",data); 
        socket.emit("liveRecordDashboard",data); 
        socket.emit("upcomingRecordDashboard",data); 
        if(collectThis.state.upcomingIntervalName !=''){
            clearInterval(collectThis.state.upcomingIntervalName);
        }
        // collectThis.UpcomingContestDataEnd();
    })
    socket.off(this.props.auth.user.id)
    socket.on(this.props.auth.user.id, function (apiRespData) {
        // console.log("apiRespData==",apiRespData);
        collectThis.setState({  records: apiRespData.data, 
        balance:apiRespData.data.balance,
        winningamount:apiRespData.data.winningamount,
        kycCompleteStatus:apiRespData.data.kycCompleteStatus});
      })
      socket.off('dashboardTotalContest')
    socket.on("dashboardTotalContest", function (apiRespData) {  
        if(collectThis.upcomingTotal != apiRespData.data.upcomingtotal 
        ||
        collectThis.liveTotal != apiRespData.data.liveTotal
        ){
            var interval_id = window.setInterval(()=>{}, 99999);
            for (var i = 0; i < interval_id; i++)
                window.clearInterval(i); 
        }
        // window.location.reload();
        collectThis.setState({ upcomingTotal: apiRespData.data.upcomingtotal,pastTotal: apiRespData.data.pastTotal,liveTotal: apiRespData.data.liveTotal});
        // clearInterval();
    }) 
    socket.off('loginHistoryData')
    socket.on("loginHistoryData", function (apiRespData) {
        collectThis.setState({ loginHostory: apiRespData.data});
    })
    socket.off('upcomingRecordData')
    socket.on("upcomingRecordData", function (apiRespData) {
        if(apiRespData.data.length > 0) {
            collectThis.setState({ liveContestStartRecords: []});
            collectThis.setState({ liveContestStartRecords: apiRespData.data[0] });
            // clearInterval(intervalId2);
            var startDateTime = apiRespData.data[0].startDate+" "+apiRespData.data[0].startTime;
            var contestName = apiRespData.data[0].contestName;
            // var interval_id = window.setInterval(()=>{}, 99999);
            //  for (var i = 0; i < interval_id; i++)
            //      window.clearInterval(i);
            if(collectThis.state.upcomingIntervalName !=''){
                clearInterval(collectThis.state.upcomingIntervalName);
                // console.log("clearInterval=====upcomingRecordData",collectThis.state.upcomingIntervalName);
            }
            let intervalname= "upcommingInterval"+contestName; 
            collectThis.setState({ upcomingIntervalName: intervalname});
            socket.emit('getserverTimeUpcoming'); 
            socket.off("serverTimeUpcoming")
            socket.on("serverTimeUpcoming", function (apiRespData) {
                collectThis.UpcomingContestDataEnd(startDateTime,intervalname,apiRespData);
            })
            
        }else{
            collectThis.setState({ liveContestStartRecords: []});
        }
    })
    socket.off('liveRecordDashboardData')
    socket.on("liveRecordDashboardData", function (apiRespData) {
        if(apiRespData.data.length > 0) {
            collectThis.setState({ liveContestEndRecords: []});
            collectThis.setState({ liveContestEndRecords: apiRespData.data[0] });
           
            var endDateTime = apiRespData.data[0].endDate+" "+apiRespData.data[0].endTime;
            var contestName = apiRespData.data[0].contestName;
            if(collectThis.state.liveIntervalName !=''){
                // console.log("liveClear===",collectThis.state.liveIntervalName);
                clearInterval(collectThis.state.liveIntervalName);
            }
            let intervalname= "liveInterval"+contestName;
            collectThis.setState({ liveIntervalName: intervalname});
            collectThis.setState({isPlaying: true}); 
            socket.emit('getserverTimeLive'); 
            socket.on("serverTimeLive", function (apiRespData) {
                collectThis.liveContestDataEnd(endDateTime,intervalname,apiRespData);
            })
             
             
            // var endDateTime = apiRespData.data[0].endDate+" "+apiRespData.data[0].endTime;
            // console.log("endDateTime==",endDateTime);
            // console.log("clearInterval=====liveRecordDashboardData");
        }else{
            collectThis.setState({ liveContestEndRecords: []});
        }
    })
    socket.off('serverTime')
    socket.on("serverTime", function (apiRespData) {
        // console.log("serverTimeapiRespData===",apiRespData);
        collectThis.setState({ serverTime: apiRespData });
    })
    
    axios
    .post(baseUrl+"api/getBunner",{},config)
    .then(res => {       
        if(res.data.data.length>0){
            this.setState({banner: res.data.data});
        }                
    })
    .catch()
};

liveContestDataEnd = (endDateTime,myInterval,serverTime) => {
     
    //let intervalNameLocal=null;
    // var interval_id = window.setInterval(()=>{}, 99999);
    // console.log("serverTime=====",serverTime);
   // if(this.state.liveContestEndRecords.endDate !==undefined && this.state.serverTime !==undefined){
  
        

        
        var currentDateTime = Moment(new Date().getTime()).format(serverTime);        
        // console.log("dsdaddsdsadsadasdasddadad")
        const endDateTime1 = endDateTime.replace("-", "/");
        const endDateTime2 = endDateTime1.replace("-", "/");
        var endsendDateVal = Moment(Date.parse(endDateTime2) / 1000);
    
        const currentDateTime1 = currentDateTime.replace("-", "/");
        const currentDateTime2 = currentDateTime1.replace("-", "/");
        var currentDate = Moment(Date.parse(currentDateTime2) / 1000);
      
         
        // if(!intervalId){
            myInterval = setInterval(async () => {
                currentDate = currentDate + 1;
                // console.log("currentDate=====",currentDate)
                this.secondsToHms(currentDate, endsendDateVal);
            }, 1000);
             
        // }
 
    //   console.log("endSeconds===",this.state.endSeconds)
        if(this.state.endSeconds < 0 ){ 
            // console.log("old interval  clearInterval=====",currentDate)
            clearInterval(myInterval);
        } 
   // }
   };

UpcomingContestDataEnd = (startDateTime,intervalname,serverTime) => {
        // if(this.state.liveContestStartRecords.startDate !==undefined && this.state.serverTime !==undefined){
            // console.log("livecontestData====");
           
           
            // if(this.state.intervalNameLocalUpcomming == ""){
            //     console.log("clearIntervalclearIntervalclearInterval=====",intervalname);
            //     window.clearInterval(intervalname);
            //      var interval_id = window.setInterval(()=>{}, 99999);
            //      for (var i = 0; i < interval_id; i++)
            //          window.clearInterval(i);
            //  }else{ 
            //      this.setState({intervalNameLocalUpcomming:"1"});   
            //  }

            
            
            // console.log("currentDateTime1==",serverTime);
            var currentDateTime = Moment(new Date().getTime()).format(serverTime);        
            // console.log("dsdaddsdsadsadasdasddadad")
            const startDateTime1 = startDateTime.replace("-", "/");
            const startDateTime2 = startDateTime1.replace("-", "/");
            var endsendDateVal = Moment(Date.parse(startDateTime2) / 1000);

            const currentDateTime1 = currentDateTime.replace("-", "/");
            const currentDateTime2 = currentDateTime1.replace("-", "/");
            var currentDate = Moment(Date.parse(currentDateTime2) / 1000);

              intervalname = setInterval(async () => {
                currentDate = currentDate + 1;
                this.secondsToHms2(currentDate, endsendDateVal)
            }, 1000);

            if(this.state.endSeconds < 0 ){
                //    window.location.reload();
                    // intervalId =null;
                    // console.log("old interval  clearInterval=====",currentDate)
                    clearInterval(intervalname);
                }
            
            
            // return (() => {
            //     clearInterval(intervalId2);
            // })

       // }
    }

// const secondsToHms =async (currentDateTime, endsendDate) => {
    secondsToHms=(currentDateTime, endsendDate)=>{
    const countdown = Moment(endsendDate - currentDateTime);
    // console.log("countdown",countdown)
    var duration = Moment.duration(countdown * 1000, 'milliseconds');
    var interval = 1000;
    duration = Moment.duration(duration - interval, 'milliseconds');

    const endDays = duration.days();
    const endHours = duration.hours();
    const endMinutes = duration.minutes();
    const endSeconds = duration.seconds();

    // console.log("timer==",endDays,endHours,endMinutes,endSeconds);
    
    if(endSeconds<0){
        //    window.location.reload();
        clearInterval(this.state.liveIntervalName);
    }else{
        this.setState({ endDays:endDays, endHours:endHours, endMinutes:endMinutes, endSeconds:endSeconds });
    }    
  };
  secondsToHms2=(currentDateTime, endsendDate)=>{
    // console.log("currentDateTime==",currentDateTime);
    // console.log("endsendDate==",endsendDate);
    const countdown = Moment(endsendDate - currentDateTime);
    // console.log("countdown",countdown)
    var duration = Moment.duration(countdown * 1000, 'milliseconds');
    var interval = 1000;
    duration = Moment.duration(duration - interval, 'milliseconds');

    const startDays = duration.days();
    const startHours = duration.hours();
    const startMinutes = duration.minutes();
    const startSeconds = duration.seconds();
    // console.log("upcomingtimer==",startDays,startHours,startMinutes,startSeconds);
    
    if(startSeconds<0){
        //    window.location.reload();
        clearInterval(this.state.upcomingIntervalName);
    }else{
        this.setState({ startDays, startHours, startMinutes, startSeconds });
    }
   };
showLiBannerHtml = () => {
    const html = [];
    if(this.state.banner.length>0){
        this.state.banner.map(function (value, i) {
            html.push(
                <> <li key={i} data-target="#myCarousel" data-slide-to={i} className={i===0?"active":""}></li>
                </>
            );
        })
    }        
    return html;
}
showDivBannerHtml = () => {
    const html = [];
    if(this.state.banner.length>0){
        this.state.banner.map(function (value, i) {
            html.push(
                <>  <div className={i===0?"carousel-item active":"carousel-item"}>
                        <img src={baseUrl+"/bunner/" + value.file} alt="TTG Banner" className="img-fluid"/>
                    </div>
                </>
            );
        })
    }        
    return html;
}
componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
        this.setState({
            errors: nextProps.errors
        });
    }
}
componentWillUnmount() {
    socket.off(this.props.auth.user.id);
    socket.off("dashboardTotalContest");
    socket.off("loginHistoryData");
    socket.off("liveRecordDashboardData");
    socket.off("serverTime");
    socket.close()
}
showTimerCounterEndHtml = () => {
    if(this.state.liveContestEndRecords !==null && this.state.liveContestEndRecords._id !='' && this.state.liveContestEndRecords._id !=undefined){
      if(!isNaN(this.state.endDays)){
        return(  
          <>
		  <MetaTags>
                <title>Dashboard | Trade The Games</title>
            </MetaTags> 
		    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
		        <Link className="d_box  bg_red" to={"/trading-contest/"+this.state.liveContestEndRecords._id}>
				    <span className="ttt_box_icon ttt_box_icon1">
						<i className="las la-trophy"></i>
					</span>
					<div className="d_hadding">
                       {this.state.liveContestEndRecords.contestName}
                        <br/>
						Ends In
                        <div >
                            { this.state.endSeconds>=0 && 
                                <span className="timerdiv">
                                    <span className="dhms"> <span className="timercounter">{this.state.endDays}</span>D</span>
                                    <span>:</span>
                                     <span className="dhms"><span className="timercounter">{this.state.endHours}</span> H</span>
                                    <span>:</span>
                                    <span className="dhms"><span className="timercounter">{this.state.endMinutes}</span>M</span>
                                    <span>:</span>
                                   <span className="dhms"> <span className="timercounter">{this.state.endSeconds}</span>S</span>
                                    <span></span> 
                                </span>
                            }
                        </div>                    
                    </div> 
				</Link>
            </div>
          </>
        );
      }
    }
  }

  showTimerCounterStartHtml = () => {
    if(this.state.liveContestStartRecords!=null && this.state.liveContestStartRecords._id !='' && this.state.liveContestStartRecords._id !=undefined){
        return(  
          <>	
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">	  
                <Link className="d_box  bg_green" to={"/trading-contest/"+this.state.liveContestStartRecords._id}>
                    <span className="ttt_box_icon ttt_box_icon1">
                        <i className="las la-trophy"></i>
                    </span>
                    <div className="d_hadding"> {this.state.liveContestStartRecords.contestName}
                        <br/>
						Starts In
                        <div >
                            { this.state.startSeconds>0 && 
                                <span className="timerdiv">
                                    <span className="dhms"> <span className="timercounter">{this.state.startDays}</span>D</span>
                                    <span>:</span>
                                     <span className="dhms"><span className="timercounter">{this.state.startHours}</span> H</span>
                                    <span>:</span>
                                    <span className="dhms"><span className="timercounter">{this.state.startMinutes}</span>M</span>
                                    <span>:</span>
                                   <span className="dhms"> <span className="timercounter">{this.state.startSeconds}</span>S</span>
                                    <span></span> 
                                </span>
                            }
                        </div>
                        {/* { this.state.startSeconds<0 && 
                            "Started"  
                        }                         */}
                    </div>                    
                </Link>
            </div>
          </>
        );
    }
  }
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };
    showLoginHistoryHtml = () => {
        const html = []
        var classThis=this;
        if(this.state.loginHostory.length>0){
            this.state.loginHostory.map(function (value, i) {
         
                html.push(
                    <> <tr key={i}>
                     <td>{i+1}</td>
                    <td>{value.ip}</td>
                    <td>{value.cityName}</td>
                    <td>{value.countryName}</td>
                    <td>{value.device}</td>
                    <td>{value.browser}</td>
                    <td>{value.os}</td>
                    <td>{Moment(value.date).format('lll')}</td>
                </tr>
                    </>
                );
            
           

        })
        }        
        return html;
    }
    render() {
        //const { user } = this.props.auth;
        return (
            <>
                <Sidebar />
                <Navbar urlPath={true} symbol={false}/>
                <div className="container container2">
                    <div className="in_page">
                        <div className="row ">
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                <span   className="d_box ">
								<span className="ttt_box_icon ttt_box_icon1">
                                        <i className="las la-rupee-sign"></i>
                                    </span>
                                    <Link to="/add-money" className="d_hadding">
                                    Cash Wallet (Add Cash)
                                        <div className="d_value">
                                            
                                            {this.state.balance?this.state.balance.toFixed(2):''}
                                        </div>                                   
                                    </Link>
                                    
                                    
                                    
                                </span>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                <Link to="/contest/live" className="d_box ">
								<span className="ttt_box_icon ttt_box_icon1">
                                        <i className="las la-tv"></i>
                                    </span>
                                    <div className="d_hadding">
                                        Live Contest
                                        <div className="d_value">
                                            {this.state.liveTotal}
                                        </div>
                                    </div>
                                    
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                <Link to="/contest/past" className="d_box ">
								    <span className="ttt_box_icon ttt_box_icon1">
                                        <i className="las la-history"></i>
                                    </span>
                                    <div className="d_hadding">
                                        Past Contest
                                        <div className="d_value">
                                            {this.state.pastTotal}
                                        </div>
                                    </div>

                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                <Link to="/contest/upcoming" className="d_box " >
								 <span className="ttt_box_icon ttt_box_icon1">
                                        <i className="las la-calendar"></i>
                                    </span>
                                    <div className="d_hadding" >
                                        Upcoming Contest
                                        <div className="d_value" >
                                            {this.state.upcomingTotal}
                                        </div>
                                    </div>
                                   
                                </Link>
                            </div>
                            
                               {this.showTimerCounterEndHtml()} 
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                <Link className="d_box" to="/wallet">
								   <span className="ttt_box_icon ttt_box_icon1">
                                        <i className="las la-medal"></i>
                                    </span>
                                    <div className="d_hadding">
                                        You Won
                                        <div className="d_value">
                                            {this.state.winningamount}
                                        </div>
                                    </div>
                                    
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
                                <Link className="d_box " to="/kyc">
								<span className="ttt_box_icon ttt_box_icon1">
                                        <i className="las la-user-check"></i>
                                    </span>
                                    <div className="d_hadding">
									
                                        KYC Status
                                        {this.state.kycCompleteStatus ==='pending' &&
                                            <div className="d_value text-danger">
                                            Pending
                                        </div>
                                        }
                                        {this.state.kycCompleteStatus ==='complete' &&
                                            <div className="d_value text-success">
                                            Completed
                                        </div>
                                        }
                                        
                                    </div>
                                    
                                </Link>
                            </div>

                                {this.showTimerCounterStartHtml()} 
                        </div>
                        <div className="crypt-market-status2 mt-4 " >
                            <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                <ul className="carousel-indicators">
                                    {this.showLiBannerHtml()} 
                                </ul>
                                <div className="carousel-inner">
                                    {this.showDivBannerHtml()} 
                                </div>
                            </div>
                        </div>
		  
		  
                        <div className="crypt-market-status2 mt-4 p-2 p-md-3 ">
                            <h5 className=" pb-md-3 pb-2 mb-0">Login History</h5>
                            <div className="table-responsive ">
                            <div className="scrppl_th_dashbord">
                                <table className="table table  border_none table-striped mb-0">
                                    <thead className="thead">
                                        <tr>
                                            <th>#</th>
                                            <th>IP Address</th>
                                            <th>City</th>
                                            <th>Country</th>
                                            <th>Device</th>
                                            <th>Browser</th>
                                            <th>Os</th>
                                            <th>Date and Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {this.showLoginHistoryHtml()}
                                     
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
</>
        );
    }
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Dashboard);
