import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";

const UpDownFree = () => {
  console.log("hiiii");
  return (
    <div>
      <Navbar urlPath={true} symbol={false} />
      <Sidebar />
      <div className="in_page up_down_man_game">
       
        <div className="fix_box_updown text-center"> 
        <div className="container">
        <div className="row">
          <div className="col-md-2 col-6">
            <div className="crypt-market-status2 p-3">
              <h6 className="small_text">Game Start</h6>
              <h5 className="m-0">00 : 34</h5>
             </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="crypt-market-status2 p-3 h-100">
              <h6 className="small_text">Selected Coins</h6>
              <h6 className="value_text m-0">0/11</h6>
             </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="crypt-market-status2 p-3 h-100">
              <h6 className="small_text">Total Coins</h6>
              <h6 className="value_text m-0">0</h6>
             </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="crypt-market-status2 p-3 h-100">
              <h6 className="small_text">Bid Before</h6>
              <h6 className="value_text m-0">05:58 PM</h6>
             </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="crypt-market-status2 p-3 h-100">
              <h6 className="small_text">Game Starts</h6>
              <h6 className="value_text m-0">06:00 PM</h6>
             </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="crypt-market-status2 p-3 h-100">
              <h6 className="small_text">Game Ends</h6>
              <h6 className="value_text m-0">06:05 PM</h6>
             </div>
          </div>
          </div>

          <div className="">
          <div className="row align-items-center">
          <div className="col-sm-2">
          <h5 className="m-0">Time Slot</h5>
          </div>
          <div className="col-2 ">
             <button type="button" className="slot_btn">
               18-15
             </button>
          </div>
          <div className="col-2 ">
             <button type="button" className="slot_btn">
               18-20
             </button>
          </div>
          <div className="col-2 ">
             <button type="button" className="slot_btn">
               18-20
             </button>
          </div>
          <div className="col-2 ">
             <button type="button" className="slot_btn">
               18-20
             </button>
          </div>
          <div className="col-2 ">
             <button type="button" className="slot_btn">
               18-30
             </button>
          </div>
          </div>
          
         
          </div>

          </div>
          </div>
          <div className="container">
        <div className="row">
      <div className="col-xl-4 col-lg-6 col-md-6  mt-3 mt-md-4">
        <div className="crypt-market-status2 past_man_box">
          <div className="past_man_box-inner">
            <div className="top_contest text-center">
                <h6>Free Game (Practice/Contst)</h6>
            </div>
            <div className="row">
              <div className="col-6 col-md-6">
                <span>Total Players </span>
              </div>
              <div className="col-6 col-md-6 ">
                <span className="past_value">  <i className="fa fa-user" /> 12740</span>
              </div>
              <div className="col-6 col-md-4 ">
                <span> Prize Pool</span>
              </div>
              <div className="col-6 col-md-8 ">
                <span className="past_value">
                  <i className="fa fa-inr" /> 505.00 EOS
                </span>
              </div>
              <div className="col-6 col-md-4 ">
                <span> Win Ratio</span>
              </div>
              <div className="col-6 col-md-8 ">
                <span className="past_value">
                  1.10x
                </span>
              </div>
              <div className="col-6 col-md-4">
                <span> Ration</span>
              </div>
              <div className="col-6 col-md-8">
                <span className="past_value"> 1.10x</span>
              </div>
            </div>
            <div className="details-outer row">
              <div className="col-6 col-md-6 ">
                <a
                  className="view_dital"
                  href="/trading-contest/65866db3f35e3e1a90b6b0db"
                >
                  <span>
                    <i className="las la-trophy" /> View Details
                  </span>
                </a>
              </div>
              <div className="col-6 col-md-6 tr">
                <button
                  type="button"
                  className="btn btn_man p_button"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Play Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UpDownFree;
