const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    pro: {},
    proimage: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case 'PRO_ADD':
            return {
                isAuthenticated: !isEmpty(action.payload),
                pro: action.payload
            };
        case 'PRO_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                pro: action.payload,
            };
        case 'DELETE_ACCOUNT':
            return {
                isAuthenticated: !isEmpty(action.payload),
                pro: action.payload,
            };
        case 'PRO_IMAGE_UPDATE':
                return {
                    isAuthenticated: !isEmpty(action.payload),
                    proimage: action.payload,
                };
        case 'SET_CURRENT_PRO':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                pro: action.payload
            };
         
        case 'PRO_LOADING':
            return {
                ...state,
                loading: true
            };
      
        default:
            return state;
    }

}
