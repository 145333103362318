import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requery } from "../../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import {toast} from 'react-hot-toast';
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";
import axios from "axios";
import * as myConstList from '../../../BaseUrl';
import moment from 'moment';
import Slider from "react-slick";
import { interMediate } from '../../../actions/kycUpdate';
import {Modal,Button } from 'react-bootstrap';
import LiveTimerContest from '../contest/LiveTimerContest';

const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
const baseUrl = myConstList.baseUrl;
class LiveContest extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            id: this.props.id,
            description: '',
            message: '',
            status: "",
            username: user.name,
            showResults: false,
            showTeaxtArea: true,
            entryFee: 0,
            checked: false,
            bonusper: 0,
            bonus: 0,
            totalde: 0,
            errors: {},
			days:[],
			hours:[],
			minutes:[],
			seconds:[],
            show:false,
            cashWalletChecked:false,
            winningWalletChecked:false,
            rewardWalletChecked:false,
            cashWalletDeduct:0,
            winningWalletDeduct:0,
            rewardWalletDeduct:0,
            participateBtnDisable:false,
            intervalArr :[]
        };
    }
    componentDidMount() {
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
                this.setState({
                    records: nextProps.record,
                    bonus:nextProps.bonus,
                    cashWalletBalance:nextProps.cashWalletBalance,
                    winningWalletBalance:nextProps.winningWalletBalance, 
                });
                console.log("nextProps.record",nextProps.record);
                // console.log("nextProps.record",nextProps.record[0].numOfParticipation);
                console.log("intervalLength=",this.state.intervalArr.length);
             for (var i = 0; i <this.state.intervalArr.length; i++){
                 let intName = this.state.intervalArr[i];
                clearInterval(intName);
                console.log("checkInterval==",intName);
                console.log("this.state.intervalArr[i]==",this.state.intervalArr[i]);
             }
                console.log("intervalArr===",this.state.intervalArr[0]);
                 
            nextProps.record.map((value, i)=> {
                let expriryDateTime1 = value.endDate+" "+value.endTime;
                var intervalName = value.contestName+"Live";
                this.showTimerCounterHtml(expriryDateTime1,i,intervalName);
                
            })
            
        //    console.log("dddddddd");
        }
        if (nextProps.serverTime) {
            this.setState({
                serverTime: nextProps.serverTime,
            });
        }
    }

    getNodesToRemoveFromElement = (stringContent) => {
        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };
    
    showTimerCounterHtml = (expriryDateTime,getIndex,intervalName) => {     
        var expriry = new Date(expriryDateTime);
        //var today = new Date();
       // var timeComplete = moment().utcOffset('+05:30').format('HH:mm:ss');
        //var currentDate1 = ("0" + (today.getUTCDate())).slice(-2);
        //var dateformat = today.getUTCFullYear()+'-'+(today.getUTCMonth()+1)+'-'+currentDate1+" "+timeComplete;
        var dateformat = this.state.serverTime;
        var currentDate = new Date(dateformat);
        //console.log("serverTime",this.state.serverTime);
        expriry = Date.parse(expriry);
        expriry = expriry/1000;
        currentDate = Date.parse(currentDate);
        currentDate = currentDate/1000;
        var collectThis = this;
        // console.log("dataTimer1");
        var intervalStart = intervalName;
        intervalStart = setInterval(async () => {
            currentDate = currentDate + 1;
            collectThis.secondsToHms(currentDate, expriry,intervalName,getIndex)
        }, 1000);   
        let intervalTempArr = this.state.intervalArr;
        let intervalStart1 = intervalStart;
        intervalTempArr.push(intervalStart1);	
        this.setState({intervalArr:intervalTempArr});		
      }

      
      secondsToHms=(currentDate, expriry,intervalName,getIndex)=>{
        const countdown = moment(expriry-currentDate);
        var duration = moment.duration(countdown * 1000, 'milliseconds');
        var interval = 1000;
        duration = moment.duration(duration - interval, 'milliseconds');

        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        const symbol1 =this.props.symbol;
        if(seconds<0){
            clearInterval(intervalName);
            window.location.href = "/dashboard";
        }else{
            // console.log("dataTimer3=",intervalName,days,hours,minutes,seconds);
            this.state.days[getIndex] = days;
            this.state.hours[getIndex] = hours;
            this.state.minutes[getIndex] = minutes;
            this.state.seconds[getIndex] = seconds;
            this.setState({aa : "done"});
            //location.reload();
        }
       };
    showChatHtml = () => {
        var month_name = function (dt) {
            var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return mlist[dt.getMonth()];
        };
        const { user } = this.props.auth;
        const html = []
        let classThis = this;
        if(this.state.records.length>0){
            return this.state.records.map( (value, i)=> {
                
                // start date time
                let startDateTime1 = value.startDate+" "+value.startTime;
                var startDateTime = new Date(startDateTime1);
                // end date time
                let expriryDateTime1 = value.endDate+" "+value.endTime;
                var endDateTime = new Date(expriryDateTime1);

                

                var dateObj1 = new Date(value.startDate);
                var day1 = dateObj1.getUTCDate()
                var dateObj2 = new Date(value.endDate);
                var day2 = dateObj2.getUTCDate()
				var serverTime = classThis.state.serverTime;
                var intervalName = value.contestName;
                // setTimeout(function(){ classThis.showTimerCounterHtml(expriryDateTime1,i,intervalName) },1000);
                
                return (<>
                    <div className="col-xl-4 col-lg-6 col-md-6  mt-3 mt-md-4" key={i}>
                    <div className="crypt-market-status2 past_man_box">
                        <div className="past_man_box-inner">						
						     <div className="row top_contest">
								 <div className="col-12 col-md-12 text-center">
                                    <h6 >{value.contestName}</h6>
                                </div>
                                </div>
                           
                           
                            <div className="row mt-3">
							<div className="col-6 col-md-4">
                                    <span >Ends In </span>
                                   
                                </div> 
                                <div className="col-6 col-md-8 ">
								{/*<span className="past_value">{month_name(new Date(value.endDate))}, {day2} ({value.endTime})</span>*/}
                                {/* <LiveTimerContest key ={value._id} endDateTime={value.endDate+" "+value.endTime} startDateTime={value.startDate+" "+value.startTime} serverTime={this.state.serverTime} item={this.state.records} /> */}
								<span className="timerdiv2 past_value">
									<span>{classThis.state.days[i]}D</span>
									<span>:</span>
									<span>{classThis.state.hours[i]}H</span>
									<span>:</span>
									<span>{classThis.state.minutes[i]}M</span>
									<span>:</span>
									<span className="timercounter">{classThis.state.seconds[i]}S </span>
								</span>
                                </div>
								
								
								 <div className="col-6 col-md-4">
                                    <span >Started At </span>
                                   
                                </div> 
                                <div className="col-6 col-md-8 ">
                                   <span className="past_value">{month_name(new Date(value.startDate))}, {day1} ({value.startTime})</span>
                                </div>
								
								<div className="col-6 col-md-4 ">
                                   <span > Prize pool</span>
                                </div>
								
								<div className="col-6 col-md-8 ">
                                    <span className="past_value"><i className="fa fa-inr"></i> {value.winningAmount} </span>
                                </div>
								<div className="col-6 col-md-4 ">
                                   <span > Entry Fee</span>
                                </div>
								<div className="col-6 col-md-8 ">
                                   <span className="past_value"><i className="fa fa-inr"></i> {value.entryFee}</span>
                                   
                                </div>
								
								
                                <div className="col-6 col-md-4">
                                    <span > Participants</span>
                                   
                                </div> 
								
								<div className="col-6 col-md-8">
                                {/* {console.log("valueee===",value)} */}
                                    <span className="past_value">{value.numOfParticipation+"/"+ (value.max_particepate!=undefined ?value.max_particepate:"0")}</span>
                                </div>
                                
                            </div>
                            <div className="details-outer row" >
							  <div className="col-6 col-md-6 ">
                                      <Link className="view_dital" to={"/trading-contest/" + value._id} ><span><i className="las la-trophy"></i> View Details</span></Link>
                                </div>
								
								<div className="col-6 col-md-6 tr">
                                    {value.currentStatus == "ACTIVE"?
                                    value.playNow[0]== 'yes'? <Link className="play-now btn btn_man" to={"/exchange/" + value._id+"/"+value.pairRealName}><i className="las la-trophy"></i>Going On</Link>:<Link className="play-now btn btn_man" to={"/exchange/" + value._id+"/"+value.pairRealName} onClick={() => classThis.playNow(value._id)}><i className="las la-trophy"></i>Play Now</Link>
                                    : value.numOfParticipation!= value.max_particepate ? <button type="button" className="btn btn_man p_button"  onClick={() => classThis.participatedata(value._id,value.entryFee,value.virtualFund,value.max_particepate)} data-toggle="modal" data-target="#exampleModal" >Participate Now </button> : "Participation limit exceeded" }                                         
                                </div> 
                            </div>   
                        </div>                        
                    </div>
               </div> </>
                )
            })
        }
        
    }

    participatedata(id, entryFee, virtualFund, max_particepate) {
        this.setState({ id: id, entryFee: entryFee, virtualFund: virtualFund, max_particepate: max_particepate ,show:true});
    }
    playNow(contestId){
        axios
            .post(baseUrl + "api/updatePlayNowCondition", { contest_id: contestId},config)
            .catch();
    }
    participate(id, entryFee, virtualFund, max_particepate, checked) {

        const { user } = this.props.auth;
        this.setState({participateBtnDisable:true});
         var sendData = { contest_id: id,
            entryFee: entryFee, 
            virtualFund: virtualFund, 
            max_particepate: max_particepate, 
            cashWalletChecked: this.state.cashWalletChecked,
            winningWalletChecked: this.state.winningWalletChecked,
            rewardWalletChecked: this.state.rewardWalletChecked   
           }
            axios
            .post(baseUrl + "api/participateCondition", sendData ,config)
            .then(res => {
                this.setState({
                    participateBtnDisable:false
                });
                if (res.data.status) {
                    this.setState({
                        status: "active",
                        show:false
                    });
                    toast.success(res.data.message, toastStyles)
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                }else{
                    toast.error(res.data.message, toastStyles)
                }               
                
            })
            .catch();
    }
    onChange = e => {
        // alert(e.target.value,"fsfd");

        if (e.target.id === 'user-update-subject') {
            this.setState({ subject: e.target.value });
        }

    };
    handleRewardWalletCheck = e => {        
        this.setState({ rewardWalletChecked: !this.state.rewardWalletChecked });
        this.calculateIt(this.state.cashWalletChecked,this.state.winningWalletChecked,!this.state.rewardWalletChecked);
    }

    handleCashWalletCheck = e => {
        
        this.setState({ cashWalletChecked: !this.state.cashWalletChecked });
        this.calculateIt(!this.state.cashWalletChecked,this.state.winningWalletChecked,this.state.rewardWalletChecked);
    }

    handleWinningWalletCheck = e => {       
        this.setState({ winningWalletChecked: !this.state.winningWalletChecked });
        this.calculateIt(this.state.cashWalletChecked,!this.state.winningWalletChecked,this.state.rewardWalletChecked);
    }

    calculateIt=(cashCheck,winningCheck,rewardCheck)=>{
       
        var remainingAmount = this.state.entryFee;
        var rewardWalletDeduct = 0;
        var winningWalletDeduct = 0;
        var cashWalletDeduct = 0;

        if(rewardCheck){
            var rewardWalletDeduct = remainingAmount * 50 / 100;
            rewardWalletDeduct = (rewardWalletDeduct>this.state.bonus)  ? this.state.bonus : rewardWalletDeduct;
            var remainingAmount = remainingAmount - rewardWalletDeduct; 
        }

        if(winningCheck){

            var winningWalletDeduct = remainingAmount;
            winningWalletDeduct = (parseInt(winningWalletDeduct) > parseInt(this.state.winningWalletBalance))  ? this.state.winningWalletBalance : winningWalletDeduct;
            
            var remainingAmount = remainingAmount - winningWalletDeduct; 
        }

        if(cashCheck){
            var cashWalletDeduct = remainingAmount;
            cashWalletDeduct = (cashWalletDeduct>this.state.cashWalletBalance)  ? this.state.cashWalletBalance : cashWalletDeduct;
            var remainingAmount = remainingAmount - cashWalletDeduct; 
        }

        
        this.setState({
            rewardWalletDeduct:rewardWalletDeduct,
            winningWalletDeduct:winningWalletDeduct,
            cashWalletDeduct:cashWalletDeduct,
        })

    }
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUserUpdate = e => {
        const { user } = this.props.auth;
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
            user_id: user.id,
            description: this.state.description
        };
        this.props.requery(newUser);
    };

    hideModal=()=>{
        this.setState({show:false});
    }

    render() {
        var settings = {
            initialSlide: 0,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 3500,
            dots: true,
            arrows: false,
            pauseOnHover: true,
            pauseOnFocus: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    adaptiveHeight: true,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 575,
                  settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                  },
                },
            ],
        };

        const { errors } = this.state;
        const { user } = this.props.auth;
        return (
            <>
                <div className="row">
               
                    {this.showChatHtml()}
             
                </div>


                <Modal
                    show={this.state.show}
                    onHide={this.hideModal}
                    dialogClassName="modal-90w mx400"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    <h5 className="modal-title" id="exampleModalLabel">Select an option to pay -₹ {this.state.entryFee}</h5>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                   
                    <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleCashWalletCheck} defaultChecked={this.state.cashWalletChecked} />    
                        <label className="form-check-label" for="flexCheckChecked">
                        <span className="amountshow">
                           Cash Wallet({this.state.cashWalletBalance}) :</span>₹ {this.state.cashWalletDeduct}
                        </label>
                    </div>
                    
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleWinningWalletCheck} defaultChecked={this.state.winningWalletChecked} />
                        <label className="form-check-label" for="flexCheckChecked"><span className="amountshow">
                        Winning Wallet({this.state.winningWalletBalance}) :</span>₹ {this.state.winningWalletDeduct}
                            
                        </label>
                    </div>

                    <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleRewardWalletCheck} defaultChecked={this.state.rewardWalletChecked} />
                    <label className="form-check-label" for="flexCheckChecked">
                    <span className="amountshow">
                    Reward Wallet({this.state.bonus}): </span>₹ {this.state.rewardWalletDeduct}
                        <br /><span className="amountshow">Total to participate:</span>₹ {this.state.entryFee}
                        <br /><span className="text-danger">Note: You can use maximum 50% of entry fees from the reward wallet</span>
                    </label>
                    </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideModal}>Close</Button>
                        <button variant="primary" className="btn btn_man" disabled={this.state.participateBtnDisable} onClick={() => this.participate(this.state.id, this.state.entryFee, this.state.virtualFund, this.state.max_particepate, this.state.checked)}>Participate</button>
                    </Modal.Footer>
                </Modal>


            </>

        )
    }
}

LiveContest.propTypes = {
    requery: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    contestdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    contestdata: state.contestdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { requery }
)(withRouter(LiveContest));
