import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import ForgetPassword from "../../components/auth/ForgetPassword";
import Footer from "../../components/pages/Footer";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
import MetaTags from 'react-meta-tags';
import Scroll from 'react-scroll';
import axios from "axios";
const Link = Scroll.Link;
const baseUrl = myConstList.baseUrl;
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName:'',
            balance:'',
           errors: {}
        };
     
    }
    componentDidMount() {               
        if (this.props.auth.isAuthenticated) {
            this.setState({userName:this.props.auth.user.name});
        } 
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
    }
   render() {
    const { errors } = this.state;
        return (
            <>
			 <MetaTags>
                <title>Terms And Conditions | Trade The Games</title>
                <meta name="description" content="Read our terms and condition before register trade the games platform. in which you use your crypto trading skills to win contests and earn money." />
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://www.tradethegames.com/term-and-condition" />
		<meta name="facebook-domain-verification" content="ih4k1e3ay891tcx2z9uelbtn60zdsr"/>
	<meta property="og:title" content="Terms And Conditions | Trade The Games"/>
	<meta property="og:site_name" content="Trade the Games"/>
    <meta property="og:url" content="https://www.tradethegames.com/"/>
	<meta property="og:description" content="Read our terms and condition before register trade the games platform. in which you use your crypto trading skills to win contests and earn money."/>
	<meta property="og:type" content="article"/>
	<meta property="og:image" content="https://www.tradethegames.com/img/ttg-logo.jpg"/>
    <meta name="twitter:card" content="summary_large_image"/>
	<meta name="twitter:site" content="@tradethegames"/>
	<meta name="twitter:title" content="Terms And Conditions | Trade The Games"/>
	<meta name="twitter:description" content="Read our terms and condition before register trade the games platform. in which you use your crypto trading skills to win contests and earn money."/>
    <meta name="twitter:image" content=" https://www.tradethegames.com/img/ttg-logo.jpg"/>		
            </MetaTags> 
            <div className="">
                <div >
                <nav className="navbar navbar-expand-lg home_nav">
                        <div className="container ">
                        {/* <!-- logo --> */}
                        <a className="navbar-brand" href="/">
                            <img src="./img/logo.png" alt="header-Logo" className="logo logo1"/>
							<img src="./img/logo_mobile.png" alt="header-Logo" className="logo logo2"/>
						</a>
                        <div className="right_menu ">
                            <li className="nav-item">
                                {this.state.userName !=='' &&
                                <a href = "/profile-user" className="man_color fw-500" >{this.state.userName}
                                </a>
                                
                                }
                                {/* {this.state.userName ==='' &&
                                <a className="btn" ref={this.button}  data-toggle="modal" data-target="#loginmodal" id="main_btn">Login/Sign Up</a>
                                } */}

                            
                            </li>
                        </div>
                        </div>
                    </nav>
                    <div className="modal fade" id="loginmodal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body login_modal"> <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>

                                <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#SignIn" id="signintab">Sign In</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#SignUp" id="signuptab">Sign Up</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-4">
                                        <Login />
                                        <Register />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                   
					<div className="inner_banner text-center" >
					    <img src="./img/about_banner.jpg" alt="about_banner"/>
						 <div>
                        <h1>Terms & Conditions</h1>
                       </div>
                    </div>
                  <div className="p60 how faq-main">
<div className="container">
<h4>Trade The Games</h4>
<p className="mt-0"><a className="color_link" href="https://www.tradethegames.com/">Trade the Games</a> is the flagship project of Technoloader IT Services Private Limited. Through TTG, Technoloader is introducing a platform that will allow the users to engage in dummy crypto trading while giving them a chance to win rewards.</p>

<h4>Concept</h4>
<p className="mt-0">It combines the 2 different concepts of fantasy games and crypto trading and enables many people to learn about the buying and selling of decentralized tokens. With this project, it will become possible for the user of Trade The Games to learn the complex equations of the trading environment.</p>
<p className="mt-0">With this website, it would become possible for any individual to hone the skill of trading. It would make them familiar with so many concepts of trading and with a highly-simulated environment. At the same time, it would make the rules and regulations to be supple. </p>
<p className="mt-0">Through this website, it becomes possible for every individual to be aware of all the subtleties involved in the crypto domain, it would make the whole thing very easy and conducive for the users. With the help of this platform, you would be able to get more familiar with all types of situations that you can come across in the trading environment.</p>


<h4>Services</h4>

<ol className="list_li">
<li>You need to be 18years old to be eligible for this game. Since the platform exposes to a core financial environment, it is important that users have reached a level of maturity. The company has the right to terminate any account wherein the user is found to be below the age of 18 years or under the age of majority in your country.</li>
<li>Since the laws of gaming differ from state to state, we may prevent users from a few states to participate in the game. Residents of <b>Assam, Odisha, Sikkim, Nagaland, Andhra Pradesh, Telangana, Meghalaya and Tamil Nadu</b> may not participate in the paid version of the contest. This is because these states have ambiguous laws regarding participation in games of skills. If someone from these excluded states tries to participate in the games by giving false details and we find it out, we would bar the person’s admission and wouldn’t allow him/her to participate.</li>
<li>TTG makes Know Your Customer (KYC) norms lenient yet effective. We allow the users to register, log in, deposit and participate in the contest without going through this procedure. However, the users won’t be able to withdraw the money unless they are through with KYC.</li>
<li>To get yourself registered with the service, you need to use your email and have it verified too. You need to assert that all the personally identifiable information provided by you is true, precise, and complete. You must refrain from providing any false piece of information which is false or does not belong to you. Also, you will be solely responsible for securing your account and you agree not to disclose your password to any other third party. If your password is compromised, then you will be totally responsible for the actions and activities. However, if you notice unauthorized use of your password or account, you will notify the company about it. You must abstain from using false identities impersonating any other person or using a username or password that you are not authorized to use.</li>
<li>You accede to utilizing all our services while adhering to all the applicable laws, rules, and regulations. If there is any specific restriction regarding the use of the service or content, then you’ll abide by that too.</li>
<li>We have the right to use the information provided by you. By providing the information to us, you agree with our privacy policy.
For any sort of assistance, information, or queries regarding our services, you need to write to us on this email address support@tradethegames.com</li>
<li>The company is committed to safeguarding that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app for maintenance at any time. If such situations cause an interruption of paid-for services for a reasonable duration, the company shall not bear any liability to you and/or to any third parties. The company will make attempts on a best-efforts basis to let you beware of any scheduled maintenance to such extent as is possible. Additionally, the company shall be entitled to modify these Terms and Conditions, rules and regulations referred to herein, at any time, by duly posting the same on the platform.</li>
<li>TTG is determined to keep the platform in its most functional and efficient state. To make that possible, we have the right to overhaul the website or to make changes in it without prior notice. If such an attempt causes the interruption of paid service for any duration, the company will not bear any liability to you or to any third party. However, the company will try its best to notify the users about the scheduled maintenance in advance. Furthermore, the company has the right to modify the terms, conditions, rules, and regulations, on this page, anytime.</li>
<li>You agree not to deploy any robot, spider, scraper, or any other means to access our service to bypass, eliminate, degrade, deactivate or stop any of the contents. Also, you agree that you won’t try to tamper with any software or process through the means of disassembling, reverse engineering, or decompiling. In addition to that, you agree not to share, post or transmit any piece of information that could cause interruption, destruction, or limit the functionality of our service. If we find anyone violating these terms or engaging in any illegal/fraudulent practices, we would terminate their usage.</li>
<li>Further, the company may also collect from its users' information regarding their employment, profession, the status of vocation etc. If in any case user indicates or the company comes to know or the company reasonably believes that-</li>
<li>Moreover, the company has the right to collect user information regarding their status of vocation, employment, profession, etc. If in any case user indicates or the company comes to know or the company reasonably believes that-</li>
</ol>

<p className="mt-0">A. A particular user is in possession of or access to any information irrespective of its means, relating to any company or its securities, directly or laterally which isn’t available in the general course with the public at large and upon being circulated, similar information may have a material effect on the price, price moment or performance of the stock/cryptocurrency of that company or;</p>
<p className="mt-0">B. Such a user is or has been associated to any listed company, directly or laterally, or is or has been connected to a similar company in a contractual, fiduciary or employment relationship or is as was a director, officer or a hand of the company or holds or held any position including that relationship in the nature of being a professional or business relationship between the user and the company irrespective of its being temporary or endless, that allows similar user to pierce or retain, whether, directly or laterally, to any information as defined above of it such a user is nicely anticipated to have been allowed similar access to similar information.  </p>




<h4>Technoloader IT Services  Pvt. LTD. Rights</h4>
<p className="mt-0">Technoloader IT Services  Pvt. LTD. is the parent company of Trade The Games will hold all the rights equally. It has the right to merge this platform with another or to make any type of changes in the existing platform. We can also change the format of contests or any other particular detail. 
</p>
<p className="mt-0">In case we find anyone engaging in false play, we will be forced to cancel that person’s account. All the amount earned will be refunded on cancellation.
</p>
<p className="mt-0">Company has rights to cancel any contest without any prior notice. If any user participated in that contest then his paid fee will be refunded without any deductions. 
</p>






<h4>Registration Process and KYC:  </h4>
<p className="mt-0">In order to join the platform, it is essential that you sign up with a valid email address and keep a strong password. After putting in the information and clicking the ‘sign up', you see a message that says that an email has been sent to you.
Know Your Customer (KYC) is necessary for every customer. You would be able to join, participate in contests, and win them without going through that procedure. However, you won’t be able to withdraw the money unless you have gone through the process. 
</p>




<h4>Wallets: </h4>
<p className="mt-0"><strong>Reward Wallet:</strong> Referral Bonus and Registration Bonus and other bonuses will be credited in the same wallet which the company will decide to give from time to time.  </p>
<p className="mt-0"><strong> Winning Wallet:</strong> The winning amount will be credited to this wallet only. And you can withdraw this amount only. Cash Wallet: All deposited amounts will get credited to this wallet. </p>







<h4>Profile</h4>
<p className="mt-0">The ‘profile’ section consists of the personal data that has the first name, last name, DOB, gender, contacts, email, referral code, password, and bank account details. The overall registration process is very to go through and makes everything very quick.
</p>



<h4>KYC</h4>
<p className="mt-0">The KYC section makes this detail very clear and makes the whole process easy. First of all, you get the email verified, the next step is the verification of the phone number. The intermediate verification takes the process further and helps get the other info processed.
</p>
<p className="mt-0">Post that, the address of the user is verified followed by the verification of PAN card and then face. The procedure can be easily started by putting your phone number. As soon as you start typing the number, you get the button to send the “OTP”. 
</p>

<p className="mt-0">You hit the button and trigger the whole process. </p>

<p className="mt-0">Once you sign the link given in the mail, the verification is done and you get to access the account. Here, you see a few options on the dashboard which are Balance, Live Contest, Past Contest, Upcoming Contest, You won.
</p>

<p className="mt-0">Each of the options enables facilitate a different function. The ‘balance’ function lets you see how many tokens you have in your wallet. In the ‘Live Contest’, you get to see the status of all the contests that you have participated in. The ‘Past Contest’ tells you about the contests completed in past.
</p>

<p className="mt-0">The ‘Upcoming Contest’ tells you about the forthcoming contests that you can participate in. The last section of ‘You Won’ lets you see all the competitions can you have won so far. You also see the ‘login history’ where you get to see how you have performed in all these years.
</p>





<h4>Bank Detail</h4>
<p className="mt-0">This section takes very specific information that helps facilitate the transactions. The given details are bank name, account number, account holder name, IFSC code, branch name, and cancelled cheque. 
</p>
<p className="mt-0">User will be able to add bank account details only after completion of KYC. Users have to add correct bank account details. Cancelled cheque image should be clearly visible. 
</p>



<h4>Withdrawal :</h4>
<p className="mt-0">Withdrawal can be done only with verified accounts only. If your account is not verified, we won’t be able to proceed with the payments. 
</p>


<h4>Deductions:</h4>
<p className="mt-0">Add Cash<br/>
     Withdrawal Fee <br/>
     TDS 
</p>


<h4>Contest Rules</h4>
<p className="mt-0">To participate in the contest, users will have to pay the fee, which will be decided by the TTG admin. That fee will be different for every contest</p>
<p className="mt-0">The user will have to place at least one trade, otherwise, he will not be qualified for prize money.
</p>
<p className="mt-0">The user’s trade balance should be equal or more to the assigned virtual fund. 
</p>
<p className="mt-0">Prize money is different for every contest .</p>
<p className="mt-0">If two users have the same amount of funds in their trading wallets at the end of the contest then prize money will be distributed between them. </p>





<h4>Help Center</h4>
<p className="mt-0">The section lets the users inform the relevant about the technical issues they are facing. The section highlights 3 different points which are subject, description, and attachments. The “Subject” lets you define the problem in a few words so the team could determine what type of issues it is.
</p>

<p className="mt-0">The following section is “Description” in which the users can describe the issues in more words. This explanation will make the issue clearer to the team and will help them find the resolution too. The last section is “Attachments” which lets you attach a screenshot or file that manifest the issue with the correct visuals.
</p>





<h4>Help History</h4>
<p className="mt-0">This section helps you see the history of technical issues that you have come across on this platform. On the page, you’ll be able to see the ‘ID’ of the issues that have been raised along with the ‘subject’, ‘status’, ‘date’, and ‘action’.
</p>
<p className="mt-0">With each of the sections, the usage becomes easier and sorted for everyone. Also, you get to see some amazing features that make this platform very distinguished at every front. The ‘about us’ section gives an elaborate explanation and keeps the whole thing to the point.
</p>






<h4>Use of Cookies</h4>
<p className="mt-0">A cookie is a small text file that is sent by a website server to your browser and stored on your computer. Cookies allow website operators to accumulate useful information, such as whether the computer (or its users) has visited the site before. 
</p>
<p className="mt-0">Cookies are needed for several functions which make websites work, or work more efficiently, and they can provide information to website owners about how you use their website. Cookies can also be used to show you adverts that are relevant to you, based on your browsing habits, to personalize your user experience.
</p>
<p className="mt-0">The only personal information a cookie can contain is information supplied by the user while on the platform.
</p>









<h4>How are cookies used?
</h4>
<p className="mt-0">Trade The Games uses cookies to monitor how people use our website, including estimating audience size, consumer behaviour, user navigation and patterns of use, to help us provide you with a better service. Cookies also make it easier for you to use the website on future visits and allow us to personalize the content of the website to you by recording information about your preferences. 
</p>
<p className="mt-0">We both persistent cookies (which expire on a specified date) and session cookies (which expire when you close your browser). Please note that third parties (including, for example, providers of external services like web traffic analysis services such as Google Analytics, or advertising services and vendors) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.
</p>






<h4>Configure The Cookie Settings </h4>
<p className="mt-0">It is very easy for users to configure the cookie settings on their browser. Moreover, you can do this on all the leading browsers:-<br/>
Chrome<br/>
Firefox<br/>
Safari<br/>
Opera<br/>
IE & Edge
</p>
<p className="mt-0">While it is possible to prevent the cookies from getting saved on your computer, you must remember that not allowing it can result in limited access to the platform or reduced functionality which can lead to a suboptimal experience.
</p>
<p className="mt-0">Trade The Games has the right to include links to other websites. These websites are not governed or controlled by us, they run as per their own privacy policies. Once the user leaves the company’s servers (the user can tell where he/she is by checking the URL in the location bar on the user’s browser), the use of any information provided by the user is governed by the privacy policy of the operator of the site which the user is visiting. The policy of the website can differ from that of ours. 
</p>
<p className="mt-0">In case you want to find the privacy policy of those websites then you’ll have to contact that website to get all the information. TTG will not be responsible for the privacy practices of the other websites. Hence, we suggest that users check the policies of all such third-party platforms before accessing them.
</p>





<h4>Marketing & Analytics</h4>
<p className="mt-0">The cookies saved on your computer will be accessed by us as well as the third-party websites that use our webpages for advertisement purposes. This would help us and them in determining what type of content you like to consume. 
</p>
<p className="mt-0">As a result, you will get an optimal experience that would be provided after doing a thorough analysis of your preferences.</p>








<h4>Users Rights</h4>
<p className="mt-0">The users of TTG will enjoy a number of rights in terms of information that concerns them.
</p>
<p className="mt-0">a.   The right to access the information we hold about you, and to obtain the details of processing.
</p>
<p className="mt-0">b.   The right to withdraw your consent to our continued processing of the information, at any point of time, subject only to any legitimate reason for continued processing in certain circumstances.
</p>
<p className="mt-0">c.   You will be able to edit the account information and preferences available with us.
</p>
<p className="mt-0">d.   The right to seek from us your personal data to be provided to another company.
</p>
<p className="mt-0">e.   The right to the erasure of any personal data that we process, in certain circumstances, to the extent permissible in applicable law, and feasible; and,
</p>
<p className="mt-0">f.   The right to move your information to another service provider, in a compatible format. This will only cover such information which we have received from you.
</p>







<h4>Retention Policy
</h4>
<p className="mt-0">Retention periods for records are based on the type of record, the nature of the activity and product and service that the same is associated with, linked to. The periods for retention of your personal information vary, depending on specific legal, business and statutory requirements.
</p>
<p className="mt-0">We will retain your personal information for such a period that is legally or statutorily necessary, and relevant for us to perform our contractual obligations to you.
</p>








<h4>Information Security</h4>
<p className="mt-0">We take the onus of safeguarding the users’ personal information available with us. We deploy optimum technical, organizational, logical, physical, administrative measures to protect your personal information against any unauthorized access, modification, or use. 
    </p>
<p className="mt-0">We use industry-standard technical and organizational measures to deliver protection of the information we have. </p>










<h4>Overall Security
</h4>
<p className="mt-0">All the collected information is stored in the TTG controlled database. This database is stored on servers secure with a firewall; access to such servers is password-protected and strictly limited based on a need-to-know basis. 
</p>
<p className="mt-0">Having said that, we acknowledge the fact that no security system, irrespective of how robust it is, is impenetrable. Therefore, we don’t say that the security of our database is foolproof. We also can’t guarantee that the data you supply will not be intercepted while being transmitted to us over the internet.
</p>
<p className="mt-0">If you leave any posts in the discussion areas, they will be available to anyone without any internet access. By using the portal, you understand and agree that your information may be used in or transferred to countries other than India. 
</p>
<p className="mt-0">Trade The Games believes that the internet is an ever-evolving medium. Therefore, we may make any sort of changes in our privacy policy without any notice. Our use of any information we gather will always be consistent with the policy under which the information was collected irrespective of what the new policy may be.
</p>
<p className="mt-0">Any change to our privacy policy will be posted on this page, so you are always aware of what information we collect, how we use it, how we store it and under what circumstances we disclose it.</p>





<h4>Advertising</h4>
<p className="mt-0">Trade The Games will share the aggregated statistics on traffic to various pages within its site. This would help the online advertisers to understand the preferences of our audiences and will also show the significance of advertising on our platform.
</p>
<p className="mt-0">When you register with TTG, we contact you from time to time about updating your content to provide features that we believe may benefit you. We also want to warn the users that they might see some deceptive emails, websites, blogs claiming to be associated with us.</p>
<p className="mt-0">We will have no relation with any such campaign and will not be responsible if you lose any money in these spam projects. We may use the information provided by you to TTG, including your email address or phone number to contact you about the services availed by you or to inform you of our updated services if any.</p>







<h4>Third-Party Sites, Services and Products</h4>
<p className="mt-0">The company has all the rights to share the personal information of users with its affiliates and third parties. This is done in order to make many other services better and to show the right kind of advertisements and promotions that the users would like to see. It would also help us and the third-party service providers to process financial transactions and customer services. It would help us shape up the solutions as per your preferences.</p>






<h4>Intellectual Property </h4>
<p className="mt-0">The website of Trade The Games and its mechanism is an intellectual property owned by Technoloader IT Services Pvt. Ltd.  Every piece of content is governed by us and we can make any changes at any time. If the content or any other material is copied by any other individual or business entity, then we will have to sue them. 
</p>




<h4>Privacy Policy</h4>
<p className="mt-0">Trade The Games is owned by Technoloader IT Services Pvt. Ltd.  which reserves all rights to shape up this website the way it likes. All the activities on this platform will be handled by us and we can make any changes in the content or format of the Games without any prior notice. The company will not be liable for any type of financial loss made by the user. The company will be collecting the information of the users and will use them for marketing purposes too.</p>




<h4>Legality</h4>
<p className="mt-0">Trade The Games is registered under the rules and regulations enacted by the Indian government for fantasy gaming and trading websites. We would be running our operations above-board following all the methods sanctioned by the government. We would be rolling out paid contests on this platform which would be executed with its own rules. As per the relevant rules, we have all the rights to do it. 
</p>





<h4>Dispute and Dispute Resolution</h4>
<p className="mt-0">The courts of competent jurisdiction at Jaipur, shall have exclusive jurisdiction to determine any and all disputes arising out of, or in connection with, the Trade The Games and Technoloader IT Services Pvt. Ltd.  Services provided by Trade The Games and Technoloader IT Services Pvt. Ltd.  (including the Contest(s)), the construction, validity, interpretation and enforceability of these Terms and Conditions, or the rights and obligations of the User(s) (including Participants) or Trade The Games and Technoloader IT Services Pvt. Ltd.  , as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed in accordance with the laws of the Republic of India.
</p>
<p className="mt-0">In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ("Notification") to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute shall be settled by arbitration.
</p>
<p className="mt-0">The place of arbitration shall be Jaipur, India. All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time.
</p>
<p className="mt-0">The arbitration award will be final and binding on the Parties, and each Party will bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.
</p>
<p className="mt-0">Nothing contained in these Terms and Conditions shall prevent Trade The Games and Technoloader IT Services Pvt. Ltd.   from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard Trade The Games and Technoloader IT Services Pvt. Ltd.   interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of Trade The Games and Technoloader IT Services Pvt. Ltd.   to pursue any remedy for monetary damages through the arbitration described herein.
</p>







<h4>Disclaimer and Limitation Regarding Loss
</h4>
<p className="mt-0">We will not be liable for any type of loss you make while engaging in the contests on our platform. Though the platform is crafted to make the learning of trade easier, it does give you an environment of trade. However, if the users join the contests in hopes of winning money and losing, we won’t be responsible for that. The website will choose the winner on the basis of the performance of the contestants.</p> </div>
                     </div>
                    <Footer />

                    
                </div>
            </div>
            <ForgetPassword />
  
</>
        );
    }
}

Home.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Home);
