import React, { Component } from "react";
import { Link, withRouter  } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { forgetPassword } from "../../actions/authActions";
import classnames from "classnames";
import {toast} from 'react-hot-toast';
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class ForgetPassword extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
          
            errors: {}
        };
    }
    componentWillReceiveProps(nextProps) {
        toast.dismiss();
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if ( nextProps.auth.reg != undefined
            && nextProps.auth.reg.data != undefined
            &&nextProps.auth.reg.data.message != undefined
            && nextProps.auth.reg.data.status) {
            toast.success( nextProps.auth.reg.data.message, toastStyles);
            nextProps.auth.reg.data.message = undefined;
             this.setState({               
                email:"",
                errors: {}
            });

        }else{
            if(nextProps.auth.reg != undefined
                && nextProps.auth.reg.data != undefined && nextProps.auth.reg.data.message !=undefined){
                toast.error( nextProps.auth.reg.data.message,toastStyles);
                nextProps.auth.reg.data.message = undefined;
                  this.setState({errors:{email:nextProps.auth.reg.data.email,email1:nextProps.auth.reg.data.email1}});
            }            
        }
        
    }
    componentDidMount() {
        if (window.location.pathname != '/privacy-policy' && window.location.pathname != '/term-and-condition' && window.location.pathname != '/faq' && window.location.pathname != '/about-us' && window.location.pathname != '/contact-us' && this.props.auth.isAuthenticated) {
            this.props.history.push("/");
        }
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({
            message:"",
            errors: {
               email: "",
              }
        });
    };

    onForgetPasswordSubmit = e => {
        e.preventDefault();
        const newUser = {
           email: this.state.email
         };
       this.props.forgetPassword(newUser, this.props.history);
     };
   
    render() {
        const { errors } = this.state;
        return (
           
                
            <form noValidate onSubmit={this.onForgetPasswordSubmit}>
                <div id="pwdModal" className="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                               
                                <h5 className="text-center rp_h5">Reset Password</h5>
								 <button type="button" className="close close5" data-dismiss="modal"><i className="las la-times"></i></button>

                            </div>
                            <div className="modal-body nextt">
                                <div className="col-md-12 pt-md-4">
                                    <div className="panel panel-default">
                                        <div className="panel-body">
                                          
                                                <div className="panel-body">
                                                    <fieldset>
                                                        <div className="form-group">
                                                            <input onChange={this.onChange} placeholder="Email" value={this.state.email} error={errors.email} id="email"
                                                                type="email" className={classnames("form-control", { invalid: errors.email1 })} />
                                                            <span className="text-danger f12">{errors.emailf}</span>
                                                        </div>
                                                        <input className="btn w100 btn_man" value="Submit" type="submit" />
                                                    </fieldset>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </form>
          
        );
    }
}

ForgetPassword.propTypes = {
    forgetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { forgetPassword }
)(withRouter(ForgetPassword));