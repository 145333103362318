import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PairSection from "../partials/exchange/PairSection";
import ProfitLoseTable from "../partials/exchange/ProfitLoseTable";
import OrderSection from "../partials/exchange/OrderSection";
import MarketActivities from "../partials/exchange/MarketActivities";
import MarketTrade from "../partials/exchange/MarketTrade";
import OrderHistory from "../partials/exchange/OrderHistory";
import Ticker from "../partials/exchange/Ticker";
import Loading from "../layout/Loading";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import * as myConstList from "../../BaseUrl";
import MetaTags from "react-meta-tags";
import socketIOClient from "socket.io-client";

const baseUrl = myConstList.baseUrl;
const socketUrl = myConstList.baseUrlSocket;
const jwtToken = localStorage.getItem("jwtToken");
const socket = socketIOClient(socketUrl);
const config = {
  headers: {
    Authorization: "Bearer " + `${localStorage.getItem("jwtToken")}`,
  },
};
class Exchange extends Component {
  constructor(props) {
    super(props);
    // console.log("this.props.match.params.symbol=",this.props.match.params.symbol);
    this.state = {
      records: [],
      allCloseOrder: [],
      allOpenOrder: [],
      allAccount: [],
      portfoliototal: 0,
      PortfolioValue: 0,
      pairType: this.props.match.params.symbol,
      pair: [],
      virtualFund: 0,
      isLoaded: false,
      contestId: this.props.match.params.id,
      symbol: this.props.match.params.symbol,
      virtualFundassign: 0,
      CurrencyValue: 0,
      currentprice: 0,
      currentprice1: 0,
      tempArrPair: [],
      flagPairData: "1",
      timerData: {},
    };

    this.tradesCount = 10;
    this.streams = ["@ticker", "@depth20", "@trade"];
    this.connection = "";
    // this.getData = this.getData.bind(this);
  }

  _disconnectSocketStreams(streams) {
    // console.log("streamsstreamsstreams-----",streams,this[connection].readyState);
    streams = streams.join("/");
    this.connection = btoa(streams);
    if (this[this.connection].readyState == WebSocket.OPEN) {
      this[this.connection].close();
      // this._connectSocketStreams(streams);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.symbol !== prevProps.match.params.symbol) {
      const symbolData = this.props.match.params.symbol.toLowerCase();
      const symbolType = this.props.match.params.symbol;
      let prevSymbol = prevProps.match.params.symbol.toLowerCase();
      // Handle the URL change and fetch data based on the newId
      // this._disconnectSocketStreams(symbolData);
      // console.log("this.streams===",this.streams);
      // this._disconnectSocketStreams(this.streams.map(i => `${prevSymbol}${i}`));
      this.binanceConnect(symbolData, symbolType);
      this.getData();
    }
  }

  _connectSocketStreams(streams) {
    if (this[this.connection]) {
      this[this.connection].close();
    }
    streams = streams.join("/");
    this.connection = btoa(streams);

    this[this.connection] = new WebSocket(
      `wss://stream.binance.com:9443/stream?streams=${streams}`
    );

    this[this.connection].onmessage = (evt) => {
      let eventData = JSON.parse(evt.data);
      if (eventData.stream.endsWith("@ticker")) {
        eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0;
        this.props.dispatch({
          type: "SET_TICKER",
          data: eventData.data,
        });
        this.setState({
          loadedTicker: true,
        });
      }
      if (eventData.stream.endsWith("@trade")) {
        if (this.props.trades && Object.keys(this.props.trades).length > 0) {
          let trades = this.props.trades;
          trades.push(eventData.data);
          trades = trades.slice(-1 * this.tradesCount);
          this.props.dispatch({
            type: "SET_TRADES",
            data: trades,
          });
          this.setState({
            loadedTrades: true,
          });
        }
      }
      if (eventData.stream.endsWith("@depth20")) {
        this.props.dispatch({
          type: "SET_DEPTH",
          data: eventData.data,
        });
        this.setState({
          loadedDepth: true,
        });
      }
      this.setState({
        isLoaded: true,
      });
    };
    this[this.connection].onerror = (evt) => {
      // console.error(evt);
    };
    // }
  }

  participateById = () => {
    const { user } = this.props.auth;
    axios
      .post(
        baseUrl + "api/participateById",
        { contest_id: this.props.match.params.id, user_id: user.id },
        config
      )
      .then((res) => {
        this.setState({ virtualFund: res.data.data.virtualFund });
        var classThis = this;
        setTimeout(function () {
          classThis.participateById();
        }, 1000);
      })
      .catch();
  };

  getAccountData = () => {
    axios
      .post(
        baseUrl + "api/getAccount-data",
        { contestId: this.props.match.params.id },
        config
      )
      .then((res) => {
        var arrnew = [];
        var total = 0;
        var portfoliototal = res.data.data.total;
        if (res.data.data.total == "undefined") {
          var portfoliototal = 0;
        }
        this.setState({
          allAccount: res.data.data.allAccount,
          portfoliototal: portfoliototal,
        });
        var classThis = this;
        setTimeout(function () {
          classThis.getAccountData();
        }, 1000);
      })
      .catch();
  };

  updateLeaderBoard = () => {
    const { user } = this.props.auth;
    axios
      .post(
        baseUrl + "api/updateLeaderBoard",
        {
          user_id: user.id,
          contestId: this.props.match.params.id,
          symbol: this.props.match.params.symbol,
        },
        config
      )
      .then((res) => {
        var classThis = this;
        setTimeout(function () {
          classThis.updateLeaderBoard();
        }, 2000);
      })
      .catch();
  };

  crptoPrice = () => {
    fetch(`${baseUrl}api/crpto-price?symbol=${this.props.match.params.symbol}`)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ currentprice: response.price });
        var classThis = this;
        setTimeout(function () {
          classThis.crptoPrice();
        }, 1000);
      })
      .catch();
  };

  getData = () => {
    const { user } = this.props.auth;
    console.log("ININDA");
    axios
      .post(
        baseUrl + "api/contestByID-data",
        { _id: this.props.match.params.id },
        config
      )
      .then((res) => {
        console.log("res.data.dataPair=====", res.data.data);
        this.setState({
          records: res.data.data.item,
          virtualFundassign: res.data.data.item.virtualFund,
          tempArrPair: res.data.data.item.pair_id,
          pair: res.data.data.pair,
        });
      })
      .catch((err) => console.log(err, "errror"));

    this.setState({ socketCollect: socket });
    var collectThis = this;
    var userId = this.props.auth.user.id;
    var socketEventPortfolio = "portfolio" + userId + this.state.contestId;
    var socketEventorder = "order" + userId + this.state.contestId;
    var socketEventOrderSymbol =
      "order" + userId + this.state.contestId + this.props.match.params.symbol;
    // client-side
    // socket.on("connect", () => {
    //     collectThis.setState({ currentSocketId: socket.id });
    //     socket.emit("callExchangeData");
    // });
    var dataVar = {
      socketName: socketEventorder,
      contestId: this.state.contestId,
      userId: this.props.auth.user.id,
      symbol: this.props.match.params.symbol,
    };
    socket.emit("exchangeOrderData", dataVar);
    socket.emit("liveContestTimerById", this.props.match.params.id);

    socket.emit("portfolioExchange", {
      socketName: socketEventPortfolio,
      contestId: this.state.contestId,
      userId: this.props.auth.user.id,
      symbol: this.props.match.params.symbol,
      token: jwtToken,
    });
    // socket.off("exchangeOrderData1")
    socket.on("exchangeOrderData1", function (apiRespData) {
      socket.emit("exchangeOrderData", dataVar);
    });
    // portfolio
    // socket.off(socketEventPortfolio)
    socket.on(socketEventPortfolio, function (apiRespData) {
      // console.log('portfoliototal', apiRespData)
      // console.log("===socketapiRespData.data.leaderboardList",apiRespData.data.leaderboardList);
      var portfoliototal = apiRespData.data.total;
      if (apiRespData.data.total == "undefined") {
        var portfoliototal = 0;
      }

      collectThis.setState({
        contestProfolioByUserData: apiRespData.data.portfolioData,
        allAccount: apiRespData.data.allAccount,
        leaderboard: apiRespData.data.leaderboardList,
        leaderboardPaticipants: apiRespData.data.leaderboardList,
        portfoliototal: portfoliototal,
      });
    });
    // order
    // socket.off(socketEventorder)
    socket.on(socketEventorder, function (apiRespData) {
      collectThis.setState({
        getOrderByIdData: apiRespData.data.allOrder,
        virtualFund: apiRespData.data.virtualFund,
        currentprice: apiRespData.data.curruntprice,
      });
      //console.log("getCurrencyValueData==",collectThis.state.getCurrencyValueData);
    });
    // socket.off(socketEventOrderSymbol)
    socket.on(socketEventOrderSymbol, function (apiRespData) {
      collectThis.setState({
        getCurrencyValueData: apiRespData.data.totalcurrency,
      });
    });
    var eventNameTimer = "timer" + this.state.contestId;
    // socket.off(eventNameTimer)
    socket.on(eventNameTimer, function (apiRespData) {
      // console.log("apiRespDataTimer===", apiRespData.data, eventNameTimer);
      collectThis.setState({ timerData: apiRespData.data });
    });

    // socket.emit("getserverTime");
    // socket.off("serverTime");
    socket.on("serverTime", function (apiRespData) {
      console.log(apiRespData, "serverTime");
      collectThis.setState({ serverTime: apiRespData });
    });
  };

  binanceConnect = (symbol, symbolType) => {
    this._connectSocketStreams(this.streams.map((i) => `${symbol}${i}`));
    fetch(
      `https://api.binance.com/api/v1/aggTrades?limit=${10}&symbol=${symbolType}`
    )
      .then((response) => response.json())
      .then((response) => {
        this.props.dispatch({
          type: "SET_TRADES",
          data: response,
        });
        this.setState({
          isLoaded: true,
          loadedTrades: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoaded: false,
          error: error,
        });
      });
    fetch(`${baseUrl}api/crpto-price?symbol=${symbolType}`)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ currentprice1: response.price });
        // this.leaderboardData();
      })
      .catch();
  };
  componentDidMount() {
    let symbolType = this.props.match.params.symbol;
    let symbol = this.props.match.params.symbol.toLowerCase();

    /*setInterval(() => {
            this.getData();
        }, 5000);*/
    //this.contestByIDdata();
    //this.participateById();
    //this.getAccountData();
    //this.updateLeaderBoard();
    //this.leaderboardData();
    //this.crptoPrice();
    this.binanceConnect(symbol, symbolType);
    socket.emit("disconnectliveContestTimerById", this.props.match.params.id);
    this.getData();
  }

  componentWillUnmount() {
    // const socket = socketIOClient(baseUrl, {query:"jwtToken=" +jwtToken });
    var userId = this.props.auth.user.id;
    var socketEventPortfolio = "portfolio" + userId + this.state.contestId;
    var socketEventorder = "order" + userId + this.state.contestId;
    var socketEventOrderSymbol =
      "order" + userId + this.state.contestId + this.props.match.params.symbol;
    const data = {
      socketEventPortfolioactivePair: socketEventOrderSymbol,
      socketEventPortfolio: socketEventPortfolio,
      socketEventorder: socketEventorder,
    };
    // socket.emit("portfoliodisconnect",data);

    let symbol = this.state.pairType.toLowerCase();
    // this._disconnectSocketStreams(this.streams.map(i => `${symbol}${i}`))
  }
  componentWillReceiveProps(nextProps) {
    // if(this.state.tempArrPair !='' && this.state.flagPairData==1){
    //     this.setState({flagPairData:'2'});
    //     var pair_idArr = this.state.tempArrPair.split(",");
    //     axios.post(baseUrl+"api/pairById-data", { id: pair_idArr},config)
    //     .then(res1 => {
    //         this.setState({ pair: res1.data.data });
    //     }).catch()
    // }
  }
  openTradingChart = (symbol) => (
    <TradingViewWidget
      symbol={"BINANCE:" + symbol}
      theme={Themes.DARK}
      locale="en"
      autosize={true}
      details={true}
    />
  );

  render() {
    const { error, isLoaded, loadedDepth, loadedTicker, loadedTrades } =
      this.state;
    if (error) {
      return <div className="alert alert-danger"></div>;
    }
    if (!isLoaded) {
      return <Loading />;
    }
    return (
      <>
        <MetaTags>
          <meta className="robots" content="noindex,nofollow" />
        </MetaTags>

        <Navbar
          serverTime={this.state.serverTime}
          contestProfolioByUserData={this.state.contestProfolioByUserData}
          symbol={this.state.symbol}
          urlPath={true}
          endDate={this.state.records.endDate}
          endTime={this.state.records.endTime}
          virtualFundassign={this.state.virtualFundassign}
          {...this.props.ticker}
          virtualFund={this.state.virtualFund}
          portfoliototal={this.state.portfoliototal}
          timerdata={this.state.timerData}
        />
        <Sidebar />
        <div className="dashbord_manbox ">
          <div className="container-fluid">
            <div className="row sm-gutters row_d">
              <div className="col-md-9 col_d">
                <div className="kline-para kline-para-basic mt-0 mt-md-3 crypt-market-status ">
                  <div className="box-kline-para-basic-left">
                    <span className="productSymbol has-info ng-scope">
                      <strong className="ng-binding">
                        {this.props.match.params.symbol.slice(0, -4)}/
                        {this.props.match.params.symbol.slice(-4)}
                      </strong>
                      {/* <font className="ng-binding"> / BTC</font> */}
                      <div className="info-box"></div>
                    </span>
                  </div>
                  {loadedTicker ? (
                    <Ticker {...this.props.ticker} />
                  ) : (
                    <Loading />
                  )}
                  <div className="cls"></div>
                </div>
                <div className="row sm-gutters row_d">
                  {loadedDepth ? (
                    <ProfitLoseTable
                      record={this.state.records}
                      bids={this.props.depth.bids}
                      asks={this.props.depth.asks}
                      {...this.props.ticker}
                    />
                  ) : (
                    <Loading />
                  )}
                  <div className="col-md-8 col_d">
                    <div className="crypt-market-status mt-2 chart">
                      {this.openTradingChart(this.props.match.params.symbol)}
                    </div>
                    <OrderSection
                      getCurrencyValueData={this.state.getCurrencyValueData}
                      {...this.props.ticker}
                      currentprice={this.state.currentprice1}
                      virtualFund={this.state.virtualFund}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3  col_d">
                <PairSection
                  {...this.props.ticker}
                  pairs={this.state.pair}
                  urldummy={baseUrl}
                />
                <MarketTrade
                  leaderboard={this.state.leaderboard}
                  trades={this.props.trades}
                  record={this.state.allCloseOrder}
                  {...this.props.ticker}
                />
                {/* <MarketActivities record={this.state.records} /> */}
              </div>
            </div>
          </div>
          <OrderHistory
            getOrderByIdData={this.state.getOrderByIdData}
            virtualFund={this.state.virtualFund}
            record={this.state.records}
            {...this.props.ticker}
            currentprice={this.state.currentprice}
            allAccount={this.state.allAccount}
            allCloseOrder={this.state.allCloseOrder}
          />
        </div>
        <Footer />
      </>
    );
  }
}

Exchange.propTypes = {
  auth: PropTypes.object.isRequired,
  contestdata: PropTypes.object.isRequired,
  orderdata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  contestdata: state.contestdata,
  orderdata: state.orderdata,
  records: state.records,
});

export default connect((state) => state)(Exchange);
