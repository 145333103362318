import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import WalletBox from "../partials/WalletBox";
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";
import SidebarWallet from "./SidebarWallet";
import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
const configToken = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
const baseUrl = myConstList.baseUrl;
class Wallet extends Component {

    constructor(props) {
        super(props);
       

        this.state = {
            records: [],
            bonus:"",
            balance:"",
            winningamount:"",
           
        };
      
        this.columns = [
            {
                key: "tx_id",
                text: "Transaction Id",
                className: "upcase",
                align: "left",
                sortable: true,
            },
            {   
                key:"amount",
                text:"Amount",
                className: "upcase",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                className: "upcase",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
<Fragment>
{record.status}

</Fragment>
                    );
                }
            },
            {
                key: "txStatus",
                text: "Regard To",
                className: "upcase",
                align: "left",
                sortable: true,
            },
            {
                key: "transType",
                text: "Tx Type",
                className: "upcase",
                align: "left",
                sortable: true,
            },
            {
                key: "date",
                text: "Date",
                className: "upcase",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
               
            },
   
          
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };
      

        this.getData = this.getData5.bind(this);
    }
    editRecord(record) {
        this.setState({ reject: record.reject,approve: record.approve });
    }
    componentWillReceiveProps(nextProps) {
      
        this.getData5();

    }
    componentDidMount() {
        this.getData5()
    };
    getData5() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl+"api/getrefferaltransaction",{},configToken)
            .then(res => {
                this.setState({ records: res.data.data })
            })
            .catch()
     /*    axios
        .post(baseUrl+"api/profile-data",{},configToken)
        .then(res => {
          if(res.data.data.videoStatus!=="verify" && this.props.urlPath!=false){
             this.setState({modalIsOpen: true}); 
          }

          this.setState({ bonus:res.data.data.bonus,balance:res.data.data.balance.toFixed(2),videoStatus:res.data.data.videoStatus,winningamount:res.data.data.winningamount })})
        .catch() */
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
  
    render() {
        const { errors } = this.state;

        return (
            <>
<Navbar urlPath={true} symbol={false} />
<Sidebar />
<div className="container container2">
   <div className=" padding_d in_page">
      <WalletBox/>
        <div className="row">
            <div className="col-md-12">
                <div className="tab-content">
                    <div className="white_box " >
                        <h5 className=" mb-3 mb-md-4">Referral History
                        </h5>
                        <div className="ccrool_table" >
                            <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</div>
<Footer />
                <ToastContainer />
            </>
        );
    }

}

Wallet.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,

    errors: state.errors
});

export default connect(
    mapStateToProps,

)(withRouter(Wallet));
