import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateKyc } from "../../../actions/updatePersionalDataKycActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class KycUpdateMobile extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {
            id: this.props.id,
            mobile: this.props.mobile,
            email: this.props.email,
            message:'',
            showResults : false,
            errors: {}
        };     
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                mobile: nextProps.record.mobile,
                email: nextProps.record.email
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.prodata !== undefined
            && nextProps.prodata.pro !== undefined
            && nextProps.prodata.pro.data !== undefined
            && nextProps.prodata.pro.data.message !== undefined
            && nextProps.prodata.pro.data.status && nextProps.prodata.pro.data.messageStatus==='2') {
                
                this.setState({        
                    message: nextProps.prodata.pro.data.message,           
                    errors: {
                        mobile: ""
                    }
               
            });
        }
    }

    onChange = e => {
        this.setState({
            message: "",
            errors: {
                mobile: ""
            }
        })
    if (e.target.id === 'user-update-mobile') {
            this.setState({ mobile: e.target.value });
        }
        this.setState({
            showResults: this.state.name===null ? false : true
        })
   
    };

    onUserUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.state.id,
             type:"mobile",
             mobile: this.state.mobile
            
        };
        this.props.updateKyc(newUser);
    };

    render() {
        const { errors } = this.state;
        return (
            <form noValidate onSubmit={this.onUserUpdate} id="update-user">{console.log("this.state==",this.state)}
                 {/* <span className="text-success">{this.state.message}</span> */}
                <div className="row">
                    {(this.state.email =="" || typeof this.state.email == 'undefined') && <div className="form-group col-md-12">
                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                        <label>Mobile number</label>
                        <input onChange={this.onChange} value={this.state.mobile}
                            id="user-update-mobile"
                            type="number" className="form-control" disabled="disabled"

                        />
                        <span className="text-danger">{errors.mobile}</span>
                    </div>}
                    {(this.state.mobile ==""  || typeof this.state.mobile == 'undefined') && <div className="form-group col-md-12">
                    <input
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        id="user-update-email"
                                        type="text"
                                        className="d-none"/>
                        <label>Email</label>
                        <input onChange={this.onChange} value={this.state.email}
                            id="user-update-email"
                            type="text" className="form-control" disabled="disabled"

                        />
                        <span className="text-danger">{errors.email}</span>
                    </div>}
                    <div className="form-group col-md-4" style={{display:(this.state.showResults? 'block':'none')}}>
                        <button className="btn btn_man ">Save</button>
                    </div>
                </div>
              </form>

            
        )
    }
}

KycUpdateMobile.propTypes = {
    updateKyc: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    prodata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    prodata: state.prodata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateKyc }
)(withRouter(KycUpdateMobile));
