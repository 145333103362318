import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";

const UpDown = () => {
  console.log("hiiii");
  return (
    <div>
      <Navbar urlPath={true} symbol={false} />
      <Sidebar />
      <div className="in_page">
        <div className="container">
        <div className="row">
  <div className="col-xl-4 col-lg-6 col-md-6  mt-3 mt-md-4">
    <div className="crypt-market-status2 past_man_box">
      <div className="past_man_box-inner">
        <div className="top_contest text-center">
            <h6>Free Game (Practice/Contst)</h6>
        </div>
        <div className="row mt-3">
          
          <div className="col-6 col-md-6">
            <span>Total Players </span>
          </div>
          <div className="col-6 col-md-6 ">
            <span className="past_value">  <i className="fa fa-user" /> 12740</span>
          </div>
          <div className="col-6 col-md-4 ">
            <span> Prize Pool</span>
          </div>
          <div className="col-6 col-md-8 ">
            <span className="past_value">
              <i className="fa fa-inr" /> 505.00 EOS
            </span>
          </div>
          <div className="col-6 col-md-4 ">
            <span> Win Ratio</span>
          </div>
          <div className="col-6 col-md-8 ">
            <span className="past_value">
              1.10x
            </span>
          </div>
          <div className="col-6 col-md-4">
            <span> Ration</span>
          </div>
          <div className="col-6 col-md-8">
            <span className="past_value"> 1.10x</span>
          </div>
        </div>
        <div className="details-outer row">
          <div className="col-6 col-md-6 ">
            <a
              className="view_dital"
              href="/trading-contest/65866db3f35e3e1a90b6b0db"
            >
              <span>
                <i className="las la-trophy" /> View Details
              </span>
            </a>
          </div>
          <div className="col-6 col-md-6 tr">
            <a
              href="up-down-free"
              className="btn btn_man p_button">
               Play Now
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-xl-4 col-lg-6 col-md-6  mt-3 mt-md-4">
    <div className="crypt-market-status2 past_man_box">
      <div className="past_man_box-inner">
        <div className="top_contest text-center">
            <h6>Cash 5 Minut Slot (Bull/Bear)</h6>
        </div>
        <div className="row mt-3">
          
          <div className="col-6 col-md-6">
            <span>Total Players </span>
          </div>
          <div className="col-6 col-md-6 ">
            <span className="past_value">  <i className="fa fa-user" /> 12740</span>
          </div>
          <div className="col-6 col-md-4 ">
            <span> Prize Pool</span>
          </div>
          <div className="col-6 col-md-8 ">
            <span className="past_value">
              <i className="fa fa-inr" /> 505.00 EOS
            </span>
          </div>
          <div className="col-6 col-md-4 ">
            <span> Win Ratio</span>
          </div>
          <div className="col-6 col-md-8 ">
            <span className="past_value">
              1.10x
            </span>
          </div>
          <div className="col-6 col-md-4">
            <span> Ration</span>
          </div>
          <div className="col-6 col-md-8">
            <span className="past_value"> 1.10x</span>
          </div>
        </div>
        <div className="details-outer row">
          <div className="col-6 col-md-6 ">
            <a
              className="view_dital"
              href="/trading-contest/65866db3f35e3e1a90b6b0db"
            >
              <span>
                <i className="las la-trophy" /> View Details
              </span>
            </a>
          </div>
          <div className="col-6 col-md-6 tr">
            <button
              type="button"
              className="btn btn_man p_button"
              data-toggle="modal"
              data-target="#exampleModal"
            >
             Play Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-xl-4 col-lg-6 col-md-6  mt-3 mt-md-4">
    <div className="crypt-market-status2 past_man_box">
      <div className="past_man_box-inner">
        <div className="top_contest text-center">
            <h6>Cash 10 Minut Slot (High/Low)</h6>
        </div>
        <div className="row mt-3">
          
          <div className="col-6 col-md-6">
            <span>Total Players </span>
          </div>
          <div className="col-6 col-md-6 ">
            <span className="past_value">  <i className="fa fa-user" /> 12740</span>
          </div>
          <div className="col-6 col-md-4 ">
            <span> Prize Pool</span>
          </div>
          <div className="col-6 col-md-8 ">
            <span className="past_value">
              <i className="fa fa-inr" /> 505.00 EOS
            </span>
          </div>
          <div className="col-6 col-md-4 ">
            <span> Win Ratio</span>
          </div>
          <div className="col-6 col-md-8 ">
            <span className="past_value">
              1.10x
            </span>
          </div>
          <div className="col-6 col-md-4">
            <span> Ration</span>
          </div>
          <div className="col-6 col-md-8">
            <span className="past_value"> 1.10x</span>
          </div>
        </div>
        <div className="details-outer row">
          <div className="col-6 col-md-6 ">
            <a
              className="view_dital"
              href="/trading-contest/65866db3f35e3e1a90b6b0db"
            >
              <span>
                <i className="las la-trophy" /> View Details
              </span>
            </a>
          </div>
          <div className="col-6 col-md-6 tr">
            <button
              type="button"
              className="btn btn_man p_button"
              data-toggle="modal"
              data-target="#exampleModal"
            >
             Play Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UpDown;
