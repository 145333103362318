import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Moment from 'moment';
import HelpCenterUpdate from "../partials/help/HelpCenterUpdate";
import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
import ReactGA from 'react-ga';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class Exchange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                subjects: '',
                discription: ''
            }
        };

        this.getData = this.getData.bind(this);
    }



    getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl+"api/profile-data", { _id: user.id })
            .then(res => {
                this.setState({ records: res.data.data })

            })
            .catch()
    }
    componentDidMount() {
        ReactGA.initialize('UA-218461585-1');
        ReactGA.pageview('/help-center');
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.helpdata !== undefined
            && nextProps.helpdata.help !== undefined
            && nextProps.helpdata.help.data !== undefined
            && nextProps.helpdata.help.data.message !== undefined
            && nextProps.helpdata.help.data.status) {
                toast.success( nextProps.helpdata.help.data.message, toastStyles);                
        }
        this.getData()
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <>
                <Navbar urlPath={false} symbol={false}/>
                <Sidebar />
                <HelpCenterUpdate record={this.state.records} />
                <Footer />
                <Toaster />
            </>

        );
    }

}

Exchange.propTypes = {
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Exchange);
