import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
const isEmpty = require("is-empty");

class UserOrderHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_id: '',
            record: this.props.record,
        };
 }
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({ 
                record: nextProps.record
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {
            this.setState({
                message: nextProps.auth.user.data.message
            });
        }
    }

    showHtml = () => {
        let counts = this.state.record.length;

        const html = []
        if (counts) {
            this.state.record.map(function (value, i) {
				if(value.status==="success") {
					if (value.orderType == "Buy") {
						html.push(
							<>
								<tr key={i}>
									<td>{Moment(value.date).format('lll')}</td>
									<td>{value.symbol.slice(0, -4)}</td>
									<td className="crypt-up">{value.orderType}</td>
									<td className="crypt-up">{value.price}</td>
									<td className="crypt-up">{value.amount}</td>
									<td className="crypt-up">{value.price*value.amount}</td>
								   
								</tr>
							</>
						);
					}
					if (value.orderType == "Sell") {
						html.push(
							<>
								<tr key={i}>
									<td>{Moment(value.date).format('lll')}</td>
									<td>{value.symbol.slice(0, -4)}</td>
									<td className="crypt-down">{value.orderType}</td>
									<td className="crypt-down">{-value.price}</td>
									<td className="crypt-down">{-value.amount}</td>
									<td className="crypt-down">{value.price*value.amount}</td>
								  
								</tr>
							</>
						);
					}
				}
            })
        } else {
            html.push(
                <>
                    <br /> <br /> <br />
                    <div className="text-center">No Record Found</div>
                </>
            )
        }
        return html;
    }

    render() {
        return (
            <table className="table table-striped border_none mb-0">
                    <tr>
                        <th scope="col">Time</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Buy/sell</th>
                        <th scope="col">Price USDT</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total</th>
                       
                    </tr>
                <tbody>
                    {this.showHtml()}

                </tbody>
            </table>
        );
    }

}

UserOrderHistory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(UserOrderHistory);
