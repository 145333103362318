import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { BrowserRouter as Router, Route, Switch,Redirect,withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { addWithdrawalRe } from "../../actions/withdrawalActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import {toast,Toaster } from 'react-hot-toast';
import $ from 'jquery';
import classnames from "classnames";
import Modal from 'react-modal';
import {Link} from "react-router-dom";
import SidebarWallet from "./SidebarWallet";
// import * as taxData from "../../../../config/tax";
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const tdsDataValue = 30;
const config = {
  headers: {
    Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
  },
};
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    
  },
};
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }


class Withdral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      videoStatus:"",
      withdrawalAmount:"",
      errors: {},
	  userBankDetail:[],
	  bankAccount:"",
    tdsDeduct:"",
    receivedAmount:"",
    confrimWithDrawal:false,
    tdsDataValue:tdsDataValue,
    };


  }
  
   componentDidMount() {
        this.getBankDetail()
    };
  
   getBankDetail=()=> {
      const { user } = this.props.auth;
	   const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios
            .get(baseUrl+"api/user_bank_account_list",config)
            .then(res => {
				
                this.setState({ userBankDetail: res.data.data,userBankDetailStatus:res.data.status})
            })
            .catch()
   }
   
   bankAccountListHtml=()=>{
	   var optionHtml=[];
	   optionHtml.push(<option value=''>Select</option>)
	   if(this.state.userBankDetailStatus===true){
		   for(var i=0; i < this.state.userBankDetail.length; i++){
			var singleOption = <option value={this.state.userBankDetail[i]._id}>{this.state.userBankDetail[i].accountNo+" - "+this.state.userBankDetail[i].bankName} </option>
			optionHtml.push(singleOption);
		   }
		   return <select onChange={this.onChange} id="bank_account"  className="form-control">{optionHtml}</select>
	   }
   }

  componentWillReceiveProps(nextProps) {  
    console.log("nextProps.transdata.trans.data=",nextProps.transdata.trans.data);
      if (nextProps.errors) {
        this.setState({
          errors: nextProps.errors
        });
      }
      if (nextProps.transdata !== undefined
        && nextProps.transdata.trans !== undefined
        && nextProps.transdata.trans.data !== undefined
        && nextProps.transdata.trans.data.message !== undefined
        && nextProps.transdata.trans.data.status) {
          this.setState({
            message: "",
            withdrawalAmount:"",
            errors: {}
        })
        toast.success(nextProps.transdata.trans.data.message, toastStyles);
       }else if (nextProps.transdata !== undefined
        && nextProps.transdata.trans !== undefined
        && nextProps.transdata.trans.data !== undefined){
        this.setState({
            errors: nextProps.transdata.trans.data
        });
      }   
    }
    getdata3() {   
      axios
          .post(baseUrl+"api/profile-data",{},config)
          .then(res => {
            if((res.data.data.videoStatus==="pending" || res.data.data.videoStatus==="reject" || res.data.data.bImage_status === "pending" || res.data.data.bImage_status === "reject" || res.data.data.addressStatus==="pending" || res.data.data.addressStatus==="reject" || res.data.data.taxStatus==="pending" || res.data.data.taxStatus==="reject" || res.data.data.mobileVerifyStatus==="pending" || res.data.data.mobileVerifyStatus==="reject")&& this.props.urlPath!=false){
               this.setState({modalIsOpen: true}); 
            }
          });
          
    }
    onChange = e => {
        this.setState({
            message: "",
            errors: {}
        })
        if (e.target.id === 'withdrawalAmount') {
          this.setState({ withdrawalAmount: e.target.value });
          // console.log("e.target.value==",e.target.value);
            if (/^0/.test(e.target.value)) {
                e.target.value = e.target.value.replace(/^0/, "")
            }

            // if(e.target.value !=""){
              
              let withdrawalAmount = e.target.value;
              let totalTax = withdrawalAmount * (tdsDataValue/(100+tdsDataValue));
    
              console.log("totalTax==",totalTax);
              let receivedAmount = withdrawalAmount-totalTax;
              this.setState({receivedAmount:receivedAmount.toFixed(2),tdsDeduct:totalTax.toFixed(2),confrimWithDrawal:true});
            // }
        }
		
		if (e.target.id === 'bank_account') {
       
            this.setState({ bankAccount: e.target.value });
        }
      };
	  
      confirmWithdrawal = e => {
        e.preventDefault();
        console.log("tdsDataValue==",tdsDataValue);
        if(this.state.withdrawalAmount !=""){
          let withdrawalAmount = this.state.withdrawalAmount;
          let totalTax = withdrawalAmount * (tdsDataValue/(100+tdsDataValue));

          console.log("totalTax==",totalTax);
          let receivedAmount = withdrawalAmount-totalTax;
          this.setState({receivedAmount:receivedAmount.toFixed(2),tdsDeduct:totalTax.toFixed(2),confrimWithDrawal:true});
        }
      }
	  
      onUserUpdate = e => {
        e.preventDefault();
         if(this.state.withdrawalAmount===""){
          toast.error('Withdrawal amount is required',
          toastStyles
        );
			return false;
		}
    if(this.state.withdrawalAmount<=0){
          toast.error('Please enter valid withdrawal amount',
          toastStyles
        );
      return false;
    }

		if(this.state.bankAccount===""){      
        toast.error('First bank account is required',
          toastStyles
        );
      
      setTimeout(function () {
        window.location.href = "/bankdetail";
      },2000);
			// return false;
		}		

    // const random =  Math.random() * (8);
    const { user } = this.props.auth;
    const newUser = {
      user_id: user.id, 
      amount: this.state.withdrawalAmount,
      bank_account_id: this.state.bankAccount
      // rand: random 
    };
    this.getdata3();
    this.props.addWithdrawalRe(newUser);
    };
  render() {
    const { errors } = this.state;
    if(this.state.videoStatus!=""){
      var videom=this.state.videoStatus;
    }
    return (
        <>
        <Navbar urlPath={true} symbol={false}/>
        <Sidebar />
        <div className="container container2">
           <div className="in_page ">

            <SidebarWallet/>

            <div className="mt-4">
              <div className=" padding_d" >
               <div className="tab-content">
            
            
                 <div className="white_box " >
            <h5 className=" mb-3 ">Withdrawal Request
            </h5>
            <div className="" >
        
            <form noValidate onSubmit={this.onUserUpdate} id="update-user" >
              <div className="form-group " >
              {/* <label>Bank Account <Link to="/bankdetail" target="_blank" className="btn_addnew">Add New</Link></label> */}
              
				  {this.bankAccountListHtml()}

              <br/>		  
			   <label>Amount<span className="text-danger"> *</span></label>
			     <div className="row">
			       <div className="col-md-4">
				    <input type="number" placeholder="Amount" value={this.state.withdrawalAmount} onChange={this.onChange} id="withdrawalAmount"  className="form-control" pattern="[0-9]*"/>
             {this.state.confrimWithDrawal && <><span>Received Amount : {this.state.receivedAmount}<br/></span>
              <span >TDS Deduct Amount: {this.state.tdsDeduct}</span><br/>
              <span >TDS Percentage: {this.state.tdsDataValue}%</span></>}
              {/* <span className="text-danger">{errors.message}</span> */}
				   </div>
           <div className="col-md-6">
            {/* <button type="button" className="btn btn_man" onClick={this.confirmWithdrawal}>Confirm</button> */}
            <button type="submit" className="btn btn_man">Submit</button>
				   </div>
				    {/* <div className="col-md-8">
            <br/><br/>{this.state.confrimWithDrawal}
				    <button type="submit" disabled={this.state.confrimWithDrawal?false:true} className="btn btn_man">Submit</button>
				   </div> */}
				 </div>
             

             
             
              </div>
            </form>
        
          
        
            </div>
            </div>
             
        
       
              
              </div>
        
            </div>
        
        
            </div>
             </div>
             </div>
             {videom !="verify" &&
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <h2 ref={subtitle => this.subtitle = subtitle}>Verified Your KYC</h2>
       
          <a href="/kyc" className="click-here"> Click Here</a>
         
        </Modal>
        }
        <Footer />
        <Toaster/>
        </>
    );
  }
}

Withdral.propTypes = {
  addWithdrawalRe: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  transdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  transdata: state.transdata,
  errors: state.errors
});

export default connect(
  mapStateToProps,
 {addWithdrawalRe}
)(withRouter(Withdral));
