import React, { Component } from "react";
class NotFound extends Component {

    render() {

        return (
            <main className="for04">
			  <h1>4<span className="mr-2"><img src="img/logo_mobile.svg"/></span>4</h1>
			  <h2 className="mb-4">Sorry, The Page You're Looking For<br/> Cannot be Accessed</h2>
			 
			  <a href="/" className="btn btn_man">Back To Home</a>
			</main>
        );
    }
}
export default NotFound;
