import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loading from "../layout/Loading";
import ReactGA from 'react-ga';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import BigNumber from 'bignumber.js'

class MobileExchange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contestId: this.props.match.params.id,
            symbol: this.props.match.params.symbol,
            tradingTickerData:"",

        };

        this.tradesCount = 10;
        this.streams = ['@ticker', '@depth20', '@trade'];
    }



    _connectSocketStreams(streams) {

        streams = streams.join('/');

        let connection = btoa(streams);

        this[connection] = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${streams}`);


        this[connection].onmessage = evt => {
            let eventData = JSON.parse(evt.data);
            if (eventData.stream.endsWith('@ticker')) {
                eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
                this.props.dispatch({
                    type: 'SET_TICKER',
                    data: eventData.data
                })
                this.setState({
                    loadedTicker: true,
                    tradingTickerData:eventData.data
                })
            }

            if (eventData.stream.endsWith('@trade')) {
                if (this.props.trades && Object.keys(this.props.trades).length > 0) {
                    let trades = this.props.trades;
                    trades.push(eventData.data);
                    trades = trades.slice(-1 * this.tradesCount);
                    this.props.dispatch({
                        type: 'SET_TRADES',
                        data: trades
                    })
                    this.setState({
                        loadedTrades: true,
                    })
                }
            }
            if (eventData.stream.endsWith('@depth20')) {
                this.props.dispatch({
                    type: 'SET_DEPTH',
                    data: eventData.data
                })
                this.setState({
                    loadedDepth: true,
                })
            }
            this.setState({
                isLoaded: true
            })
        };
        this[connection].onerror = evt => {
            // console.error(evt);
        }
    }
    _disconnectSocketStreams(streams) {
        streams = streams.join('/');
        let connection = btoa(streams);
        if (this[connection].readyState === WebSocket.OPEN) {
            this[connection].close();
        }
    }

    componentDidMount() {
        ReactGA.initialize('UA-218461585-1');
        ReactGA.pageview('/exchange');
        let symbolType = this.props.match.params.symbol;
        let symbol = this.props.match.params.symbol.toLowerCase();
        this._connectSocketStreams(this.streams.map(i => `${symbol}${i}`));
    };

    openTradingChart = (symbol) => (
        <TradingViewWidget
            symbol={"BINANCE:" + symbol}
            theme={Themes.DARK}
            locale="en"
            autosize={true}
            details={true}

        />
    );

    render() {
        const { error, isLoaded, loadedDepth, loadedTicker, loadedTrades } = this.state;
        if (error) {
            return <div className="alert alert-danger"></div>;
        }
        if (!isLoaded) {
            return <Loading />;
        }
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <div style={{flexDirection: 'column', fontSize:'12px'}}>
                        <p style={{margin: 0}}>Last Price: <span className={new BigNumber(this.state.tradingTickerData.c).gte(new BigNumber(this.state.tradingTickerData.lastc)) ? 'text-success' : 'text-danger'}>{new BigNumber(this.state.tradingTickerData.c).toFormat(null,1)}</span>
                        </p>
                        <p style={{margin: 0}}>Change: <span className={this.state.tradingTickerData.p < 0 ? 'text-danger' : 'text-success'}>{`${new BigNumber(this.state.tradingTickerData.p).toFormat(null,1)} (${new BigNumber(this.state.tradingTickerData.P).toFormat(2,1)}%)`}</span></p>
						
                    </div>
                    <div style={{flexDirection: 'column', fontSize:'12px',textAlign:'right'}}>
                        <p style={{margin: 0, whiteSpace: 'nowrap'}}>24h-High: <strong className="ng-binding">{new BigNumber(this.state.tradingTickerData.h).toFormat(null,1)}</strong></p>
                        <p style={{margin: 0, whiteSpace: 'nowrap'}}>24h-Low: <strong className="ng-binding">{new BigNumber(this.state.tradingTickerData.l).toFormat(null,1)}</strong></p>
                       
                    </div>

                </div>
				 <p style={{margin: 0, whiteSpace: 'nowrap', fontSize:'12px'}}>24h Volume: <strong className="ng-binding">{new BigNumber(this.state.tradingTickerData.q).toFormat(2,1)} {this.state.tradingTickerData && this.state.tradingTickerData.s.length === 7 ? this.state.tradingTickerData.s.slice(-4) : this.state.tradingTickerData && this.state.tradingTickerData.s.slice(-3)}</strong></p>
                <div style={{ height: 'calc(100vh - 95px)', width: "100%" }}>
                    {this.openTradingChart(this.props.match.params.symbol)}
                </div>
            </>
        );
    }

}

MobileExchange.propTypes = {
    auth: PropTypes.object.isRequired,
    contestdata: PropTypes.object.isRequired,
    orderdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    contestdata: state.contestdata,
    orderdata: state.orderdata,
    records: state.records,

});

export default connect(

    state => state
)(MobileExchange);
