import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requery } from "../../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import * as myConstList from '../../../BaseUrl';
import moment from 'moment';
import Slider from "react-slick";
import { interMediate } from '../../../actions/kycUpdate';
import TimerMyContest from '../contest/TimerMyContest';
import TimerContest from '../contest/TimerContest';
import {Modal,Button } from 'react-bootstrap';

const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
const baseUrl = myConstList.baseUrl;
class MyContest extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            myUpcomingData: [],
            myPastData: [],
            id: this.props.id,
            description: '',
            message: '',
            status: "",
            username: user.name,
            showResults: false,
            showTeaxtArea: true,
            entryFee: 0,
            checked: false,
            bonusper: 0,
            bonus: 0,
            totalde: 0,
            errors: {},
			days:[],
			hours:[],
			minutes:[],
			seconds:[],
            show:false,
            intervalArr :[]
        };
        this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        this.getData();
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.serverTime) {
            this.setState({
                serverTime: nextProps.serverTime,
            });
        }
        if (nextProps.record) {
            console.log("nextProps.record=",nextProps.record);
                this.setState({
                    records: nextProps.record,
                    bonus:nextProps.bonus
                });
                for (var i = 0; i <this.state.intervalArr.length; i++){
                    let intName = this.state.intervalArr[i];
                   clearInterval(intName);
                   console.log("checkInterval==",intName);
                   console.log("this.state.intervalArr[i]upcoming==",this.state.intervalArr[i]);
                }
                nextProps.record.map((value, i)=> {
                    var intervalName = value.contestName+"my";
                    let startDateTime1 = value.startDate+" "+value.startTime;
                    let expriryDateTime1 = value.endDate+" "+value.endTime;
                    if(value.contestType ==='Upcomming') {
                    	this.showTimerCounterHtml(startDateTime1,i,intervalName)
                    }
                    else if(value.contestType ==='Live'){
                    	this.showTimerCounterHtml(expriryDateTime1,i,intervalName);
                    }
                })
        }
       

        
    }
    getData() {
        const { user } = this.props.auth;
        const currentTime = moment().utcOffset('+05:30').format('HH:mm');
        const endTime = moment().utcOffset('+05:30').add(1, 'minutes').format('HH:mm');
        	
        axios
            .post(baseUrl + "api/profile-data", {},config)
            .then(res => {
                // if (res.data.data.videoStatus !== "verify" && this.props.urlPath != false) {
                //     this.setState({ modalIsOpen: true });
                // }

                this.setState({ bonus: res.data.data.bonus })
            })
            .catch()

    }
    getNodesToRemoveFromElement = (stringContent) => {
        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };



	showTimerCounterHtml = (getDateTime,getIndex,intervalName) => {     
        var checkDateTime = new Date(getDateTime);
        // var today = new Date();
        // var timeComplete = moment().format('HH')+':'+moment().format('mm')+":"+moment().format('ss');
        // var currentDate1 = ("0" + (today.getUTCDate())).slice(-2);
        // var dateformat = today.getUTCFullYear()+'-'+(today.getUTCMonth()+1)+'-'+currentDate1+" "+timeComplete;
        var dateformat = this.state.serverTime;
        var currentDate = new Date(dateformat);

        checkDateTime = Date.parse(checkDateTime);
        checkDateTime = checkDateTime/1000;
        currentDate = Date.parse(currentDate);
        currentDate = currentDate/1000;
        var collectThis = this;
        console.log("dataTimer1");
        var intervalStart = intervalName;
        intervalStart = setInterval(async () => {
            currentDate = currentDate + 1;
            collectThis.secondsToHms(currentDate, checkDateTime,intervalStart,getIndex)
        }, 1000); 	
        let intervalTempArr = this.state.intervalArr;
        let intervalStart1 = intervalStart;
        intervalTempArr.push(intervalStart1);	
        this.setState({intervalArr:intervalTempArr});		
      }

      secondsToHms=(currentDate, expriry,intervalName,getIndex)=>{
        const countdown = moment(expriry-currentDate);
        var duration = moment.duration(countdown * 1000, 'milliseconds');
        var interval = 1000;
        duration = moment.duration(duration - interval, 'milliseconds');

        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        const symbol1 =this.props.symbol;
        if(seconds<0){
            clearInterval(intervalName);
        }else{
            console.log("dataTimer3=",days,hours,minutes,seconds);
            this.state.days[getIndex] = days;
            this.state.hours[getIndex] = hours;
            this.state.minutes[getIndex] = minutes;
            this.state.seconds[getIndex] = seconds;
            this.setState({aa : "done"});
            //location.reload();
        }
       };
      
    showChatHtml = () => {
        var month_name = function (dt) {
            var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return mlist[dt.getMonth()];
        };
        const { user } = this.props.auth;
        const html = []
        let classThis = this;
        if(this.state.records.length>0){
            // console.log("this.state.records=",this.state.records);
            return this.state.records.map( (value, i)=> {
                // // start date time
                // let startDateTime1 = value.startDate+" "+value.startTime;
                // var startDateTime = new Date(startDateTime1);
                // // end date time
                // let expriryDateTime1 = value.endDate+" "+value.endTime;
                // var endDateTime = new Date(expriryDateTime1);

                // var expriry = Date.parse(endDateTime);
                // expriry = expriry/1000;
                // var currentDate = Date.parse(startDateTime);
                // currentDate = currentDate/1000;
                // const countdown = moment(expriry-currentDate);
                // var duration = moment.duration(countdown * 1000, 'milliseconds');
                // var interval = 1000;
                // duration = moment.duration(duration - interval, 'milliseconds');
                // const days = duration.days();
                // const hours = duration.hours();
                // const minutes = duration.minutes();
                // const seconds = duration.seconds();
                // time diffrence
                var dateObj1 = new Date(value.startDate);
                var day1 = dateObj1.getUTCDate()
                var dateObj2 = new Date(value.endDate);
                var day2 = dateObj2.getUTCDate()
				
				// if(value.contestType ==='Upcomming') {
				// 	setTimeout(function(){ classThis.showTimerCounterHtml(startDateTime1,i) },1000);
				// }
				// else if(value.contestType ==='Live'){
				// 	setTimeout(function(){ classThis.showTimerCounterHtml(expriryDateTime1,i) },1000);
				// }

                return (<>
                     <div className="col-xl-4 col-lg-6 col-md-6  mt-3 mt-md-4">
                    <div className="crypt-market-status2 past_man_box">
                        <div className="past_man_box-inner">
						
						     <div className="row top_contest ">
                                <div className="col-6 ">
                                    { value.contestType =='Live' && <span>
									<span className="live_bg lup">LIVE</span>
                                        </span>
                                    }
                                    { value.contestType ==='Upcomming' && <span> <span className="upcomiong_bg lup">UPCOMING</span>                              
                                        </span>
                                     
                                    }
                                    { value.contestType ==='Past' && <span><span className="past_bg lup">PAST</span></span>
                                     
                                    }
                                    
                                </div>
								 <div className="col-6 tr">
                                    <h6 className="mb-0">{value.contestName}</h6>
                                </div>
                                </div>                           
                                { (value.contestType ==='Live' || value.contestType ==='Upcomming') &&
                                    <span className="row mt-3">
                                        <div className="col-4">
                                            <span> {(value.contestType ==='Live') ? "End In" : "Start In"}</span>
                                        </div>
                                        <div className="col-8 tr">
                                            <span className="timerdiv2 past_value">
											<span>{classThis.state.days[i]}D</span>
											<span>:</span>
											<span>{classThis.state.hours[i]}H</span>
											<span>:</span>
											<span>{classThis.state.minutes[i]}M</span>
											<span>:</span>
											<span className="timercounter">{classThis.state.seconds[i]}S </span>
										    </span>
                                        </div>
                                    </span>
                                }
								{ (value.contestType ==='Past') &&
                                    <span className="row  mt-3">
                                        <div className="col-6 col-md-4">
                                            <span > Started At</span>                             
                                        </div>								
                                        <div className="col-6 col-md-8">                                  
                                        <span className="past_value">{month_name(new Date(value.startDate))}, {day1} ({value.startTime}) </span>
                                        </div>
                                    </span>
                                }
								
								
                            <div className="row ">
                                
								<div className="col-6 col-md-4 ">
                                   <span > Prize pool</span>
                                </div>
								
								<div className="col-6 col-md-8 ">
                                    <span className="past_value"><i className="fa fa-inr"></i> {value.winningAmount}</span>
                                </div>
								<div className="col-6 col-md-4 ">
                                   <span > Entry Fee</span>
                                </div>
								<div className="col-6 col-md-8 ">
                                   <span className="past_value"><i className="fa fa-inr"></i> {value.entryFee}</span>
                                   
                                </div>
								
								
                                <div className="col-6 col-md-4">
                                    <span > Participants</span>
                                   
                                </div> 
								
								<div className="col-6 col-md-8">
                                  
                                    <span className="past_value">{value.totalParticipat+"/"+ (value.max_particepate!=undefined ?value.max_particepate:"0")}</span>
                                </div></div>
                                { value.contestType =='Live' && 
                                    <span className="row">
                                        <div className="col-6 col-md-4">
                                            <span > Started At</span>                             
                                        </div>								
                                        <div className="col-6 col-md-8">                                  
                                        <span className="past_value">{month_name(new Date(value.startDate))}, {day1} ({value.startTime}) </span>
                                        </div>
                                    </span>
                                }
                                { value.contestType =='Upcomming' && 
                                    <span className="row">
                                        <div className="col-6 col-md-4">
                                            <span > End On</span>                             
                                        </div>								
                                        <div className="col-6 col-md-8">                                  
                                        <span className="past_value">{month_name(new Date(value.endDate))}, {day2} ({value.endTime}) </span>
                                        </div>
                                    </span>
                                }
                                { value.contestType =='Past' && 
                                    <span className="row">
                                        <div className="col-6 col-md-4">
                                            <span > Ended At </span>                             
                                        </div>								
                                        <div className="col-6 col-md-8">                                  
                                        <span className="past_value">{month_name(new Date(value.endDate))}, {day2} ({value.endTime}) </span>
                                        </div>
                                    </span>
                                }
                            
                            <div className="details-outer row" >
							  <div className="col-6 col-md-6 ">
                                      <Link className="view_dital" to={"/trading-contest/" + value.contest_id} ><span><i className="las la-trophy"></i> View Details</span></Link>
                                </div>
								{
                                    value.contestType=='Live' && 
                                    <div className="col-6 col-md-6 tr">
                                    {value.currentStatus == "ACTIVE" ?
                                    value.playNow== 'yes'? <Link className="play-now btn btn_man" to={"/exchange/" + value.contest_id+"/"+value.pairRealName}><i className="las la-trophy"></i>Going On</Link>:<Link className="play-now btn btn_man" to={"/exchange/" + value.contest_id+"/"+value.pairRealName} onClick={() => classThis.playNow(value.contest_id)}><i className="las la-trophy"></i>Play Now</Link>
                                    : value.numOfParticipation!= value.max_particepate ? <button type="button" className="btn btn_man p_button"  onClick={() => classThis.participatedata(value._id,value.entryFee,value.virtualFund,value.max_particepate)} data-toggle="modal" data-target="#exampleModal" >Participate Now </button> : "Participation limit exceeded" }                                         
                                </div> 
                                }   
                                {
                                    value.contestType=='Upcomming' && 
                                    <div className="col-6 col-md-6 pt-2 tr">
                                        <span>Already Participated</span>                             
                                    </div> 
                                }                         
                            </div>   
                        </div>                        
                    </div>
               </div> </>
                )
            })
        }
        
    }
	
	
	showMyUpcomingDataHtml = () =>{
        var month_name = function(dt){
          var  mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
              return mlist[dt.getMonth()];
            };
        let classThis = this;
        const html = []
        this.state.myUpcomingData.map(function(value, i){
             // start date time
             let startDateTime1 = value.startDate+" "+value.startTime;
             var startDateTime = new Date(startDateTime1);
             // end date time
             let expriryDateTime1 = value.endDate+" "+value.endTime;
             var endDateTime = new Date(expriryDateTime1);

             var expriry = Date.parse(endDateTime);
             expriry = expriry/1000;
             var currentDate = Date.parse(startDateTime);
             currentDate = currentDate/1000;
             const countdown = moment(expriry-currentDate);
             var duration = moment.duration(countdown * 1000, 'milliseconds');
             var interval = 1000;
             duration = moment.duration(duration - interval, 'milliseconds');
             const days = duration.days();
             const hours = duration.hours();
             const minutes = duration.minutes();
             const seconds = duration.seconds();
             // time diffrence
            var dateObj1 = new Date(value.startDate);
            var day1 = dateObj1.getUTCDate()
            var dateObj2 = new Date(value.endDate);
            var day2 = dateObj2.getUTCDate()
			
			
			
          html.push(
            <>
               <div className="col-md-4  mt-3 mt-md-4">
               <div className="crypt-market-status2 past_man_box">
                   <div className="past_man_box-inner">

					<div className="row top_contest">
					<div className="col-12 col-md-6 "> 
					<span className="op_0_5">{month_name(new Date(value.startDate))}, {day1} ({value.startTime}) <span style={{background:'#496ECE',fontWeight:'bold',padding:'4px',color:'#FFF'}}>UPCOMING</span>
                 
                    </span>
					</div>
					<div className="col-12 col-md-6 tr"> <h6 >{value.contestName}</h6></div>
					</div>
					
					
					
                    <div className="row mt-3">
                                <div className="col-12 col-md-6 ">
                                    <span className="op_0_5"> Duration</span>
                                </div> 
								<div className="col-12 col-md-6 ">
                                    <span className="past_value">{days} days {hours} hr {minutes} min {seconds} sec</span>
                                </div>

								<div className="col-6 col-md-6 ">
                                    <span className="op_0_5"> Prize pool</span>
                                </div> 
								
								<div className="col-6 col-md-6 ">
                                    <span className="past_value">{value.winningAmount}</span>
                                </div> 
								
								<div className="col-6 col-md-3 ">
                                    <span className="op_0_5"> Entry Fee</span>
                                   
                                </div>
								<div className="col-6 col-md-3 ">
                                   
                                    <span className="past_value">{value.entryFee}</span>
                                </div>
								
								
								
                                <div className="col-6">
                                    <span className="op_0_5"> Participants</span>
                                </div>
								
								<div className="col-6">
                                   
                                    <span className="past_value">{value.numOfParticipation+"/"+ (value.max_particepate!=undefined ?value.max_particepate:"0")}</span>
                                </div>
                            </div>
                    <div className="details-outer row" >
					 <div className="col-6 col-md-6 ">
                        <Link className="view_dital" to={"/trading-contest/" + value._id} ><span><i className="las la-trophy"></i> View Details</span></Link>
						</div>
						  
                       </div> 
                </div>
               </div>
               </div>
             </>
            );
        })
        return html;
    }
	
	
	
	
    participatedata(id, entryFee, virtualFund, max_particepate) {
        this.setState({ id: id, entryFee: entryFee, virtualFund: virtualFund, max_particepate: max_particepate,show:true });


    }
    participate(id, entryFee, virtualFund, max_particepate, checked) {

        const { user } = this.props.auth;
        axios
            .post(baseUrl + "api/participateCondition", { contest_id: id,entryFee: entryFee, virtualFund: virtualFund, max_particepate: max_particepate, checked: checked },config)
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        status: "active"
                    });

                }
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                })
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            })
            .catch();
    }
    playNow(contestId){
        axios
            .post(baseUrl + "api/updatePlayNowCondition", { contest_id: contestId},config)
            .catch();
    }
    onChange = e => {
        // alert(e.target.value,"fsfd");

        if (e.target.id === 'user-update-subject') {
            this.setState({ subject: e.target.value });
        }

    };
    handleCheck = e => {


        var bonusper = this.state.entryFee * 10 / 100;
        var totalde = this.state.entryFee - bonusper;

        if (this.state.checked == false) { this.setState({ checkBox: true, bonusper: bonusper, totalde: totalde }); } else {

            this.setState({ checkBox: false, bonusper: 0, totalde: 0 });
        }


        this.setState({ checked: !this.state.checked });
    }
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUserUpdate = e => {
        const { user } = this.props.auth;
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
            user_id: user.id,
            description: this.state.description
        };
        this.props.requery(newUser);
    };

    hideModal=()=>{
        this.setState({show:false});
    }

    render() {
        var settings = {
            initialSlide: 0,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 3500,
            dots: true,
            arrows: false,
            pauseOnHover: true,
            pauseOnFocus: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    adaptiveHeight: true,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 575,
                  settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                  },
                },
            ],
        };

        const { errors } = this.state;
        const { user } = this.props.auth;
        return (
            <>
                <div className="row">
               
                    {this.showChatHtml()}
             
                </div>
                <Modal
                    show={this.state.show}
                    onHide={this.hideModal}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    <h5 className="modal-title" id="exampleModalLabel">Select an option to pay - ₹ {this.state.entryFee}</h5>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                   
                    <div className="form-check">

                    <label className="form-check-label" for="flexCheckChecked">
                        Cash Wallet : ₹ {this.state.totalde ? this.state.totalde : this.state.entryFee}
                    </label>
                    </div>

                    <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleCheck} defaultChecked={this.state.checked} />
                    <label className="form-check-label" for="flexCheckChecked">
                        Bonus  : ₹ {this.state.bonusper}
                        <br />Total to participate: ₹ {this.state.entryFee}
                        <br /><span className="text-danger">Note: You can use maximum 10% of entry fees from the bonus</span>
                    </label>
                    </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideModal}>Close</Button>
                        <button variant="primary" className="btn btn_man" onClick={() => this.participate(this.state.id, this.state.entryFee, this.state.virtualFund, this.state.max_particepate, this.state.checked)}>Participate</button>
                    </Modal.Footer>
                </Modal>
            </>

        )
    }
}

MyContest.propTypes = {
    requery: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    contestdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    contestdata: state.contestdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { requery }
)(withRouter(MyContest));
