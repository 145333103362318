import React, { Component } from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";

import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:this.props.match.params.userId,
            token:this.props.match.params.token,
            password2: "",
            password: "",
            message:'',
            messageerror:'',
            err:'',
			type:"password",
            typePassConfm:"password",
            errors: {
          
        },
         
        };
    }


    onChange = e => {
        this.setState({ errors: ''});
        this.setState({ [e.target.id]: e.target.value });
		if(e.target.id=="password"){
            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            var test = strongRegex.test(e.target.value);
     
            if (test) {         
                this.setState({ passwordnew: "strong password",
                statusB:false});
            }else{
                this.setState({ errors: {password: "Min 8 characters(Including 1 Upper case, 1 Numeric value, 1 Special symbol)"}});   
            }
        }
        if(e.target.id=="password2"){
            if(e.target.value !== this.state.password){
                this.setState({ errors: {password2: "Passwords must match"}}); 
            }
        }

    };

     
   
    onSubmit = e => {
        e.preventDefault();
		
		const strongRegexSubmit = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		var testSubmit = strongRegexSubmit.test(this.state.password);
 
		if (!testSubmit) {         
			this.setState({ errors: {password: "Min 8 characters(Including 1 Upper case, 1 Numeric value, 1 Special symbol)"}}); 
			return false;
		}
		if(this.state.password2 !== this.state.password){
			this.setState({ errors: {password2: "Passwords must match"}});  
			return false;
		}
        axios
            .post(baseUrl+"api/reset-password", {userId: this.state.userId,token: this.state.token,password: this.state.password,password2: this.state.password2})
            .then(res => {
               
                 if (res.data.status) {
                     toast.success( res.data.message, toastStyles);
                  
                }
                if (res.data.err) {
                       toast.error( res.data.err, toastStyles);
                    
                }
                if (res.data.password || res.data.password2 ) {
                       toast.error( res.data,toastStyles);
            }
              
            })
            .catch(error=>{
                // alert(error);
            }

            );
    };
	
	
	
     eyeFucntion= e =>{
        if(this.state.type=="password"){
         this.setState({type:"text"});
        }else{
         this.setState({type:"password"});
        }
    }
    eyeConfmPassFucntion= e =>{
        if(this.state.typePassConfm=="password"){
         this.setState({typePassConfm:"text"});
        }else{
         this.setState({typePassConfm:"password"});
        }
    }
    render() {
        const { errors } = this.state;
        return (
            <>
                <div className="container">
                <div className="row mt-5">
                    <div className="col-md-5 mx-auto">
                    <div className="white_box">
                        <div className="card-body p-1">

                        <h4 className="text-center  mt-3 mb-3">Reset Password</h4>

                            <form noValidate onSubmit={this.onSubmit}>
                                <label htmlFor="password">Password</label>
								<div className="form-group position-relative">
								
                                <input onChange={this.onChange}  style={{color:'#000000'}} id="password" type={this.state.type=='password'?"password":"text"} className={classnames("form-control", { invalid: errors.email1 })} />
								<i className= {this.state.type=='password'?"las la-low-vision eyestyle":"las la-eye eyestyle"} onClick={this.eyeFucntion} ></i>
                                
                         
                                <span className="text-danger f12">{errors.password}</span>
								</div>
                            <label class="mt-3" htmlFor="password">Confirm Password</label>
						   <div className="form-group position-relative">
                          

                                   <input onChange={this.onChange} value={this.state.password2}
                                    error={errors.password2}
                                    id="password2"
                                    type={this.state.typePassConfm=='password'?"password":"text"} 
                                    style={{color:'#000000'}}
                                   
                                    className={classnames("form-control", {
                                        invalid: errors.password2
                                    })}
                                />
								<i className= {this.state.typePassConfm=='password'?"las la-low-vision eyestyle":"las la-eye eyestyle"} onClick={this.eyeConfmPassFucntion} autoComplete="off"></i>
                                   <span className="text-danger f12">{errors.password2}</span>
								   
								 </div>  
                                   <p className="text-center pb-0 mt-2">

                                    <button
                                        type="submit"
                                        className="btn  mt-2 w100 btn_man">
                                         Reset Password
                                    </button> 

                                </p>
                                <div className="text-center"><Link to={"/"} ><i className="las la-arrow-left mr-2"></i> Go to Home</Link></div>

                            </form>
                        
                    </div>
                    </div>
                </div>
            </div>
            </div>
               <Toaster/>
            </>

        );
    }
}


export default ResetPassword;
