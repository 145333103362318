import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requery } from "../../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import {toast} from 'react-hot-toast';
import axios from "axios";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Moment from 'moment';
import { Link } from "react-router-dom";
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }

class HelpQueryModal extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            id: this.props.id,
            description: '',
            message: '',
            issueImageR:'',
            username: user.name,
            replyBy: this.props.userrecords.replyTo,
            showResults: false,
            showTeaxtArea: true,
            invalidImage:'',
            status:"",
            errors: {},
        };
 }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userrecords) {
            this.setState({
              status:nextProps.userrecords.status
            });
        }
        // console.log("nextProps.userrecords.status=",nextProps.userrecords.status);
        if (nextProps.userrecords.status === "Resolved") {
            this.setState({
                showTeaxtArea: false,
               
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.helpdata !== undefined
            && nextProps.helpdata.help !== undefined
            && nextProps.helpdata.help.data !== undefined
            && nextProps.helpdata.help.data.message !== undefined
            && nextProps.helpdata.help.data.status) {

            this.setState({
                description: "",
                issueImageR:"",
                errors: {}
            });
        }
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };
    showChatHtml = () => {
        const html = []
        var classThis=this;
        this.props.record.map(function (value, i) {
            console.log("value===",value);
            if (value.replyBy == "USER") {
                html.push(
                    <>
                       
                        <div className="container_white chatroom" style={{ textAlign: 'right' }}>
                        <div className="row mb-2 time-right">
						   <div className="col-auto pr-0">
						     <img className="userimg" src="/img/u.png" />
						   </div>
						    <div className="col-auto">
						      <p className="fw-500 mt-2">{classThis.getNodesToRemoveFromElement(value.description)}</p>
						    </div>
						</div>                           
                            {value.issueImage !== undefined && value.issueImage !== '' &&
                            <a href={baseUrl+"helpIssue/" + value.issueImage} target="_blank"> <img className="subject_img" src={baseUrl+"helpIssue/"+value.issueImage} /></a>

                            }
                            <br/>
                            <span className="time-right">{Moment(value.date).format('lll')}</span>
                        </div>
                    </>
                );
            }
            if (value.replyBy == "ADMIN") {
                html.push(
                    <>
                    <div className="container darker chatroom">
					<div className="row mb-2">
						  <div className="col-auto pr-0">
						      <img className="userimg" src="/img/admin.png" />
						   </div>
						    <div className="col-auto">
						      <p className="fw-500 mt-2">{classThis.getNodesToRemoveFromElement(value.description)}</p>
						   </div>
						</div>
						{value.issueImage !== undefined && value.issueImage !== '' &&
                            <a href={baseUrl+"helpIssue/" + value.issueImage} target="_blank"><img className="subject_img" src={baseUrl+"helpIssue/"+value.issueImage} /></a>}<br/>
                            <span className="time-left">{Moment(value.date).format('lll')}</span>
                        </div>
                </>
                );
            }

        })
        return html;
    }
    onChange = e => {

        this.setState({
            message: "",
            errors: {}
        });
        if (e.target.name === 'issueImage') {
            var issueImage = e.target.files[0];
            console.log('issueImage');
            console.log(issueImage);
            var fileSize = e.target.files[0].size;
            if (fileSize > 1000000) {
                this.setState({ invalidImage: "Maximum 1 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
           
            if (!issueImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }

            this.setState({
                issueImageR: issueImage,
            });
        }
        if (e.target.id === 'user-update-subject') {
            this.setState({ subject: e.target.value });
        }

    };
    handleCommentChange = (event, editor) => {
        this.setState({
            message: "",
            errors: {}
        });
        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onQueryUpdate = e => {
        e.preventDefault();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const { user } = this.props.auth;
        axios.post(baseUrl+"api/chat-room-tichekdata", { _id:this.props.match.params.id,user_id:user.id })
        .then(res => {
            if(res.data.status === "Resolved"){
                toast.error("Your Ticket Resolved Please check",toastStyles);
                return false;
            }else{
                if(this.state.description == ""){
                    toast.error("Please enter description",toastStyles);
                    return false;
                }
                const formData = new FormData();
                formData.append('_id', this.props.match.params.id);       
                formData.append('user_id', user.id);
                formData.append('description', this.state.description);
                formData.append('issueImage', this.state.issueImageR);
                this.props.requery(formData, config);
            }          
        })
        .catch()
       
       
    };
    render() {
        const { errors } = this.state;
        const { user } = this.props.auth;
        return (
            <div className="container">
                <form noValidate onSubmit={this.onQueryUpdate} id="update-user">
                    <input onChange={this.onChange} value={this.state.id} id="user-update-id"
                        type="text" className="d-none" />
                    <div className="in_page row" >
                        <div className="col-md-2" ></div>
                        <div className="col-md-8" >
                            <div className=" padding_d" >
                                <div className="white_box mt-3" >
								<div className="row mb-2">
							  <div className="col-auto pr-0">
								  <img className="userimg" src="/img/u.png" />
							   </div>
								<div className="col-auto">
								   <p className="fw-500 mt-2">Username: {this.state.username}</p>
							   </div>
							</div>
								
                                    
                                    <p className="mb-0"><b>Ticket Id:</b> {this.props.userrecords.id}</p>
                                    <p className="mt-0"><b>Status:</b> {this.state.status}</p>
                                    <p className="mt-0"><b>Description:</b> {this.getNodesToRemoveFromElement(this.props.userrecords.description)}</p>
                                    <p>
                                        {this.props.userrecords.issueImage !==undefined && this.props.userrecords.issueImage !=="" &&
                                            <Link to={baseUrl+"/helpIssue/" + this.props.userrecords.issueImage} target="_blank"><img className="subject_img" src={baseUrl+"helpIssue/"+this.props.userrecords.issueImage} /></Link>
                                        }
                                    </p>
                                    <h4 className=" mt-4">Subject: {this.props.userrecords.subject}</h4>
                                    <hr />
                                    {/* <div className="container_white chatroom"> 
                          <div className="row mb-2">
							  <div className="col-auto pr-0">
								  <img className="userimg" src="/img/u.png" />
							   </div>
								<div className="col-auto">
								   <p className="fw-500 mt-2">{this.getNodesToRemoveFromElement(this.props.userrecords.description)}</p>
							   </div>
							</div>									
                                    
                                        <p>
                                        {this.props.userrecords.issueImage !==undefined && this.props.userrecords.issueImage !=="" &&
                                            <Link to={baseUrl+"/helpIssue/" + this.props.userrecords.issueImage} target="_blank"><img className="subject_img" src={baseUrl+"helpIssue/"+this.props.userrecords.issueImage} /></Link>
                                        }</p>
                                        <span className="time-right">{Moment(this.props.userrecords.date).format('lll')}</span>
                                    </div> */}

                                    {this.showChatHtml()}
                                    <hr />
                                    <div style={{ display: (this.state.showTeaxtArea ? 'block' : 'none') }} >
                                        <div className="form-group u_name">
                                        </div>
                                        <div><span className="text-success">{this.state.message}</span></div>
                                        <div className="form-group " >
                                            <CKEditor
                                                editor={ClassicEditor}
                                                value={this.state.description}
                                                onChange={this.handleCommentChange}
                                                placeholder="Enter Description"
                                                className="form-control form_control_textarea"
                                                data={this.state.description}
                                            />
                                            <span classNameName="text-danger">{errors.description}</span>
                                        </div>
                                        <div className="form-group">
                                            <div className="add_fileinput"><i className="fa fa-plus-circle mr-1"></i>Add File<input className="profile_input" type="file" name="issueImage" onChange={this.onChange} /></div><span className="text-danger">{this.state.invalidImage}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" className="btn btn_man" value="Submit" style={{ display: (this.state.showResults && this.state.showTeaxtArea ? 'block' : 'none') }}/>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

HelpQueryModal.propTypes = {
    requery: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { requery }
)(withRouter(HelpQueryModal));
