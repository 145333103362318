import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { videoKyc } from "../../../actions/kycUpdate";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class VerifiVideo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            video: this.props.record.video,
            videoTemp: "",
            videoStatus:this.props.record.videoStatus,
            v_rejectResion:this.props.record.v_rejectResion,
            message: '',
            invalid: '',
            showResults: false,
            errors: {}
        };
      

    }
   
    componentWillReceiveProps(nextProps) {
   
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
              
                videoStatus:nextProps.record.videoStatus,
                v_rejectResion:nextProps.record.v_rejectResion,
            })
}


        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined
            && nextProps.kycdata.kyc.data.messagev !== undefined
            && nextProps.kycdata.kyc.data.status) {

            this.setState({
                message: nextProps.kycdata.kyc.data.messagev,
                video:"",
                errors: {},
                invalid:""

            });
            setTimeout(function () {
                window.location.reload();
            }, 500);
        }
    }

    onChange = e => {
        this.setState({
            message: "",
            errors: {},
            invalid:""
        })
        if (e.target.name === 'video') {
            this.setState({ invalid: "" });
            
            
            var video = e.target.files[0];
            var fileSize = e.target.files[0].size;
            if (fileSize >= 10000000 || fileSize < 1000000) {
                this.setState({ invalid: "Please select video between 1 MB to 10 MB", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }

            if (!video.name.match(/\.(mp4)$/)) {
                this.setState({ invalid: "Please select mp4 format video.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
          

            this.setState({
                video: video,
                showResults: this.state.name === null ? false : true,
                videoTemp:URL.createObjectURL(e.target.files[0])

            });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })

    };

    onUserUpdate = e => {

        e.preventDefault();
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer '+`${localStorage.getItem("jwtToken")}`
            }
        }
        const { user } = this.props.auth;
        const formData = new FormData();
        formData.append('id', user.id);
        formData.append('video', this.state.video);
        this.props.videoKyc(formData, config);

    };

    render() {
        const { errors } = this.state;
      
       
        return (

            <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                <span className="text-success">{this.state.message}</span>
                <div className="">
                    <h3 className="mb-3">Face Verification </h3>
                    <label className="fw-500">Select Video {this.state.videoStatus == "reject" ? <> Status: <span className="text-danger">Rejected</span><button type="button" class="btn btn_man ml-2 q_btn" data-toggle="modal" data-target="#exampleModalPan">
                                <i className="fa fa-info"></i>
                    </button> </>:<> { this.state.videoStatus !== undefined && <span>Status: <span className="text-success">{this.state.videoStatus}</span></span>}</> }</label>
                    <div className="row">
                    {this.state.videoTemp === '' && this.state.video !== undefined && this.state.video !== ''&&
                     <>                    
					 <div className="kyc_video col-md-4">
					 <div className="upload_img">
                    <video controls>
                     <source src={baseUrl+"static/video/" + (this.state.video!="" && this.state.video!=undefined? this.state.video:"")} type="video/mp4" />
                    </video>
                    </div>
                    </div>
                    </>
                    }
                    {this.state.videoTemp !== undefined && this.state.videoTemp !== ''&&
                     <>
                      <div className="kyc_video col-md-4">
					   <div className="upload_img">
                    <video controls>
                     <source src={this.state.videoTemp} type="video/mp4" />
                    </video>
                    </div>
                    </div>
                    </>
                    }

                    {(this.state.videoStatus == "reject" || this.state.videoStatus == undefined ) &&
                    <>
                        <div className="form-group col-md-4">
                            <div className="add_fileinput mb-2"><i className="fa fa-plus-circle mr-1"></i>Upload Video
                                <input onChange={this.onChange}
                                    id="video"
                                   
                                    name="video"
                                    type="file" className="profile_input"

                                />
                               </div> <span className="text-danger">{this.state.invalid}</span>
                        </div>
                     <div className="form-group col-md-4" style={{ display: (this.state.showResults ? 'block' : 'none') }}>
                            <button type="submit" className="btn btn_man w110">Submit</button>
                        </div>
                        </>
                    }
                    </div>
                    <div class="modal fade" id="exampleModalPan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Rejected Reason</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {this.state.v_rejectResion}
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{color:'#ff2929'}}>
                    *The video must be clear and unedited.<br/>
                    *Face must be clearly visible and user should not be wearing any mask, stylish goggles etc.<br/>
                    *User must be showing the same documents which were uploaded in the "Photo ID Verification" and  "Address Verification" section.<br/>
                    *Min size 1 MB / Max size 10 MB.<br/>
                    *Supported format: Mp4
                    </div>
                </div>
 </form>
)
    }
}

VerifiVideo.propTypes = {
    videoKyc: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    kycdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    kycdata: state.kycdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { videoKyc }
)(withRouter(VerifiVideo));
