import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requery } from "../../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import {toast} from 'react-hot-toast';
import $ from 'jquery';
import axios from "axios";
import * as myConstList from '../../../BaseUrl';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import TimerContest from '../contest/TimerContest';
import {Modal,Button } from 'react-bootstrap';

const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
const baseUrl = myConstList.baseUrl;
class UpcomingContest extends React.Component {

    constructor(props) {        
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records:[],
            bonus:0,
            id: this.props.id,
            description: '',
            message: '',
            username:user.name,
            showResults: false,
            showTeaxtArea: true,
            entryFee: 0,
            checked: false,
            bonusper: 0,
            bonus: 0,
            totalde: 0,
            errors: {},
			days:[],
			hours:[],
			minutes:[],
			seconds:[],
            show:false,
            cashWalletChecked:false,
            winningWalletChecked:false,
            rewardWalletChecked:false,
            cashWalletDeduct:0,
            winningWalletDeduct:0,
            rewardWalletDeduct:0,
            participateBtnDisable:false,
            intervalArr :[]
        };

    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.serverTime) {
            console.log('HIIII')
            this.setState({
                serverTime: nextProps.serverTime,
            });
        }
        if (nextProps.record) {
            //if(nextProps.record.length !== this.state.records.length ){
                this.setState({
                    records: nextProps.record,
                    bonus:nextProps.bonus,
                    cashWalletBalance:nextProps.cashWalletBalance,
                    winningWalletBalance:nextProps.winningWalletBalance, 
                });
            //}
            // var interval_id = window.setInterval(()=>{}, 99999);
            // for (var i = 0; i < interval_id; i++)
            // console.log(i);
                // window.clearInterval(i);
                // console.log("intervalLength=",this.state.intervalArr.length);
                for (var i = 0; i <this.state.intervalArr.length; i++){
                    let intName = this.state.intervalArr[i];
                   clearInterval(intName);
                   console.log("checkInterval==",intName);
                   console.log("this.state.intervalArr[i]upcoming==",this.state.intervalArr[i]);
                }
                nextProps.record.map((value, i)=> {
                    let expriryDateTime1 = value.startDate+" "+value.startTime;
                    var intervalName = value.contestName+"upcoming";
                    this.showTimerCounterHtml(expriryDateTime1,i,intervalName);
                })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {
            this.setState({
                message: nextProps.auth.user.data.message
            });
        }
    }
    getNodesToRemoveFromElement = (stringContent) => { 
        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };
    participatedata=(id, entryFee, virtualFund, max_particepate) =>{
        this.setState({ id: id, entryFee: entryFee, virtualFund: virtualFund, max_particepate: max_particepate,show:true });


    }
    participate(id, entryFee, virtualFund, max_particepate, checked) {
        this.setState({participateBtnDisable:true});
        const { user } = this.props.auth;
        var sendData = { contest_id: id,
                         entryFee: entryFee, 
                         virtualFund: virtualFund, 
                         max_particepate: max_particepate, 
                         cashWalletChecked: this.state.cashWalletChecked,
                         winningWalletChecked: this.state.winningWalletChecked,
                         rewardWalletChecked: this.state.rewardWalletChecked   
                        }
        axios
            .post(baseUrl + "api/participateCondition", sendData ,config)
            .then(res => {
                this.setState({participateBtnDisable:false});
                if (res.data.status) {
                    this.setState({
                        status: "active",
                        show:false
                    });
                    toast.success(res.data.message, toastStyles)
                    setTimeout(function () {
                        window.location.reload();
                    }, 2000);
                }else{
                    toast.error(res.data.message, toastStyles)
                }  
            })
            .catch();
    }
   
	
	handleRewardWalletCheck = e => {
        
        this.setState({ rewardWalletChecked: !this.state.rewardWalletChecked });
        this.calculateIt(this.state.cashWalletChecked,this.state.winningWalletChecked,!this.state.rewardWalletChecked);
    }

    handleCashWalletCheck = e => {
        
        this.setState({ cashWalletChecked: !this.state.cashWalletChecked });
        this.calculateIt(!this.state.cashWalletChecked,this.state.winningWalletChecked,this.state.rewardWalletChecked);
    }

    handleWinningWalletCheck = e => {
       
        this.setState({ winningWalletChecked: !this.state.winningWalletChecked });
        this.calculateIt(this.state.cashWalletChecked,!this.state.winningWalletChecked,this.state.rewardWalletChecked);
    }

    calculateIt=(cashCheck,winningCheck,rewardCheck)=>{
       
        var remainingAmount = this.state.entryFee;
        var rewardWalletDeduct = 0;
        var winningWalletDeduct = 0;
        var cashWalletDeduct = 0;

        if(rewardCheck){
            var rewardWalletDeduct = remainingAmount * 50 / 100;
            rewardWalletDeduct = (rewardWalletDeduct>this.state.bonus)  ? this.state.bonus : rewardWalletDeduct;
            var remainingAmount = remainingAmount - rewardWalletDeduct; 
        }

        if(winningCheck){

            var winningWalletDeduct = remainingAmount;
            winningWalletDeduct = (parseInt(winningWalletDeduct) > parseInt(this.state.winningWalletBalance))  ? this.state.winningWalletBalance : winningWalletDeduct;
            
            var remainingAmount = remainingAmount - winningWalletDeduct; 
        }

        if(cashCheck){
            var cashWalletDeduct = remainingAmount;
            cashWalletDeduct = (cashWalletDeduct>this.state.cashWalletBalance)  ? this.state.cashWalletBalance : cashWalletDeduct;
            var remainingAmount = remainingAmount - cashWalletDeduct; 
        }

        
        this.setState({
            rewardWalletDeduct:rewardWalletDeduct,
            winningWalletDeduct:winningWalletDeduct,
            cashWalletDeduct:cashWalletDeduct,
        })

    }
	
	
	showTimerCounterHtml = (getStartDateTime,getIndex,intervalName) => {     
        var startContestDateTime = new Date(getStartDateTime);
        // var today = new Date();
        // var timeComplete = moment().format('HH')+':'+moment().format('mm')+":"+moment().format('ss');
        // var currentDate1 = ("0" + (today.getUTCDate())).slice(-2);
        // var dateformat = today.getUTCFullYear()+'-'+(today.getUTCMonth()+1)+'-'+currentDate1+" "+timeComplete;
        var dateformat = this.state.serverTime;
        console.log(dateformat,'date from server')
        var currentDate = new Date(dateformat);

        startContestDateTime = Date.parse(startContestDateTime);
        startContestDateTime = startContestDateTime/1000;
        currentDate = Date.parse(currentDate);
        currentDate = currentDate/1000;
        var collectThis = this;
        console.log("dataTimer1");
        var intervalStart = intervalName;
        intervalStart = setInterval(async () => {
            currentDate = currentDate + 1;
            collectThis.secondsToHms(currentDate, startContestDateTime,intervalStart,getIndex)
        }, 1000); 	
        let intervalTempArr = this.state.intervalArr;
        let intervalStart1 = intervalStart;
        intervalTempArr.push(intervalStart1);	
        this.setState({intervalArr:intervalTempArr});	
      }
      secondsToHms=(currentDate, expriry,intervalName,getIndex)=>{
        const countdown = moment(expriry-currentDate);
        var duration = moment.duration(countdown * 1000, 'milliseconds');
        var interval = 1000;
        duration = moment.duration(duration - interval, 'milliseconds');

        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        const symbol1 =this.props.symbol;
        if(seconds<0){
            clearInterval(intervalName);
            // this.props.history.push("/contest/live");
            window.location.reload();
        }else{
            //console.log("dataTimer3=",days,hours,minutes,seconds);
            this.state.days[getIndex] = days;
            this.state.hours[getIndex] = hours;
            this.state.minutes[getIndex] = minutes;
            this.state.seconds[getIndex] = seconds;
            this.setState({aa : "done"});
            //location.reload();
        }
       };
	
	
    showChatHtml = () =>{
        var month_name = function(dt){
          var  mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
              return mlist[dt.getMonth()];
            };
        let classThis = this;
        const html = []

         this.state.records.map(function(value, i){
             // start date time
             let startDateTime1 = value.startDate+" "+value.startTime;
             var startDateTime = new Date(startDateTime1);
             // end date time
             let expriryDateTime1 = value.endDate+" "+value.endTime;
             var endDateTime = new Date(expriryDateTime1);

            var dateObj1 = new Date(value.startDate);
            var day1 = dateObj1.getUTCDate()
            var dateObj2 = new Date(value.endDate);
            var day2 = dateObj2.getUTCDate()
			
			
			// setTimeout(function(){ classThis.showTimerCounterHtml(startDateTime1,i) },1000);
          html.push(
            <>
                 <div className="col-xl-4 col-lg-6 col-md-6  mt-3 mt-md-4">
               <div className="crypt-market-status2 past_man_box">
                   <div className="past_man_box-inner">

					<div className="row top_contest">
					
					<div className="col-12 text-center"> <h6 >{value.contestName}</h6></div>
					</div>
                    <div className="row mt-3">
							{	/*<div className="col-4 ">
                                    <span className="op_0_5"> Duration</span>
                                </div>
                                <div className="col-8 tr">
                                <span className="timerdiv2 past_value">
                                    <span>{days}D</span>
                                    <span>:</span>
                                    <span>{hours}H</span>
                                    <span>:</span>
                                   <span>{minutes}M</span>
                                    <span>:</span>
                                  <span className="timercounter">{seconds}S </span>
								  </span>
								</div> */ }								
								<div className="col-4 ">
                                    <span className="op_0_5"> Starts In</span>
                                </div>
                                <div className="col-8 tr">
                                {/* <TimerContest key ={value._id+value.startDate+" "+value.startTime} endDateTime={value.startDate+" "+value.startTime} startDateTime={value.startDate+" "+value.startTime} serverTime={classThis.state.serverTime} item={classThis.state.records} /> */}
                                <span className="timerdiv2 past_value">
									<span>{classThis.state.days[i]}D</span>
									<span>:</span>
									<span>{classThis.state.hours[i]}H</span>
									<span>:</span>
									<span>{classThis.state.minutes[i]}M</span>
									<span>:</span>
									<span className="timercounter">{classThis.state.seconds[i]}S </span>
								</span>
								</div>
								
								<div className="col-6 col-md-4">
                                    <span >Ends on </span>
                                   
                                </div> 
                                <div className="col-6 col-md-8 ">
                                   <span className="past_value">{month_name(new Date(value.endDate))}, {day2} ({value.endTime})</span>
                                </div>
								
								<div className="col-6 col-md-6 ">
                                    <span className="op_0_5"> Prize pool</span>
                                </div> 
								
								<div className="col-6 col-md-6 ">
                                    <span className="past_value"><i className="fa fa-inr"></i> {value.winningAmount} </span>
                                </div> 
								
								<div className="col-6 col-md-6">
                                    <span className="op_0_5"> Entry Fee</span>
                                   
                                </div>
								<div className="col-6 col-md-6 ">
                                   
                                    <span className="past_value"><i className="fa fa-inr"></i> {value.entryFee}</span>
                                </div>
								
								
								
                                <div className="col-6">
                                    <span className="op_0_5"> Participants</span>
                                </div>
								
								<div className="col-6">
                                   
                                    <span className="past_value">{value.numOfParticipation+"/"+ (value.max_particepate!=undefined ?value.max_particepate:"0")}</span>
                                </div>
                                {/*<div className="col-6 col-md-4">
                                    <span >Starts on </span>
                                   
                                </div> 
                                <div className="col-6 col-md-8 ">
                                   <span className="past_value">{month_name(new Date(value.startDate))}, {day2} ({value.startTime})</span>
                                </div>*/}
                            </div>
                    <div className="details-outer row" >
					 <div className="col-6 col-md-6">
                        <Link className="view_dital" to={"/trading-contest/" + value._id} ><span><i className="las la-trophy"></i> View Details</span></Link>
						</div>
						 <div className="col-6 col-md-6 tr">
                        {value.currentStatus == "ACTIVE" ? <h6> Already Participated </h6> :value.numOfParticipation!= value.max_particepate ? <button type="button" className="btn btn_man p_button"  onClick={() => classThis.participatedata(value._id,value.entryFee,value.virtualFund,value.max_particepate)} data-toggle="modal" data-target="#participateModal" >Participate Now </button> : "Participation limit exceeded"}
                          </div> 
                       </div> 
                </div>
               </div>
               </div>
             </>
            );
        })
        return html;
    }
    onChange = e => {
        if (e.target.id === 'user-update-subject') {
            this.setState({ subject: e.target.value });
        }
    };


    onUserUpdate = e => {
        const { user } = this.props.auth;
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
             user_id: user.id,
             description: this.state.description


        };
        this.props.requery(newUser);
    };
    
    hideModal=()=>{
        this.setState({show:false});
    }
   
    render() {
        var settings = {
            initialSlide: 0,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 3500,
            dots: true,
            arrows: false,
            pauseOnHover: true,
            pauseOnFocus: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    adaptiveHeight: true,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 575,
                  settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                  },
                },
            ],
            
        };
        const { errors } = this.state;
        const { user } = this.props.auth;
        return (
            <>
            <div className="row">
                    {this.showChatHtml()}

			 </div>
            

             <Modal
                    show={this.state.show}
                    onHide={this.hideModal}
                    dialogClassName="modal-90w mx400"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    <h5 className="modal-title" id="exampleModalLabel">Select an option to pay - ₹ {this.state.entryFee}</h5>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                   
                    <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleCashWalletCheck} defaultChecked={this.state.cashWalletChecked} />    
                        <label className="form-check-label" for="flexCheckChecked">
                        <span className="amountshow">
                            Cash Wallet({this.state.cashWalletBalance}):</span>₹ {this.state.cashWalletDeduct}
                        </label>
                    </div>
                    
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleWinningWalletCheck} defaultChecked={this.state.winningWalletChecked} />
                        <label className="form-check-label" for="flexCheckChecked">
                        <span className="amountshow">
                        Winning Wallet({this.state.winningWalletBalance}):</span>₹ {this.state.winningWalletDeduct}
                            
                        </label>
                    </div>

                    <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="termsandcondition" value='1' onChange={this.handleRewardWalletCheck} defaultChecked={this.state.rewardWalletChecked} />
                    <label className="form-check-label" for="flexCheckChecked">
                    <span className="amountshow">
                    Reward Wallet({this.state.bonus}):</span>₹ {this.state.rewardWalletDeduct}
                        <br />  <span className="amountshow">Total to participate:</span>₹ {this.state.entryFee}
                        <br /><span className="text-danger">Note: You can use maximum 50% of entry fees from the reward wallet</span>
                    </label>
                    </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideModal}>Close</Button>
                        <button variant="primary" className="btn btn_man" onClick={() => this.participate(this.state.id, this.state.entryFee, this.state.virtualFund, this.state.max_particepate, this.state.checked)}>Participate</button>
                    </Modal.Footer>
                </Modal>
        </>
        )
    }
}

UpcomingContest.propTypes = {
    requery: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { requery }
)(withRouter(UpcomingContest));
