import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// // import * as firebase from 'firebase';
// import '@firebase/messaging';
// console.log("firebaseenter111");

// import firebase from 'firebase/app';
import 'firebase/messaging';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdB85ouF4y081rFXhpZEZJpMdRUw6KsgU",
  authDomain: "trade-the-games.firebaseapp.com",
  projectId: "trade-the-games",
  storageBucket: "trade-the-games.appspot.com",
  messagingSenderId: "992569054244",
  appId: "1:992569054244:web:0a2ba72732df35e51034b1",
  measurementId: "G-ZEL5NYD19B"
};
firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();
export const  getToken = async (setTokenFound) => {
  let currentToken = '';
  try {
    // currentToken = await messaging.getToken({vapidKey: 'BANH8n8iK07pVT5bnZvvvxM2RjkdxqR8TqaG_4bWGmoeDxcYqNCFXwUSl_kpm3JguPMY0yGnL2PZMxJWTE3nSJc'});
    // if (currentToken) {
    //   setTokenFound(true);
    // } else {
    //   setTokenFound(false);
    // }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    // messaging.onMessage((payload) => {
    //   resolve(payload);
    // });
  });