import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateHelpIssue } from "../../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditor } from 'ckeditor4-react';
import 'react-toastify/dist/ReactToastify.css';

class HelpCenterUpdate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            subject: '',
            description: '',
            issueImage: '',
            message: '',
            invalidImage: '',
			otherSubject:'',
            showResults: false,
            errors: {},
        };

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.helpdata !== undefined
            && nextProps.helpdata.help !== undefined
            && nextProps.helpdata.help.data !== undefined
            && nextProps.helpdata.help.data.message !== undefined
            && nextProps.helpdata.help.data.status) {

            this.setState({
                subject: "",
                description: "",
                userIssueImage: "",
                errors: {}
            });
        }
    }
    onChange = e => {
        this.setState({
            message: "",
            errors: {}
        });
        if (e.target.id === 'user-update-subject') {
            this.setState({ subject: e.target.value });
        }
		
		 if (e.target.id === 'user-update-othersubject') {
            this.setState({ otherSubject: e.target.value });
        }
		
        if (e.target.name === 'issueImage') {
			this.setState({userIssueImage:URL.createObjectURL(e.target.files[0])});
            var issueImage = e.target.files[0];

            var fileSize = e.target.files[0].size;
            if (fileSize > 10000000) {
                this.setState({ invalidImage: "Maximum 10 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
            console.log(this.state.invalidImage);
            if (!issueImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }

            this.setState({
                issueImage: issueImage,
            });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };
    handleCommentChange = (event, editor) => {
        this.setState({
            message: "",
            errors: {}
        });
        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }

    };

    onUserUpdate = e => {

        e.preventDefault();

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
		
		
		if(this.state.subject.length===0){
			 this.setState({errors:{subject:"Subject is required"}});
			 return false;
		}
		
		if(this.state.description.length===0){
			 this.setState({errors:{description:"Description is required"}});
			 return false;
		}
		
		if(this.state.subject==="Others" && this.state.otherSubject.length===0){
			 this.setState({errors:{otherSubject:"Other Subject is required"}});
			 return false;
		}
        var otherSubject ="";
        if(this.state.subject==="Others"){
            otherSubject = this.state.otherSubject.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' '); 
        }
		
		var sendSubject = (this.state.subject==="Others") ? otherSubject : this.state.subject;
		
		
        const formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('subject', sendSubject);
        formData.append('description', this.state.description);
        formData.append('issueImage', this.state.issueImage);
        this.props.updateHelpIssue(formData, config);

    };
	
	
	showOtherSubjectBox=()=>{
		if(this.state.subject==="Others"){
			return ( 
			           <div className="mt-3">
					         <label className="fw-500"> Other Subject<span class="text-danger"> *</span></label>
							<input
								value={this.state.otherSubject}
								onChange={this.onChange}
								placeholder="Other Subject"
								className="form-control form_control"
								id="user-update-othersubject"
							/>
						</div>
			)
		}
		else {
			return( 
			<></>
			)
		}
	}

    render() {
        const { errors } = this.state;
        return (
            <div className="container">
                <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                    <input
                        onChange={this.onChange}
                        value={this.state.id}
                        id="user-update-id"
                        type="text"
                        className="d-none" />
                    <div className="in_page row" >
                        <div className="col-md-1" ></div>
                        <div className="col-md-10" >
                            <div className=" padding_d" >
                                <h6 className=" mb-3">Help Center</h6>
                                <div className="white_box mt-3 kyc-outer" >
                                    <span className="text-success">{this.state.message}</span>
                                    <div className="form-group">
                                        <label className="fw-500">Subject<span className="text-danger"> *</span></label>
                                        <select
                                            onChange={this.onChange}
                                            value={this.state.subject}
                                            id="user-update-subject"
                                            type="text"
                                            placeholder="Enter Issue"
                                            error={errors.subject}
                                            className={classnames("form-control", {
                                                invalid: errors.subject
                                            })} >
											<option value="">Select</option>
											<option value="Wallet">Wallet</option>
											<option value="Contest">Contest</option>
											<option value="Trading">Trading</option>
											<option value="Withdraw">Withdraw</option>
											<option value="Others">Others</option>	
											</select>
                                        <span className="text-danger">{errors.subject}</span>
											{this.showOtherSubjectBox()}
										<span className="text-danger">{errors.otherSubject}</span>	
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-500"> Description<span className="text-danger"> *</span></label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            value={this.state.description}
                                            onChange={this.handleCommentChange}
                                            placeholder="Enter Description"
                                            class="form-control form_control_textarea"
                                            data={this.state.description}
                                        />
                                        <span className="text-danger">{errors.description}</span>
                                    </div>
                                    <div className="form-group">
                                        <label className="fw-500">Attachments</label>
                                        <div className="add_fileinput mb-2"><i className="fa fa-plus-circle" ></i> Add File
                                            <input className="profile_input" onChange={this.onChange} type="file" name="issueImage" /></div>
											 {this.state.userIssueImage !== undefined && this.state.userIssueImage !== '' &&
												<>
													<div className="row">
													<div className="col-6">
													<div className="upload_img">
													<img src={this.state.userIssueImage!==''?this.state.userIssueImage:""} alt="Cinque Terre"></img></div></div></div>
												</>
											}
                                        <span className="text-danger">{this.state.invalidImage}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" className="btn btn_man" value="Submit" />
                                    </div></div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

HelpCenterUpdate.propTypes = {
    updateHelpIssue: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateHelpIssue }
)(withRouter(HelpCenterUpdate));
