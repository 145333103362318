import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { TempUserLogin } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Header from "../../components/pages/Header";
import Register from "../../components/auth/Register";
import SubscribeUser from "../../components/auth/SubscribeUser";
import ForgetPassword from "../../components/auth/ForgetPassword";
import Footer from "../../components/pages/Footer";
import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Scroll from 'react-scroll';
import $ from 'jquery';
import {Modal,Button } from 'react-bootstrap';


const Link = Scroll.Link;

const baseUrl = myConstList.baseUrl;
class Home extends Component {
    constructor() {
        super();
        this.state = {         
            balance:'',
           errors: {},
           showLogin:true,
           email:"",
           mobile:"",
           password:""
        };
		
		this.button = React.createRef();
		this.signupTabBtn = React.createRef();
     
    }

    componentDidMount() {
        if(this.props.match.params._id != undefined){
            axios
                .post(baseUrl+"api/email-verification", {userId: this.props.match.params._id})
                .then(res => {     
                        toast( res.data.message);
                this.props.history.push("/");
            })
            .catch(error=>{
                // alert(error);
            });
            
        }
        
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
		// if(this.props.match.params.refer!==undefined){
		// 	// this.button.current.click();
		// 	this.signupTabBtn.current.click();
			
		// }
		
    };

    componentWillReceiveProps(nextProps) {        
        if (nextProps.auth.isAuthenticated) {
           window.location = "/dashboard";
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({
            errors: {
                name: "",
                mobile: "",
                otp:""
            }
        });
		if (e.target.id === 'user-update-email') {
            this.setState({ email: e.target.value });
        }
        if (e.target.id === 'user-update-mobile' && e.target.value.length<11) {
            this.setState({ mobile: e.target.value });
        }
        if (e.target.id === 'user-update-password') {
            this.setState({ password: e.target.value });
        }
}
    onSubmit = e => {
        e.preventDefault();	
        if(this.state.email ===""){
            this.setState({ errors:{'email':'Please enter email'}});
            return false;
        }
        if(this.state.password ===""){
            this.setState({ errors:{'password':'Please enter password'}});
            return false;
        }
        if(this.state.mobile ===""){
            this.setState({ errors:{'mobile':'Please enter mobile'}});
            return false;
        }
            const newUser = {            
            email: this.state.email,
            password: this.state.password,
            mobile: this.state.mobile
        };
        console.log("newUser==",newUser);
        let res = this.props.TempUserLogin(newUser);        
    }

render() {
        const { errors } = this.state;
        return (
            <>	
            <div className="">
                <div >
                    <nav className="navbar navbar-expand-lg home_nav">
                        <div className="container ">
                        {/* <!-- logo --> */}
                        <a className="navbar-brand" href="/">
                            <img src="/img/logo.png" alt="header-Logo" className="logo logo1"/>
							<img src="./img/logo_mobile.svg" alt="header-Logo" className="logo logo2"/>
						</a>

                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarText">
                            <span className="icon-bar"><i className="las la-times"></i></span>
                        </button>
                        <div className="right_menu ">
                            <li className="nav-item">
                            </li>
                        </div>
                        </div>
                    </nav>


                    <div class="login_bg" >
                  <div class="container">
                     <div class="row ">
                        <div class="col-md-5 mr-auto ml-auto">
                        <div class="bg-white p-md-4 p-2 logi_signup_box">
						<h5 className="login_hadding text-center mb-0">Let's Get Started</h5>                        
                        <div className="tab-content pt-4">
                        <div className="tab-pane active" id="SignUp">
                <form noValidate onSubmit={this.onSubmit} id="update-user">
                
               
                    {/* <label className="fw-500">Phone</label> */}
                            <div className="form-group row">
                                <div className="col-12 col-lg-12 col-md-12">                            
                                    <input onChange={this.onChange} placeholder="Email"
                                        id="user-update-email"
                                        type="text" className="form-control"
                                        value={this.state.email}
                                    />
                                    <span className="text-danger">{errors.email}</span>
                                    <span className="text-danger">{errors.message}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 col-lg-12 col-md-12">                            
                                    <input onChange={this.onChange} placeholder="Password"
                                        id="user-update-password"
                                        type="password" className="form-control"
                                        value={this.state.password}
                                    />
                                    <span className="text-danger">{errors.password}</span>
                                    <span className="text-danger">{errors.message}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 col-lg-12 col-md-12">                            
                                    <input onChange={this.onChange} placeholder="Mobile number"
                                        id="user-update-mobile"
                                        type="number" className="form-control"
                                        value={this.state.mobile}
                                        readOnly={this.state.readonly}
                                    />
                                    <span className="text-danger">{errors.mobile}</span>
                                    <span className="text-danger">{errors.message}</span>
                                </div>
                            </div>
                            <div className="">							
                                <button className="btn btn_man w100" type="submit">Submit</button>
                            </div>
                        </form>                   
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>                       
                     </div>
               </div>
                    <Footer />
                </div>
            </div>
            <ForgetPassword />
            <Toaster />
</>
        );
    }
}

Home.propTypes = {
    TempUserLogin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { TempUserLogin }
)(Home);
