import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Modal from "react-modal";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { Link, withRouter, useNavigate } from "react-router-dom";
import * as myConstList from "../../BaseUrl";
import Moment from "moment";
import socketIOClient from "socket.io-client";
const baseUrl = myConstList.baseUrl;
const baseUrlSocket = myConstList.baseUrlSocket;
const socket = socketIOClient(baseUrlSocket);
const jwtToken = localStorage.getItem("jwtToken");
const config = {
  headers: {
    Authorization: "Bearer " + `${localStorage.getItem("jwtToken")}`,
  },
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const toastStyles = {
  style: {
    borderRadius: "10px",
    background: "#fff",
    color: "#000",
  },
  autoClose: 500,
};

//   Modal.setAppElement('#yourAppElement');
class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      balance: 0,
      bonus: 0,
      virtualFund: this.props.virtualFund,
      Rebalance: 0,
      videoStatus: "",
      // PortfolioValue:0,
      winningamount: 0,
      contestId: this.props.match.params.id,
      timerData: "",
      ex_symbol: this.props.match.params.symbol,
      //portfoliototal:this.props.portfoliototal,
      openBuyOrderValue: 0,
      virtualFundassign: this.props.virtualFundassign,
      endDate: "",
      endTime: "",
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      modalOnOff: "0",
      symbol: "",
      userPortfolioPercent: 0,
      userPortfolioAmt: 0,
      contestStatus1: "",
    };

    if (
      this.props.portfoliototal == undefined ||
      this.props.portfoliototal == ""
    ) {
      console.log(this.props.portfoliototal, "portfoliototal222");
      this.setState({
        portfoliototal: 0,
      });
    } else {
      console.log("i am elsesssss");
      this.setState({
        portfoliototal: this.props.portfoliototal,
      });
    }

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps, "idddddd.hsdkfslf");
    // Compare previous props with current props
    if (prevProps.timerdata !== this.props.timerdata) {
      // Update state or perform actions based on prop changes
      if (prevProps.timerdata) {
        // console.log(prevProps.timerdata,'timerdata')
        this.setState({
          days: prevProps.timerdata.endDays,
          hours: prevProps.timerdata.endHours,
          minutes: prevProps.timerdata.endMinutes,
          seconds: prevProps.timerdata.endSeconds,
        });
        // console.log("prevProps.timerdata====",window.location.pathname);
        if (
          prevProps.timerdata.endDays <= 0 &&
          prevProps.timerdata.endHours <= 0 &&
          prevProps.timerdata.endMinutes <= 0 &&
          prevProps.timerdata.endSeconds <= 0 &&
          this.props.match.params.id == prevProps.timerdata.contestId
        ) {
          this.state.modalOnOff = "1";
          // this.openTimerModal();
          setTimeout(function () {
            // var socketEventPortfolio = "portfolio"+userId+this.props.match.params.id;
            // socket.off();
            // window.location.href = window.location.pathname;
            // if(nextProps.timerdata.contestId !==""){
            window.location.href = "/dashboard";
            // }else{
            //   window.location.reload();
            // }
          }, 3000);
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    // console.log(nextProps.serverTime, "hiiserver", nextProps.timerdata);
    if (nextProps.serverTime) {
      this.setState({
        serverTime: nextProps.serverTime,
      });
    }

    if (nextProps.contestStatus) {
      console.log("contestStatus===", nextProps.contestStatus);
      this.setState({
        contestStatus1: nextProps.contestStatus,
      });
    }

    if (nextProps.contestProfolioByUserData) {
      if (nextProps.contestProfolioByUserData) {
        this.setState({
          userPortfolioPercent:
            nextProps.contestProfolioByUserData.per.toFixed(3),
          userPortfolioAmt: nextProps.contestProfolioByUserData.portfoliovalue,
        });
      }
    }

    // if (nextProps.timerdata) {
    //   // console.log(nextProps.timerdata,'timerdata')
    //   this.setState({
    //     days: nextProps.timerdata.endDays,
    //     hours: nextProps.timerdata.endHours,
    //     minutes: nextProps.timerdata.endMinutes,
    //     seconds: nextProps.timerdata.endSeconds,
    //   });
    //   // console.log("nextProps.timerdata====",window.location.pathname);
    //   if (
    //     nextProps.timerdata.endDays <= 0 &&
    //     nextProps.timerdata.endHours <= 0 &&
    //     nextProps.timerdata.endMinutes <= 0 &&
    //     nextProps.timerdata.endSeconds <= 0 &&
    //     this.props.match.params.id == nextProps.timerdata.contestId
    //   ) {
    //     this.state.modalOnOff = "1";
    //     // this.openTimerModal();
    //     setTimeout(function () {
    //       // var socketEventPortfolio = "portfolio"+userId+this.props.match.params.id;
    //       // socket.off();
    //       // window.location.href = window.location.pathname;
    //       // if(nextProps.timerdata.contestId !==""){
    //       window.location.href = "/dashboard";
    //       // }else{
    //       //   window.location.reload();
    //       // }
    //     }, 3000);
    //   }
    // }

    if (nextProps.virtualFund) {
      this.setState({
        virtualFund: nextProps.virtualFund,
      });
    }
    //if (nextProps.portfoliototal) {
    this.setState({
      portfoliototal:
        nextProps.portfoliototal !== undefined ? nextProps.portfoliototal : 0,
    });
    // }
    if (nextProps.virtualFundassign) {
      this.setState({
        virtualFundassign: nextProps.virtualFundassign,
      });
    }

    if (
      nextProps.orderdata != undefined &&
      nextProps.orderdata.order != undefined &&
      nextProps.orderdata.order.data != undefined &&
      nextProps.orderdata.order.data.message1 != undefined &&
      nextProps.orderdata.order.data.success
    ) {
    }
    if (nextProps.endTime != undefined && nextProps.endDate != undefined) {
      this.setState({
        endTime: nextProps.endTime,
        endDate: nextProps.endDate,
      });

      let expriryDateTime1 = this.state.endDate + " " + this.state.endTime;
      this.setState({ expriryDateTime: expriryDateTime1 });
    }
    //this.getData();
  }

  liveContestDataEnddd = () => {
    if (
      this.state.expriryDateTime !== undefined &&
      this.state.serverTime !== undefined
    ) {
      var endDateTime = this.state.expriryDateTime;
      var serverTime = this.state.serverTime;
      var currentDateTime = Moment(new Date().getTime()).format(serverTime);
      // console.log("dsdaddsdsadsadasdasddadad")
      const endDateTime1 = endDateTime.replace("-", "/");
      const endDateTime2 = endDateTime1.replace("-", "/");
      var endsendDateVal = Moment(Date.parse(endDateTime2) / 1000);

      const currentDateTime1 = currentDateTime.replace("-", "/");
      const currentDateTime2 = currentDateTime1.replace("-", "/");
      var currentDate = Moment(Date.parse(currentDateTime2) / 1000);

      let intervalId = setInterval(async () => {
        currentDate = currentDate + 1;
        this.secondsToHms(currentDate, endsendDateVal);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  };
  // const secondsToHms =async (currentDateTime, endsendDate) => {
  // secondsToHms=(currentDateTime, endsendDate)=>{
  //   const countdown = Moment(endsendDate - currentDateTime);
  //   // console.log("countdown",countdown)
  //   var duration = Moment.duration(countdown * 1000, 'milliseconds');
  //   var interval = 1000;
  //   duration = Moment.duration(duration - interval, 'milliseconds');

  //   const days = duration.days();
  //   const hours = duration.hours();
  //   const minutes = duration.minutes();
  //   const seconds = duration.seconds();

  //   this.setState({ days, hours, minutes, seconds });
  // if (days <= 0 && minutes <= 0 && hours <= 0 && seconds <= 0 && this.state.modalOnOff == '0') {
  //     this.state.modalOnOff = "1";
  //     // timeout process API
  //       // var collectThis = this;
  //       // const socket = socketIOClient(baseUrl, {query:"jwtToken=" +jwtToken+"&contestId="+this.state.contestId});
  //       // socket.emit("timeOutProcess");
  //     //
  //     this.openTimerModal();
  //     setTimeout(function () {
  //       var socketEventPortfolio = "portfolio"+userId+this.props.match.params.id;
  //       socket.off();
  //       // window.location.href = window.location.pathname;
  //       window.location.href = "/dashboard";
  //     },3000);
  //   }
  // };

  // contestProfolioByUser=()=>{
  //   axios
  //     .post(baseUrl+"api/contestProfolioByUser", {contestId: this.state.contestId},config)
  //     .then(res => {
  //         if(res.data.status===true){
  //           this.setState({userPortfolioPercent:res.data.data.per.toFixed(3),
  //           userPortfolioAmt:res.data.data.portfoliovalue})
  //         }
  //         var classThis = this;
  //         setTimeout(function(){ classThis.contestProfolioByUser(); },1000);
  //     })
  //     .catch()

  // }

  componentDidMount() {
    console.log(
      this.props.match.params.id,
      "did mount",
      this.state.timerData,
      "hii"
    );
    const { user } = this.props.auth;
    var collectThis = this;

    // socket.on("timeOutLiveContest", function (apiRespData) {
    //   console.log("collectThis.props.match.params.id=",collectThis.props.match.params.id);
    //   if(apiRespData.data.contestId === collectThis.props.match.params.id){
    //     // collectThis.openTimerModal();
    //     collectThis.state.modalOnOff = "1";
    //     setTimeout(function () {
    //       var socketEventPortfolio = "portfolio"+collectThis.props.auth.user.id+collectThis.props.match.params.id;
    //         socket.off(socketEventPortfolio);
    //         socket.emit("disconnectliveContestTimerById",collectThis.props.match.params.id);
    //       // window.location.href = window.location.pathname;
    //       window.location.href = "/dashboard";
    //     },1000);
    //   }
    // })
    //this.contestProfolioByUser();
    if (this.state.contestId && this.state.ex_symbol) {
      axios
        .post(
          baseUrl + "api/contestByID-data",
          { _id: this.props.match.params.id },
          config
        )
        .then((res) => {
          // console.log("res.data.data.item.contestStatus===",res.data.data.item.contestStatus);
          if (res.data.data.item.contestStatus === "Complete") {
            collectThis.state.modalOnOff = "1";
            setTimeout(function () {
              var socketEventPortfolio =
                "portfolio" +
                collectThis.props.auth.user.id +
                collectThis.props.match.params.id;
              socket.off(socketEventPortfolio);
              socket.emit(
                "disconnectliveContestTimerById",
                collectThis.props.match.params.id
              );
              window.location.href = window.location.pathname;
              console.log(
                "collectThis.props.match.params.id=",
                collectThis.props.match.params.id
              );
              window.location.href = "/dashboard";
            }, 3000);
          }
          // else if(collectThis.state.days<=0 && collectThis.state.hours<=0 && collectThis.state.minutes<=0 && collectThis.state.seconds<=0){
          //   collectThis.state.modalOnOff = "1";
          //   setTimeout(function () {
          //     var socketEventPortfolio = "portfolio"+collectThis.props.auth.user.id+collectThis.props.match.params.id;
          //       socket.off(socketEventPortfolio);
          //       socket.emit("disconnectliveContestTimerById",collectThis.props.match.params.id);
          //     // window.location.href = window.location.pathname;
          //     window.location.href = "/dashboard";
          //   },3000);
          // }
        })
        .catch();
      // this.intervalExchnge = setInterval(() => {

      // this.liveContestDataEnd();
      // }, 1000);
    } else {
      clearInterval(this.intervalExchnge);
    }

    axios
      .post(baseUrl + "api/profile-data", {}, config)
      .then((res) => {
        // if(res.data.data.videoStatus!="verify" && this.props.urlPath!=false){
        //    this.setState({modalIsOpen: true});
        // }
        // console.log("res.data.data.userBlockres.data.data.userBlock=",res.data.data.userBlock);
        if (res.data.data.userBlock == "yes") {
          localStorage.removeItem("jwtToken");
        }
        // console.log("res.data.data===",res.data.data);
        this.setState({
          winningamount: res.data.data.winningamount,
          bonus: res.data.data.bonus,
          balance: res.data.data.balance,
          Rebalance: res.data.data.Rebalance,
          videoStatus: res.data.data.videoStatus,
        });
      })
      .catch();
    // if (this.state.contestId && this.state.ex_symbol) {
    //   this.intervalExchnge = setTimeout(() => {
    //     this.liveContestDataEnddd();
    //   }, 1000);
    // } else {
    //   clearInterval(this.intervalExchnge);
    // }
  }

  componentWillUnmount() {
    console.log(this.props.match.params.id, "will unmount");
    clearInterval(this.intervalExchnge);
  }

  getData() {
    const { user } = this.props.auth;
    const contestId = this.props.contestId;
    axios
      .post(baseUrl + "api/profile-data", {}, config)
      .then((res) => {
        this.setState({
          winningamount: res.data.data.winningamount,
          bonus: res.data.data.bonus,
          balance: res.data.data.balance,
          Rebalance: res.data.data.Rebalance,
          videoStatus: res.data.data.videoStatus,
        });
      })
      .catch();
    var profitLoass = (
      ((this.state.openBuyOrderValue +
        this.props.portfoliototal +
        this.state.virtualFund -
        this.props.virtualFundassign) *
        100) /
      this.props.virtualFundassign
    ).toFixed(2);
  }
  onLogoutClick = (e) => {
    e.preventDefault();
    toast.success("Logout successfully", toastStyles);
    setTimeout(() => {
      this.props.logoutUser();
    }, 1000);
  };
  openModal() {
    this.setState({ modalIsOpen: true });
  }
  openTimerModal() {
    this.setState({ modalIstimerOpen: true });
  }
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: true });
  }
  showTimerCounterHtml = () => {
    // console.log(
    //   this.props.match.params.id,
    //   "inside time",
    //   this.state.timerData
    // );

    if (this.state.contestId != "") {
      // console.log("timer",this.state.seconds);
      if (!isNaN(this.state.days)) {
        // if(this.state.days<=0 && this.state.hours<=0 && this.state.minutes<=0 && this.state.seconds<=0){
        //   this.state.modalOnOff = "1";
        //   setTimeout(function () {
        //     // var socketEventPortfolio = "portfolio"+this.props.auth.user.id+this.props.match.params.id;
        //     //   socket.off(socketEventPortfolio);
        //     //   socket.emit("disconnectliveContestTimerById",this.props.match.params.id);
        //     // window.location.href = window.location.pathname;
        //     window.location.href = "/dashboard";
        //   },3000);
        // }
        return (
          <li>
            <span className="header_timer 5">
              {this.state.days}
              <span>Day </span>
              {this.state.hours}
              <span>Hr </span>
              {this.state.minutes}
              <span>Min </span>
              {this.state.seconds}
              <span>Sec </span>
              Left
            </span>
          </li>
        );
      }
      // else if(this.state?.days ==undefined && this.state?.hours==undefined && this.state?.minutes==undefined && this.state.seconds==undefined){
      // //socket.off(socketEventPortfolio);
      //  window.location.href = "/dashboard";
      //  console.log("hi timer",this.state?.days,this.state?.hours,this.state?.minutes,this.state.seconds);
      // }
      // console.log("this.state?.minutes==",this.state.minutes);
    }
  };
  render() {
    if (this.state.videoStatus != "") {
      var videom = this.state.videoStatus;
    }
    var totalTemp =
      this.state.openBuyOrderValue +
      this.state.portfoliototal +
      this.state.virtualFund;
    totalTemp = totalTemp - this.props.virtualFundassign;

    const virtualFundassignCal =
      (totalTemp * 100) / this.props.virtualFundassign;
    var finalVertualFund = "";
    if (!isNaN(virtualFundassignCal)) {
      finalVertualFund = virtualFundassignCal.toFixed(3) + "%";
    } else {
      finalVertualFund = "0.00%";
    }

    const { user } = this.props.auth;

    return (
      <>
        <nav className="navbar navbar-expand-lg nav-scroll2">
          <div className="container-fluid ">
            <a className="navbar-brand" href="/dashboard">
              <img
                src={process.env.PUBLIC_URL + "/img/logo.png"}
                alt="header-Logo"
                className="logo logo1"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/logo_mobile.svg"}
                alt="header-Logo"
                className="logo logo2"
              />
            </a>
            <div className="dashbord_menu">
              <ul className="">
                {/* {console.log("this.state.balance != undefined && this.state.virtualFund===",this.state.balance,this.state.virtualFund)} */}
                {this.state.balance != undefined &&
                  this.state.virtualFund &&
                  this.showTimerCounterHtml()}
                <li className="renone">
                  {/* {this.state.balance !=undefined && !this.state.virtualFund ? "Cash Wallet: "+ this.state.balance.toFixed(2) +" INR" :"" } */}
                  {this.state.balance != undefined &&
                    !this.state.virtualFund && (
                      <span>
                        Cash Wallet:{" "}
                        <span className="man_color">
                          {this.state.balance.toFixed(2)} INR
                        </span>
                      </span>
                    )}
                </li>
                <li className="renone">
                  {/* {this.state.balance !=undefined && !this.state.virtualFund  ? "Winning Amount: "+ (this.state.winningamount!=undefined?this.state.winningamount:0) +" INR" :"" } */}
                  {this.state.balance != undefined &&
                    !this.state.virtualFund && (
                      <span>
                        Winning Amount:{" "}
                        <span className="man_color">
                          {this.state.winningamount != undefined
                            ? this.state.winningamount
                            : 0}{" "}
                          INR{" "}
                        </span>
                      </span>
                    )}
                </li>
                <li className="renone">
                  {this.state.balance != undefined &&
                    !this.state.virtualFund && (
                      <span>
                        Reward Wallet:
                        <span className="man_color">
                          {" "}
                          {this.state.bonus != undefined
                            ? this.state.bonus
                            : 0}{" "}
                          INR
                        </span>
                      </span>
                    )}
                </li>
                <li className="renone">
                  {this.state.virtualFundassign && (
                    <span>
                      {" "}
                      Allocated Fund:{" "}
                      <span className="man_color">
                        {this.state.virtualFundassign}
                      </span>{" "}
                      USDT
                    </span>
                  )}
                </li>
                <li className="renone">
                  {/*this.state.virtualFund && <span> Portfolio Value: <span className="man_color">{(this.state.portfoliototal+this.state.virtualFund+this.state.openBuyOrderValue).toFixed(2)}</span></span>*/}
                  {this.state.contestId && this.state.ex_symbol && (
                    <span>
                      {" "}
                      Portfolio Value:{" "}
                      <span className="man_color">
                        {this.state.userPortfolioAmt.toFixed(2)}
                      </span>{" "}
                    </span>
                  )}
                </li>
                <li className="renone">
                  {/*this.state.virtualFund && <span>Gain: 
                  {finalVertualFund >='0' && <span className="man_color"> {finalVertualFund}</span>
                  }
                  {finalVertualFund <'0' && <span className="text-danger"> {finalVertualFund}</span>
                  }
				        </span> */}
                  {this.state.contestId && this.state.ex_symbol && (
                    <span>
                      Gain:
                      {this.state.userPortfolioPercent >= "0" && (
                        <span className="man_color">
                          {" "}
                          {this.state.userPortfolioPercent}
                        </span>
                      )}
                      {this.state.userPortfolioPercent < "0" && (
                        <span className="text-danger">
                          {" "}
                          {this.state.userPortfolioPercent}
                        </span>
                      )}
                    </span>
                  )}
                </li>
                <li className="renone">
                  <a className="man_color" href="/profile-user">
                    {user.name.toUpperCase()}
                  </a>
                </li>
                <li className="">
                  <a href="#" onClick={this.onLogoutClick}>
                    <i className="las la-sign-out-alt f35"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <Toaster/> */}

        {videom != "verify" && (
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
              Verified Your KYC
            </h2>

            <a href="/kyc" className="click-here">
              {" "}
              Click Here
            </a>
          </Modal>
        )}

        {this.state.modalOnOff == "1" && this.state.contestId != undefined && (
          <div class="man_div">
            <div class="auto_box">
              <div class="white_box tc">
                <img src="/img/time-out.gif" />
                <br />
                <br />
                <h4>Time Out</h4>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Navbar));
