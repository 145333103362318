import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Header from "../../components/pages/Header";
import Register from "../../components/auth/Register";
import ForgetPassword from "../../components/auth/ForgetPassword";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
import axios from "axios";
const baseUrl = myConstList.baseUrl;
class ComingSoon extends Component {
    constructor() {
        super();
        this.state = {
         
            balance:'',
           errors: {}
        };
     
    }
    

render() {
        const { errors } = this.state;
        return (
            <>
           <div className="coming-soon text-center">
            <img src="./img/coming-soon-logo.png" alt="header-Logo" className="coming-logo" />
            <div className="coming-heading">
                <h1>coming soon</h1>
            </div>
            <div className="powered-by">
                <h5>Powered By : <a href="https://technoloaderitservices.com/" target="_blank">Technoloader IT Services Pvt. Ltd.</a></h5>
            </div>
           </div>
  
</>
        );
    }
}

ComingSoon.propTypes = {  
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(ComingSoon);
