import React, { Component } from "react";
import { Link, withRouter  } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { subscriberUser } from "../../actions/authActions";
import {toast,Toaster } from 'react-hot-toast';
import classnames from "classnames";

class SubscribeUser extends Component {

    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            mobile: "",
            errors: {},
            errorsName:"",
            errorsMobile:"",
            error:'0'
        };
        
    }
    componentWillReceiveProps(nextProps) {
        console.log("nextProps",nextProps);
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });            
        }       
        if (nextProps.auth.reg != undefined
            && nextProps.auth.reg.data != undefined
            && nextProps.auth.reg.data.message != undefined
            && nextProps.auth.reg.data.status) {
                // toast( nextProps.auth.reg.data.message, {
                //     position: toast.POSITION.TOP_CENTER
                //   });
                // setTimeout(function(){
                //     window.location.href = "/";              
                //   },2000); 
             this.setState({
                message: nextProps.auth.reg.data.message,
                name:"",
                email:"",
                mobile:"",
            });       
        }else{
            if(nextProps.auth.reg.data != undefined){
                this.setState({errors:{message:nextProps.auth.reg.data.message,email:nextProps.auth.reg.data.email,password:nextProps.auth.reg.data.password,password2:nextProps.auth.reg.data.password2}});
            }            
        }        
    }
    componentDidMount() {
        if (window.location.pathname != '/privacy-policy' && window.location.pathname != '/term-and-condition' && window.location.pathname != '/faq' && window.location.pathname != '/about-us' && this.props.auth.isAuthenticated) {
            this.props.history.push("/");
        }
		if(this.props.match.params.refer!==undefined){
			
			this.setState({referralBy:this.props.match.params.refer})
		}
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({
            errorsName: "",
            errorsMobile: "",
            error:'0'
        });
		if(e.target.id=="name"){
            const strongRegexName = new RegExp(/^[a-zA-Z ]*$/);
            var testName = strongRegexName.test(e.target.value);
     
            if (!testName){
                this.setState({ errorsName: "Only use alphabetic characters",error:"1"});  
                return false;
            }		
        }
       
        if(e.target.id=="mobile"){     
            if (e.target.value.length!=10) { 
                this.setState({ errorsMobile: "Please enter valid mobile",error:"1"});
                return false;
            }
        }
    }

    onSubmit = e => {
        e.preventDefault();	  
        if(this.state.error == "0") {
            const newUser = {
                name: this.state.name,
                email: this.state.email,
                mobile: this.state.mobile
            };
            let res = this.props.subscriberUser(newUser);
        }
    }
    render() {
        // alert(this.state.message);
        const { errors } = this.state;
        return (
            <>
                <div className="modal fade" id="AlertTTG">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src="img/alertttg.jpg" className="img-fluid" alt="alertttg"/>
                                </div>
                                <div className="col-md-7">
                                    <div className="modal-body login_modal"> 
                                        <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>
                                        <h5>Get updates about TTG on launch</h5>
                                            <form className="pr-md-4" onSubmit={this.onSubmit}>
                                                <div className="form-group">
                                                    <input placeholder="Name" id="name" type="Name" className="form-control" value={this.state.name} onChange={this.onChange} required/>
                                                    <span className="text-danger f12">{this.state.errorsName}</span>
                                                </div>
                                                <div className="form-group">
                                                    <input placeholder="Email" value={this.state.email} id="email" type="email" className="form-control" onChange={this.onChange} required/>
                                                </div>
                                                <div className="form-group">
                                                    <input placeholder="Mobile No." id="mobile" type="number" className="form-control" value={this.state.mobile} onChange={this.onChange} />
                                                    <span className="text-danger f12">{this.state.errorsMobile}</span>
                                                </div>
                                                <div className="form-group">
                                                    <button className="btn w100 btn_man">Submit</button>
                                                </div>
                                            </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

SubscribeUser.propTypes = {
    subscriberUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { subscriberUser }
)(withRouter(SubscribeUser));