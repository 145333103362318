const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    order: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case 'ORDER_ADD':
            return {
                isAuthenticated: !isEmpty(action.payload),
                order: action.payload
            };
        case 'ORDER_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                order: action.payload,
            };
        case 'SET_CURRENT_ORDER':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                ORDER: action.payload
            };
         
        case 'ORDER_LOADING':
            return {
                ...state,
                loading: true
            };
      
        default:
            return state;
    }

}
