import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddContest from "../partials/AddContest";
import ProfileUpdateDetails from "../partials/profile/ProfileUpdateDetails";
import ProfileUpdateMobile from "../partials/profile/ProfileUpdateMobile";
import ProfileUpdateEmail from "../partials/profile/ProfileUpdateEmail";
import ProfileUpdateChangePassowrd from "../partials/profile/ProfileUpdateChangePassowrd";
import { toast, Toaster } from "react-hot-toast";
import * as myConstList from "../../BaseUrl";
import { deleteAccount } from "../../actions/updatePersionalDataKycActions";
import { logoutUser } from "../../actions/authActions";
import { InlineReactionButtons } from "sharethis-reactjs";
import { InlineShareButtons } from "sharethis-reactjs";
import { StickyShareButtons } from "sharethis-reactjs";
import { InlineFollowButtons } from "sharethis-reactjs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import ReactGA from "react-ga";

const baseUrl = myConstList.baseUrl;

const toastStyles = {
  style: {
    borderRadius: "10px",
    background: "#fff",
    color: "#000",
  },
};
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      show: false,
    };
    this.state = {
      currentRecord: {
        id: "",
        fname: "",
        lname: "",
        dob: "",
        gender: "",
        email: "",
        mobile: "",
        password: "",

        password2: "",
        profileDataFound: false,
        urlPath: false,
        symbol: false,
      },
    };

    this.getData = this.getData.bind(this);
	this.handleClose = this.handleClose.bind(this);
	this.handleShow = this.handleShow.bind(this);
	this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
	console.log('hiii')
    this.setState({ show: true });
  }

	handleDeleteAccount() {
		console.log('hiiisadad')
    const configToken = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
      'Content-Type': 'application/json'
    },
  };
		this.props.deleteAccount(configToken);
	}

  getData() {
    console.log(window.location.origin);
    const { user } = this.props.auth;
    axios
      .post(baseUrl + "api/profile-data", { _id: user.id })
      .then((res) => {
        var referralLink =
          window.location.origin + "/referral/" + res.data.data.referralCode;
        this.setState({
          records: res.data.data,
          kycCompleteStatus: res.data.data.kycCompleteStatus,
          referralCode: res.data.data.referralCode,
          referralLink: referralLink,
          linkShareWithText:
            "Hello There! TTG has helped me to understand cryptocurrency trading risk. I urge you to Register at Trade The Games , worlds first skill based cryptocurrency trading game.  Let's play together and earn money.  Please follow the invite link below as per you preference. Referral Code: " +
            res.data.data.referralCode +
            ", Referral Link:" +
            referralLink,
          linkShare: referralLink,
          profileDataFound: true,
        });
      })
      .catch();
  }
  componentDidMount() {
    ReactGA.initialize("UA-218461585-1");
    ReactGA.pageview("/profile-user");
    this.getData();
  }
  componentWillReceiveProps(nextProps) {	
    if (
      nextProps.prodata !== undefined &&
      nextProps.prodata.pro !== undefined &&
      nextProps.prodata.pro.data !== undefined &&
      nextProps.prodata.pro.data.message !== undefined &&
      nextProps.prodata.pro.data.success
    ) {
      toast.success(nextProps.prodata.pro.data.message, toastStyles);
    }

	if(nextProps.prodata.pro.data?.status)
	{
		this.setState({show: false});
		setTimeout(() => {
        this.props.logoutUser();
      }, 1000); 
	}
    this.getData();
  }

  copyTextToClipboard = (text) => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text);
      toast.success("Referral Link Copied", toastStyles);
    } else {
      document.execCommand("copy", true, text);
      toast.success("Referral Link Copied", toastStyles);
    }
  };

  showShareFbHtml = () => {
    if (this.state.profileDataFound === true) {
      return (
        <InlineShareButtons
          config={{
            alignment: "center", // alignment of buttons (left, center, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            labels: null, // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              "messenger",
              "facebook",
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: false,
            size: 40, // the size of each button (INTEGER)

            // OPTIONAL PARAMETERS
            url: this.state.linkShare, // (defaults to current url)
            image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
            description: this.state.linkShare, // (defaults to og:description or twitter:description)
            title: this.state.linkShare, // (defaults to og:title or twitter:title)
            message: this.state.linkShare, // (only for email sharing)
            subject: this.state.linkShare, // (only for email sharing)
            username: "custom twitter handle", // (only for twitter sharing)
          }}
        />
      );
    }
  };

  showShareHtml = () => {
    if (this.state.profileDataFound === true) {
      return (
        <InlineShareButtons
          config={{
            alignment: "center", // alignment of buttons (left, center, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            labels: null, // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              "whatsapp",
              "linkedin",
              "twitter",
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: true,
            size: 40, // the size of each button (INTEGER)

            // OPTIONAL PARAMETERS
            url: this.state.linkShareWithText, // (defaults to current url)
            image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
            description: this.state.linkShareWithText, // (defaults to og:description or twitter:description)
            title: this.state.linkShareWithText, // (defaults to og:title or twitter:title)
            message: this.state.linkShareWithText, // (only for email sharing)
            subject: this.state.linkShareWithText, // (only for email sharing)
            username: "custom twitter handle", // (only for twitter sharing)
          }}
        />
      );
    }
  };

  render() {
    return (
      <>
        <Navbar urlPath={this.state.urlPath} symbol={this.state.symbol} />
        <Sidebar />
        <div className="container">
          <div className="in_page row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className=" padding_d">
                <ProfileUpdateDetails record={this.state.records} />
                <div className="white_box">
                  <ProfileUpdateMobile record={this.state.records} />
                  {/* <ProfileUpdateEmail record={this.state.records}/> */}
                </div>
                <div className="white_box">
                  <label>Referral Code</label>
                  <div className="row">
                    <div className="form-group col-md-9">
                      <div className="copy_input">
                        <input
                          onChange={this.onChange}
                          value={this.state.referralCode}
                          id="user-update-mobile"
                          type="text"
                          className="form-control"
                          disabled="disabled"
                        />
                        <CopyToClipboard
                          text={this.state.referralCode}
                          onCopy={() => {
                            toast.success("Referral Code Copied", toastStyles);
                          }}
                        >
                          <i className="las la-copy"></i>
                        </CopyToClipboard>
                      </div>
                      {/* {this.state.kycCompleteStatus ==='pending' &&
								<div className="copy_input">
									<input onChange={this.onChange} value="XXXXXX"
										id="user-update-mobile"
										type="text" className="form-control" disabled="disabled"/>
										<CopyToClipboard text={"XXXXXX"} onCopy={() => { toast.error("Please complete your KYC", toastStyles);}} >
									<i className="las la-copy"></i></CopyToClipboard>
								</div>
							} */}
                    </div>
                    {/* {this.state.kycCompleteStatus ==='complete' && */}
                    <div className="form-group col-md-3 sharethis ">
                      {this.showShareFbHtml()}
                    </div>
                    {/* } */}

                    {/* {this.state.kycCompleteStatus ==='pending' &&
								<div className="form-group col-md-3 sharethis hide_link">
									{this.showShareFbHtml()}
								</div>
							}  */}
                  </div>

                  <label>Referral Link</label>
                  <div className="row">
                    <div className="form-group col-md-9">
                      {/* {this.state.kycCompleteStatus ==='complete' && */}
                      <div className="copy_input">
                        <input
                          onChange={this.onChange}
                          value={this.state.referralLink}
                          id="user-update-mobile"
                          type="text"
                          className="form-control"
                          disabled="disabled"
                        />
                        <CopyToClipboard
                          text={this.state.referralLink}
                          onCopy={() => {
                            toast.success("Referral Link Copied", toastStyles);
                          }}
                        >
                          <i className="las la-copy"></i>
                        </CopyToClipboard>
                      </div>
                      {/* } */}
                      {/* {this.state.kycCompleteStatus ==='pending' &&
							<div className="copy_input mb-2">
								<input onChange={this.onChange} value="XXXXXX"
									id="user-update-mobile"
									type="text" className="form-control" disabled="disabled"

								/>
								<CopyToClipboard text={"XXXXXX"} onCopy={() => { toast.error("Please complete your KYC", toastStyles);}}>
								<i className="las la-copy"></i>
								</CopyToClipboard>
							</div>
						} */}
                      {/* <span className="text-danger">Note:- You will not get referral amount without KYC complete.</span> */}
                    </div>
                    {/* {this.state.kycCompleteStatus ==='complete' && */}
                    <div className="form-group col-md-3 sharethis ">
                      {this.showShareHtml()}
                    </div>
                    {/* } */}

                    {/* {this.state.kycCompleteStatus ==='pending' &&
							<div className="form-group col-md-3 sharethis hide_link">
								{this.showShareHtml()}
							</div>
						} */}
                  </div>
                </div>
                <Button variant="danger" onClick={this.handleShow}>Delete Account</Button>                
                <Modal show={this.state.show} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Delete Account</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure, you want to delete this account?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={this.handleDeleteAccount}>
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* <ProfileUpdateChangePassowrd record={this.state.records}/>    */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Toaster />
      </>
    );
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  prodata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  prodata: state.prodata,
  records: state.records,
});

export default connect(mapStateToProps, {deleteAccount, logoutUser})(Profile);
