import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HelpQueryModal from "../partials/help/HelpQueryModal";
import * as myConstList from '../../BaseUrl';
import {toast,Toaster } from 'react-hot-toast';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class ChatRoom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            userrecords:{
                id:'',
                subject:'',
                description:'',
                issueImage:"",
                date:'',
                status:''
            },
            tichekId:this.props.match.params.id
        };
        this.getData = this.getData.bind(this);
    }



    getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl+"api/chat-room-data", { _id:this.state.tichekId,user_id:user.id })
            .then(res => {
                this.setState({records: res.data});
              
            })
            .catch()

            axios
            .post(baseUrl+"api/chat-room-tichekdata", { _id:this.state.tichekId,user_id:user.id })
            .then(res => {
                this.setState({userrecords: {
                    id:res.data._id,
                    subject:res.data.subject,
                    description:res.data.description,
                    date:res.data.date,
                    issueImage:res.data.issueImage,
                    status:res.data.status
                }
                });
              
            })
            .catch()
    }
    componentDidMount() {
        this.getData()
     
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.helpdata !== undefined
            && nextProps.helpdata.help !== undefined
            && nextProps.helpdata.help.data !== undefined
            && nextProps.helpdata.help.data.message !== undefined
            && nextProps.helpdata.help.data.status) {
                toast.success( nextProps.helpdata.help.data.message, toastStyles);  
        }
        this.getData();
        
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    render() {
        return (
            <>
                <Navbar urlPath={false} symbol={false}/>
                <Sidebar />
                <HelpQueryModal record={this.state.records} userrecords={this.state.userrecords}/>
                <Footer />
                <Toaster />
            </>

        );
    }

}

ChatRoom.propTypes = {
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    records: state.records
});
export default connect(
    mapStateToProps
)(ChatRoom);
