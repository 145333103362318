const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    help: {},
    contact:{},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case 'HELP_ADD':
            return {
                isAuthenticated: !isEmpty(action.payload),
                help: action.payload
            };
        case 'HELP_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                help: action.payload,
            };
        case 'SET_CURRENT_HELP':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                help: action.payload
            };
        case 'CONTACTUS':
            return {
                isAuthenticated: !isEmpty(action.payload),
                contact: action.payload
            };
         
        case 'HELP_LOADING':
            return {
                ...state,
                loading: true
            };
      
        default:
            return state;
    }

}
