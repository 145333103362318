import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addressKyc } from "../../../actions/kycUpdate";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class VerifiAddress extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            docTypeAddress: this.props.record.docTypeAddress ? this.props.record.docTypeAddress:"aadhar",
            addProof: "",
            addProofImage: this.props.record.addProofImage,
            addProofImageTemp: "",
            addressStatus:this.props.record.addressStatus,
            message: '',
            add_rejectResion:this.props.record.add_rejectResion,
            invalidImage: '',
            showResults: false,
            errors: {}
        };
        }
        componentDidMount(){
            this.setState({addProofImage: this.props.record.addProofImage,add_rejectResion: this.props.record.add_rejectResion,addressStatus:this.props.record.addressStatus })
        }
    componentWillReceiveProps(nextProps) {

        if (nextProps.record) {
            this.setState({ 
                id: nextProps.record.id, 
                addProofImage: nextProps.record.addProofImage,
                docTypeAddress: nextProps.record.docTypeAddress,
                addressStatus: nextProps.record.addressStatus,
                add_rejectResion: nextProps.record.add_rejectResion
            })
        }
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
        if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined
            && nextProps.kycdata.kyc.data.message !== undefined
			&& nextProps.kycdata.kyc.data.status) {            
                this.setState({
                    message:nextProps.kycdata.kyc.data.message,
                    errors: { }               
            });
        }
		else if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined){
            this.setState({
                errors: nextProps.kycdata.kyc.data
            });
        } 
    }
  
    onChange = e => {
        this.setState({ message: "", errors: {} })
        if (e.target.name === 'user-update-optradio') {
            this.setState({ docTypeAddress: e.target.value });
        }
        if (e.target.name === 'addProof') {
            this.setState({ invalidImage: "",addProofImageTemp: URL.createObjectURL(e.target.files[0])});
            var addProof = e.target.files[0];
            var fileSize = e.target.files[0].size;
            if (fileSize > 1000000 || fileSize<10000) {
                this.setState({ invalidImage: "Min 10 KB/Max 1 MB.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
            console.log(this.state.invalidImage);
            if (!addProof.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
            this.setState({ addProof: addProof, });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })

    };
    onUserUpdate = e => {
      
        e.preventDefault();
        const config = {
            headers: { 'content-type': 'multipart/form-data','Authorization': 'Bearer '+`${localStorage.getItem("jwtToken")}` }
        }
        const { user } = this.props.auth;
        const formData = new FormData();
        formData.append('id', user.id);
        if(this.state.docTypeAddress){
            formData.append('docTypeAddress', this.state.docTypeAddress);
        }else{
            formData.append('docTypeAddress', "");
        }
       
        formData.append('addProof', this.state.addProof);
        formData.append('addressStatus', this.state.addressStatus);
        this.props.addressKyc(formData, config);
    };

    render() {
       
        const { errors } = this.state;  
        return (
            <form noValidate onSubmit={this.onUserUpdate}>
                <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                <span className="text-success">{this.state.message}</span>
				
                <div className="">
                    <h3 className="mb-2">Verify Address </h3>
                    <div className="form-group ">
                    <span className="text-danger">{errors.docTypeAddress}</span><br/>
                        <label className="fw-500">Select Your Document Type</label><br />
                        {/*<div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docTypeAddress == "eb"}
                                    onChange={this.onChange}
                                    value="eb"
                                    name="user-update-optradio"
                                    id="eb"
                                    type="radio" className="form-check-input" />
                                Electricity Bill</label>
                        </div>
                        <div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docTypeAddress == "fb"}
                                    onChange={this.onChange}
                                    value="fb"
                                    name="user-update-optradio"
                                    id="fb"
                                    type="radio" className="form-check-input" />
                                Phone Bill
                            </label>
                        </div>
                        <div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docTypeAddress == "bpobs"}
                                    onChange={this.onChange}
                                    value="bpobs"
                                    name="user-update-optradio"
                                    id="bpobs"
                                    type="radio" className="form-check-input" />
                                Bank Passbook or Bank Statement
                            </label>
                        </div>*/}
						
						<div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docTypeAddress == "aadhar"}
                                    onChange={this.onChange}
                                    value="aadhar"
                                    name="user-update-optradio"
                                    id="aadhar"
                                    type="radio" className="form-check-input" />
                                Aadhar Card</label>
                        </div>
						<div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docTypeAddress == "drivingLicense"}
                                    onChange={this.onChange}
                                    value="drivingLicense"
                                    name="user-update-optradio"
                                    id="drivingLicense"
                                    type="radio" className="form-check-input" />
                                Driving License</label>
                        </div>
						<div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docTypeAddress == "passport"}
                                    onChange={this.onChange}
                                    value="passport"
                                    name="user-update-optradio"
                                    id="passport"
                                    type="radio" className="form-check-input" />
                                Passport</label>
                        </div>
						<div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docTypeAddress == "utilityBill"}
                                    onChange={this.onChange}
                                    value="utilityBill"
                                    name="user-update-optradio"
                                    id="utilityBill"
                                    type="radio" className="form-check-input" />
                                Utility Bill</label>
                        </div>
						<div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docTypeAddress == "bankPassbook"}
                                    onChange={this.onChange}
                                    value="bankPassbook"
                                    name="user-update-optradio"
                                    id="bankPassbook"
                                    type="radio" className="form-check-input" />
                                Bank Passbook</label>
                        </div>
						
						
                    </div>
                    
					  <div className="row">
                        <div className=" col-md-6">
						<label className="fw-500">Select Address Document {this.state.addressStatus == "reject" ? <> Status: <span className="text-danger">Rejected</span><button type="button" class="btn" data-toggle="modal" data-target="#exampleModalAdd">
                                <i className="fa fa-info"></i>
                            </button> </>: <> {this.state.addressStatus !== undefined && <span> Status: <span className="text-success">{this.state.addressStatus}</span></span>}</> }</label>
                    {this.state.addProofImageTemp === '' && this.state.addProofImage !== undefined && this.state.addProofImage !== '' &&
                        <>
                       <div className="upload_img">
                        <img src={baseUrl+"/address/" + this.state.addProofImage} alt="Cinque Terre"></img></div>
                        </>
                    }
                    {this.state.addProofImageTemp !== undefined && this.state.addProofImageTemp !== '' &&
                        <>
                        <div className="upload_img">
                        <img src={this.state.addProofImageTemp} alt="Cinque Terre"></img></div>
                        </>
                    }
						</div>
						</div>
                    <div className="row">
                        <div className="form-group col-md-6">
                        {(this.state.addressStatus == "reject" || this.state.addressStatus == undefined ) &&
                           <> <div className="add_fileinput mb-2"><i className="fa fa-plus-circle mr-1"></i>Add Document<input onChange={this.onChange}
                                id="addProof"
                                name="addProof"
                                type="file" className="profile_input" />
                               
                                </div>
								 <span className="text-danger">{this.state.invalidImage}</span>
								<span className="text-danger">{errors.addProof}</span>
								</>}
                        </div>
                        {(this.state.addressStatus == "reject" || this.state.addressStatus == undefined ) &&
                        <div className="form-group col-md-6" style={{ display: (this.state.showResults ? 'block' : 'none') }}>
                            <button type="submit" className="btn btn_man w110">Next</button>
                        </div>}
                    </div>
                </div>  
                <div class="modal fade" id="exampleModalAdd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Rejected Reason</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {this.state.add_rejectResion}
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
            
        )
    }
}

VerifiAddress.propTypes = {
    addressKyc: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    kycdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    kycdata: state.kycdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addressKyc }
)(withRouter(VerifiAddress));
