import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyOtp } from "../../../actions/kycUpdate";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class ConfirmOtp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            otp: '',
            message: '',
            showResults: false,
            errors: {}
        };
        console.log(this.state.id);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
            })

        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kycOTP !== undefined
            && nextProps.kycdata.kycOTP.data !== undefined
            && nextProps.kycdata.kycOTP.data.message !== undefined
            && nextProps.kycdata.kycOTP.data.status) {
                // this.props.mobileVerifyStatus("verify");  
                this.setState({
                message: nextProps.kycdata.kycOTP.data.message,
                otp: '',
                errors: {}
            });
        }
        if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kycOTP !== undefined
            && nextProps.kycdata.kycOTP.data !== undefined
            && nextProps.kycdata.kycOTP.data.message !== undefined
            && !nextProps.kycdata.kycOTP.data.status) {
            this.setState({errors:{message: nextProps.kycdata.kycOTP.data.message}})
        }
    }
    onChange = e => {
        this.setState({
            messageM: "",
            errors: {

            }
        })
        if (e.target.id === 'user-update-otp') {
            this.setState({ otp: e.target.value });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
 };
 onOtpUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.state.id,
            type: "otp",
            otp: this.state.otp
        };

        this.props.verifyOtp(newUser);

    };
  
    render() {
        const { errors } = this.state;
        return (
            <form noValidate onSubmit={this.onOtpUpdate} id="update-user">
                <span className="text-success">{this.state.message}</span>
                <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                <div className="form-group">
                    <label className="fw-500">Confirmation code</label>
                    <div className="row">
                        <div className="col-md-5">
                            <input onChange={this.onChange}
                                id="user-update-otp"
                                type="number" className="form-control"
                                placeholder="Enter confirmation code"
                               
                            />
                            <span className="text-danger">{errors.message}</span>

                        </div>
                        <div className="col-md-3 " style={{ display: (this.state.showResults ? 'block' : 'none') }}>
                            <button type="submit" className="btn btn_man w110">Verify</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

ConfirmOtp.propTypes = {
    verifyOtp: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    kycdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    kycdata: state.kycdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyOtp }
)(withRouter(ConfirmOtp));
