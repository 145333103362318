import axios from "axios";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
export const updateKyc = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/persional-data-update", userData,configToken)
        .then(res =>
            dispatch({
                type: 'PRO_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};

export const updateKycProfile = (userData,config) => dispatch => {
    axios
        .post(baseUrl+"api/profile-data-update", userData,config)
        .then(res =>
            dispatch({
                type: 'PRO_IMAGE_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};

export const deleteAccount = (config) => dispatch => {
    console.log(config,'{...configToken, config}')
    // return;
    axios
        .post(baseUrl+"api/delete-account", {}, config)
        .then(res =>
            dispatch({
                type: 'DELETE_ACCOUNT',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};

