import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {Link,withRouter} from "react-router-dom";
import {toast,Toaster } from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const toastStyles = {
  style: {
    borderRadius: '10px',
    background: '#fff',
    color: '#000',
  },
}
class HelpCenter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: []
        };        
    }

  


   

  
 
    render() {
      const { user } = this.props.auth;
    //   const urlSet = baseUrl+"checkout/index?userName="+this.state.name+"&userEmail="+this.state.email+"&usermobile="+this.state.mobile+"&userId="+user.id;
    //   const urlSetZaakpay = baseUrl+"transact?userName="+this.state.name+"&userEmail="+this.state.email+"&userId="+user.id+"&os=browser";
        return (
            <>
<div className="add-money-mobile-outer">
    <div className="in_page_mobile"> 
    <div className=" ">
      <div className=" padding_d" >
       <div className="tab-content">
        <div id="home5" className=" tab-pane active">
         <div className="white_box " >
         <h5 className=" mb-3 mb-md-4">Choose Payment Method </h5>
    <div className="" >
<div  id="qa_cashier_paymentMethods" className="cards">
  <div>
  <ul className="row nav">
    <li className="col-6 col-md-3">
       <a className="card-item ng-star-inserted active" data-toggle="tab" href="#neft">
         <img style={{height: "25px"}} className="icon" src="img/neftimps.png"/>
          <p  className="text-caption">NEFT/IMPS</p>
       </a>
    </li>
    <li className="col-6 col-md-3">
       <a className="card-item ng-star-inserted" data-toggle="tab" href="#upitranfer">
          <img style={{height: "25px"}} className="icon" src="img/upi.png"/>
          <p className="text-caption">UPI Transfer</p>
       </a>
    </li>
    </ul>
    </div>
    </div>
    </div>
    </div>
         <div className="tab-content" >
		 <div id="neft" className="white_box tab-pane fade active show">
		  <h6>Bank Detail</h6> 
      <div className="row mt-4">
		   <div className="col-md-3 col-12">
		   <label className="mb-0">Account Holder:</label>
		   
		   </div>
		   <div className="form-group col-md-9 col-12">
       <span className="">
        Technoloader IT Services Pvt. Ltd.
        <CopyToClipboard text='Technoloader IT Services Pvt. Ltd.' onCopy={() => { toast.success("Copied", toastStyles);}}><span className="copy_btn" title="Copy Text"><i class="las la-copy"></i></span></CopyToClipboard>
            </span>
		   </div>
       
		   </div> 
		  <div className="row">
		   <div className=" col-md-3 col-12">
		   <label className="mb-0">Bank Account No:</label>
		   
		   </div>
		   <div className="form-group col-md-9 col-12">
          361605500569
          <CopyToClipboard text='361605500569' onCopy={() => { toast.success("Copied", toastStyles);}}><span className="copy_btn"><i class="las la-copy" title="Copy Text" ></i></span></CopyToClipboard>
		   </div>
		   </div> 
          <div className="row ">
		   <div className="col-md-3 col-12">
		   <label className="mb-0">Bank Name:</label>
		   </div>
		   <div className="form-group col-md-9 col-12">
        ICICI Bank Ltd
        <CopyToClipboard text='ICICI Bank Ltd' onCopy={() => { toast.success("Copied", toastStyles);}}><span className="copy_btn"><i class="las la-copy" title="Copy Text"></i></span></CopyToClipboard>
		   </div>
		   </div>
		   <div className="row ">
		   <div className=" col-md-3 col-12">
		   <label className="mb-0">IFSC Code:</label>
		   
		   </div>
		   <div className="form-group col-md-9 col-12">
         ICIC0003616
         <CopyToClipboard text='ICIC0003616' onCopy={() => { toast.success("Copied", toastStyles);}}><span className="copy_btn"><i class="las la-copy" title="Copy Text"></i></span></CopyToClipboard>
		   </div>
		   </div>
       <div className="row ">
		   <div className=" col-md-3 col-12">
		   <label className="mb-0">Branch:</label>
		   
		   </div>
		   <div className="form-group col-md-9 col-12">
       Sirsi Road, Jaipur
       <CopyToClipboard text='Sirsi Road, Jaipur' onCopy={() => { toast.success("Copied", toastStyles);}}><span className="copy_btn"><i class="las la-copy" title="Copy Text"></i></span></CopyToClipboard>
		   </div>
		   </div>
       <div className="row ">
		   <div className="col-md-3 col-12">
		   <label className="mb-0">Branch Address:</label>
		   
		   </div>
		   <div className="form-group col-md-9 col-12">
       ICICI BANK LTD, PLOT NO 1 SHEKHAWAT COMPLEX SIRSI ROAD JAIPUR
       <CopyToClipboard text='ICICI BANK LTD, PLOT NO 1 SHEKHAWAT COMPLEX SIRSI ROAD JAIPUR' onCopy={() => { toast.success("Copied", toastStyles);}}><span className="copy_btn" title="Copy Text"><i class="las la-copy"></i></span></CopyToClipboard>
		   </div>
		   </div>	
       <p><b>Note:</b><br/> Verification may take few minutes.  <br/>Once verified, you can use your Bank Account to deposit fund.<br/>
Please note that deposits can take few minutes to reflect in your Trade The Games wallet.
</p>	   
		 </div> 
         <div id="upitranfer" className="tab-pane fade">
		  
		  <div className="row mt-4">
		   <div className="form-group col-md-12 col-12">
		   <div className="white_box text-center">
		   <h6 className="text-left">UPI Detail</h6>
		     <img  className="img-fluid" src="img/barcode.jpg"/>
         <p>Note: Please mention your username in custom message.<a href="https://www.tradethegames.com/img/pay.jpg" target="_blank" className="text-danger"> Click here </a> for reference.
</p>
		   <p><b>UPI ID:</b> eazypay.577182310@icici<CopyToClipboard text='eazypay.577182310@icici' onCopy={() => { toast.success("Copied", toastStyles);}}><span className="copy_btn"><i class="las la-copy" title="Copy Text"></i></span></CopyToClipboard></p>
       
       <p className="text-left"><b>Note:</b><br/> Verification may take few minutes. <br/> Once verified, you can use your UPI ID to deposit fund.<br/>
Please register the UPI associated with the bank account(s) registered with Trade The Games
Please note that deposits can take few minutes to reflect in your Trade The Games wallet.</p>
		   </div>
		   </div>		  
		   </div>
		 </div> 		 
		</div>      
		</div>      
      </div>
    </div>
    </div>
     </div>
     </div>
{/* <Footer /> */}
<Toaster/>
</>
             
        );
    }

}

HelpCenter.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps
)(withRouter(HelpCenter));
