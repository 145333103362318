import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requery } from "../../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import OpenOrders from "./OpenOrders";
import UserOrderHistory from "./UserOrderHistory";
import Funds from "./Funds";
import Leaderboard from "./Leaderboard";
import Account from "./Account";
import axios from "axios";
import * as myConstList from "../../../BaseUrl";
const config = {
  headers: {
    Authorization: "Bearer " + `${localStorage.getItem("jwtToken")}`,
  },
};
const baseUrl = myConstList.baseUrl;
class OrderHistory extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props.auth;
    this.state = {
      allOrder: [],
      allOpenOrder: [],
      allCloseOrder: this.props.allCloseOrder,
      allFundsOrder: [],
      leaderboard: [],
      allAccount: this.props.allAccount,
      usdt: this.props.virtualFund,
      // portfoliototal:0,
      id: this.props.id,
      errors: {},
    };
    this.getData = this.getData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.getOrderByIdData) {
      this.setState({
        allOrder: nextProps.getOrderByIdData,
      });
    }
    if (nextProps.allAccount) {
      this.setState({
        allAccount: nextProps.allAccount,
        portfoliototal: nextProps.portfoliototal,
      });
    }
    if (nextProps.allCloseOrder.lengh > 0) {
      this.setState({
        allCloseOrder: nextProps.allCloseOrder,
      });
    }

    if (nextProps.record) {
      this.setState({
        records: nextProps.record,
        // allAccount: nextProps.allAccount,
        usdt: nextProps.usdt,
      });
    }
    if (nextProps.virtualFund != undefined) {
      this.setState({
        usdt: nextProps.virtualFund,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined &&
      nextProps.auth.user.data.success
    ) {
      this.setState({
        message: nextProps.auth.user.data.message,
      });
    }
  }
  componentDidMount() {
    /* setInterval(() => {
            this.getData();
        }, 3000); */
    //this.getOrderByIdData();
  }

  // getOrderByIdData=()=>{
  //     const { user } = this.props.auth;
  //     axios
  //         .post(baseUrl+"api/getOrderById-data",{user_id:user.id,contestId: this.props.match.params.id},config)
  //         .then(res => {
  //             this.setState({allOrder: res.data.data});
  //             var classThis = this;
  //             setTimeout(function(){ classThis.getOrderByIdData(); },2000);
  //         })
  //         .catch()
  // }
  getData() {
    const { user } = this.props.auth;
    // fetch(`https://api.binance.com/api/v1/ticker/price?symbol=${this.props.match.params.symbol}`)
    // .then(response => response.json())
    // .then(response => {
    /*  axios
                .post(baseUrl+"api/allBuyOrder-update",{contestId:this.props.match.params.id},config)
                .then(res => {
            
                })
                .catch()
                axios
                .post(baseUrl+"api/allSellOrder-update",{contestId:this.props.match.params.id},config)
                .then(res => {
            
                })
                .catch() */
    // })
    // .catch();
    /*  axios
            .post(baseUrl+"api/getOpenOrderById-data",{user_id:user.id,contestId: this.props.match.params.id},config)
            .then(res => {
                this.setState({allOpenOrder: res.data.data})
            })
            .catch() */
    /*  axios
            .post(baseUrl+"api/getCloseOrderById-data",{user_id:user.id,contestId: this.props.match.params.id},config)
            .then(res => {
                this.setState({allCloseOrder: res.data.data})
            })
            .catch() */
    /*9axios
            .post(baseUrl+"api/getOrderById-data",{user_id:user.id,contestId: this.props.match.params.id},config)
            .then(res => {
                this.setState({allOrder: res.data.data})
            })
            .catch()*/
    /*  axios
            .post(baseUrl+"api/fundRequestByUserId-data",{user_id:user.id,contestId: this.props.match.params.id},config)
            .then(res => {
                this.setState({allFundsOrder: res.data.data})
            })
            .catch() */

    /*  axios
            .post(baseUrl+"api/leaderboard-data",{contestId: this.props.match.params.id},config)
            .then(res => {
               
                this.setState({ leaderboard: res.data.data })
            })
            .catch() */
    // axios
    // .post(baseUrl+"api/participateById", { contest_id: this.props.match.params.id, user_id: user.id })
    // .then(res => {
    //     this.setState({ usdt: res.data.virtualFund })
    // })
    // .catch()
  }

  render() {
    const { errors } = this.state;
    const { user } = this.props.auth;

    return (
      <div className="container-fluid mt-2">
        <div className=" col_d mt-4 exchange_orders">
          <div>
            <div className="crypt-market-status market-status-outer">
              <div>
                <ul className="nav nav-tabs">
                  <li role="presentation">
                    <a href="#OpenOrders" className="active" data-toggle="tab">
                      Open Orders
                    </a>
                  </li>
                  <li role="presentation">
                    <a href="#OrderHistory" data-toggle="tab">
                      Order History
                    </a>
                  </li>
                  <li role="presentation">
                    <a href="#Funds" data-toggle="tab">
                      Funds
                    </a>
                  </li>
                  {/*<li role="presentation"><a href="#leaderboard" data-toggle="tab">leaderboard
                                    </a></li>*/}
                  <li role="presentation">
                    <a href="#account" data-toggle="tab">
                      Account
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="OpenOrders"
                  >
                    <OpenOrders
                      record={this.state.allOrder}
                      contestId={this.props.match.params.id}
                      symbol={this.props.match.params.symbol}
                    />
                  </div>
                  <div role="tabpanel" className="tab-pane" id="OrderHistory">
                    <UserOrderHistory record={this.state.allOrder} />
                  </div>
                  <div role="tabpanel" className="tab-pane" id="Funds">
                    <Funds record={this.state.allOrder} />
                  </div>
                  <div role="tabpanel" className="tab-pane" id="leaderboard">
                    {/*<Leaderboard leaderboard={this.state.leaderboard}/>*/}
                  </div>
                  <div role="tabpanel" className="tab-pane" id="account">
                    <Account
                      allAccount={this.state.allAccount}
                      usdt={this.state.usdt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderHistory.propTypes = {
  requery: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { requery })(withRouter(OrderHistory));
