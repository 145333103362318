import React, { Component } from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";

class Footer extends Component {

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };
    render() {
        const { user } = this.props.auth;
        return (            	
            <section className="contact padding footer" id="contact" data-scroll-index="6" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 ">
                        <img src="/img/banner-logo.png" alt="footer_logo" className="img-fluid footer_logo"/>
                        </div>
                        <div className="col-md-3 pl-md-5">
                        <div className="nav_link">
                            <h5>Company</h5>
                            <ul >
                            <li ><a  href="/about-us.html">About Us</a></li>
                            <li ><a  href="/faq.html">FAQ</a> </li>
                            <li><a target="_blank" href="https://www.tradethegames.com/blog">Blog</a> </li>
							<li><a target="_blank" href="/cancellation-refund-policy">Cancellation & Refund Policy</a> </li>
                            <li ><a  href="/term-and-condition">Terms and Conditions</a> </li>
                            <li ><a  href="/legality">Legality</a> </li>
                            <li ><a  href="/privacy-policy">Privacy Policy </a> </li>
                            <li ><a  href="/contact-us">Contact Us</a> </li>
                            </ul>
                        </div>
                        </div>
						  <div className="col-md-3 ">
                        <div className="vertical-social nav_link wow fadeInDown  animated" data-wow-delay="0.1s">
<h5>Social Media</h5>
<ul>
	<li><a rel="nofollow" target="_blank" href="https://www.facebook.com/tradethegames/"><i className="fa fa-facebook"></i> Facebook</a></li>
	 <li><a rel="nofollow" target="_blank" href="https://twitter.com/tradethegames"> <i className="fa fa-twitter"></i>Twitter</a></li>
	 <li><a rel="nofollow" target="_blank" href="https://www.linkedin.com/company/trade-the-games"> <i className="fa fa-linkedin"></i>Linkedin</a></li>
	 <li><a rel="nofollow" target="_blank" href="https://www.instagram.com/tradethegames/"><i className="fa fa-instagram"></i>Instagram</a></li>
     
	 <li><a rel="nofollow" target="_blank" href="https://medium.com/@tradethegames"><i className="fa fa-medium"></i>Medium</a></li>
	 <li><a rel="nofollow" target="_blank" href="https://www.reddit.com/r/tradethegames/"><i className="fa fa-reddit"></i>Reddit</a></li>
	 <li><a rel="nofollow" target="_blank" href="https://t.me/TTG_announcements"><i className="fa fa-telegram"></i>Telegram Channel</a></li>
</ul>
                        </div>
                        </div>
                        <div className="col-md-3 ">
                        <div className="vertical-social wow fadeInDown  animated" data-wow-delay="0.1s">
                            <div className="nav_link">
                            <h5>Download</h5>
                            <div className="login_btns ">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ttg" className="mb-2 mr-2"><img src="/img/google-btn.png" className="img-fluid" alt=""/> </a>
                                <a href="https://apps.apple.com/in/app/trade-the-games/id1610688947" target="_blank"><img src="/img/apple-btn.png" className="img-fluid" alt=""/></a>
                            </div>
                            </div>
						
                        </div>
                        </div>
                      

                    </div>
                </div>
                <div className="copyright tc">
               © 2022 <a className="man_color" href="https://www.tradethegames.com"><strong>Trade The Games</strong></a>. A Product of <a className="man_color" target="_blank" href="https://technoloaderitservices.com/">Technoloader</a>
                </div>
				
<a className="telegram_sticky faa-horizontal animated" target="_blank" href="https://t.me/tradethegames">
<i className="fa fa-paper-plane"></i>
</a>
{/*<div id="scroll-to-top"><i className="fa fa-arrow-up fa-fw"></i></div>*/}
            </section>
        );
    }
}

Footer.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Footer);
 