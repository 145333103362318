import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { taxKyc } from "../../../actions/kycUpdate";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class TaxVerification extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            id: this.props.id,
            panNo:this.props.record.panNo,
            panDoc:this.props.record.panDocImage,
            panDocImage:this.props.record.panDocImage,
            taxStatus:this.props.record.taxStatus,
            tax_rejectResion:this.props.record.tax_rejectResion,
            message:'',
            invalidImage:'',
            showResults : false,
            panDocImageTemp:'',
            errors: {}
        };       
    }
  componentDidMount(){
    this.setState({
        id: this.props.record.id,
        email:this.props.record.email,
        taxStatus:this.props.record.taxStatus,
        panDocImage: this.props.record.panDocImage,
        panNo: this.props.record.panNo,
        tax_rejectResion: this.props.record.tax_rejectResion        
    })
  }
  componentWillReceiveProps(nextProps) {
        if (nextProps.record.panNo) {
            this.setState({
                id: nextProps.record.id,
                email:nextProps.record.email,
                taxStatus:nextProps.record.taxStatus,
                panDocImage: nextProps.record.panDocImage,
                // panNo: nextProps.record.panNo,
                tax_rejectResion: nextProps.record.tax_rejectResion
            })
        }
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors});
        }
        if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined
            && nextProps.kycdata.kyc.data.message !== undefined
            && nextProps.kycdata.kyc.data.status) {            
                this.setState({
                    message:nextProps.kycdata.kyc.data.message,
                    errors: { }               
            });
        }else if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined){
            this.setState({
                errors: nextProps.kycdata.kyc.data
            });
        }  
    }
  
    onChange = e => {
        this.setState({
            message: "",
            errors: {}
        })
    if (e.target.id === 'user-update-panNo') {
            if(e.target.value.length<11){
                this.setState({ panNo: e.target.value });
            }
        }

        if (e.target.name === 'panDoc') {
            this.setState({ invalidImage: "",panDocImageTemp:URL.createObjectURL(e.target.files[0])});
            var panDoc = e.target.files[0]; 
            var fileSize  = e.target.files[0].size;
            if(fileSize > 10000000 || fileSize<10000){
               this.setState({ invalidImage: "Min 10 KB/Max 10 MB" ,firstSubmitIsDisabled:true,userUploadImage:false});
                  return false;  
            }
            
              if (!panDoc.name.match(/\.(jpg|jpeg|png|gif)$/)) {         
                  this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif." ,firstSubmitIsDisabled:true,userUploadImage:false});
                  return false;  
                } 
                this.setState({panDoc: panDoc});
        }
        this.setState({
            showResults: this.state.name===null ? false : true
        })
        
    };
   
  
    onUserUpdate = e => {      
        e.preventDefault();

        const numeric = new RegExp(/^[0-9\b]+$/);
        const strongRegexNameCk1 = new RegExp(/^[a-zA-Z0-9]*$/);

        var testNameCk = strongRegexNameCk1.test(this.state.panNo);
        var testOnlyNumric = numeric.test(this.state.panNo);

        if (!testNameCk || testOnlyNumric){     
            this.setState({ errors: {panNo: "Please enter valid pan no"}});  
            return false;
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer '+`${localStorage.getItem("jwtToken")}`
            }
          }
             
          console.log("panNo===",this.state.panNo);
          const { user } = this.props.auth;
          const formData = new FormData();
          formData.append('id',user.id);
          formData.append('panNo',this.state.panNo);
          formData.append('panDoc',this.state.panDoc);
          formData.append('taxStatus',this.state.taxStatus);
          this.props.taxKyc(formData,config);
      
    };
 
    render() {
        const { errors } = this.state;
        return (
            <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                <span className="text-success">{this.state.message}</span>
                <span className="text-danger">{errors.message}</span>
                <div className="">
                    <h3 className="mb-3">PAN Verification </h3>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="fw-500">PAN Card No</label>
                            <input onChange={this.onChange} 
                             value={this.state.panNo}
                                id="user-update-panNo"
                                type="text" className="form-control"
                                placeholder="Enter PAN Card No" />
                            <span className="text-danger">{errors.panNo}</span>                            
                        </div>
                        
                        <div className="form-group col-md-6">
                            <label className="fw-500">Photo/Scan {this.state.taxStatus == "reject" ? <> Status: <span className="text-danger">Rejected</span><button type="button" class="btn" data-toggle="modal" data-target="#exampleModalPan">
                                <i className="fa fa-info"></i>
                            </button> </>:<> {this.state.taxStatus !== undefined && <span> Status: <span className="text-success">{this.state.taxStatus}</span></span>}</> }</label>
                            {this.state.panDocImageTemp === '' && this.state.panDocImage !== undefined && this.state.panDocImage !== ''&&
                                <>
                                   <div className="upload_img">
                                    <img src={baseUrl+"static/pan/" + this.state.panDocImage} alt="Cinque Terre"></img></div>
                                </>
                            }
                            {this.state.panDocImageTemp !== undefined && this.state.panDocImageTemp !== ''&&
                                <>
                                    <div className="upload_img">
                                    <img src={this.state.panDocImageTemp} alt="Cinque Terre"></img></div>
                                </>
                            }
                            {(this.state.taxStatus == "reject" || this.state.taxStatus == undefined ) &&
                            <div><div className="add_fileinput mb-2"><i className="fa fa-plus-circle mr-1"></i>Add Document
                                <input onChange={this.onChange}
                                    id="panDoc"
                                    name="panDoc"
                                    type="file" className="profile_input" />
                                </div>
								<span className="text-danger">{this.state.invalidImage}</span>
                                <span className="text-danger">{errors.panDoc}</span></div>
                            }
                        </div>
                        {(this.state.taxStatus == "reject" || this.state.taxStatus == undefined ) &&
                        <div className="form-group col-md-12 tr" style={{ display: (this.state.showResults ? 'block' : 'none') }}>
                            <button type="submit" className="btn btn_man w110">Next</button>
                        </div>
                        }
                    </div>
                </div>
                <div class="modal fade" id="exampleModalPan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Rejected Reason</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {this.state.tax_rejectResion}
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

TaxVerification.propTypes = {
    taxKyc: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    kycdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    kycdata: state.kycdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { taxKyc }
)(withRouter(TaxVerification));
