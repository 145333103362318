import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { interMediate } from "../../../actions/kycUpdate";
import { withRouter } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import * as myConstList from '../../../BaseUrl';
import { json } from 'body-parser';
const baseUrl = myConstList.baseUrl;

class Intermediate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            email: '',  
            docType: this.props.userdata.docType,
            documentNo: this.props.userdata.documentNo,
            propdocumentNo: this.props.userdata.documentNo,
            fname: this.props.userdata.fname,
            lname: this.props.userdata.lname,
            dob: this.props.userdata.dob,
            gender: this.props.userdata.gender,


            fImage:this.props.userdata.fImage,
            bImage:this.props.userdata.bImage,
            fi_rejectResion:this.props.userdata.fi_rejectResion,
            bi_rejectResion:this.props.userdata.bi_rejectResion,
            fImage_status:this.props.userdata.fImage_status,
            bImage_status:this.props.userdata.bImage_status,
            frontSideFile: '',
            backSideFile: '',
            invalidbImage: '',
            invalidfImage: '',
            I: '',
            message: '',
            showResults: false,
            userUploadImageFront:'',
            userUploadImageBack:'',
            countryCode:this.props.userdata.countryCode,
            aadharCardDisplay:this.props.userdata.docType=='id'?true:false,
            passportDisplay:this.props.userdata.docType=='ps'?true:false,
            drivingLicenceDisplay:this.props.userdata.docType=='li'?true:false,
            errors: {}
        };        
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userdata.docType !=undefined) {
            if(nextProps.userdata.docType == 'id'){
                this.setState({aadharCardDisplay:true});
            }
            if(nextProps.userdata.docType == 'ps'){
                this.setState({passportDisplay:true});
            }
            if(nextProps.userdata.docType == 'li'){
                this.setState({drivingLicenceDisplay:true});
            }

            this.setState({ id: nextProps.userdata.id,fImage: nextProps.userdata.fImage,bImage: nextProps.userdata.bImage,fi_rejectResion: nextProps.userdata.fi_rejectResion,
                bi_rejectResion: nextProps.userdata.bi_rejectResion,
                fImage_status: nextProps.userdata.fImage_status,
                bImage_status: nextProps.userdata.bImage_status,
                // documentNo: nextProps.userdata.documentNo,
                lname: nextProps.userdata.lname,
                fname: nextProps.userdata.fname,
                dob: nextProps.userdata.dob,
                gender: nextProps.userdata.gender,
                docType: nextProps.userdata.docType,
                countryCode: nextProps.userdata.countryCode,
            })

        }

       
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors});
        }
        if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined
            && nextProps.kycdata.kyc.data.message !== undefined
            && nextProps.kycdata.kyc.data.status) {
            this.setState({
                message: nextProps.kycdata.kyc.data.message, 
                errors: {}
            });
        }else if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined){
            this.setState({
                errors: nextProps.kycdata.kyc.data
            });
        }        
    }

    onChange = e => {
        this.setState({ message: "", errors: {} })
		const alpha = new RegExp(/^[A-Za-z ]+$/);
        const numeric = new RegExp(/^[0-9\b]+$/);
        

        const strongRegexNameCk = new RegExp(/^[a-zA-Z0-9]*$/);
        
		if (e.target.id === 'user-update-lname') {
            if(e.target.value.length == 0 || alpha.test(e.target.value) ) {
			    this.setState({ lname: e.target.value });
            }
		}
		if (e.target.id === 'user-update-fname'){
            if(e.target.value.length == 0 || alpha.test(e.target.value)) {
			    this.setState({ fname: e.target.value });
            }
		}
		if (e.target.id === 'user-update-dob') {
			this.setState({ dob: e.target.value });
		}
		if (e.target.id === 'user-update-gender') {
			this.setState({ gender: e.target.value });
		}
        if (e.target.name === 'user-update-optradio') {
            this.setState({ docType: e.target.value });
        }
        if (e.target.id === 'user-update-documentNo') {
            if(this.state.docType=='id' && e.target.value.length<13){
                this.setState({ documentNo: e.target.value });
            }else if(this.state.docType=='ps' && e.target.value.length<9){               
                this.setState({ documentNo: e.target.value });                
            }else if(this.state.docType=='li' && e.target.value.length<16){               
                this.setState({ documentNo: e.target.value });                
            }            
        }
        if (e.target.name === 'fimage') {
            // console.log("imagess===",URL.createObjectURL(e.target.files[0]));
            this.setState({userUploadImageFront:URL.createObjectURL(e.target.files[0])});
            
            this.setState({ invalidfImage:""});
            var frontSideFile = e.target.files[0];
          
            
            var fileSize = e.target.files[0].size;
            // console.log("file::",fileSize);
            if (fileSize > 10000000 || fileSize<10000) {
                this.setState({ invalidfImage: "Min size 10 KB / Max size 10 MB", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }

            if (!frontSideFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidfImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
            this.setState({ frontSideFile: frontSideFile });
        }
        if (e.target.name === 'bimage') {
            this.setState({ invalidbImage:""});
            var backSideFile = e.target.files[0];
            var fileSize = e.target.files[0].size;
            this.setState({userUploadImageBack:URL.createObjectURL(e.target.files[0])});

            if (fileSize > 10000000 || fileSize<10000) {
                this.setState({ invalidbImage: "Min size 10 KB / Max size 10 MB", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }

            if (!backSideFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidbImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
            this.setState({ backSideFile: backSideFile });
        }
        this.setState({ showResults: this.state.name === null ? false : true });
    };
    

    onUserUpdate = e => {
        e.preventDefault();
        // alert("fileSize")        
		if(this.state.invalidfImage!='' || this.state.invalidbImage !=''){
            return false;
        }
		if(this.state.fname=="" || this.state.fname===undefined){
			this.setState({errors:{fname:"First Name is required"}});
			return false;
		}
		
		if(this.state.lname=="" || this.state.lname===undefined){
			this.setState({errors:{lname:"Last Name is required"}});
			return false;
		}
		
		if(this.state.gender=="" || this.state.gender===undefined){
			this.setState({errors:{gender:"gender is required"}});
			return false;
		}
		
		if(this.state.dob=="" || this.state.dob===undefined){
			this.setState({errors:{dob:"dob is required"}});
			return false;
		}
		
        const numeric = new RegExp(/^[0-9\b]+$/);
        const strongRegexNameCk = new RegExp(/^[a-zA-Z0-9]*$/);
        var testNameCk = strongRegexNameCk.test(this.state.documentNo);
        var testOnlyNumric = numeric.test(this.state.documentNo);
        if(this.state.docType=='id' && (this.state.documentNo.length>12 || this.state.documentNo.length<12)){
            this.setState({ errors: {documentNo: "Please enter valid aadhaar no"}});
            return false;
        }
        if(this.state.docType=='ps' && (this.state.documentNo.length>8 || this.state.documentNo.length<8)){               
            this.setState({ errors: {documentNo: "Please enter valid passport no"}});  
            return false;             
        }
        if(this.state.docType=='li' && (this.state.documentNo.length>15 || this.state.documentNo.length<17)){               
            this.setState({ errors: {documentNo: "Please enter valid driving licence no"}}); 
            return false;              
        }

        if ((!testNameCk || testOnlyNumric) && (this.state.docType === 'ps' || this.state.docType ==='li')){            
            this.setState({ errors: {documentNo: "Only use Alphanumeric Characters without Space"}});
            return false; 
        }
		
        const { user } = this.props.auth;
        const config = { headers: { 'content-type': 'multipart/form-data','Authorization': 'Bearer '+`${localStorage.getItem("jwtToken")}`} }
        const formData = new FormData();
        formData.append('id', user.id);
        if(this.state.docType){
            formData.append('docType', this.state.docType);
        }else{
            formData.append('docType', "");
        }
		
		
		
        formData.append('fname', this.state.fname);
        formData.append('lname', this.state.lname);
        formData.append('gender', this.state.gender);
        formData.append('dob', this.state.dob);
        formData.append('fImage_status', this.state.fImage_status);
        formData.append('bImage_status', this.state.bImage_status);
        formData.append('oldbImage', this.state.bImage);
        formData.append('oldfImage', this.state.fImage);
        if(this.state.documentNo){
            formData.append('documentNo', this.state.documentNo);
        }else{
            formData.append('documentNo', "");
        }
        formData.append('fImage', this.state.frontSideFile);
        formData.append('bImage', this.state.backSideFile);
        // Display the key/value pairs
        for (var pair of formData.entries()) {
            console.log("p=======",pair[0]+ ', ' + pair[1]); 
        }
        this.props.interMediate(formData, config);

    };

    handleClick= e => {
        this.setState({ documentNo: "" });
        // if(this.state.propdocumentNo === "" && this.state.documentNo !== this.state.propdocumentNo){
        // }
        this.setState({docType: e.target.value});        
        if(e.target.value==="id"){
            this.setState({
                aadharCardDisplay: true,passportDisplay: false,drivingLicenceDisplay: false
            });
			
		}
        if(e.target.value==="ps"){
            this.setState({
                aadharCardDisplay: false,passportDisplay: true,drivingLicenceDisplay: false
            });
        } 
        if(e.target.value==="li"){
            this.setState({
                aadharCardDisplay: false,passportDisplay: false,drivingLicenceDisplay: true
            });
        } 
    }
    render() {
     if(this.state.docType){
        var docType= this.state.docType;
     }
        const { errors } = this.state;
 
        return (
            <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                <span className="text-success">{this.state.message}</span>
                <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                <h3 className="mb-3">Photo ID Verification</h3>
                <div className="form-group">
				            <div className="row" >
                                <div className="form-group col-md-4" >
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none" />
                                    <label>First name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.fname}
                                        id="user-update-fname"
                                        type="text"
                                        error={errors.fname}
                                        className={classnames("form-control", {
                                            invalid: errors.fname
                                        })} />
                                    <span className="text-danger">{errors.fname}</span>
                                </div>
                                <div className="form-group col-md-4" >
                                    <label>Last name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.lname}
                                        id="user-update-lname"
                                        type="text"
                                        error={errors.lname}
                                        className={classnames("form-control", {
                                            invalid: errors.lname
                                        })} />
                                    <span className="text-danger">{errors.lname}</span>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="form-group col-md-4" >
                                    <label>Date of Birth</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.dob}
                                        id="user-update-dob"
                                        type="date"
                                        maxLength="8"
                                        error={errors.dob}
                                        className={classnames("form-control", {
                                            invalid: errors.dob
                                        })} />
                                    <span className="text-danger">{errors.dob}</span>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Gender</label>
                                    <select error={errors.gender} onChange={this.onChange} value={this.state.gender} className="form-control" id="user-update-gender">
                                        <option value="">Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Eunuch">Eunuch</option>
                                    </select>
                                    <span className="text-danger">{errors.gender}</span>
                                </div>
                            </div>
				
				
                    <div className="form-group ">
                        <span className="text-danger">{errors.docType}</span><br/>
                        <label className="fw-500">Select Your Document Type</label><br />
                        <div className="form-check-inline">
                            <label className="form-check-label">
                                <input checked={this.state.docType == "id"} onClick={this.handleClick} onChange={this.onChange} value="id" name="user-update-optradio"
                                    type="radio" className="form-check-input" placeholder="Enter Document No" />
                                <span className="text-danger">{errors.idCard}</span>{this.state.countryCode=="91"?"Aadhaar Card":"National Identity Card"}</label></div>
                        <div className="form-check-inline">
                            <label className="form-check-label">
                                <input
                                    checked={this.state.docType == "ps"}
                                    onChange={this.onChange}
                                    value="ps"
                                    name="user-update-optradio"
                                    type="radio" className="form-check-input"
                                    placeholder="Enter Document No" onClick={this.handleClick}/>
                                <span className="text-danger">{errors.passport}</span>Passport
                            </label>
                        </div>
                        <div className="form-check-inline">
                            <label className="form-check-label">
                                <input onChange={this.onChange} onClick={this.handleClick}
                                    value="li"
                                    checked={docType == "li"}
                                    name="user-update-optradio"
                                    type="radio" className="form-check-input"
                                    placeholder="Enter Document No" />
                                    <span className="text-danger">{errors.licence}</span>
                                Driving Licence
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        {/*  */}
                        { this.state.aadharCardDisplay &&
                            <div className="form-group col-md-8">
                                <label className="fw-500">{this.state.countryCode=="91"?"Aadhaar No":"National Identity Card No"}</label>
                                <input onChange={this.onChange}
                                    value={this.state.documentNo}
                                    id="user-update-documentNo"
                                    type="number" className="form-control"
                                    placeholder="Enter Document No" />
                                <span className="text-danger">{errors.documentNo}</span>
                            </div>
                        }
                        { this.state.passportDisplay &&
                            <div className="form-group col-md-8">
                                <label className="fw-500">Passport No</label>
                                <input onChange={this.onChange}
                                    value={this.state.documentNo}
                                    id="user-update-documentNo"
                                    type="text" className="form-control"
                                    placeholder="Enter Document No" />
                                <span className="text-danger">{errors.documentNo}</span>
                            </div>
                        }
                        { this.state.drivingLicenceDisplay &&
                            <div className="form-group col-md-8">
                                <label className="fw-500">Driving Licence No</label>
                                <input onChange={this.onChange}
                                    value={this.state.documentNo}
                                    id="user-update-documentNo"
                                    type="text" className="form-control"
                                    placeholder="Enter Document No" />
                                <span className="text-danger">{errors.documentNo}</span>
                            </div>
                        }
                    {/*  */}
                    </div>
                    <div className="row">
                    <div className="form-group col-md-12">
                    <p>{this.state.bImage_status == "reject" ? <> Status: <span className="text-danger">Rejected</span><button type="button" class="btn" data-toggle="modal" data-target="#exampleModal">
                                <i className="fa fa-info"></i>
                            </button><br/><p><span className="text-danger">( Note: Please upload front/back image )</span></p> </>: "" } {this.state.bImage_status =="verify" ?<> Status: <span className="text-success">{this.state.bImage_status}</span></>:""}</p> </div>
                        <div className="form-group col-md-4">
                            <label className="fw-500">Photo/Scan Front </label>
                            {/* ==================================== */}
                                {this.state.userUploadImageFront === '' && this.state.fImage !== undefined && this.state.fImage !== '' && 
                                <>
                          
                                <div className="upload_img"> 
                                    <img src={baseUrl+"profile/" + this.state.fImage}  alt="Cinque Terre"></img></div>                                   
                                </>
                                    }
                                    {this.state.userUploadImageFront !== undefined && this.state.userUploadImageFront !== '' &&
                                        <>
										<div className="upload_img"> 
                                            <img src={this.state.userUploadImageFront!==''?this.state.userUploadImageFront:""}  alt="Cinque Terre"></img>
											</div>
                                        </>
                                    }
									 
                            {/* =================================== */}
                            {(this.state.bImage_status == "reject" || this.state.bImage_status == undefined) &&
                            <div>
                                <div className="add_fileinput mb-2"><i className="fa fa-plus-circle mr-1"></i>Add Document
                                    <input onChange={this.onChange} value={this.state.fimage}
                                        id="fimage"
                                        name="fimage"
                                        type="file" className="profile_input" />
                                        
                                    
                                </div>                            
                                <span className="text-danger">{errors.fImage}</span>
                                <span className="text-danger">{this.state.invalidfImage}</span>
                            </div>
                            }
                        </div>
                        <div className="form-group col-md-4">
                            <label className="fw-500">Photo/Scan Back </label>
							
                            {this.state.userUploadImageBack === '' && this.state.bImage !== undefined && this.state.bImage !== '' && 
                                <>
                             <div className="upload_img">   
                                    <img src={baseUrl+"profile/" + this.state.bImage}  alt="Cinque Terre"></img></div>                                   
                                </>
                            }
                            {this.state.userUploadImageBack !== undefined && this.state.userUploadImageBack !== '' &&
                                <>
                                   <div className="upload_img">   
                                    <img src={this.state.userUploadImageBack!==''?this.state.userUploadImageBack:""} alt="Cinque Terre"></img></div>
                                </>
                            }
                                {(this.state.bImage_status == "reject" || this.state.bImage_status == undefined ) &&
                                <div>
                                    <div className="add_fileinput mb-2"><i className="fa fa-plus-circle mr-1"></i>Add Document
                                        <input onChange={this.onChange} value={this.state.bimage}
                                            id="bimage"
                                            name="bimage"   
                                            type="file" className="profile_input" />                               
                                    </div>
                                    <span className="text-danger">{errors.bImage}</span>
                                    <span className="text-danger">{this.state.invalidbImage}</span>
                                </div>
                            }
                        </div>
                    </div>
                    {( this.state.bImage_status == "reject" || this.state.bImage_status == undefined || this.state.bImage_status == "reject" || this.state.bImage_status == undefined ) &&
                     <div className="row"><div className="form-group col-md-8 mb-5 tr" style={{ display: (this.state.showResults ? 'block' : 'none') }}>
                        <button type="submit" className="btn btn_man w110">Next</button>
                    </div>
                    </div>
                    }
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Rejected Reason</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {this.state.bi_rejectResion}
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Rejected Reason</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {this.state.fi_rejectResion}
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>
				<div style={{color:'#ff2929'}}>
                *The photo/scan must be uncompressed and clear in colour.<br/>
                *The photo/scan must not be edited and cropped.<br/>
                *The document must be valid for at least 3 months from the submission date.<br/>
                *All details filled in the "Photo Id Verification" section must be same as per the uploaded documents.<br/>
                *Min size 10 KB / Max size 5 MB.<br/>
                *Make sure you are uploading two different document in Photo ID Verification and Address proof Verification.<br/>
                *Supported format jpg, jpeg.
				</div>
            </form>
        )
    }
}

Intermediate.propTypes = {
    interMediate: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    kycdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};  

const mapStateToProps = state => ({
    auth: state.auth,
    kycdata: state.kycdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { interMediate }
)(withRouter(Intermediate));
