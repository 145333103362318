import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateKyc } from "../../../actions/updatePersionalDataKycActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class KycUpdateEmail extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            id: this.props.id,
            
            email: '',
            referralCode: '',
            message:'',
            showResults : false,
            errors: {}
        };
        console.log(this.state.id);
       
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
               email: nextProps.record.email, 
               referralCode: nextProps.record.referralCode 
             })
            
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.prodata !== undefined
            && nextProps.prodata.pro !== undefined
            && nextProps.prodata.pro.data !== undefined
            && nextProps.prodata.pro.data.message !== undefined
            && nextProps.prodata.pro.data.status && nextProps.prodata.pro.data.messageStatus=='2') {            
                this.setState({
                    message: nextProps.prodata.pro.data.message,               
                    errors: {                   
                    }               
            });
        }else if (nextProps.prodata !== undefined
            && nextProps.prodata.pro !== undefined
            && nextProps.prodata.pro.data !== undefined){
            this.setState({
                errors: nextProps.prodata.pro.data
            });
        }
    }

    onChange = e => {
        this.setState({
            message: "",
            errors: {
                email: ""
            }
        })
        
    if (e.target.id === 'user-update-email') {
            this.setState({ email: e.target.value });
        }
        this.setState({
            showResults: this.state.name===null ? false : true
        })
   
    };
   
   
    onUserUpdate = e => {
      
        e.preventDefault();
        const newUser = {
            _id: this.state.id,
             type:"email",
             email: this.state.email
             };
        
        this.props.updateKyc(newUser);
      
    };
   
    render() {
        const { errors } = this.state;
        return (
            
            <form noValidate onSubmit={this.onUserUpdate}  id="update-user">
                 <span className="text-success">{this.state.message}</span>
                <div className="row">
                <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                    <div className="form-group col-md-12 col-12">
                        <label>Email</label>
                        <input onChange={this.onChange} disabled="disabled"   value={this.state.email}
                            id="user-update-email"
                            type="email" className="form-control"

                        />
                         <span className="text-danger">{errors.email}</span>
                    </div>
                  
                    <div className="form-group col-md-4" style={{display:(this.state.showResults? 'block':'none')}}>
                        <label className="label_hight"></label>
                        <button className="btn btn_man ">Save</button>
                    </div>
                </div>
</form>
            
        )
    }
}

KycUpdateEmail.propTypes = {
    updateKyc: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    prodata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    prodata: state.prodata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateKyc }
)(withRouter(KycUpdateEmail));
