import React from 'react'
import BigNumber from 'bignumber.js'
import Moment from 'moment';
import {Link} from "react-router-dom";
import { BrowserRouter as Router, Route, Switch,Redirect,withRouter } from "react-router-dom";
const SidebarWallet = (props) => {


    return (
        <ul className="nav contatcs-tab mb-3 mb-sm-4">
        <li className="nav-item">
          <Link className={props.location.pathname=="/add-money"?"nav-link active":"nav-link"}  to="/add-money">Deposit funds
          </Link>
        </li>
        <li className="nav-item">
          <Link className={props.location.pathname=="/withdrawal-request"?"nav-link active":"nav-link"} to="/withdrawal-request">Withdraw funds</Link>
        </li>
        <li className="nav-item">
          <Link className={props.location.pathname=="/transaction-history"?"nav-link active":"nav-link"}  to="/transaction-history">Transaction history</Link>
        </li> 
        <li className="nav-item">
          <Link className={props.location.pathname=="/withdrawalHistory"?"nav-link active":"nav-link"}  to="/withdrawalHistory">Withdrawal History</Link>
        </li> 
        </ul>
    )
}

export default withRouter(SidebarWallet);