import {
    SET_CURRENT_USER,
    USER_ADD,
    USER_LOADING,
    USER_UPDATE
} from "../actions/types";
const isEmpty = require("is-empty");
const initialState = {
   reset: {},
  
};

export default function(state = initialState, action) {
    switch (action.type) {
  
        case 'RESET':
                return {
                    ...state,
                    reset: action.payload
                };
        default:
            return state;
    }

}
