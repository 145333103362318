import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE
} from "./types";


import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;

export const updateHelp = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/help-data", userData)
        .then(res =>
            dispatch({
                type: 'HELP_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const requery = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/requery-data", userData)
        .then(res =>
            dispatch({
                type: 'HELP_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const updateHelpIssue = (userData,config) => dispatch => {
    axios
        .post(baseUrl+"api/helpIssue-data", userData,config)
        .then(res =>
            dispatch({
                type: 'HELP_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const contactUsUser = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/contactUs", userData)
        .then(res =>
            {
                if(res.data){
                    console.log("contactuser==",res.data)
                }
                dispatch({
                    type: 'CONTACTUS',
                    payload: res,
                })
            }
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
