import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import ForgetPassword from "../../components/auth/ForgetPassword";
import Footer from "../../components/pages/Footer";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Scroll from 'react-scroll';
const baseUrl = myConstList.baseUrl;
const Link = Scroll.Link;
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName:'',
           errors: {}
        };
     
    }

    componentDidMount() {               
        if (this.props.auth.isAuthenticated) {
            this.setState({userName:this.props.auth.user.name});
        } 
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }        
    }

render() {
        const { errors } = this.state;
        return (
            <>
			<MetaTags>
                <title>About Us | Trade The Games</title>
                <meta name="description" content="Trade The Games is a crypto fantasy trading gaming platform where you do virtual crypto trading in a contest format and win games based on your skills and performance." />
                <meta name="robots" content="index, follow"/>
     <link rel="canonical" href="https://www.tradethegames.com/about-us" />
	<meta name="facebook-domain-verification" content="ih4k1e3ay891tcx2z9uelbtn60zdsr"/> 
    <meta property="og:title" content="About Us | Trade The Games"/>
	<meta property="og:site_name" content="Trade the Games"/>
    <meta property="og:url" content="https://www.tradethegames.com/"/>
	<meta property="og:description" content="Trade The Games is a crypto fantasy trading gaming platform where you do virtual crypto trading in a contest format and win games based on your skills and performance."/>
	<meta property="og:type" content="article"/>
	<meta property="og:image" content="https://www.tradethegames.com/img/ttg-logo.jpg"/>
    <meta name="twitter:card" content="summary_large_image"/>
	<meta name="twitter:site" content="@tradethegames"/>
	<meta name="twitter:title" content="About Us | Trade The Games"/>
	<meta name="twitter:description" content="Trade The Games is a crypto fantasy trading gaming platform where you do virtual crypto trading in a contest format and win games based on your skills and performance."/>
    <meta name="twitter:image" content=" https://www.tradethegames.com/img/ttg-logo.jpg"/>
            </MetaTags> 
            <div className="">
                <div >
                    <nav className="navbar navbar-expand-lg home_nav">
                        <div className="container ">
                        <a className="navbar-brand" href="/">
                            <img src="./img/logo.png" alt="header-Logo" className="logo logo1"/>
							<img src="./img/logo_mobile.png" alt="header-Logo" className="logo logo2"/>
						</a>

                       
                     <div className="right_menu ">
                            <li className="nav-item">
                                {this.state.userName !=='' &&
                                <a href = "/profile-user" className="man_color fw-500" >{this.state.userName}
                                </a>
                                
                                }
                                {/* {this.state.userName ==='' &&
                                <a className="btn" ref={this.button}  data-toggle="modal" data-target="#loginmodal" id="main_btn">Login/Sign Up</a>
                                } */}

                            
                            </li>
                        </div>
                        </div>
                    </nav>
                    <div className="modal fade" id="loginmodal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body login_modal"> <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>

                                <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#SignIn" id="signintab">Sign In</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#SignUp" id="signuptab">Sign Up</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-4">
                                        <Login />
                                        <Register />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner_banner text-center" >
					    <img src="./img/about_banner.jpg" alt="about_banner"/>
						 <div>
                        <h1>About Us</h1>
                       </div>
                    </div>
                    <div className="p60 how" >
                    <div className="container">
					<p><a className="color_link" href="https://www.tradethegames.com/">Trade the Games</a> is world’s most epic fantasy trading game. This is a groundbreaking platform where trading on cryptocurrency take place with multiple games editions to get many chances to win profits. It is a revolutionary adventure for new generation and experienced too. </p>
					<p>It brings crypto trading and fantasy gaming together. It gives endless chances to learn crypto trading while eliminating all the risks and offering rewards. This is an innovative and unique way to diversifying investment portfolios. Self-Custodial skills allow crypto enthusiasts to maintain sole control over their digital assets.</p>
					<p>On the website, the crypto trading aspirants can learn the subtleties of buying and selling crypto. TTG offers crypto trading to aspirants who want to dabble in cryptocurrency. It’s a fun games with learning legitimacy. Direct cash withdrawal without any hazel is possible on platform. </p>
					<p>TTG integrates the elements of fantasy gaming and engages the participants in contests. It enthuses the users to work on their performance and to trade smartly to win. </p>
					</div>
                    </div>
                   
                    <Footer />

                    
                </div>
            </div>
            <ForgetPassword />
  
</>
        );
    }
}

Home.propTypes = {
    errors: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps
)(Home);
