import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { subscriberUser } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Header from "../../components/pages/Header";
import Register from "../../components/auth/Register";
import SubscribeUser from "../../components/auth/SubscribeUser";
import ForgetPassword from "../../components/auth/ForgetPassword";
import Footer from "../../components/pages/Footer";
import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Scroll from 'react-scroll';
import $ from 'jquery';


const Link = Scroll.Link;

const baseUrl = myConstList.baseUrl;
class Home extends Component {
    constructor() {
        super();
        this.state = {         
            balance:'',
           errors: {}
        };
		
		this.button = React.createRef();
		this.signupTabBtn = React.createRef();
     
    }

    componentDidMount() {
        if(this.props.match.params._id != undefined){
            axios
                .post(baseUrl+"api/email-verification", {userId: this.props.match.params._id})
                .then(res => {     
                        toast( res.data.message);
                this.props.history.push("/");
            })
            .catch(error=>{
                // alert(error);
            });
            
        }
        
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
		if(this.props.match.params.refer!==undefined){
			this.button.current.click();
			this.signupTabBtn.current.click();
			
		}
		
    };

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.auth.isAuthenticated) {
           window.location = "/dashboard";
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
    }
    onSubmit = e => {
        e.preventDefault();	
            const newUser = {
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile
        };
        let res = this.props.subscriberUser(newUser);        
    }

render() {
        const { errors } = this.state;
        return (
            <>	
            <div className="">
                <div >
                    <nav className="navbar navbar-expand-lg home_nav">
	<div className="container ">
        <a className="navbar-brand" href="/">
        <img src="img/logo.png" alt="header-Logo" className="logo logo1"/>
        <img src="img/logo_mobile.svg" alt="header-Logo" className="logo logo2"/>
		
		</a>
		
        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarText"><span className="icon-bar"><i className="las la-times"></i></span></button>
        <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav  line ml-auto">
			 <li className="nav-item">
                    <a className="nav-link active" href="" data-scroll-nav="1">About</a> 
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="2">How To Play</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="3">Contest</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="4">Media</a>
                </li>
               <li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="5">FAQ</a>
                </li> 				
				<li className="nav-item">
                    <a className="nav-link" href="#" data-scroll-nav="6">Contact</a>
                </li>
				
            </ul>
        </div>
		        <div className="right_menu ">
                     <li className="nav-item">
                        <a href="/login" className="btn" id="main_btn">Login/Sign Up</a>
                     </li>
                  </div>
		
		</div>
       
    </nav>
                    <div className="modal fade" id="loginmodal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body login_modal"> <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>

                                 <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#SignIn" id="signintab">Sign In</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" ref={this.signupTabBtn} data-toggle="tab" href="#SignUp" id="signuptab">Sign Up</a>
                                        </li>
                                    </ul>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <header className="home" id="home">
						
                        <div className="banner_content">
                        <div className="container">
                            <div className="row ">
                            <div className="col-md-6 mt-md-5 mb-3 mb-md-0">
                            
                        <SubscribeUser />
                                <h1 className="mt-md-4">India’s first cryptocurrency fantasy trading gaming platform which gives you real trading experience</h1>
                                
                                <div className="login_btns mt-4">
                                <a href="ttg.apk" className="mr-3"><img src="img/google-btn.png" className="img-fluid" alt="TTG App Store"/> </a>
                                <a href="#" className=""><img src="img/apple-btn.png" className="img-fluid" alt="TTG App Store"/></a>
                                </div>
                            </div>
                            <div className="col-md-6 mt-md-4">
                                <img src="img/lp.png" alt="TTG Sceen" className="img-fluid animation-bounce"/>
                            </div>
                            </div>
                        </div>
                        </div>
                        <img src="img/wave-1.png" alt="About TTG" className="b_b"/>
                    </header>
                    <div className="about p80 pb-0" data-scroll-index="1" id="about-ttg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                <h2 className=" man_hadding mb-3">About TTG</h2>
                                <p>Trade The Games is a groundbreaking platform that brings cryptocurrency trading and fantasy gaming together. On this website, the crypto trading aspirants can learn the subtleties of buying and selling cryptos without losing a big amount. It becomes a playground for all the people who want to master the art of trading before getting into it. While doing that, it gives them opportunities to earn actual money. </p>
                                <p>Yes, the Trade The Games is nothing less than a boon for individuals who want to attain perfection in their trading. To make this happen, TTG integrates the elements of fantasy gaming and engages the participants in contests. It enthuses the users to work on their performance and to trade smartly to win. All in all, it is a website that gives endless chances to learn crypto trading while eliminating all the risks and offering rewards.</p>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                    <div className="p80 about " data-scroll-index="2" id="how-to-play">
                        <div className="container">
                            <div className=" tc mb-4 mb-md-5">
                                <h2 className="man_hadding">How to Play</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div className="work_box">
                                    <div className="icon-wrapper">
                                    <i className="las la-user-alt"></i>
                                    </div>
                                    <h4>Sign Up</h4>
                                    <p>Sign up with your email and password. You instantly receive the verification email. Click on it, you’re good to go.
                                    </p>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div className="work_box">
                                    <div className="icon-wrapper">
                                    <i className="las la-user-check"></i>
                                    </div>
                                    <h4>Participate In Contest</h4>
                                    <p>After signing up, you can quickly become a member of the platform and go through all the competitions rolled out. 
                                    </p>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div className="work_box">
                                    <div className="icon-wrapper">
                                    <i className="las la-coins"></i>
                                    </div>
                                    <h4>Start Trading & Earn</h4>
                                    <p>You can choose the contest and start the fun ride of crypto trade learning. You get time to do mistakes and learn.
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="join_contact p80" data-scroll-index="3" id="contest">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-5">
                                <img src="img/track-time.png" alt="Join Contest" className="img-fluid"/>
                                </div>
                                <div className="col-md-7 pl-md-5">
                                <h3 className=" man_hadding mb-3 text-white">Join Contest</h3>
                                <p>Joining the contests on TTG is not just easy, it incites the participants to delve deeper into the world of trading. The whole activity has been designed to give way to many possibilities. It enables the users to find the different sides of crypto trading. It helps them hone their skills and makes them familiar with all types of conditions that affect markets. The users get to face the conditions and circumstances that really occur in the markets and cause plenty of losses to the crypto market participants.</p>
                                <p>
Once the users are engaged in the competition, they get to see different factors of trading. TTG take the dummy trading to a whole new level and makes you familiar with its possibilities of it. It gives you a framework that works exactly like the real trading environment and includes everything that causes the fluctuation of the prices of crypto. It makes trading possible for all those who usually get intimidated by charts, numbers, and think that it is an otherworldly thing. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p80 news_logo_section" data-scroll-index="4" id="media">
                        <div className="container">
                        <h3 className=" man_hadding mb-0 tc mb-2">News & media</h3>
                       
                            <div className="row align-items-center ">
							  <div className="col-md-4 col-sm-4 col-6">
                                <a rel="nofollow" href="https://www.indiatoday.in/pr-newswire?rkey=20220214EN61007&filter=4315" target="_blank" className="news_logo">
                                    <img src="img/indiatoday.png" alt="indiatoday" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a rel="nofollow" href="https://www.bloomberg.com/press-releases/2022-02-14/technoloader-launched-india-s-first-fantasy-crypto-trading-trade-the-games" target="_blank" className="news_logo">
                                    <img src="img/bloomberg-logo.png" alt="bloomberg" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a rel="nofollow" href="https://www.businesstoday.in/prnewswire/?rkey=20220214EN61007&filter=2418" target="_blank" className="news_logo">
                                    <img src="img/businesstoday.png" alt="businesstoday" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a rel="nofollow" href="https://www.freepressjournal.in/technology/technoloader-launches-fantasy-crypto-trading-trade-the-games" target="_blank" className="news_logo">
                                    <img src="img/freepressjournal.png" alt="freepressjournal" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a rel="nofollow" href="https://www.newdelhitimes.com/news-release/?rkey=20220214EN61007&filter=5147" target="_blank" className="news_logo">
                                    <img src="img/newdelhitimes.png" alt="newdelhitimes" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a href="/media" className="news_logo">
                                    <img src="img/dainikbhaskar.png" alt="dainikbhaskar" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a href="/media"  className="news_logo">
                                    <img src="img/rajasthanpatrika.png" alt="rajasthanpatrika" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a href="/media"  className="news_logo">
                                    <img src="img/dainiknavajyoti.png" alt="dainik navajyoti" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a href="/media" className="news_logo">
                                    <img src="img/samacharjagat.png" alt="samacharjagat" className="img-fluid"/>
                                </a>
                                </div>
								<div className="col-md-4 col-sm-4 col-6">
                                <a href="/media" className="news_logo">
                                    <img src="img/hindustanexpress.png" alt="hindustanexpress" className="img-fluid"/>
                                </a>
                                </div>
    
                            </div>
                            <div className="tc mt-2 mt-md-3">
                                <a className="btn btn_man" href="/media">View More</a>
                            </div>
                        </div>
                    </div>
                    <div className="download_app p80">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-5">

                                </div>
                                <div className="col-md-7 pl-md-5">
                                <h3 className=" man_hadding mb-3 text-white">Don’t Trade Yet <br />
                                  Just Learn & Earn</h3>
                                <p>Many people commit the grave mistake of jumping into the crypto trade without perfecting it and find themselves in dire straits. TTG does not just let you learn, it totally subverts the need to do real trading and make it possible to keep earning while learning. </p>
                                <h4>Download the App</h4>
                                <div className="login_btns mt-4">
                                    <a href="ttg.apk" className="mr-3"><img src="img/google-btn.png" className="img-fluid" alt="TTG App Store"/> </a>
                                    <a href="#" className=""><img src="img/apple-btn.png" className="img-fluid" alt="TTG App Store"/></a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p80 faq" data-scroll-index="5" id="faq">
                        <div className="container">
                            <h3 className=" man_hadding mb-3 tc">FAQ</h3>
                            <div className="row align-items-center">
                                <div className="col-lg-1">

                                </div>
                                <div className="col-lg-10">

                                <div className="accordion md-accordion style-2" id="accordionEx" role="tablist" aria-multiselectable="true">
                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingOne1">
                                        <a className="" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true"
                                        aria-controls="collapseOne1">
                                       What is Trade The Games?<i className="las la-plus rotate-icon"></i>
                                        </a>
                                    </div>
                                    <div id="collapseOne1" className="collapse show " role="tabpanel" aria-labelledby="headingOne1"
                                        data-parent="#accordionEx">
                                        <div className="card-body">
                                      Trade The Games is a cryptocurrency fantasy trading gaming platform in which you use your crypto trading skills to win contests and earn money. 
                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo2">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
                                        aria-expanded="false" aria-controls="collapseTwo2">
                                      How can I participate in the contest? <i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                                        data-parent="#accordionEx">
                                        <div className="card-body">First of all, you need to sign up with Trade the Games and become a member. After that, you can pay the entry fee of the contests and participate.
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo3">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo3"
                                        aria-expanded="false" aria-controls="collapseTwo3">
                                        Which currency can I use to add funds? <i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo3" className="collapse" role="tabpanel" aria-labelledby="headingTwo3"
                                        data-parent="#accordionEx">
                                        <div className="card-body">You need to pay fiat currency (INR) to add funds to your cash wallets.
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo4">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo4"
                                        aria-expanded="false" aria-controls="collapseTwo4">
                                       Can I add funds by paying cryptocurrency?<i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo4" className="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> No. Adding funds will be possible only via INR.
                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo5">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo5"
                                        aria-expanded="false" aria-controls="collapseTwo5">
                                      How many contests can I join at a time?<i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo5" className="collapse" role="tabpanel" aria-labelledby="headingTwo5"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> Yes, you can join multiple contests at a time to enhance your chances of winning. By allowing this functionality, the platform makes participation more rewarding for every individual.

                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo6">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo6"
                                        aria-expanded="false" aria-controls="collapseTwo6">
                                       What is the age criteria to play the game? <i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo6" className="collapse" role="tabpanel" aria-labelledby="headingTwo6"
                                        data-parent="#accordionEx">
                                        <div className="card-body">You must be 18 years old to be able to engage with this game. The age limit is kept considering the fact that the game gets you ready for crypto trading which exposes you to financial risks. Upon attaining this age, you can start practising the crypto trade and take the first step towards becoming a winner in this activity.
                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo6">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo7"
                                        aria-expanded="false" aria-controls="collapseTwo7">
                                      Is KYC compulsory for playing the game? <i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo7" className="collapse" role="tabpanel" aria-labelledby="headingTwo7"
                                        data-parent="#accordionEx">
                                        <div className="card-body">KYC will mandatory in case when you add bank details to platform while withdraw. Other than this, no KYC required. User can participate to numerous contests without KYC completion.
                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo8">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo8"
                                        aria-expanded="false" aria-controls="collapseTwo8">
                                    Is cryptocurrency knowledge compulsory for playing the game?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo8" className="collapse" role="tabpanel" aria-labelledby="headingTwo8"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> Yes, Trade the Games is a game of skill and in order to win, you need to have knowledge of cryptocurrency trading.
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="tc mt-2 ">
                                <a className="btn btn_man" href="/faq">View More</a>
                            </div>
                        </div>
                    </div>
					
					
					
					
			
                </div>
            </div>


<section className="contact padding footer" id="contact" data-scroll-index="6"><div className="container"><div className="row"><div className="col-md-3 "><img src="img/banner-logo.png" alt="footer_logo" className="img-fluid footer_logo"/></div><div className="col-md-3 pl-md-5"><div className="nav_link"><h5>Company</h5> <ul >
    <li ><a  href="/about-us">About Us</a></li>
    <li ><a  href="/faq">FAQ</a> </li>
    <li><a target="_blank" href="https://www.tradethegames.com/blog">Blog</a> </li>
    <li ><a  href="/term-and-condition">Terms and Conditions</a> </li>
    <li ><a  href="/privacy-policy">Privacy Policy </a> </li>
    <li ><a  href="/contact-us">Contact Us</a> </li>
    </ul></div></div><div className="col-md-3 "><div className="vertical-social nav_link wow fadeInDown  animated" data-wow-delay="0.1s"><h5>Social Media</h5><ul><li><a rel="nofollow" target="_blank" href="https://www.facebook.com/tradethegames/"><i className="fa fa-facebook"></i> Facebook</a></li><li><a rel="nofollow" target="_blank" href="https://twitter.com/tradethegames"> <i className="fa fa-twitter"></i>Twitter</a></li><li><a rel="nofollow" target="_blank" href="https://www.linkedin.com/company/trade-the-games"> <i className="fa fa-linkedin"></i>Linkedin</a></li><li><a rel="nofollow" target="_blank" href="https://www.instagram.com/tradethegames/"><i className="fa fa-instagram"></i>Instagram</a></li><li><a rel="nofollow" target="_blank" href="https://medium.com/@tradethegames"><i className="fa fa-medium"></i>Medium</a></li><li><a rel="nofollow" target="_blank" href="https://www.reddit.com/r/tradethegames/"><i className="fa fa-reddit"></i>Reddit</a></li><li><a rel="nofollow" target="_blank" href="https://t.me/TTG_announcements"><i className="fa fa-telegram"></i>Telegram Channel</a></li></ul></div></div><div className="col-md-3 "><div className="vertical-social wow fadeInDown  animated" data-wow-delay="0.1s"><div className="nav_link"><h5>Download</h5><div className="login_btns "><a href="ttg.apk" className="mb-2 mr-2"><img src="img/google-btn.png" className="img-fluid" alt=""/> </a><a href="#" className=""><img src="img/apple-btn.png" className="img-fluid" alt=""/></a></div></div></div></div></div></div><div className="copyright tc">© 2022 Trade The Games. A Product of <a className="man_color" target="_blank" href="https://technoloaderitservices.com/">Technoloader</a></div><a className="telegram_sticky faa-horizontal animated" target="_blank" href="https://t.me/tradethegames"><i className="fa fa-paper-plane"></i></a></section>
</>
        );
    }
}

Home.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Home);
