import React, { Component, Fragment } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requery } from "../../../actions/updateHelp";
import ReactDatatable from '@ashvin27/react-datatable';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Link} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Slider from "react-slick";
class PastContest extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records:[],
            id: this.props.id,
            description: '',
            message: '',
            username:user.name,
            showResults: false,
            showTeaxtArea: true,
            errors: {},
        };
        // reactData Table
        this.columns = [
            {
                key: "contestName",
                text: "Contest Name",
                classNameName: "contestName",
                align: "left",
                sortable: true,
            },
			{
                key: "winningAmount",
                text: "Prize pool",
                classNameName: "winningAmount",
                align: "left",
                sortable: true,
            },
			{
                key: "entryFee",
                text: "Entry Fee",
                classNameName: "entryFee",
                align: "left",
                sortable: true,
            },{
                key: "numOfParticipation",
                text: "Participants",
                classNameName: "numOfParticipation",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {record?.numOfParticipation+"/"+record.max_particepate}
                        </Fragment>
                    );
                }
            },{
                key: "startDate",
                text: "Timing",
                classNameName: "startDate",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {record.startDate+"("+record.startTime+") - "+record.endDate+"("+record.endTime+")"}
                        </Fragment>
                    );
                }
            }
            ,{
                key: "_id",
                text: "Action",
                classNameName: "action",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          <Link className="btn btn_man" to={"/trading-contest/" + record._id} ><span>
                                    View Details</span></Link>
                        </Fragment>
                    );
                }
            }
        ];
    }

    componentWillReceiveProps(nextProps) {
 
        if (nextProps.record) {
            //if(nextProps.record.length !== this.state.records.length ){
                this.setState({
                    records: nextProps.record,
                    bonus:nextProps.bonus
                });
            //}
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {

            this.setState({
                message: nextProps.auth.user.data.message
            });
        }
    }
    getNodesToRemoveFromElement = (stringContent) => {
 
        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };
    showChatHtml = () =>{
        var month_name = function(dt){
          var  mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
              return mlist[dt.getMonth()];
            };
           
        const html = []
        this.state.records.map(function(value, i){
            var dateObj1 = new Date(value.startDate);
            var day1 = dateObj1.getUTCDate()
            var dateObj2 = new Date(value.endDate);
            var day2 = dateObj2.getUTCDate()

		  html.push(
            <>
                  <div className="col-xl-4 col-lg-6 col-md-6  mt-3 mt-md-4" key={i}>
                <div className="crypt-market-status2 past_man_box">
                    <div className="past_man_box-inner">
					<div className="row top_contest">
					<div className="col-12 text-center">  <h6 >{value.contestName}</h6></div>
					</div>
                            <div className="row mt-3">
                                <div className="col-6 ">
                                    <span className="op_0_5"> Prize pool</span>
                                </div>
								
								<div className="col-6 ">
                                    <span className="past_value"><i className="fa fa-inr"></i> {value.winningAmount}</span>
                                </div>
								
								
								<div className="col-6 ">
                                    <span className="">Entry Fee: </span>
                                   
                                </div>
								
								<div className="col-6 ">
                                   
                                    <span className="past_value"><i className="fa fa-inr"></i> {value.entryFee}</span>
                                </div>
                                <div className="col-6 ">
                                    <span className="op_0_5"> Participants</span>
                                   
                                </div> 
								<div className="col-6 ">
                                   
                                    <span className="past_value">{value?.numOfParticipation}</span>
                                </div>
                                <div className="col-6 ">
                                    <span className="op_0_5"> Timing</span>
                                   
                                </div> 
								<div className="col-6 ">
                                   
                                    <span className="past_value">{month_name(new Date(value.startDate))}, {day1} ({value.startTime}) – {month_name(new Date(value.endDate))}, {day2} ({value.endTime})</span>
                                </div>								
                            </div>						
							
							 <div className="details-outer row" >
							  <div className="col-6 col-md-6 pt-1">
                            <Link className="view_dital" to={"/trading-contest/" + value._id} ><span><i className="las la-trophy"></i>
                                    View Details</span></Link>
                              </div>
                              </div>
                    </div>
                </div>
                </div>
             </>
            );
        })
        return html;
    }
    onChange = e => {
        if (e.target.id === 'user-update-subject') {
            this.setState({ subject: e.target.value });
        }

    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUserUpdate = e => {
        const { user } = this.props.auth;
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
             user_id: user.id,
             description: this.state.description


        };
        this.props.requery(newUser);
    };
   
    render() {
        var settings = {
            initialSlide: 0,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 3500,
            dots: true,
            arrows: false,
            pauseOnHover: true,
            pauseOnFocus: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    adaptiveHeight: true,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 575,
                  settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                  },
                },
            ],
            
        };
        const { errors } = this.state;
        const { user } = this.props.auth;
        return (
            <div className="crypt-market-status2 mt-4 p-2 p-sm-3">
					<div className=" table-striped mb-0">
					<div className="ccrool_table">
					 
					<ReactDatatable
                    records={this.state.records}
                    columns={this.columns}
                    
                />

					 </div>
	
					 </div>
					 </div>
           
)
    }
}

PastContest.propTypes = {
    requery: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { requery }
)(withRouter(PastContest));
