import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import Moment from 'moment';


class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allAccount: this.props.allAccount,
            usdt:this.props.usdt,
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.allAccount) {
            this.setState({
                allAccount: nextProps.allAccount
            
            });
        }
        if(nextProps.usdt != undefined){
            this.setState({usdt:nextProps.usdt});  
        } 
        
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {

            this.setState({
                message: nextProps.auth.user.data.message
            });
        }
    }

    showChatHtml = () => {
       

        const html = []
		if(this.state.allAccount!==undefined) {
			 this.state.allAccount.map(function (value, i) {
				html.push(
					<>
						<tr key={i}>
							<td className="align-middle"><i className="las la-star mr-1 stsr5"></i>
							{i+2}</td>
							<td><span className="pr-2">{value._id.slice(0, -4)}</span></td>
							<td><span className="pr-2">{value.totalcurrency}</span></td>
							<td><span className="pr-2">{(value.totalcurrency*value.price).toFixed(0)}</span></td>
						</tr>
					</>
				);
			})
		}
        return html;
    }
 

 
  

    render() {
        return (
            <table className="table table-striped border_none mb-0">            
                <tr>
                    <th>S.No.</th>
                    <th>Currency</th>
                    <th>Quantity</th>
                    <th>Value (IN USDT)</th>
                </tr>
                <tbody>
                    <tr>
                        <td className="align-middle"><i className="las la-star stsr5"></i>
                        {1}</td>
                        <td><span className="pr-2">USDT</span></td>
                        <td><span className="pr-2">{this.state.usdt}</span></td>
                        <td><span className="pr-2">{this.state.usdt}</span></td>
                    </tr>
                    {this.showChatHtml()}
                </tbody>
        </table>
        );
    }

}

Account.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});
export default connect(
    mapStateToProps
)(Account);
