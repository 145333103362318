import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import ForgetPassword from "../../components/auth/ForgetPassword";
import Footer from "../../components/pages/Footer";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Scroll from 'react-scroll';
const Link = Scroll.Link;
const baseUrl = myConstList.baseUrl; 
class Home extends Component {
    constructor() {
        super();
        this.state = {
            userName:'',
            balance:'',
           errors: {}
        };
     
    }

    componentDidMount() {               
        if (this.props.auth.isAuthenticated) {
            this.setState({userName:this.props.auth.user.name});
        } 
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
    }

render() {
        const { errors } = this.state;
        return (
            <>
			<MetaTags>
                <title>Frequently Asked Questions (FAQ ) | Trade The Games</title>
                <meta name="description" content="Do you know about trade the games and how it works? Read our all FAQ for more clarifications." />
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://www.tradethegames.com/faq" />
    <meta name="facebook-domain-verification" content="ih4k1e3ay891tcx2z9uelbtn60zdsr"/>
	<meta property="og:title" content="Frequently Asked Questions (FAQ ) | Trade The Games"/>
	<meta property="og:site_name" content="Trade the Games"/>
    <meta property="og:url" content="https://www.tradethegames.com/"/>
	<meta property="og:description" content="Do you know about trade the games and how it works? Read our all FAQ for more clarifications."/>
	<meta property="og:type" content="article"/>
	<meta property="og:image" content="https://www.tradethegames.com/img/ttg-logo.jpg"/>
    <meta name="twitter:card" content="summary_large_image"/>
	<meta name="twitter:site" content="@tradethegames"/>
	<meta name="twitter:title" content="Frequently Asked Questions (FAQ ) | Trade The Games"/>
	<meta name="twitter:description" content="Do you know about trade the games and how it works? Read our all FAQ for more clarifications."/>
    <meta name="twitter:image" content=" https://www.tradethegames.com/img/ttg-logo.jpg"/>
            </MetaTags> 
            <div className="">
                <div >
                <nav className="navbar navbar-expand-lg home_nav">
                        <div className="container ">
                        {/* <!-- logo --> */}
                        <a className="navbar-brand" href="/">
                            <img src="./img/logo.png" alt="Trade the Games" className="logo"/></a>
                            <div className="right_menu ">
                            <li className="nav-item">
                                {this.state.userName !=='' &&
                                <a href = "/profile-user" className="man_color fw-500" >{this.state.userName}
                                </a>
                                
                                }
                                {/* {this.state.userName ==='' &&
                                <a className="btn" ref={this.button}  data-toggle="modal" data-target="#loginmodal" id="main_btn">Login/Sign Up</a>
                                } */}

                            
                            </li>
                        </div>
                        </div>
                    </nav>
                    <div className="modal fade" id="loginmodal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body login_modal"> <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>

                                <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#SignIn" id="signintab">Sign In</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#SignUp" id="signuptab">Sign Up</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-4">
                                        <Login />
                                        <Register />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                   <div className="inner_banner text-center" >
					    <img src="./img/about_banner.jpg" alt="about_banner"/>
						 <div>
                        <h1>FAQ</h1>
                       </div>
                    </div>
                    <div className="p60 how faq">
    <div className="container">
                           
                            <div className="row align-items-center">
                                <div className="col-lg-1">

                                </div>
                                <div className="col-lg-10">

                                <div className="accordion md-accordion style-2" id="accordionEx" role="tablist" aria-multiselectable="true">
                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingOne1">
                                        <a className="" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true"
                                        aria-controls="collapseOne1">
                                       What is Trade The Games?<i className="las la-plus rotate-icon"></i>
                                        </a>
                                    </div>
                                    <div id="collapseOne1" className="collapse show " role="tabpanel" aria-labelledby="headingOne1"
                                        data-parent="#accordionEx">
                                        <div className="card-body">
                                     <a className="color_link" href="https://www.tradethegames.com/">Trade the Games</a> is a cryptocurrency fantasy trading gaming platform in which you use your trading skills to win contests and earn money. 
                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo2">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
                                        aria-expanded="false" aria-controls="collapseTwo2">
                                      How can I participate in the contest? <i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                                        data-parent="#accordionEx">
                                        <div className="card-body">First of all, you need to sign up with Trade the Games and become a member. After that, you can pay the entry fee of the contest and participate.
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo3">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo3"
                                        aria-expanded="false" aria-controls="collapseTwo3">
                                        Which currency can I use to add funds? <i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo3" className="collapse" role="tabpanel" aria-labelledby="headingTwo3"
                                        data-parent="#accordionEx">
                                        <div className="card-body">You need to pay fiat currency (INR) to add funds to your cash wallet.
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo4">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo4"
                                        aria-expanded="false" aria-controls="collapseTwo4">
                                       Can I add funds by paying cryptocurrency?<i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo4" className="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> No. Adding funds will be possible only via INR.
                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo5">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo5"
                                        aria-expanded="false" aria-controls="collapseTwo5">
                                      How many contests can I join at a time?<i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo5" className="collapse" role="tabpanel" aria-labelledby="headingTwo5"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> You can join multiple contests at a time to enhance your chances of winning. By allowing this functionality, the platform makes participation more rewarding for every individual.

                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo6">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo6"
                                        aria-expanded="false" aria-controls="collapseTwo6">
                                       What is the age criteria to play the game? <i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo6" className="collapse" role="tabpanel" aria-labelledby="headingTwo6"
                                        data-parent="#accordionEx">
                                        <div className="card-body">You must be 18 years old to be able to engage with this game. The age limit is kept considering the fact that the game gets you ready for crypto trading which exposes you to financial risks. Upon attaining this age, you can start practising the crypto trade and take the first step towards becoming a winner in this activity.
                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo6">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo7"
                                        aria-expanded="false" aria-controls="collapseTwo7">
                                      Is KYC compulsory for playing the game? <i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo7" className="collapse" role="tabpanel" aria-labelledby="headingTwo7"
                                        data-parent="#accordionEx">
                                        <div className="card-body">KYC will mandatory in case when you add bank details to platform while withdraw. Other than this, no KYC required. User can participate to numerous contests without KYC completion.
                                        </div>
                                    </div>
                                    </div>

                                    <div className="card">
                                    <div className="card-header" role="tab" id="headingTwo8">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo8"
                                        aria-expanded="false" aria-controls="collapseTwo8">
                                    Is cryptocurrency knowledge compulsory for playing the game?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo8" className="collapse" role="tabpanel" aria-labelledby="headingTwo8"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> Yes, Trade the Games is a game of skill and in order to win, you need to have knowledge of cryptocurrency trading.
                                        </div>
                                    </div>
                                    </div>
									
									
									
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo9">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo9"
                                        aria-expanded="false" aria-controls="collapseTwo9">
                                    How Trade the Game works?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo9" className="collapse" role="tabpanel" aria-labelledby="headingTwo9"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> Trade The Games is a crypto fantasy trading gaming platform where you do virtual crypto trading in a contest format and win games based on your skills and performance.
                                        </div>
                                    </div>
                                    </div>
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo10">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo10"
                                        aria-expanded="false" aria-controls="collapseTwo10">
                                   How I can register on Trade the Game..?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo10" className="collapse" role="tabpanel" aria-labelledby="headingTwo10"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> Enter to the website https://www.tradethegames.com/ and sign up. To sign up, user needs to submit entities like user name, email, password, and referral by. Email verification at the end confirms the Registration.
                                        </div>
                                    </div>
                                    </div>
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo11">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo11"
                                        aria-expanded="false" aria-controls="collapseTwo11">
                                   What does mean of Reward Wallet?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo11" className="collapse" role="tabpanel" aria-labelledby="headingTwo11"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> It is a wallet in which you collect bonuses and rewards by referring the website to other people. The amount in this wallet can only be used for playing the game, it cannot be withdrawn.  
                                        </div>
                                    </div>
                                    </div>
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo12">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo12"
                                        aria-expanded="false" aria-controls="collapseTwo12">
                                    What does the mean of Winning Wallet?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo12" className="collapse" role="tabpanel" aria-labelledby="headingTwo12"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> This is the wallet that collects the amount that you get after winning the contest. The amount can be transferred/withdrawn to your bank account. 
                                        </div>
                                    </div>
                                    </div>
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo13">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo13"
                                        aria-expanded="false" aria-controls="collapseTwo13">
                                    What does the mean of Cash Wallet?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo13" className="collapse" role="tabpanel" aria-labelledby="headingTwo13"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> This wallet works as a reserve. It contains the total amount you add. The amount in this wallet can only be used in playing the game, it cannot be withdrawn. 
                                        </div>
                                    </div>
                                    </div>
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo14">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo14"
                                        aria-expanded="false" aria-controls="collapseTwo14">
                                    Can I withdraw the amount from my cash wallet?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo14" className="collapse" role="tabpanel" aria-labelledby="headingTwo14"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> No, the amount in this wallet can only be used in playing the game, it cannot be withdrawn.
                                        </div>
                                    </div>
                                    </div>
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo15">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo15"
                                        aria-expanded="false" aria-controls="collapseTwo15">
                                    How user can add money to the cash wallet?<i
                                            className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo15" className="collapse" role="tabpanel" aria-labelledby="headingTwo15"
                                        data-parent="#accordionEx">
                                        <div className="card-body"> Using the Zaakpay and Cashfree you can add money to your cash wallet easily.
                                        </div>
                                    </div>
                                    </div>
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo16">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo16"
                                        aria-expanded="false" aria-controls="collapseTwo16">
                                    How to verify KYC?<i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo16" className="collapse" role="tabpanel" aria-labelledby="headingTwo16" data-parent="#accordionEx">
                                        <div className="card-body"> Register on website https://www.tradethegames.com/ and proceed to the KYC. It accommodates six different verification paces. First <b>verify Email</b>, Next is <b>Phone verification</b> which followed by a verification code or OTP. Next, Submit One Photo <b>ID proof </b>along with image of ID proof from front and back both sides, First name, last name, Gender, document number, and date of birth. ID proof can be Adhar card, Passport, Driving License. <br/><br/>
										Forge ahead with <b>Address verification</b>, select one document type from given five choices which are Adhar card, Passport, Driving License, Utility Bill, Bank Passbook. <br/><br/>
										Next <b>PAN Verification</b> needs PAN number and scanned photo of PAN Card. Last will be <b>Face Verification</b> and requires a short video of user with self introduction. Upload the video and submit. Soon Admin approve details and user’s KYC will be updated. 
                                        </div>
                                    </div>
                                    </div>
									
									<div className="card">
                                    <div className="card-header" role="tab" id="headingTwo17">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo17"
                                        aria-expanded="false" aria-controls="collapseTwo17">
                                   What are the rules for playing a game?<i className="las la-plus rotate-icon"></i></a>
                                    </div>
                                    <div id="collapseTwo17" className="collapse" role="tabpanel" aria-labelledby="headingTwo17" data-parent="#accordionEx">
                                        <div className="card-body"> Minimum five Buy and Sell bids per game are mandatory while playing each contest. User can exceed from five bids, but no less bid will counted as player. Crypto pair can be different or same as per user choice.
                                        </div>
                                    </div>
                                    </div>
									
									
									
                                </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    
        <Footer />

                    
                </div>
            </div>
            <ForgetPassword />
  
</>
        );
    }
}

Home.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Home);
