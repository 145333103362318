import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import ForgetPassword from "../../components/auth/ForgetPassword";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
import axios from "axios";
import Scroll from 'react-scroll';
const baseUrl = myConstList.baseUrl;
const Link = Scroll.Link;
class Header extends Component {
    constructor() {
        super();
    }
    render() {
        const { errors } = this.state;
        return (
        <>
                    <nav className="navbar navbar-expand-lg home_nav">
                        <div className="container ">
                        {/* <!-- logo --> */}
                        <a className="navbar-brand" href="index.html">
                           <img src="./img/logo.svg" alt="header-Logo" className="logo logo1"/>
							<img src="./img/logo_mobile.svg" alt="header-Logo" className="logo logo2"/>
							</a>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText">
                            <span className="icon-bar"><i className="fa fa-bars fa-2x"></i></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav  line ml-auto">
                            <li className="nav-item">
                            <Link className="nav-link" to="about-ttg" spy={true} smooth={true} duration={500}
                            >   About
                            </Link>  
                            </li>                            
                            <li className="nav-item">
                                <Link className="nav-link" to="how-to-play" spy={true} smooth={true} duration={500}>How To Play
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="contest" spy={true} smooth={true} duration={500}>Contest
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="media" spy={true} smooth={true} duration={500}>Media
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="faq" spy={true} smooth={true} duration={500}
                                >FAQ
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="contact" spy={true} smooth={true} duration={500}
                                >Contact
                                </Link>
                            </li>

                            </ul>
                            {/* <!--End of Main menu navigation list--> */}
                        </div>
                        <div className="right_menu ">
                            <li className="nav-item">
                            <a className="btn" ref={this.button}  data-toggle="modal" data-target="#loginmodal" id="main_btn">Login/Sign Up</a>
                            </li>
                        </div>
                        </div>
                    </nav>
         </>
        );
    }
}

Header.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
)(Header);
