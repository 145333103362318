const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    trans: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case 'TR_ADD':
            return {
                isAuthenticated: !isEmpty(action.payload),
                trans: action.payload
            };
  
         
      
        default:
            return state;
    }

}
