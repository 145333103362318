import axios from "axios";
import * as myConstList from '../BaseUrl';
import {toast} from 'react-hot-toast';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
    position: 'top-right',
  }
const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
export const order = (userData, history) => dispatch => {
    axios
        .post(baseUrl+"api/order-add", userData,config)
        .then(function(res){
            if(res.data.status){
                toast.success( res.data.message, toastStyles);
            }else{
                toast.error( res.data.message, toastStyles);
            }			
			res.data.message = "";
            dispatch({
                type: 'ORDER_ADD',
                payload: res,
            }) 
        }).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};


export const updateUser = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/user-update", userData)
        .then(res =>
            dispatch({
                type: 'ORDER_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
