import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from 'ckeditor4-react';
import BigNumber from "bignumber.js";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import * as myConstList from "../../../BaseUrl";
const baseUrl = myConstList.baseUrl;
class PairSection extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props.auth;
    this.state = {
      records: [],
      id: this.props.match.params.id,
      pairRecord: [],
      pairSerachRecord: "",
      errors: {},
      virtualFundassign: 0,
      pair_idArr: this.props.pairs,
      baseUrl: this.props.urldummy,
      tempArrPair: [],
      pair: [],
      pairSectionFlag: 1,      
      steamPairs: [],
    };
    this.connection = "";
    var classThis = this;    
    
  }

  componentDidMount() {
   this.getpairDetailList();
  }

   getpairDetailList(pairsData) {
    
    const streams = pairsData
      ?.map(
        (pair) =>
          `${pair.name.toLowerCase()}@ticker/${pair.name.toLowerCase()}@depth20/${pair.name.toLowerCase()}@trade`
      )
      .join("/");
    this._connectSocketStreams(streams);    
  }

  componentWillReceiveProps(nextProps) {
    
    if (nextProps.pairs && nextProps.pairs.length>0) {
        
      this.setState({ pair_idArr: nextProps.pairs });

        if (this.state.pairSectionFlag === 1) {
            var classThis = this;
            this.getpairDetailList(nextProps.pairs);
            classThis.setState({pairSectionFlag: 2 });
        }
    }

    
    // if (this.state.pairSectionFlag == 1) {
    //   var classThis = this;
    //   var newpair = [];

    //   this.state.pair_idArr.map(function (value, i) {
    
    //     fetch(`${baseUrl}api/ticker-data?symbol=${value.name}`)
    //       .then((response) => response.json())
    //       .then((response) => {
    
    //         var objPair = {
    //           symbol: response.symbol,
    //           priceChangePercent: parseFloat(
    //             response.priceChangePercent
    //           ).toFixed(2),
    //           lastPrice: parseFloat(response.lastPrice).toFixed(2),
    //         };
    
    //         newpair.push(objPair);
    //         classThis.setState({ pairRecord: newpair, pairSectionFlag: "2" });
    //       })
    //       .catch();
    //   });
    // }
  }

  _connectSocketStreams(streams) {
    if (this[this.connection]) {
      this[this.connection].close();
    }
    // streams = streams.join("/");
    
    this.connection = btoa(streams);

    this[this.connection] = new WebSocket(
      `wss://stream.binance.com:9443/stream?streams=${streams}`
    );

    this[this.connection].onmessage = (evt) => {
        
      let eventData = JSON.parse(evt.data);
      if (eventData.stream.endsWith("@ticker")) {
        // Find the index of the pair data in the array based on the symbol
        const pairIndex = this.state.steamPairs.findIndex(
          (pair) => pair.s === eventData.data.s
        );

        if (pairIndex === -1) {
          // If the pair doesn't exist in the array, add it as a new object
          this.setState((prevState) => ({
            steamPairs: [
              ...prevState.steamPairs,
              eventData.data, // Add new pair data as a new object to the array
            ],
          }));
        } else {
          // If the pair exists in the array, update its data
          this.setState((prevState) => ({
            steamPairs: prevState.steamPairs.map((pair, index) =>
              index === pairIndex ? eventData.data : pair
            ),
          }));
        }
      }
    };
    this[this.connection].onerror = (evt) => {
      // console.error(evt);
    };
    // }
  }

  showChatHtml = () => {
    const url = window.location.href;
    const lastSegment = url.split("/").pop();
    const searchString = this.state.pairSerachRecord;
    const html = [];
    var classThis = this;
    let newArray = [];
    let filterRecord = this.state.steamPairs;

    if (searchString === "") {
      newArray = filterRecord;
    } else {
      newArray = this.state.steamPairs.filter(function (el) {
        return el.s.includes(searchString.toUpperCase());
      });
    }
    
    newArray.map((value, i) => {
      html.push(
        <>
          <tr key={i}>
            <td className="align-middle" value={value.s}>
              {/* <a href={"/exchange/" + classThis.props.match.params.id + "/" + value.symbol} style={{ color: lastSegment==value.symbol ? '#dbb747' : '#f2f4f5' }}>
                                  <i className="las la-star stsr5 mr-1"></i>{value.symbol.slice(0, -4)}/{value.symbol.slice(-4)}</a> */}

              <Link
                to={
                  "/exchange/" + classThis.props.match.params.id + "/" + value.s
                }
                style={{
                  color: lastSegment == value.s ? "#dbb747" : "#f2f4f5",
                }}
              >
                <i className="las la-star stsr5 mr-1"></i>
                {value.s.slice(0, -4)}/{value.s.slice(-4)}
              </Link>
            </td>
            <td>
              <span className="pr-2">
                {" "}
                <strong className="ng-binding">
                  <span
                    className={value.p < 0 ? "text-danger" : "text-success"}
                  >
                    {new BigNumber(value.c).toFormat(null, 1)}
                  </span>
                </strong>
              </span>
            </td>
            <td className="tr">
              <b className="crypt-down">
                <span className={value.p < 0 ? "text-danger" : "text-success"}>
                  {/* {value.p + "%"} */}
                  {`${new BigNumber(value.P).toFormat(2, 1)}%`}
                  {/* {`${new BigNumber(value.p).toFormat(
                    null,
                    1
                  )} (${new BigNumber(value.P).toFormat(2, 1)}%)`} */}
                </span>
              </b>{" "}
            </td>
          </tr>
          {/* <tr>
                          <td className="align-middle" value={value.symbol} >
                          <a href={"/exchange/"+classThis.state.id+"/"+value.symbol} style={{ color: lastSegment==value.symbol ? '#dbb747' : '#f2f4f5' }}><i className="las la-star stsr5"></i>{value.symbol}</a></td>
                          <td><span className="pr-2">{value.lastPrice}</span></td>
                          <td className="tr"><b className="crypt-down">{value.priceChangePercent}</b> </td>
                      </tr> */}
        </>
      );
    });
    return html;
  };

  //   showChatHtml = () => {
  //     const url = window.location.href;
  //     var lastSegment = url.split("/").pop();
  //     var searcString = this.state.pairSerachRecord;
  //     const html = [];
  //     var classThis = this;
  //     // var filterRecord = this.state.pairRecord;
  //     let filterRecord = this.state.steamPairs;
  
  //     if (searcString == "") {
  //       var newArray = filterRecord;
  //     } else {
  //       var newArray = filterRecord.filter(function (el) {
  //         return el.symbol.search(searcString.toUpperCase()) != -1;
  //       });
  //     }
  
  //     newArray.map(function (value, i) {
  //       html.push(
  //         <>
  //           <tr key={i}>
  //             <td className="align-middle" value={value.symbol}>
  //               {/* <a href={"/exchange/" + classThis.props.match.params.id + "/" + value.symbol} style={{ color: lastSegment==value.symbol ? '#dbb747' : '#f2f4f5' }}>
  //                                 <i className="las la-star stsr5 mr-1"></i>{value.symbol.slice(0, -4)}/{value.symbol.slice(-4)}</a> */}

  //               <Link
  //                 to={
  //                   "/exchange/" +
  //                   classThis.props.match.params.id +
  //                   "/" +
  //                   value.symbol
  //                 }
  //                 style={{
  //                   color: lastSegment == value.symbol ? "#dbb747" : "#f2f4f5",
  //                 }}
  //               >
  //                 <i className="las la-star stsr5 mr-1"></i>
  //                 {value.symbol.slice(0, -4)}/{value.symbol.slice(-4)}
  //               </Link>
  //             </td>
  //             <td>
  //               <span className="pr-2">
  //                 {" "}
  //                 <strong className="ng-binding">
  //                   <span
  //                     className={
  //                       value.priceChangePercent < 0
  //                         ? "text-danger"
  //                         : "text-success"
  //                     }
  //                   >
  //                     {value.lastPrice}
  //                   </span>
  //                 </strong>
  //               </span>
  //             </td>
  //             <td className="tr">
  //               <b className="crypt-down">
  //                 <span
  //                   className={
  //                     value.priceChangePercent < 0
  //                       ? "text-danger"
  //                       : "text-success"
  //                   }
  //                 >
  //                   {value.priceChangePercent + "%"}
  //                 </span>
  //               </b>{" "}
  //             </td>
  //           </tr>
  //           {/* <tr>
  //                         <td className="align-middle" value={value.symbol} >
  //                         <a href={"/exchange/"+classThis.state.id+"/"+value.symbol} style={{ color: lastSegment==value.symbol ? '#dbb747' : '#f2f4f5' }}><i className="las la-star stsr5"></i>{value.symbol}</a></td>
  //                         <td><span className="pr-2">{value.lastPrice}</span></td>
  //                         <td className="tr"><b className="crypt-down">{value.priceChangePercent}</b> </td>
  //                     </tr> */}
  //         </>
  //       );
  //     });
  //     return html;
  //   };
  searchPair = (e) => {
    this.state.pairSerachRecord = e.target.value;
    this.showChatHtml();
  };

  render() {
    return (
      <div className="crypt-market-status mt-3">
        <div>
          <div className="tab-content crypt-tab-content">
            <div role="tabpanel" className="tab-pane active show" id="btc">
              <div className="searchFilter f-cb">
                <div className="search-box search-box-new">
                  <input
                    type="text"
                    id="search-int"
                    placeholder=""
                    className="ng-pristine ng-untouched ng-valid ng-empty"
                    onChange={this.searchPair}
                  />
                  <i className="las la-search"></i>
                </div>
                <div className="cls"></div>
              </div>
              <table className="table border_none mb-0">
                <thead>
                  <tr>
                    <th className="col-4">Pair</th>
                    <th className="col-4">Price</th>
                    <th className="col-4 tr">Change</th>
                  </tr>
                </thead>
              </table>
              <div className="table-overflow mb-2">
                <table className="table border_none  mb-0">
                  <tbody className="crypt-table-hover">
                    {this.showChatHtml()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PairSection.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect((state) => state)(withRouter(PairSection));
