import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { order } from "../../../actions/orderAction";
import { withRouter } from "react-router-dom";
import $ from 'jquery';
import {toast,Toaster } from 'react-hot-toast';
import socketIOClient from "socket.io-client";
import * as myConstList from '../../../BaseUrl';
const jwtToken = localStorage.getItem("jwtToken");
const baseUrl = myConstList.baseUrl;
const socket = socketIOClient(myConstList.baseUrlSocket);
const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
}

class BuyOrder extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            user_id:this.props.user_id,
            bprice: this.props.currentprice,
            bamount: "",
            stopLoss: "",
            message: "",
            usdt:this.props.usdt,
            btotal:"",
			btnDisable:false,
            errors: {},
        };

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            bprice: nextProps.currentprice,  
            usdt: nextProps.usdt,
        });
        if (nextProps.usdt !=undefined) {
            this.setState({ usdt: nextProps.usdt
            });
        }
        if (nextProps.user_id) {
            this.setState({ user_id: nextProps.user_id,
                bprice: nextProps.currentprice,  
            });
        }
        if (nextProps.errors) {
         this.setState({ errors: nextProps.errors });
        }
		
        if (nextProps.orderdata !== undefined
            && nextProps.orderdata.order !== undefined
            && nextProps.orderdata.order.data !== undefined
            && nextProps.orderdata.order.data.message !== undefined) {                
                const socketEventorder = "order"+this.props.auth.user.id+this.props.match.params.id;
                socket.emit('exchangeOrderData',{'socketName':socketEventorder,'contestId':this.props.match.params.id,'userId':this.props.auth.user.id,'symbol':this.props.match.params.symbol});  
            this.setState({             
                btotal:"",
                bamount:"",
                stopLoss:"",
				btnDisable:false
            });
        }
    }

  
    onChange = e => {
         this.setState({ message:"", errors:{}});
        if (e.target.id === 'user-update-id') {
            this.setState({ user_id: e.target.value });
        }
        if (e.target.id === 'bprice') {
			// var btotal =(this.state.bamount*e.target.value).toFixed(2);
			var btotal =this.state.bamount*e.target.value;
            this.setState({ bprice: e.target.value ,btotal: btotal});
        }
        if (e.target.id === 'bamount') {
            // var btotal =(this.state.bprice*e.target.value).toFixed(2);
            var btotal =this.state.bprice*e.target.value;
            this.setState({ bamount: e.target.value,btotal: btotal});
        }
        if (e.target.id === 'stopLoss') {
            this.setState({ stopLoss: e.target.value });
        }
     };
    onOrderUpdate = e => {
        e.preventDefault();
		this.setState({btnDisable:true});
        const { user } = this.props.auth;
        const newOrder = {
            user_id: user.id,
            contest_id: this.props.match.params.id,
            price: this.state.bprice,
            amount: this.state.bamount,
            stopLoss: this.state.stopLoss,
            symbol: this.props.match.params.symbol,
            orderType: "Buy"
        };
        this.props.order(newOrder);       
    };

    render() {
        if(this.state.bprice!=undefined){
            var pricess=this.state.bprice;
        }
        const { errors } = this.state;
        const { user } = this.props.auth;
       return (
            <div className="col-md-6">
             {/* onSubmit={()=>this.onOrderUpdate()} */}
             <form noValidate onSubmit={this.onOrderUpdate} id="update-user">             
             <input onChange={this.onChange} value={this.state.user_id} id="user-update-id"
                        type="text" className="d-none" />
                <div className="crypt-buy-sell-form">
                    <div className="crypt-buy">
                        <div className="row mb-2">
                            <span className="col-3 pr-0">
                                Buy <span className="crypt-up">{this.props.match.params.symbol.slice(0, -4)}
                                </span>
                            </span>
                            <span className="fright col-9 tr">
                                <b className="crypt-up ">USDT Balance : { this.state.usdt.toFixed(3)} </b>
                            </span>
                        </div>
                        <span className="text-status">{this.state.message}</span>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend"> <span
                                className="input-group-text">Price</span> </div>
                            <input  onChange={this.onChange}  id="bprice"
                            type="number" className="form-control" value={pricess} placeholder="0.00" />
                            <span className="text-danger">{errors.bprice}</span>
                                
                            <div className="input-group-append"> <span
                                className="input-group-text">USDT</span> </div> 
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend"> <span
                                className="input-group-text">Quantity</span> </div>
                            <input onChange={this.onChange} id="bamount"
                            type="number" className="form-control" value={this.state.bamount!=""?this.state.bamount:""} placeholder="0.00" />
                            <span className="text-danger">{errors.bamount}</span>
                            <div className="input-group-append"> <span
                                className="input-group-text">{this.props.match.params.symbol.slice(0, -4)}</span> </div>
                        </div>
						{/* <div className="input-group mb-3">
                            <div className="input-group-prepend"> <span
                                className="input-group-text">Stop Loss</span> </div>
                             <input   onChange={this.onChange}  id="stopLoss"
                            type="number" className="form-control" value={this.state.stopLoss} placeholder="0.00" />
                            <span className="text-danger">{errors.stopLoss}</span>
                        </div>*/}
                        <div className="input-group mb-3">
                            <div className="input-group-prepend"> <span
                                className="input-group-text">Total</span> </div>
                            <input onChange={this.onChange} id="btotal"
                            type="number" className="form-control" value={this.state.btotal} placeholder="0.00" />
                            <span className="text-danger">{errors.btotal}</span>
                            <div className="input-group-append"> <span
                                className="input-group-text">USDT</span> </div>
                        </div>
                        <div className="">
                            <button type="submit" disabled={this.state.btnDisable} className="crypt-button-green-full">Buy</button>
                        </div>
                    </div>
                </div>
                </form>
                <Toaster />
            </div>        
        )
    }
}

BuyOrder.propTypes = {
    order: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    orderdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    orderdata: state.orderdata,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { order }
)(withRouter(BuyOrder));
