import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Moment from "moment";
import * as myConstList from "../../../BaseUrl";
const baseUrl = myConstList.baseUrl;

class Participant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participant: [],
      contestType: "",
      contestTypePast: "",
    };

    // this.getData = this.getData.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.participant) {
      this.setState({
        participant: nextProps.participant,
        contestType: nextProps.contestType,
        contestTypePast: nextProps.contestTypePast,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined &&
      nextProps.auth.user.data.success
    ) {
      this.setState({
        message: nextProps.auth.user.data.message,
      });
    }
    // this.getData()
  }

  showChatHtml = () => {
    let collectThis = this;
    console.log("this.state.participant", this.state.participant);
    var participantcount = this.state.participant.length;
    console.log("participantcount", participantcount);
    const html = [];

    if (
      this.state.participant != undefined &&
      this.state.participant.length > 0
    ) {
      this.state.participant.map(function (value, i) {
        console.log(value, "valuesss");
        html.push(
          <>
            <tr>
              <td className="align-middle col-3">{i + 1}</td>
              <td className="col-3">{value.name}</td>
              <td className="col-3">
                {collectThis.state.contestType == "live" ||
                collectThis.state.contestTypePast
                  ? value.portfoliovalue
                  : 0}
              </td>
              <td className="col-3">
                {collectThis.state.contestType == "live" ||
                collectThis.state.contestTypePast
                  ? Number(value.per).toFixed(3) + "%"
                  : ""}
              </td>
              <td className="col-3">
                {(typeof value.order_count === "undefined" &&
                  collectThis.state.contestType == "live") ||
                collectThis.state.contestTypePast
                  ? value.bid_count
                  : value.order_count}
              </td>
              {/* <td className="tr"><b className="crypt-down">-5.4%</b> </td> */}
            </tr>
          </>
        );
      });
    }

    return html;
  };
  // componentDidMount() {
  //     this.getData()
  // };

  // getData() {
  //     axios
  //         .post(baseUrl+"api/user-data")
  //         .then(res => {
  //             this.setState({ records: res.data.data})
  //         })
  //         .catch()
  // }

  render() {
    return (
      <div className="table-responsive">
        <div className="table-responsive winners_table">
          <table className="table  mb-0">
            <tr>
              <th className="th">S.No.</th>
              <th className="th">Username</th>
              <th className="th">Portfolio Value</th>
              <th className="th">Gain/Loss %</th>
              <th className="th">Bids</th>
            </tr>
            <tbody>{this.showChatHtml()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

Participant.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});
export default connect(mapStateToProps)(Participant);
