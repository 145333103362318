import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import Moment from 'moment';
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;

class LeaderboardGloble extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leaderboardGloble: []
        };


        this.getData = this.getData.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.leaderboardGloble) {
            this.setState({
                leaderboardGloble: nextProps.leaderboardGloble
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {

            this.setState({
                message: nextProps.auth.user.data.message
            });
        }
        this.getData()
    }

    showChatHtml = () => {
       
    
        const html = []
        this.state.leaderboardGloble.map(function (value, i) {   
         
            html.push(
                <>
                    <tr>
                        <td className="align-middle"><i className="las la-star mr-1 stsr5"></i>
                        {i+1}</td>
                        <td><span className="pr-2">{value.name}</span></td>
                        <td><span className="pr-2">{value.portfoliovalue}</span></td>
                        <td><span className="pr-2">{(value.percent).toFixed(2)+"%"}</span></td>
                        {/* <td className="tr"><b className="crypt-down">-5.4%</b> </td> */}
                    </tr>
                </>
            );
        })
        return html;
    }
    componentDidMount() {
        this.getData()
    };

  

    getData() {
        axios
            .post(baseUrl+"api/user-data")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }
  

    render() {
        return (
            <table className="table table-striped border_none mb-0">
            <tbody>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Trades</th>
                    <th>Profit Generated %</th>
                </tr>
                {this.showChatHtml()}
          </tbody>
        </table>
        );
    }

}

LeaderboardGloble.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});
export default connect(
    mapStateToProps
)(LeaderboardGloble);
