import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { contactUsUser } from "../../actions/updateHelp";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import ForgetPassword from "../../components/auth/ForgetPassword";
import { withRouter } from "react-router-dom";
import Footer from "../../components/pages/Footer";
import {toast,Toaster } from 'react-hot-toast';
import MetaTags from 'react-meta-tags';
const toastStyles = {
  style: {
    borderRadius: '10px',
    background: '#fff',
    color: '#000',
  },
}
class contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
        balance:'',
        name: "",
        email: "",
        message: "",
        subject: "",
        errors: {},
        errorsName:"",
        errorsEmail:"",
        errorsSuject:"",
        errorsMessage:"",
        error:'0'
    };
  }
  componentDidMount() {          
    if (this.props.auth.isAuthenticated) {
        this.setState({userName:this.props.auth.user.name});
    } 
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.helpdata.contact.data.status) {
      toast.success(nextProps.helpdata.contact.data.message,
        toastStyles
    );
        this.setState({
          balance:'',
          name: "",
          email: "",
          message: "",
          subject: "",
        });
    }
    
  }

  onChange = e => {
    console.log("ids==",e.target.id);
    this.setState({ [e.target.id]: e.target.value });
    this.setState({
        errorsName: "",
        errorsEmail: "",
        errorsSubject: "",
        errorsMessage: "",
        error:'0'
    });
        if(e.target.id==="name"){
            const strongRegexName = new RegExp(/^[a-zA-Z ]*$/);
            var testName = strongRegexName.test(e.target.value);
    
            if (!testName){
                this.setState({ errorsName: "Only use alphabetic characters",error:"1"});  
                return false;
            }		
        }
        if(e.target.id==="subject"){
          var testName = e.target.value;
  
          if (!testName){
              this.setState({ errorsSubject: "Please enter subject",error:"1"});  
              return false;
          }		
        }

        if(e.target.id==="email"){
          var testName = e.target.value;
  
          if (!testName){
              this.setState({ errorsEmail: "Please enter email",error:"1"});  
              return false;
          }		
        }
        if(e.target.id==="message"){
          var testName = e.target.value;
  
          if (!testName){
              this.setState({ errorsMessage: "Please enter message",error:"1"});  
              return false;
          }		
        }
      
    }
  onSubmit = e => {
    e.preventDefault();	 
    const strongRegexName = new RegExp(/^[a-zA-Z ]*$/);
    var testName = strongRegexName.test(this.state.name);

    if (!testName){
        this.setState({ errorsName: "Only use alphabetic characters",error:"1"});  
        return false;
    }
    if (this.state.name===''){
      this.setState({ errorsName: "Please enter name",error:"1"});  
      return false;
    }
    if (this.state.email ===''){
      this.setState({ errorsEmail: "Please enter email",error:"1"});  
      return false;
    }	
    if (this.state.subject ===''){
      this.setState({ errorsSubject: "Please enter subject",error:"1"});  
      return false;
    }
    if (this.state.message ===''){
      this.setState({ errorsMessage: "Please enter message",error:"1"});  
      return false;
    }		 
    if(this.state.error === "0") {
        const newUser = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            subject: this.state.subject,
        };
        let res = this.props.contactUsUser(newUser);
    }
  }
render() {
const { errors } = this.state;
return (
<>
         <MetaTags>
                <title>Contact Us | Trade The Games</title>
                <meta name="description" content="Do you have any query regarding game and payment related, feel free to contact us via fill the form." />
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://www.tradethegames.com/contact-us" />
	<meta name="facebook-domain-verification" content="ih4k1e3ay891tcx2z9uelbtn60zdsr"/>
    <meta property="og:title" content="Contact Us | Trade The Games"/>
	<meta property="og:site_name" content="Trade the Games"/>
    <meta property="og:url" content="https://www.tradethegames.com/"/>
	<meta property="og:description" content="Do you have any query regarding game and payment related, feel free to contact us via fill the form."/>
	<meta property="og:type" content="article"/>
	<meta property="og:image" content="https://www.tradethegames.com/img/ttg-logo.jpg"/>
    <meta name="twitter:card" content="summary_large_image"/>
	<meta name="twitter:site" content="@tradethegames"/>
	<meta name="twitter:title" content="Contact Us | Trade The Games"/>
	<meta name="twitter:description" content="Do you have any query regarding game and payment related, feel free to contact us via fill the form."/>
    <meta name="twitter:image" content=" https://www.tradethegames.com/img/ttg-logo.jpg"/>
            </MetaTags> 
<div className="">
  <div >
    <nav className="navbar navbar-expand-lg home_nav">
        <div className="container ">
        {/* <!-- logo --> */}
        <a className="navbar-brand" href="/">
            <img src="./img/logo.png" alt="header-Logo" className="logo"/></a>
			
			<button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarText"><span className="icon-bar"><i className="las la-times"></i></span></button>
        <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav  line ml-auto">
			 <li className="nav-item">
                    <a className="nav-link active" href="/about-us" >About Us</a> 
                </li>
                
                <li className="nav-item">
                    <a className="nav-link" href="/media">Media</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/faq">FAQ</a>
                </li> 	
                 <li className="nav-item">
                    <a className="nav-link" href="/blog" target="_blank">Blog</a>
                </li>				
				<li className="nav-item">
                    <a className="nav-link" href="/contact-us">Contact Us</a>
                </li>
				
            </ul>
        </div>
			
            <div className="right_menu ">
			  <li class="nav-item">
                        <a href="/login" class="btn" id="main_btn">Login/Sign Up</a>
                     </li>
                <li className="nav-item">
                {this.state.userName !=='' &&
                <a href = "/profile-user" className="man_color fw-500" >{this.state.userName}
                </a>
                
                }
                {/* {this.state.userName ==='' &&
                <a className="btn" ref={this.button}  data-toggle="modal" data-target="#loginmodal" id="main_btn">Login/Sign Up</a>
                } */}

            
            </li>
        </div>
        </div>
    </nav>
    <div className="modal fade" id="loginmodal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body login_modal">
            <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#SignIn" id="signintab">Sign In</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#SignUp" id="signuptab">Sign Up</a>
              </li>
            </ul>
            <div className="tab-content pt-4">
              <Login />
              <Register />
            </div>
          </div>
        </div>
      </div>
    </div>
	  <div className="inner_banner text-center contate_uspage" >
					    <img src="./img/about_banner.jpg" alt="about_banner"/>
						 <div>
                        <h1>Contact Us</h1>
                       </div>
                    </div>
    <div className="p60 how" >
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-12">
            <div className="contact-wrap w-100 p-md-5 p-4">
              <h3 className="mb-4">Get in touch</h3>
              <div id="form-message-warning" className="mb-4"></div>
              <form method="post" onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <input type="text" className="form-control" name="name" id="name" placeholder="Name" value={this.state.name} onChange={this.onChange} required/>
                      <span className="text-danger f12">{this.state.errorsName}</span>
                    </div>
                   </div>
                   <div className="col-md-6">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Email" value={this.state.email} onChange={this.onChange} required/>
                      <span className="text-danger f12">{this.state.errorsEmail}</span>
                    </div>
                    </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" value={this.state.subject} onChange={this.onChange} required/>
                      <span className="text-danger f12">{this.state.errorsSubject}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea name="message" className="form-control" id="message" cols="30" rows="7" placeholder="Message" value={this.state.message} onChange={this.onChange} required></textarea>
                      <span className="text-danger f12">{this.state.errorsMessage}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input type="submit" className="btn btn_man "/>
                      <div className="submitting"></div>
                    </div>
                  </div>
                  </div>
              </form>
            </div>
          </div>
          <div className="col-md-5 d-flex align-items-stretch">
            <div className="info-wrap bg_contact w-100 p-lg-5 p-4">
              <h3 className="mb-4 mt-md-4">Contact us</h3>
                          <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-envelope"></span></div><div className="text pl-3"><p><b>Email:</b> <br/><a href="mailto:support@tradethegames.com">support@tradethegames.com</a></p></div></div></div></div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
<ForgetPassword />
<Toaster />
</>
);
}
}
contactus.propTypes = {
  contactUsUser:PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  helpdata: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  helpdata: state.helpdata,
});
export default connect(
  mapStateToProps,
  { contactUsUser }
)(withRouter(contactus));