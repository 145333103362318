import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";

class Funds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            record: []
        };
 
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                record: nextProps.record
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {

            this.setState({
                message: nextProps.auth.user.data.message
            });
        }
    }

    showHtml = () => {
        

        const html = []
        
            this.state.record.map(function (value, i) {
					if(value.status==="success") {
						html.push(
							<tr key={i}>
							<td>{value.symbol.slice(0, -4)}</td>
							<td>{value.price}</td>
							<td>{value.amount}</td>
							<td>{value.transType}</td>
						</tr>
						);
					}
                })
              
         
       
        return html;}



    render() {
     
        return (
            <table className="table border_none">
                <tr>
                    <th scope="col">Currency</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Type</th>
                </tr>
            <tbody>
            {this.showHtml()}
            </tbody>
        </table>
        );
    }

}

Funds.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Funds);
