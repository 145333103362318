import React, { Component } from "react";
import { Link, withRouter  } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import { registerEmailUser } from "../../actions/authActions";
import axios from 'axios';
import { isMobile, isDesktop, isAndroid, isIOS, browserName, browserVersion, osName, osVersion } from "react-device-detect";
import {toast,Toaster } from 'react-hot-toast';
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
class Register extends Component {

    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            password: "",
            password2: "",
            message: "",
            referralBy:"",  
            passwordnew:"",
            statusB:true,
            type:"password",
            typePassConfm:"password",
            errors: {},
			term_condition:false,
            otp:"",
            encrypted_otp:"",
            encrypted_mobile:"",
            encrypted_countryCode:"",
            showOtpBox:false,
            submitBtnText:"Get OTP",
            submitBtnDisable:false,
            mobiileNoDisable:false,
            resendOtp:false,
            readonly:false,
            showNamebox:false,
            showReferralBox:false,
            termConditionBox:false
        };        
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
                submitBtnDisable:false
            });            
        }       
        if (nextProps.auth.reg != undefined
            && nextProps.auth.reg.data != undefined
            && nextProps.auth.reg.data.message != undefined
            && nextProps.auth.reg.data.status) {
                // toast( nextProps.auth.reg.data.message, {
                //     position: toast.POSITION.TOP_CENTER
                //   });
                if(nextProps.auth.reg.data.data!=undefined && nextProps.auth.reg.data.data.encrypted_otp != undefined){
                    this.setState({
                        encrypted_otp:nextProps.auth.reg.data.data.encrypted_otp,
                        encrypted_mobile:nextProps.auth.reg.data.data.encrypted_mobile,
                        encrypted_countryCode:nextProps.auth.reg.data.data.encrypted_countryCode,
                        showOtpBox:true,
                        readonly:true,
                        submitBtnText:"Verify & Proceed",
                        submitBtnDisable:false,
                        mobiileNoDisable:true,
                        registerStatus:nextProps.auth.reg.data.data.registerStatus,
                        showNamebox: (nextProps.auth.reg.data.data.registerStatus) ? false : true,
                        showReferralBox: (nextProps.auth.reg.data.data.registerStatus) ? false : true,
                        termConditionBox : (nextProps.auth.reg.data.data.registerStatus) ? false : true
                    })
                }
                else {
                    this.setState({
                        message: nextProps.auth.reg.data.message,
                         name:"",
                         mobile:"",
                         referralBy:"",
                         encrypted_otp:"",
                         encrypted_mobile:"",
                         encrypted_countryCode:"",
                         showOtpBox:false,
                         readonly:false,
                         submitBtnText:"Get OTP",
                         submitBtnDisable:false
                    });
                }
            // setTimeout(function() {
            //     // add class
            //     const element = document.getElementById("SignIn");
            //     element.classList.add("active");
            //     const element1 = document.getElementById("signintab");
            //     element1.classList.add("show");
            //     element1.classList.add("active");
            //     // remove class
            //     const element2 = document.getElementById("SignUp");
            //     element2.classList.remove("active");
            //     const element3 = document.getElementById("signuptab");
            //     element3.classList.remove("active");
            //     element3.classList.remove("show");

            // }, 500);          
        }else{
            
            if(nextProps.auth.reg.data != undefined){
                this.setState({errors:{name:nextProps.auth.reg.data.name,mobile:nextProps.auth.reg.data.mobile,otp:nextProps.auth.reg.data.message},submitBtnDisable:false});
            }
            
            
        }        
    }
    componentDidMount() {
        if (window.location.pathname != '/privacy-policy' && window.location.pathname != '/term-and-condition' && window.location.pathname != '/faq' && window.location.pathname != '/about-us' && window.location.pathname != '/contact-us' && this.props.auth.isAuthenticated) {
            this.props.history.push("/");
        }
		if(this.props.match.params.refer!==undefined){			
			this.setState({referralBy:this.props.match.params.refer})
		}
        this.getUserIp();
		this.DeviceName();
        this.getGeoInfo();
    }

    getGeoInfo(){
        fetch("https://api.ipdata.co/?api-key=f9ba88a917827fb3c3103a318590155f3bc976cabefdb00c65e93ceb")
        .then(response => response.json())
        .then(response => {
            console.log(response);
        this.setState({
            calling_code: response.calling_code,
            country_flag: response.flag,
        });
        this.setState({ countryCode: response.calling_code })
        }).catch((error) => {})
    }
    DeviceName = () => {
		if (isMobile) { 
			this.setState({ device: "Mobile" });
		}
		else if (isAndroid) {
			this.setState({ device: "Android" });
		}
		else if (isIOS) {
			this.setState({ device: "IOS" });
		}
		else if (isDesktop) {
			this.setState({ device: "Desktop" })
		}
	}

    getUserIp = ()=> {        
        axios.get('https://geolocation-db.com/json/').then((response) => {
			let data = response.data;
			this.setState({ip:data.IPv4 ? data.IPv4 : ""
						});
		}).catch((error) => {
			console.log(error);
		});
	}
   
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({
            errors: {
                name: "",
                mobile: "",
                otp:""
            }
        });
		if (e.target.id === 'user-update-countryCode') {
            this.setState({ countryCode: e.target.value });
        }
        if (e.target.id === 'user-update-mobile' && e.target.value.length<11) {
            if (/^0/.test(e.target.value)) {
                e.target.value = e.target.value.replace(/^0/, "")
            }
            this.setState({ mobile: e.target.value });
        }
        if (e.target.id === 'user-update-name' && e.target.value.length<11) {
            this.setState({ name: e.target.value });
        }
        if (e.target.id === 'user-update-otp' && e.target.value.length<7) {
            this.setState({ otp: e.target.value });
        }
        if (e.target.id === 'user-update-email') {
            this.setState({ email: e.target.value });
        }
    }

    getGeoInfo(){
        fetch("https://api.ipdata.co/?api-key=f9ba88a917827fb3c3103a318590155f3bc976cabefdb00c65e93ceb")
        .then(response => response.json())
        .then(response => {
            console.log(response);
            this.setState({
                calling_code: response.calling_code,
                country_flag: response.flag,
            });
            this.setState({ countryCode: response.calling_code })
        }).catch((error) => {})
    }

	handleChange=()=>{
		this.setState({
		  term_condition: !this.state.term_condition
		});
		if(!this.state.term_condition){
			this.setState({
				errors: {
					term_condition: ""
				}
			});
		}
		else {
			this.setState({ errors: {term_condition: "Please accept Terms and conditions"}}); 
		}
	  }

      resendOTP=()=>{
        if(this.state.countryCode !=91 || this.state.countryCode !=+91){
            if (this.state.email === undefined || this.state.email === ""){    
                this.setState({ errors: {email: "Please enter email"}});  
                return false;
            }else{
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if(emailRegex.test(this.state.email)){
                    
                }else{
                    this.setState({ errors: {email: "Please enter valid email"}});
                    return false;
                }  
            }
        }else{
            if (this.state.mobile === undefined){    
                this.setState({ errors: {mobile: "Please enter mobile number"}});  
                return false;
            }else{
                if (this.state.mobile !==undefined && this.state.mobile.length>10){    
                    this.setState({ errors: {mobile: "Please enter valid mobile no"}});  
                    return false;
                }
            }
        }
		

        const newUser = {
            countryCode: this.state.countryCode,
            mobile: this.state.mobile,
            email: this.state.email,
            referralBy: "",
            otp:"",
            name:"",
            encrypted_otp:"",
            encrypted_mobile:this.state.encrypted_mobile,
            encrypted_countryCode:this.state.encrypted_countryCode,
            deviceName:this.state.device,
			browserName:browserName,
			osName:osName,
            ip : this.state.ip,
        };
        let res = "";
        if(this.state.email !=""){
            console.log("newUser====",newUser);
            res = this.props.registerEmailUser(newUser, this.props.history);
        }else{
            res = this.props.registerUser(newUser, this.props.history);
        }
	  }
      editMobileNo=()=>{
        this.setState({showNamebox:false,showReferralBox:false,termConditionBox:false,mobile:"",showOtpBox:false,readonly:false,submitBtnDisable:false,submitBtnText:"Get OTP",registerStatus:true,otp:"",encrypted_countryCode:'',encrypted_mobile:'',encrypted_otp:'',name:'',referralBy:''});
        return this.setState({resendOtp:false});
      }
  
    onSubmit = e => {
        e.preventDefault();
		const strongRegexNameCk = new RegExp(/^[a-zA-Z0-9]*$/);
        const onlynumeric = new RegExp(/^[0-9\b]+$/);
        
		var testNameCk = strongRegexNameCk.test(this.state.name);
        var testOnlyNumric = onlynumeric.test(this.state.name);
        console.log("this.state.countryCode==",this.state.countryCode)
        if(this.state.countryCode !=91 || this.state.countryCode !=+91){
            if(this.state.email){
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if(emailRegex.test(this.state.email)){
                    
                }else{
                    this.setState({ errors: {email: "Please enter valid email"}});
                    return false;
                }           
            }else{
                this.setState({ errors: {email: "Please enter email"}});
                    return false;
            }
        }else{
            console.log("mobileee=",this.state.mobile)
            if (this.state.mobile === undefined){    
                this.setState({submitBtnDisable:false})
                this.setState({ errors: {mobile: "Please enter mobile number"}});  
                return this.setState({resendOtp:false});
            }else{
                if (this.state.mobile !==undefined && this.state.mobile.length>10 && this.state.mobile.length<10){    
                    this.setState({ errors: {mobile: "Please enter valid mobile no"}});  
                    return this.setState({resendOtp:false});
                }
                else if(this.state.mobile !==undefined &&  this.state.mobile.length<10){
                    this.setState({ errors: {mobile: "Please enter valid mobile no"}});  
                    return this.setState({resendOtp:false});
                }
            }
        }		
        this.setState({submitBtnDisable:true,resendOtp:true})
		// const strongRegexNameCk = new RegExp(/^[a-zA-Z0-9]*$/);
        // const onlynumeric = new RegExp(/^[0-9\b]+$/);
		// var testNameCk = strongRegexNameCk.test(this.state.name);
        // var testOnlyNumric = onlynumeric.test(this.state.name);
		// if (!testNameCk || testOnlyNumric){            
		// 	this.setState({ errors: {name: "Only use Alphanumeric Characters without Space"}});  
		// 	return false;
		// }
		// if(this.state.name.length>10){
        //     this.setState({ errors: {name: "Only use 10 Characters"}});
        //     return false;
        // }
		// if (!this.state.term_condition){
		// 	this.setState({ errors: {term_condition: "Please accept Term and conditions"}});  
		// 	return false;
		// }
		
		
		// const strongRegexSubmit = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		// var testSubmit = strongRegexSubmit.test(this.state.password);
 
		// if (!testSubmit) {         
		// 	this.setState({ errors: {password: "Min 8 characters(Including 1 Upper case, 1 Numeric value, 1 Special symbol)"}}); 
		// 	return false;
		// }
        
		
        // if(this.state.password==this.state.password){
        //      const newUser = {
        //     name: this.state.name,
        //     email: this.state.email,
        //     password: this.state.password,
      
        //     password2: this.state.password2,
        //     referralBy: this.state.referralBy,
        // };
        // let res = this.props.registerUser(newUser, this.props.history);
        // }else{
        //   this.setState({ errors: {password2: "Passwords must match"}}); 
        // }

        // this.setState({submitBtnDisable:true,resendOtp:true})

        if (!this.state.name && this.state.registerStatus ===false){
			this.setState({ errors: {name: "Please enter username"},submitBtnDisable:false});  
			return false;
		}
        if (!testNameCk || testOnlyNumric && this.state.registerStatus ===false){            
			this.setState({ errors: {name: "Only use alphanumeric characters without space"},submitBtnDisable:false});  
			return false;
		}
		if(this.state.name.length>10 || this.state.name.length<4 &&this.state.registerStatus ===false){
            this.setState({ errors: {name: "Only use min 4 & max 10 Characters"},submitBtnDisable:false});
            return false;
        }
        if (!this.state.term_condition && this.state.registerStatus ===false){
			this.setState({ errors: {term_condition: "Please accept Terms and conditions"},submitBtnDisable:false});  
			return false;
		}
        const newUser = {
            countryCode: this.state.countryCode,
            mobile: this.state.mobile,
            email: this.state.email,
            referralBy: this.state.referralBy,
            otp:this.state.otp,
            name:this.state.name,
            encrypted_otp:this.state.encrypted_otp,
            encrypted_mobile:this.state.encrypted_mobile,
            encrypted_countryCode:this.state.encrypted_countryCode,
            deviceName:this.state.device,
			browserName:browserName,
			osName:osName,
            ip : this.state.ip,
        };
        
        let res = "";
        // console.log("newUserwwwwwwwww====",newUser);
        if(this.state.email !=""){            
            res = this.props.registerEmailUser(newUser, this.props.history);
        }else{
            res = this.props.registerUser(newUser, this.props.history);
        }        
    }
    eyeFucntion= e =>{
        if(this.state.type=="password"){
         this.setState({type:"text"});
        }else{
         this.setState({type:"password"});
        }
    }
    eyeConfmPassFucntion= e =>{
        if(this.state.typePassConfm=="password"){
         this.setState({typePassConfm:"text"});
        }else{
         this.setState({typePassConfm:"password"});
        }
    }
    
   
    render() {
        // alert(this.state.message);
        const { errors } = this.state;
        return (
            <div className="tab-pane active" id="SignUp">                
                {/* <span className="text-danger text-center f12">{errors.message}</span> */}
                {/* <span className="text-success">{this.state.message}</span> */}
                <form noValidate onSubmit={this.onSubmit} id="update-user">
                    {/* <label className="fw-500">Phone</label> */}
                            <div className="form-group row">        
                                <div className="col-4">
                            {/* <label className="fw-500">Country Code</label> */}
                                    <div className="country_flag_man_box">                                    
                                        {/* <span className="country_flag"><img className="w100" src={this.state.country_flag}></img></span>  */}
                                                                          
                                        {/* <input onChange={this.onChange}
                                            value={this.state.calling_code}
                                            placeholder={this.state.calling_code}
                                            id="user-update-countryCode"
                                            type="text" className="form-control country_code pr-0"/> */}
                                            <PhoneInput
                                className="country-code position-absolute"
                                countryCodeEditable={false}
                                country={"in"}
                                enableSearch={true}
                                disableCountryCode={true}
                                // disableDropdown={this.state.codeMatchedStatus || this.state.counter == 0 ? false : true}
                                value={this.state.countryCode}
                                // disabled={this.state.codeMatchedStatus}
                                disabled={this.state.readonly}
                                onChange={(countryCode) => this.setState({
                                  countryCode: "+" + countryCode,
                                  mobileError: "", emailError: "", verificationError: "",
                                  mobile:"",
                                  email:"",
                                  errors:{}
                                })}
                              /> 
                                    </div>
                                    <span className="text-danger">{errors.countryCode}</span>
                                </div>
                                <div className="col-8 pl-0">
                                  <div className="mo_number_box">
                                  {(this.state.countryCode =='91' || this.state.countryCode =='+91') && <input onChange={this.onChange} placeholder="Mobile number"
                                        id="user-update-mobile"
                                        type="number" className="form-control"
                                        value={this.state.mobile}
                                        readOnly={this.state.readonly}
                                        pattern="[0-9]*"
                                        onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}}/>}
                                    {(this.state.countryCode !='+91' && this.state.countryCode !='91') && <input onChange={this.onChange} placeholder="Email"
                                        id="user-update-email"
                                        type="text" className="form-control"
                                        value={this.state.email}
                                        readOnly={this.state.readonly}
                                    />}
                                    
									<i class="las la-times" style={{ display: (this.state.showNamebox ? 'block' : 'none') }} onClick={this.editMobileNo}></i>
									</div>
                                    <span className="text-danger">{errors.mobile}</span>
                                    <span className="text-danger">{errors.email}</span>
                                    <span className="text-danger">{errors.message}</span>
                                </div>
                            </div>

                            <div className="form-group" style={{ display: (this.state.showNamebox ? 'block' : 'none') }}>
                                <input onChange={this.onChange} value={this.state.name} name="name" className="form-control" placeholder="Username" id="user-update-name" type="text" error={errors.name}

                                />
                            <span className="text-danger">{errors.name}</span>
                            </div>

                            <div className="form-group" style={{ display: (this.state.showReferralBox ? 'block' : 'none') }}>
                                <input onChange={this.onChange} value={this.state.referralBy}  className="form-control" autoComplete="off" placeholder="Referral (Optional)" id="referralBy" type="text" error={errors.referralBy}/>
                            </div>

                            <div className="form-group"  style={{ display: (this.state.showOtpBox ? 'block' : 'none') }}>
                                <input onChange={this.onChange} value={this.state.otp}  className="form-control" autoComplete="off" placeholder="Enter OTP" id="user-update-otp" type="text" error={errors.otp}/>
                                {/* <span className="text-danger">{errors.otp}</span> */}
                            </div>
                            <div className="form-group" style={{ display: (this.state.termConditionBox ? 'block' : 'none'), lineHeight:'12px' }}>
                                <div className="form-check mb-1">
                                    <label className="form-check-label">
                                        <input onChange={this.handleChange} className="form-check-input" value={this.state.term_condition} autoComplete="off" defaultChecked={this.state.term_condition} id="term_condition" type="checkbox" error={errors.term_condition} 
                                        />
                                        I Agree all <Link className="color1" to="/term-and-condition" target="_blank">terms and conditions</Link>
                                    </label>
                                </div>
                                <span className="text-danger f12">{errors.term_condition}</span>
                            </div>
                            <div className="">							
                                <button className="btn btn_man w100"  disabled={this.state.submitBtnDisable} type="submit">{this.state.submitBtnText}</button>                               
                            </div>
                            <div className="mt-3 tc">							
                                <button className="btn re_otp w100 " style={{ display: (this.state.resendOtp)? 'block' : 'none'}} onClick={this.resendOTP} type="button">Resend OTP</button>
                            </div>          
                </form>          
            </div>
        );
    }
}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    registerEmailUser:PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { registerUser,registerEmailUser }
)(withRouter(Register));