import axios from "axios";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
export const kyc = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/userMobileKyc", userData,configToken)
        .then(res =>
            dispatch({
                type: 'KYC_UPDATE',
                payload: res,
            })
        )
};
export const verifyOtp = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/userVerifyOtp", userData,configToken)
        .then(res =>
            dispatch({
                type: 'KYC_VERIFY_OTP',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
export const interMediate = (userData,config) => dispatch => {
  
    axios
        .post(baseUrl+"api/interMediateUpdate", userData,config)
        .then(res =>
            dispatch({
                type: 'KYC_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
export const addressKyc = (userData,config) => dispatch => {
   
    axios
        .post(baseUrl+"api/addressKyc", userData,config)
        .then(res =>
            dispatch({
                type: 'KYC_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
export const taxKyc = (userData,config) => dispatch => {
  
    axios
        .post(baseUrl+"api/taxKyc", userData,config)
        .then(res =>
            dispatch({
                type: 'KYC_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
export const videoKyc = (userData,config) => dispatch => {
    axios
        .post(baseUrl+"api/videoKyc", userData,config)
        .then(res =>
            dispatch({
                type: 'KYC_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
