import React, { Component, Fragment } from "react";
import {Link} from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddContest from "../partials/AddContest";
import LiveContest from "../partials/contest/LiveContest";
import UpcomingContest from "../partials/contest/UpcomingContest";
import PastContest from "../partials/contest/PastContest";
import MyContest from "../partials/contest/MyContest";
import LeaderboardGloble from "../partials/exchange/LeaderboardGloble";
import ContestUpdateModal from "../partials/ContestUpdateModal";
import socketIOClient from "socket.io-client";
import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
import ReactGA from 'react-ga';
import moment from 'moment';

const jwtToken = localStorage.getItem("jwtToken");
const configToken = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
const baseUrl = myConstList.baseUrl;
const socket = socketIOClient(myConstList.baseUrlSocket, {query:"jwtToken=" +jwtToken});
class Contest extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "_id",
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row,index)=>index+1
            },
            {
                key: "Name",
                text: "name",
                className: "Name",
                align: "left",
                sortable: true
            },
            {
                key: "trades",
                text: "Trades",
                className: "trades",
                align: "left",
                sortable: true,
            },
            {
                key: "profit",
                text: "Profit Generated %",
                className: "profit",
                align: "left",
                sortable: true
            }
          
         
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Users",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            livePairData: [],
            upcomingData: [],
            pastData: [],
            myContestData:[],
            leaderboard:[],
            leaderboardGloble:[],
            bonus:0,
            scoketFlagOld:0,
            scoketContestChnage:0,
            
        };

        this.state = {
            currentRecord: {
                id: '',
                contestLogo: '',
                contestName: '',
                startTime: '',
                endTime: '',
                entryFee: '',
                virtualFund: '',
            }
        };

       // this.getData = this.getData.bind(this);
        const url = window.location.href;
        const lastSegment = url.split("/").pop();
       
    }
  
    componentDidMount() {
        ReactGA.initialize('UA-218461585-1');
        ReactGA.pageview('/contest');
        
        this.setState({ socketCollect: socket });
        var collectThis = this;    
        // socket.on("upcomingContestData", function (apiRespData) {
        //     console.log("upcomingContestData===",apiRespData);
        //     collectThis.setState({ upcomingData: apiRespData.data })
        // })
        axios
        .post(baseUrl + "api/profile-data", {},configToken)
        .then(res => {
            this.setState({ bonus: res.data.data.bonus,winningWalletBalance: res.data.data.winningamount,cashWalletBalance: res.data.data.balance })
        })
        .catch()
        this.setState({scoketFlagOld:0,scoketContestChnage:0});
        this.getData();
        
    };

    getData=()=> {
        // console.log("fdhfsdhsdhsdffgsdhfgf========");
        // const socket = socketIOClient(baseUrl, {query:"jwtToken=" +jwtToken});
        // console.log("Disconnected==componentDidMount==",socket);
        this.setState({ socketCollect: socket });
        var collectThis = this;    
        // client-side
        // socket.on("connect", () => {
        //     collectThis.setState({ currentSocketId: socket.id });
        //     socket.emit("callContestData")
        // });
        var data = {'token':jwtToken};
        socket.emit("contestFlag");
        socket.emit("pastContestList",data);
        socket.emit('getserverTime');  
        socket.off("pastContestData")
        socket.on("pastContestData", function (apiRespData) {
            collectThis.setState({ pastData: apiRespData.data })
        })
        const liveContestUser = "live"+this.props.auth.user.id;
        const upcomingContestUser = "upcoming"+this.props.auth.user.id;
        const myContestUser = "mycontest"+this.props.auth.user.id;
        // socket.off("timeOutLiveContest")
        socket.on("timeOutLiveContest", function (apiRespData) {
            // console.log("==timeoutLiveContest===");
            socket.emit("liveContest",{'token':jwtToken,'socketName':liveContestUser}); 
            socket.emit("upcomingContestList",{'token':jwtToken,'socketName':upcomingContestUser});            
            socket.emit("myContestList",{'token':jwtToken,'socketName':myContestUser});
            socket.emit("pastContestList",data);
            socket.emit('getserverTime');
        })
        // socket.off("timeOutupcomingContest")
        socket.on("timeOutupcomingContest", function (apiRespData) {
            // console.log("contestFlag21===");
            socket.emit("liveContest",{'token':jwtToken,'socketName':liveContestUser}); 
            socket.emit("upcomingContestList",{'token':jwtToken,'socketName':upcomingContestUser});            
            socket.emit("myContestList",{'token':jwtToken,'socketName':myContestUser});
            socket.emit("pastContestList",data);
            socket.emit('getserverTime');
        })
        // socket.off("contestFlagData")
        socket.on("contestFlagData", function (apiRespData) {
            // console.log("contestFlag31===");
            socket.emit("liveContest",{'token':jwtToken,'socketName':liveContestUser}); 
            socket.emit("upcomingContestList",{'token':jwtToken,'socketName':upcomingContestUser});            
            socket.emit("myContestList",{'token':jwtToken,'socketName':myContestUser});
            socket.emit('getserverTime');
        })
        // socket.off(liveContestUser)
        socket.on(liveContestUser, function (apiRespData) {
            // console.log("contestFlag===",apiRespData.data)
            collectThis.setState({ livePairData: apiRespData.data })
        })
        // socket.off(upcomingContestUser)
        socket.on(upcomingContestUser, function (apiRespData) {
            collectThis.setState({ upcomingData: apiRespData.data })
        })         
        // socket.off(myContestUser)   
        socket.on(myContestUser, function (apiRespData) {
            // console.log("apiRespData.data=",apiRespData.data);
            collectThis.setState({ myContestData: apiRespData.data })
        })
        // socket.off('serverTime')   
        socket.on("serverTime", function (apiRespData) {
            collectThis.setState({ serverTime: apiRespData })
        })
    }    
    
    componentWillUnmount(){
        // console.log("Disconnected==socket==");
    //   const socket = socketIOClient(baseUrl, {query:"jwtToken=" +jwtToken });
    //   socket.off("pastContestData");
    //   socket.off("contestFlagData");
    //   socket.off("livePairData");
    //   socket.off("upcomingContestData");
    //   socket.off("myContestData");
    // socket.close();
    }
    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"api/contest-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message, toastStyles);
                }
            })
            .catch();
        this.upcomingRecord();
        this.pastRecord();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <>
                <Navbar urlPath={true} symbol={false}/>
                <Sidebar />
                <div className="in_page">
                    <div className="container-fluid">
                        <ul className="nav contatcs-tab" id="crypt-tab" >
                            <li role="presentation">
                                <Link to="live" className={(this.props.match.params.type==="live" ||this.props.match.url==="/contest")?"active show":""}  >Live Contest</Link>
                            </li>
                            <li role="presentation">
                                <Link to="upcoming" className={(this.props.match.params.type==="upcoming" ||this.props.match.url==="/contest")?"active show":""}  >Upcoming Contest</Link>
                            </li>
                            <li role="presentation">
                                <Link to="past" className={(this.props.match.params.type==="past" ||this.props.match.url==="/past")?"active show":""}  >Past Contest</Link>
                                {/* <a className={this.props.match.params.type==="past"?"active show":""}   href="#tabPast" data-toggle="tab">Past Contest</a> */}
                            </li>
							<li role="presentation">
                            <Link to="my" className={(this.props.match.params.type==="my" ||this.props.match.url==="/my")?"active show":""}  >My Contest</Link>

                            </li>                          
                        </ul>
                        {/* <div className="owl-carousel owl-theme"> */}
                        <div className="tab-content">
                            <div className={(this.props.match.params.type==="live"||this.props.match.url==="/contest")?"tab-pane contest-slider active show":"tab-pane"} id="tabLive">                                
                                    <LiveContest serverTime={this.state.serverTime} record={this.state.livePairData} bonus={this.state.bonus}  winningWalletBalance={this.state.winningWalletBalance} cashWalletBalance={this.state.cashWalletBalance}/>
                            </div>
                            <div className={this.props.match.params.type==="upcoming"?"tab-pane active contest-slider show":"tab-pane contest-slider"} id="tabUpcoming">
                                    <UpcomingContest serverTime={this.state.serverTime} record={this.state.upcomingData} bonus={this.state.bonus} winningWalletBalance={this.state.winningWalletBalance} cashWalletBalance={this.state.cashWalletBalance} />
                            </div>
                            <div className={this.props.match.params.type==="past"?"tab-pane active contest-slider show":"tab-pane contest-slider"} id="tabPast">
                                    <PastContest record={this.state.pastData} bonus={this.state.bonus}/>
                            </div>
							
							<div className={this.props.match.params.type==="my"?"tab-pane active contest-slider show":"tab-pane contest-slider"} id="tabMy">
                                    <MyContest  serverTime={this.state.serverTime} record={this.state.myContestData} bonus={this.state.bonus}/>
                            </div>
                        </div>
                        {/* <div className="crypt-market-status2 mt-5 ">
                            <h5 className=" p-md-3  p-2 mb-0">Leaderboard</h5>
                            <LeaderboardGloble leaderboardGloble={this.state.leaderboardGloble}/> */}
                            {/* <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            /> */}
                        {/* </div> */}
                    </div>
                </div>
                <Footer />
                <Toaster/>
            </>
        );
    }
}

Contest.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    contestdata: state.contestdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Contest);
