import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { BrowserRouter as Router, Route,Link, Switch, Redirect, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import SidebarWallet from "./SidebarWallet";
import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
const configToken = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
const baseUrl = myConstList.baseUrl;
class WithdrawalHistory extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "tx_id",
                text: "Transaction Id",
                className: "upcase",
                align: "left",
                sortable: true,
				// cell: record => {
                //     return (
                //         <Fragment>
				// 		{record.status==="completed" ? record.approve : record.reject}
                //         </Fragment>
				// 	)
				// }
            },
            {   
                key:"amount",
                text:"Amount",
                className: "upcase",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                className: "upcase",
                align: "left",
                sortable: true,
                cell: record => {
					if(record.status==="pending") {
						return (
							<Fragment>
							<span className="accepted">Pending</span>
							</Fragment>
						);
					}
                    else if(record.status==="completed"){
                        return (
                        <Fragment>
                        <span className="accepted">Completed</span>
                        </Fragment>);
                    }
                    else if(record.status==="rejected"){
                        var rejectReason = "";
                        if(record.reject != undefined){
                            rejectReason = record.reject;
                        }
                        return (<Fragment>
                            <span className="rejected" data-toggle="modal"
                                    data-target="#reject-reason-modal"
                                    className="btn btn_man rejectedwithdrow" onClick={()=>this.setvalueReject(rejectReason)}>Rejected
                            </span>
                        </Fragment>);
                    }
                }
			},
            {
                key: "txStatus",
                text: "Regard To",
                className: "upcase",
                align: "left",
                sortable: true,
                cell: record => {
					if(record.txStatus == 'withdral'){
                        return (
                            <Fragment>
                                Withdrawal
                            </Fragment>
                        );  
                    }else{
                        return (
                            <Fragment>
                                {record.txStatus}
                            </Fragment>
                        );
                    } 
                }                
            },
            {
                key: "bankDetail",
                text: "Bank Detail",
                className: "upcase",
                align: "left",
                sortable: true,
				cell: record => {
					
                    return (
                        <Fragment>
                         <button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target={"#myModal"+record._id}>Bank Detail</button>
						 <div id={"myModal"+record._id} class="modal fade" role="dialog">
						  <div class="modal-dialog">

							
							<div class="modal-content">
							  <div class="modal-header">
								<h4 class="modal-title">Bank Detail</h4>
								<button type="button" class="close" data-dismiss="modal">&times;</button>
								
							  </div>
							  <div class="modal-body">
								<p>accountHolderName : {(record.bank_detail_id != undefined) ? record.bank_detail_id.accountHolderName : ""} </p>
								<p>accountNo : {(record.bank_detail_id != undefined) ? record.bank_detail_id.accountNo : ""}</p>
								<p>bankName : {(record.bank_detail_id != undefined) ? record.bank_detail_id.bankName : ""}</p>
								<p>ifscCode : {(record.bank_detail_id != undefined) ? record.bank_detail_id.ifscCode : ""}</p>
								<p>branchName : {(record.bank_detail_id != undefined) ? record.bank_detail_id.branchName : ""}</p>
							  </div>
							  <div class="modal-footer">
								<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
							  </div>
							</div>
						  </div>
						</div>
                        </Fragment>
                    );
                }
            },
            {
                key: "date",
                text: "Date",
                className: "upcase",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
               
            },
   
          
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };
      

        this.getData = this.getData.bind(this);
    }
    editRecord(record) {
        this.setState({ reject: record.reject,approve: record.approve });
    }
    componentWillReceiveProps(nextProps) {
      
        this.getData()

    }
    componentDidMount() {
        this.getData()
    }
    setvalueReject=(rejectReason)=>{
        this.setState({ currentReject: rejectReason});
    }
    getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl+"api/user_withdrawal_list",{},configToken)
            .then(res => {
                this.setState({ records: res.data.data })
            })
            .catch()
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    render() {
        const { errors } = this.state;

        return (
            <>
                <Navbar urlPath={true} symbol={false} />
                <Sidebar />
                <div className="container container2">
                    <div className="in_page ">
                                <SidebarWallet />
                        <div className="mt-4">
                            <div className=" padding_d" >
                                <div className="tab-content">
                                    <div className="white_box " >
                                        <h5 className=" mb-3 mb-md-4">Withdrawal History
                                        </h5>
                                        <div className="ccrool_table" >
                                       
                                        <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                            </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* show reject reason */}
                        <div className="modal fade" id="reject-reason-modal">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Withdrow Reject Reason</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <span>{this.state.currentReject}</span>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/*  */}
                </div>
                <Footer />
                <ToastContainer />
            </>
        );
    }

}

WithdrawalHistory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});
export default connect(
    mapStateToProps
)(withRouter(WithdrawalHistory));
