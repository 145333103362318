import React, { Component } from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";
import { Link } from "react-router-dom";
import axios from "axios";
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;

const configToken = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
  
class WalletBox extends Component {
	
	constructor(props) {
        super(props);
    
        this.state = {
          balance:0,
          bonus:0,
          winningamount:0,
          };
    
      }
	
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };
	 componentDidMount() {
        this.getData5()
    };
	
	getData5() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl+"api/getTransactionWithdral",{},configToken)
            .then(res => {
                this.setState({ records: res.data.data })
            })
            .catch()
        axios
        .post(baseUrl+"api/profile-data",{},configToken)
        .then(res => {
          if(res.data.data.videoStatus!=="verify" && this.props.urlPath!=false){
             this.setState({modalIsOpen: true}); 
          }

          this.setState({ bonus:res.data.data.bonus,balance:res.data.data.balance.toFixed(2),videoStatus:res.data.data.videoStatus,winningamount:res.data.data.winningamount })})
        .catch()
    }
	

    render() {
        const { user } = this.props.auth;
        return (
           <div className="row" >
			 <div className="col-md-4 mb-3" >
				<div className="wallet_box" >
				   <div className="wallet_box_right">
				   <div className="wallet_in_hight">
					  <h4 className="fw-500">
					  <span className="icon_box" >
					  <i className="las la-gift"></i>
				        </span><i className="fa fa-inr"></i> {this.state.bonus} </h4>
					  <h5>Reward Wallet (Bonus Amount + Referral Cash)</h5>
					  <span className="fw-500">You can use maximum 50% of entry fees from the bonus</span></div>
					  <Link className="btn btn_man mt-2 mr-2" to="/walletbonus">
					  Bonus History</Link>
					   <Link className="btn btn_man mt-2" to="/wallet">
					  Referral History</Link>
				   </div>
				</div>
			 </div>
			 <div className="col-md-4 mb-3" >
				<div className="wallet_box" >
				  
				   <div className="wallet_box_right">
					  <div className="wallet_in_hight">
						 <h4 className="fw-500"> <span className="icon_box" >
					  <i className="las la-trophy"></i>
				   </span>
				   <i className="fa fa-inr"></i> {this.state.winningamount}</h4>
						 <h5>Winning Wallet</h5>
						 <span className="fw-500">You can only withdraw cash</span><br/>
					  </div>
					  <Link className="btn btn_man mt-2 mr-2" to="/withdrawal-request">
					  Withdraw</Link>
					  <Link className="btn btn_man mt-2" to="/withdrawalHistoryTable">
						Winning History</Link>
				   </div>
				</div>
			 </div>
			 <div className="col-md-4 mb-3" >
				<div className="wallet_box" >
				   <div className="wallet_box_right">
					  <div className="wallet_in_hight">
						 <h4 className="fw-500"><span className="icon_box" >
					  <i className="las la-hand-holding-usd"></i>
				     </span>
				   <i className="fa fa-inr"></i> {this.state.balance}</h4>
						 <h5>Cash Wallet</h5>
						 <span className="fw-500">Your Added Cash</span><br/>
					  </div>
					  <Link className="btn btn_man  mt-2 mr-2" to="/add-money">
					  Add Cash</Link>
					  <Link className="btn btn_man  mt-2" to="/WalletHistoryTable">
					  Wallet History</Link>
				   </div>
				</div>
			 </div>
		   </div>
        );
    }
}

WalletBox.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(WalletBox);
