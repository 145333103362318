import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { order } from "../../../actions/orderAction";
import { withRouter } from "react-router-dom";
import $ from 'jquery';
import socketIOClient from "socket.io-client";
import * as myConstList from '../../../BaseUrl';
const jwtToken = localStorage.getItem("jwtToken");
const baseUrl = myConstList.baseUrl;
const socket = socketIOClient(myConstList.baseUrlSocket);
const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
class SellOrder extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            user_id: this.props.user_id,
            sprice: this.props.currentprice,
            samount: "",
            stopLoss: "",
            message: "",
            CurrencyValue:0,
            errors: {},
            total:"",
			btnDisable:false,
        };

    }

    componentWillReceiveProps(nextProps) {
        console.log("nextProps.CurrencyValue==",nextProps.CurrencyValue);
        if (nextProps.user_id) {
            this.setState({
                user_id: nextProps.user_id
              });
        }
        if (nextProps.currentprice !=undefined) {
            this.setState({
                sprice: nextProps.currentprice,
              });
        }
        if (nextProps.CurrencyValue !=undefined) {
            this.setState({
                CurrencyValue: nextProps.CurrencyValue
              });
        }else{
            this.setState({
                CurrencyValue: 0
              });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.orderdata !== undefined
            && nextProps.orderdata.order !== undefined
            && nextProps.orderdata.order.data !== undefined
            && nextProps.orderdata.order.data.message !== undefined
            ) {
                const socketEventorder = "order"+this.props.auth.user.id+this.props.match.params.id;
                console.log("exchangeOrderDatasellOrder");
                socket.emit('exchangeOrderData',{'socketName':socketEventorder,'contestId':this.props.match.params.id,'userId':this.props.auth.user.id,'symbol':this.props.match.params.symbol}); 
            //    toast( nextProps.orderdata.order.data.message2, {
            //         position: toast.POSITION.TOP_CENTER  
            //       });
            this.setState({             
                total:"",
                samount:"",
                stopLoss:"",
				btnDisable:false
            });
        }
    }


    onChange = e => {
        this.setState({ message:"", errors:{}});
        if (e.target.id === 'user-update-id') {
            this.setState({ user_id: e.target.value });
        }
        if (e.target.id === 'sprice') {
			// var total=(this.state.samount*e.target.value).toFixed(2);
			var total=this.state.samount*e.target.value;
            this.setState({ sprice: e.target.value,total:total });
        }
        if (e.target.id === 'samount') {
            // var total=(this.state.sprice*e.target.value).toFixed(2);
            var total=this.state.sprice*e.target.value;
            this.setState({ samount: e.target.value,total:total });
        }
        if (e.target.id === 'stopLoss') {
            this.setState({ stopLoss: e.target.value });
        }
     };
     onOrderUpdate = e => {
    
      
        e.preventDefault();
		this.setState({btnDisable:true});
         const { user } = this.props.auth;
        const newOrder = {
            user_id: user.id,
            price: this.state.sprice,
            contest_id: this.props.match.params.id,
            amount: this.state.samount,
            stopLoss: this.state.stopLoss,
            symbol: this.props.match.params.symbol,
            orderType: "Sell"
        };
        this.props.order(newOrder);
    };

    render() {
        if(this.state.sprice!=undefined){
            var prices=this.state.sprice;
        }
        const { errors } = this.state;
        const { user } = this.props.auth;

       
        return (
            <div className="col-md-6">
            
             <form noValidate onSubmit={this.onOrderUpdate} id="update-user">
             {/* <span className="text-danger">{errors.balance}</span> */}
             <input onChange={this.onChange} value={this.state.user_id} id="user-update-id"
                        type="text" className="d-none" />
            <div className="crypt-buy-sell-form">
                <div className="crypt-sell">
                    <div className="row mb-2">
                        <span className="col-3 pr-0">
                            Sell <span className="crypt-down">{this.props.match.params.symbol.slice(0, -4)}</span>
                        </span>
                        <span className="fright col-9 tr">
                            <b className="crypt-down"> {this.props.match.params.symbol.slice(0, -4)} Balance: {this.state.CurrencyValue}
                            {/* {this.state.CurrencyValue.toFixed(6)} */}
                            </b>
                        </span>
                    </div>
                    <span className="text-success">{this.state.message}</span>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend"> <span
                                className="input-group-text">Price</span> </div>
                        <input onChange={this.onChange}  id="sprice"
                            type="number" className="form-control" value={prices} placeholder="0.00" />
                            <span className="text-danger">{errors.sprice}</span>
                        <div className="input-group-append"> <span
                                className="input-group-text">USDT</span> </div>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend"> <span
                                className="input-group-text">Quantity</span> </div>
                         <input onChange={this.onChange} id="samount"
                            type="number" className="form-control" value={this.state.samount!=""?this.state.samount:""} placeholder="0.00" />
                            <span className="text-danger">{errors.samount}</span>
                        <div className="input-group-append"> <span
                                className="input-group-text">{this.props.match.params.symbol.slice(0, -4)}</span> </div>
                    </div>
					{  /*<div className="input-group mb-3">
                        <div className="input-group-prepend"> <span
                                className="input-group-text">Stop Loss</span> </div>
                        <input   onChange={this.onChange} value={this.state.stopLoss}  id="stopLoss"
                            type="number" className="form-control" placeholder="0" />
                            <span className="text-danger">{errors.stopLoss}</span>
					</div>*/}
                    <div className="input-group mb-3">
                        <div className="input-group-prepend"> <span
                                className="input-group-text">Total</span> </div>
                         <input  onChange={this.onChange} id="total"
                            type="number" className="form-control" value={this.state.total} placeholder="0.00" />
                            <span className="text-danger">{errors.total}</span>
                        <div className="input-group-append"> <span
                                className="input-group-text">USDT</span> </div>
                    </div>
                    <div><button disabled={this.state.btnDisable} type="submit" className="crypt-button-red-full">Sell</button>
                    </div>
                </div>
            </div>
            </form>
        </div>


        )
    }
}

SellOrder.propTypes = {
    order: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    orderdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    orderdata: state.orderdata,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { order }
)(withRouter(SellOrder));
