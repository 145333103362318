import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import VerifiPhone from "../partials/kyc/VerifiPhone";
import ConfirmOtp from "../partials/kyc/ConfirmOtp";
import Intermediate from "../partials/kyc/Intermediate";
import VerifiAddress from "../partials/kyc/VerifiAddress";
import TaxVerification from "../partials/kyc/TaxVerification";
import VerifiVideo from "../partials/kyc/VerifiVideo";
import ReactGA from 'react-ga';
import * as myConstList from '../../BaseUrl';
const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
const baseUrl = myConstList.baseUrl;
class Kyc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            userdata:[],
            email_verified:'',
            verifyPhoneStatus:'',
            fImage_status:'',
            bImage_status:'',
            verifyAddressStatus:'',
            verifyTaxStatus:'',
            verifyVideoStatus:'',
            verifyIntermediateStatus:'',
            verifyIntermediate:'',
            addressKycStatus:'',
            taxForverify:'',
            videoStatusVerify:'',           
            countryCode:"",
        };
      this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize('UA-218461585-1');
        ReactGA.pageview('/kyc');
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }
    getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl+"api/profile-data", {},config)
            .then(res => {
                this.setState({ records: res.data.data ,
                    email_verified:res.data.data.email_verified,
                    verifyPhoneStatus:res.data.data.mobileVerifyStatus,
                    fImage_status:res.data.data.fImage_status,
                    bImage_status:res.data.data.bImage_status,
                    verifyAddressStatus:res.data.data.addressStatus,
                    verifyTaxStatus:res.data.data.taxStatus,
                    verifyVideoStatus:res.data.data.videoStatus,
                    verifyIntermediate:res.data.data.interMediate,
                    addressKycStatus:res.data.data.addressKycStatus,
                    taxForverify:res.data.data.taxForverify,
                    videoStatusVerify:res.data.data.videoStatusVerify,                    
                    
                    userdata: {id: res.data.data.id,
                    fImage: res.data.data.fImage,
                    bImage: res.data.data.bImage,
                    fi_rejectResion: res.data.data.fi_rejectResion,
                    bi_rejectResion: res.data.data.bi_rejectResion,
                    documentNo: res.data.data.documentNo,
                    docType: res.data.data.docType,
                    fImage_status:res.data.data.fImage_status,
                    bImage_status:res.data.data.bImage_status,
                    fname:res.data.data.fname,
                    lname:res.data.data.lname,
                    dob:res.data.data.dob,
                    gender:res.data.data.gender,
                    countryCode:res.data.data.countryCode,
                }   
                });
                if(this.state.bImage_status === "verify"){
                    this.setState({ verifyIntermediateStatus:"verify" });
                }
           })
            .catch()
    }
  
   
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    handleLanguage= (Value)=> {
        this.setState({verifyPhoneStatus: Value});
    }
    render() {      
        return (
            <>
                <Navbar urlPath={false} symbol={false}/>
                <Sidebar />
                <div className="container">
                    <div className="in_page row">
                        <div className="col-md-12">
						{/* <h6 className=" mb-3">KYC</h6>*/}
                            <div className=" " >
                                <ul className="nav nav_left_tab">
                                    {/*<li className="nav-item">
                                        <a className="active1" data-toggle="tab" href="javascript:void(0)">
                                            <i className="la la-envelope-o l_i"></i>
                                            Verify Email
                                            <br/>
                                            this.state.email_verified
                                        </a>
                                    </li>*/}
                             
                                    {/*<li className="nav-item">
                                        <a className={this.state.verifyPhoneStatus==="verify" ? "active1":""} data-toggle="tab" href="javascript:void(0)">
                                            <i className="la la-mobile-phone l_i"></i>
                                            Verify Phone
                                            <br/>
                                            this.state.verifyPhoneStatus
                                        </a>
                                    </li>*/}

           
                                    <li className="nav-item" style={{width:"50%"}}>
                                        <a className={(this.state.verifyIntermediate==="1" && this.state.bImage_status!="reject") ? "active1":" "} data-toggle="tab" href="javascript:void(0)">
                                            <i className="las la-clipboard-check l_i"></i>
                                          Photo ID Verification
                                            <br/>
                                            {/*this.state.bImage_status*/}
                                        </a>
                                    </li>

                                    {/* <li className="nav-item">
                                        <a className={(this.state.addressKycStatus==="1" && this.state.verifyAddressStatus!=="reject") ? "active1":""} data-toggle="tab" href="javascript:void(0)">
                                            <i className="la la-map-marker l_i"></i>
                                            Verify Address
                                            <br/>
                                           this.state.verifyAddressStatus
                                        </a>
                                    </li>*/}

                                    <li className="nav-item"  style={{width:"50%"}}>
                                        <a className={(this.state.taxForverify==="1" && this.state.verifyTaxStatus!="reject") ? "active1":""} data-toggle="tab" href="javascript:void(0)">
                                            <i className="las la-money-check l_i"></i>
                                            Pan Verification
                                            <br/>
                                            {/*this.state.verifyTaxStatus*/}
                                        </a>
                                    </li>
                                    {/*<li className="nav-item">
                                        <a className={(this.state.videoStatusVerify==="1" && this.state.verifyVideoStatus!="reject") ? "active1":""} data-toggle="tab" href="javascript:void(0)">
                                            <i className="las la-camera l_i"></i>
                                            Face Verification
                                            <br/>
                                            this.state.verifyVideoStatus
                                        </a>
                                    </li>*/}
                                </ul>
                                <div className="white_box kyc-outer">
                                    <div className="tab-content ">
                                        {/* {this.state.verifyPhoneStatus !== "verify" &&
                                            <div className="tab-pane active show" id="VerifiPhone">
                                                <h3 className="mb-3">Verify Phone </h3>
                                                <VerifiPhone record={this.state.records} />
                                                <ConfirmOtp record={this.state.records} mobileVerifyStatus={this.handleLanguage} />
                                            </div>
                                        } */}
                                        {this.state.verifyPhoneStatus === "verify" && this.state.verifyIntermediate !== "1" &&
                                            <div className="tab-pane active show" id="Intermediate">
                                                <Intermediate userdata={this.state.userdata} countryCode={this.state.countryCode} />
                                            </div>
                                        }
                                        {this.state.verifyPhoneStatus === "verify" && this.state.bImage_status==="reject" &&
                                            <div className="tab-pane active show" id="Intermediate">
                                                <Intermediate userdata={this.state.userdata} />
                                            </div>
                                        }
                                        {/*this.state.verifyPhoneStatus === "verify" && this.state.verifyIntermediate === "1" && this.state.addressKycStatus !== "1" &&
                                            <div className="tab-pane active show" id="VerifiAddress">
                                                <VerifiAddress record={this.state.records} />
                                            </div>
                                        */}
                                        {/*this.state.bImage_status!=="reject" && this.state.verifyAddressStatus === "reject" &&
                                            <div className="tab-pane active show" id="VerifiAddress">
                                                <VerifiAddress record={this.state.records} />
                                            </div>
                                        */}
                                        {this.state.verifyPhoneStatus === "verify" && this.state.verifyIntermediate === "1"  && this.state.taxForverify !== "1" &&
                                            <div className="tab-pane active show" id="TaxVerification">
                                                <TaxVerification record={this.state.records} />
                                            </div>
                                        }
                                        {this.state.bImage_status!=="reject" && this.state.verifyAddressStatus!=="reject" && this.state.verifyTaxStatus === "reject" &&
                                            <div className="tab-pane active show" id="TaxVerification">
                                                <TaxVerification record={this.state.records} />
                                            </div>
                                        }
                                        {/*this.state.verifyPhoneStatus === "verify" && this.state.verifyIntermediate === "1" && this.state.taxForverify === "1" && this.state.videoStatusVerify !== "1" &&
                                            <div className="tab-pane active show " id="VerifiVideo">
                                                <VerifiVideo record={this.state.records} />
                                            </div>
                                        */}
                                        {/*this.state.bImage_status!=="reject" && this.state.verifyAddressStatus!=="reject" && this.state.verifyTaxStatus!=="reject" && this.state.verifyVideoStatus === "reject" &&
                                            <div className="tab-pane active show" id="VerifiVideo">
                                                <VerifiVideo record={this.state.records} />
                                            </div>
                                        */}
                                        {this.state.bImage_status === "pending" && this.state.verifyTaxStatus === "pending" && 
                                        <h3 className="text-danger text-center"> KYC Verification Pending</h3>
                                        }
                                        {this.state.bImage_status === "pending" && this.state.verifyTaxStatus === "verify" && 
                                        <h3 className="text-danger text-center"> KYC Verification Pending</h3>
                                        }
                                        {this.state.bImage_status === "verify" && this.state.verifyTaxStatus === "pending" && 
                                        <h3 className="text-danger text-center"> KYC Verification Pending</h3>
                                        }
                                        {this.state.verifyPhoneStatus === "verify" && this.state.bImage_status === "verify" && this.state.verifyTaxStatus === "verify" &&
                                        <h3 className="text-success text-center">KYC Verified</h3>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

Kyc.propTypes = {
    auth: PropTypes.object.isRequired,
    kycdata: PropTypes.object.isRequired
   
};

const mapStateToProps = state => ({
    auth: state.auth,
    kycdata: state.kycdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Kyc);
