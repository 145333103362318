import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditor } from 'ckeditor4-react';
import BigNumber from 'bignumber.js'
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class MarketTrade extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            leaderboard: [],
            leaderboardDataDummyUser:[],
        };
        var classThis = this;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.leaderboard) {
			this.setState({ leaderboard: nextProps.leaderboard})
        }
    }
    
showLeatherBoardHtml = () => {
	var html=[];
	var rank = 0;
	var profitArr=[];
    // console.log("this.state.leaderboard=====baint====",this.state.leaderboard);
    let data =this.state.leaderboard;
    data.sort((a, b) => b.per - a.per);

	data.map(function (value, i) {	
        let bids = 0;
        if(value.bid_count && value.bid_count !=undefined){
            bids = value.bid_count;
        }
        if(value.order_count && value.order_count !=undefined){
            bids = value.order_count;
        }
		if(bids>=5) {	
			var portfolioVal = value.portfoliovalue;		
			if(profitArr.includes(portfolioVal)===false){
				rank++;
			}
			profitArr.push(portfolioVal);
			html.push(			   
					<tr>
                        <td><span className="pr-2">{rank}</span></td>
                        <td><span className="pr-2">{value.name}</span></td>
                        <td><span className="pr-2">{(Number(value.per).toFixed(3))+"%"}</span></td>
					</tr>
			);
			//console.log("profitArr.includes(portfolioVal)",profitArr.includes(portfolioVal));			
		}		
	})
// console.log("leaderboardDataDummyUser===",this.state.leaderboardDataDummyUser);
    // this.state.leaderboardDataDummyUser.map(function (value1, i) {	      
	// 	if(value1.order_count>=5) {	
    //         let portfoliovalue = value1.contest_virtualFund-(value1.per *100);
	// 		var portfolioVal = portfoliovalue;		
	// 		if(profitArr.includes(portfolioVal)===false){
	// 			rank++;
	// 		}
	// 		profitArr.push(portfolioVal);
	// 		html.push(			   
	// 				<tr>
	// 				<td><span className="pr-2">{rank}</span></td>
	// 				<td><span className="pr-2">{value1.name}</span></td>
	// 				<td><span className="pr-2">{(value1.per)+"%"}</span></td>
	// 				</tr>
	// 		);
	// 		//console.log("profitArr.includes(portfolioVal)",profitArr.includes(portfolioVal));			
	// 	}		
	// })
	return html;
}

showParticipantsHtml = () => {
	var html=[];
	this.state.leaderboard.map(function (value, i) {
		var rowColor = (value.bid_count>=5 || value.order_count>=5) ? "#01aa78" : "#eb6a61";
		html.push(
				<tr style={{color:rowColor}}>
				   <td className="col-3 ">{i+1}</td>
				   <td className="col-3 ">{value.name}</td>
					<td className="col-3 ">{(value.portfoliovalue && value.portfoliovalue !=undefined)?value.portfoliovalue.toFixed(2):(value.contest_virtualFund && value.contest_virtualFund !=undefined)?Number(value.contest_virtualFund).toFixed(2):""}</td>
					<td className="col-3 tr">{value.bid_count !=undefined ? value.bid_count:value.order_count}</td>
					{/* <td className="col-3 tr">{value.bid_count?value.bid_count:value.order_count}</td> */}
				</tr>
		);		
	})
	return html;
}


    render() {
       return (
        <div className="crypt-market-status mt-2 ">
            <div>
                <ul className="nav nav-tabs nav-tabs5" id="crypt-tab">
				{/*<li role="presentation"><a className="active show" href="#MarketTrades"
				data-toggle="tab" className="">Market Trades</a></li>*/}
						<li role="presentation"><a className="active show" href="#MarketTrades"
                        data-toggle="tab" >Leaderboard</a></li>
						<li role="presentation"><a href="#MarketTradesParticipants"
                        data-toggle="tab" >Participants</a></li>
						{/* <li role="presentation"><a href="#MyTrades" data-toggle="tab">My Trades</a></li>*/}
                </ul>
                <div className="tab-content crypt-tab-content">
                    <div role="tabpanel" className="tab-pane active show" id="MarketTrades">
					    <div className="table-overflow mb-2">
                            <table className="table border_none mb-0">
                                <thead>
                                    <tr>
                                    {/* <th className="col-4">Price(USDT)</th>
                                        <th className="col-4">Amount({props.curruncyName})</th>

                                    <th className="col-4 tr">Time</th>*/}
                                        <th className="col-4">Rank</th>
                                        <th className="col-4">User</th>
                                        {/*<th className="col-4">Value</th>*/}
                                        <th className="col-4">Gain/Loss %</th>
                                    </tr>
                                </thead>
                                <tbody className="crypt-table-hover">
                                    {this.showLeatherBoardHtml()}
                                </tbody>
                            </table>
                        </div>
                    </div>
					
					<div role="tabpanel" className="tab-pane" id="MarketTradesParticipants">
					 <div className="table-overflow mb-2">
                        <table className="table border_none mb-0">
                            <thead>
                                <tr>
								{/* <th className="col-4">Price(USDT)</th>
                                    <th className="col-4">Amount({props.curruncyName})</th>

								<th className="col-4 tr">Time</th>*/}
									<th className="col-3">Sr No.</th>
									<th className="col-3">User</th>
									<th className="col-3">Value</th>
									
									<th className="col-3 tr">Bids</th>
									
                                </tr>
                            </thead>
							<tbody className="crypt-table-hover">
                                    {this.showParticipantsHtml()}



                                </tbody>
                        </table>
                       

                         
                        </div>
                    </div>
                    {/*<div role="tabpanel" className="tab-pane" id="MyTrades">
                        <table className="table border_none mb-0">
                            <thead>
                                <tr>
                                    <th className="col-4">Price(USDT)</th>
                                    <th className="col-4">Amount({props.curruncyName})</th>

                                    <th className="col-4 tr">Time</th>
                                </tr>
                            </thead>
                        </table>
                        <div className="table-overflow mb-2">

                            <table className="table border_none mb-0">
                                <tbody className="crypt-table-hover">


                                    {html}

                                </tbody>
                            </table>
                        </div>
                    </div>
					*/}
					
                </div>
            </div>
        </div>
    )
    }
}

MarketTrade.propTypes = {

    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(

    state => state
)(withRouter(MarketTrade));
