import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import Moment from 'moment';
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;

class Leaderboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leaderboard: [],
            currentStatusLive:"",
            contestType:"",
        };


        // this.getData = this.getData.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.leaderboard) {
            this.setState({
                leaderboard: nextProps.leaderboard,
                currentStatusLive:nextProps.currentStatusLive,
                contestType:nextProps.contestType,
                contestTypePast:nextProps.contestTypePast,
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {

            this.setState({
                message: nextProps.auth.user.data.message
            });
        }
        // this.getData()
    }

    showChatHtml = () => {
       
        console.log("this.state.leaderboard",this.state.currentStatusLive);        
        const html = [];	
        var rank = 0;
	    var profitArr=[];	
        if(this.state.leaderboard != undefined && this.state.leaderboard.length>0){
            this.state.leaderboard.map(function (value, i) {      
                console.log("value.winner_amount",value.winner_amount);      
                if(value.bid_count>=5 || value.order_count>=5) {
                    var portfolioVal = value.portfoliovalue;                
                    if(profitArr.includes(portfolioVal)===false){
                        rank++;
                    }
                    profitArr.push(portfolioVal);
                    html.push(                       
                            <tr>
                            <td><span className="pr-2">{rank}</span></td>
                            <td><span className="pr-2">{value.name}</span></td>
                            <td><span className="pr-2">{value.portfoliovalue}</span></td>
                            <td><span className="pr-2">{(Number(value.per).toFixed(3))+"%"}</span></td>
                            <td><span className="pr-2">{value.winner_amount}</span></td>
                            </tr>
                    );
                    //console.log("profitArr.includes(portfolioVal)",profitArr.includes(portfolioVal));                    
                }
            })
        }		
        return html;
    }
    // componentDidMount() {
    //     this.getData()
    // };

  

    // getData() {
    //     console.log("user-dattttaaa======");
    //     axios
    //         .post(baseUrl+"api/user-data")
    //         .then(res => {
    //             this.setState({ records: res.data.data})
    //         })
    //         .catch()
    // }
  

    render() {
        return (<div className="table-responsive">
		<div className="table-responsive winners_table">
            <table className="table  mb-0">            
                <tr>
                <th className="th">Rank</th>
                    <th className="th">Username</th>
                    <th className="th">Portfolio Value</th>
                    <th className="th">Gain/Loss %</th>
                    <th className="th">Winning Amount</th>
                </tr>
                <tbody>
            
                    {(this.state.contestType==='live' || this.state.contestTypePast) && this.showChatHtml()}
                </tbody>
        </table>
		</div>
		</div>
        );
    }

}

Leaderboard.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});
export default connect(
    mapStateToProps
)(Leaderboard);
