import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PairSection from "../partials/exchange/PairSection";
import ProfitLoseTableMobile from "../partials/exchange/ProfitLoseTableMobile";
import OrderSection from "../partials/exchange/OrderSection";
import MarketActivities from "../partials/exchange/MarketActivities";
import MarketTrade from "../partials/exchange/MarketTrade";
import OrderHistory from "../partials/exchange/OrderHistory";
import Ticker from "../partials/exchange/Ticker";
import Loading from "../layout/Loading";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import * as myConstList from "../../BaseUrl";
import MetaTags from "react-meta-tags";
import socketIOClient from "socket.io-client";

const baseUrl = myConstList.baseUrl;
const jwtToken = localStorage.getItem("jwtToken");
const socket = socketIOClient(myConstList.baseUrlSocket);
const config = {
  headers: {
    Authorization: "Bearer " + `${localStorage.getItem("jwtToken")}`,
  },
};
class Exchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      symbol: this.props.match.params.symbol,
    };

    this.tradesCount = 10;
    this.streams = ["@ticker", "@depth20", "@trade"];
    // this.getData = this.getData.bind(this);
  }

  _connectSocketStreams(streams) {
    streams = streams.join("/");

    let connection = btoa(streams);

    this[connection] = new WebSocket(
      `wss://stream.binance.com:9443/stream?streams=${streams}`
    );

    console.log("web=", this[connection].readyState + "==" + WebSocket.OPEN);
    // console.log("web==",WebSocket.OPEN);
    this[connection].onmessage = (evt) => {
      let eventData = JSON.parse(evt.data);
      if (eventData.stream.endsWith("@ticker")) {
        eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0;
        this.props.dispatch({
          type: "SET_TICKER",
          data: eventData.data,
        });
        this.setState({
          loadedTicker: true,
        });
      }
      if (eventData.stream.endsWith("@trade")) {
        if (this.props.trades && Object.keys(this.props.trades).length > 0) {
          let trades = this.props.trades;
          trades.push(eventData.data);
          trades = trades.slice(-1 * this.tradesCount);
          this.props.dispatch({
            type: "SET_TRADES",
            data: trades,
          });
          this.setState({
            loadedTrades: true,
          });
        }
      }
      if (eventData.stream.endsWith("@depth20")) {
        this.props.dispatch({
          type: "SET_DEPTH",
          data: eventData.data,
        });
        this.setState({
          loadedDepth: true,
        });
      }
      this.setState({
        isLoaded: true,
      });
    };
    this[connection].onerror = (evt) => {
      // console.error(evt);
    };
  }

  _disconnectSocketStreams(streams) {
    streams = streams.join("/");
    let connection = btoa(streams);
    if (this[connection].readyState == WebSocket.OPEN) {
      this[connection].close();
      this._connectSocketStreams(streams);
    }
  }

  getData = () => {
    const { user } = this.props.auth;
  };

  componentDidMount() {
    let symbolType = this.props.match.params.symbol;
    let symbol = this.props.match.params.symbol.toLowerCase();
    this._connectSocketStreams(this.streams.map((i) => `${symbol}${i}`));

    fetch(
      `https://api.binance.com/api/v1/aggTrades?limit=${10}&symbol=${symbolType}`
    )
      .then((response) => response.json())
      .then((response) => {
        this.props.dispatch({
          type: "SET_TRADES",
          data: response,
        });
        this.setState({
          isLoaded: true,
          loadedTrades: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoaded: false,
          error: error,
        });
      });
    fetch(`${baseUrl}api/crpto-price?symbol=${this.props.match.params.symbol}`)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ currentprice1: response.price });
      })
      .catch();
    this.getData();
  }

  componentWillUnmount() {
    this._disconnectSocketStreams(
      this.streams.map((i) => `${this.props.match.params.symbol}${i}`)
    );
  }

  componentWillReceiveProps(nextProps) {
    // if(this.state.tempArrPair !='' && this.state.flagPairData==1){
    //     this.setState({flagPairData:'2'});
    //     var pair_idArr = this.state.tempArrPair.split(",");
    //     axios.post(baseUrl+"api/pairById-data", { id: pair_idArr},config)
    //     .then(res1 => {
    //         this.setState({ pair: res1.data.data });
    //     }).catch()
    // }
  }

  render() {
    const { error, isLoaded, loadedDepth, loadedTicker, loadedTrades } =
      this.state;
    if (error) {
      return <div className="alert alert-danger"></div>;
    }
    // if (!isLoaded) {
    //     return <Loading />;
    // }
    return (
      <>
        <MetaTags>
          <meta className="robots" CONTENT="noindex,nofollow" />
        </MetaTags>
        <div className="dashbord_manbox p-0 dashbord_manbox_mobile">
          <div className="container-fluid">
            <div className="row">
              {loadedDepth ? (
                <ProfitLoseTableMobile
                  record={this.state.records}
                  bids={this.props.depth.bids}
                  asks={this.props.depth.asks}
                  {...this.props.ticker}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Exchange.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect((state) => state)(Exchange);
