import React,{ Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { kyc } from "../../../actions/kycUpdate";
import { withRouter } from "react-router-dom";
import Loading from "../../layout/Loading";
import $ from 'jquery';
class VerifiPhone extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            email: this.props.email,
            countryCode: '',
            mobile: '',
            message: '',
            showResults: false,
            errors: {},
            calling_code: '',
            country_flag:'',
            modalIsLoadingOpen: false
        };
    }
    getGeoInfo(){
        fetch("https://api.ipdata.co/?api-key=f9ba88a917827fb3c3103a318590155f3bc976cabefdb00c65e93ceb")
        .then(response => response.json())
        .then(response => {
          this.setState({
            calling_code: response.calling_code,
            country_flag: response.flag,
          });
          this.setState({ countryCode: response.calling_code })
        }).catch((error) => {})
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                email: nextProps.record.email

            })

        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined
            && nextProps.kycdata.kyc.data.message !== undefined
            && nextProps.kycdata.kyc.data.status) {  
            this.setState({modalIsLoadingOpen: true});             
            if(nextProps.kycdata.kyc.data.message!== undefined){
                this.setState({modalIsLoadingOpen: false});
                this.setState({
                    message: nextProps.kycdata.kyc.data.message,
                    errors: {}
                    }); 
            }                     
        }else if (nextProps.kycdata !== undefined
            && nextProps.kycdata.kyc !== undefined
            && nextProps.kycdata.kyc.data !== undefined){
                
            this.setState({
                errors: nextProps.kycdata.kyc.data,
                message: "",
            });
            this.setState({modalIsLoadingOpen: false});
        }
    }

    onChange = e => {
        this.setState({
            message: "",
            errors: {}
        })
        if (e.target.id === 'user-update-countryCode') {
            this.setState({ countryCode: e.target.value });
        }
        if (e.target.id === 'user-update-mobile') {
            this.setState({ mobile: e.target.value });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUserUpdate = e => {
        this.setState({modalIsLoadingOpen: true}); 
        e.preventDefault();

        const newUser = {
            _id: this.state.id,
            type: "phone",
            mobile: this.state.mobile,
            email: this.state.email,
            countryCode: this.state.countryCode
        };
        this.props.kyc(newUser);
        $('#user-update-otp').val("");
    };
    componentDidMount() {
        this.getGeoInfo();
    };
    render() {
        const { errors } = this.state;
        return (<>
            <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
               
                    {/* <label className="fw-500">Phone</label> */}
                     <label className="fw-500">Phone number</label>
                        <div className="row ">
                        <div className="col-3 col-md-2 col-lg-1 pr-0">
                           {/* <label className="fw-500">Country Code</label> */}
                            <div className="country_flag_man_box">
                                <span class="country_flag"><img className="w100" src={this.state.country_flag}></img></span>
                            
                               
                              
                                <input onChange={this.onChange}
                                    value={this.state.calling_code}
                                    placeholder="{this.state.calling_code}"
                                    id="user-update-countryCode"
                                    type="text" className="form-control country_code pr-0"/>
                                </div>
                                <span className="text-danger">{errors.countryCode}</span>
                        </div>
                            <div className="col-9 col-lg-4 col-md-6">
                           
                                <input onChange={this.onChange} placeholder="Phone number"
                                    id="user-update-mobile"
                                    type="number" className="form-control"
                                    value={this.state.mobile}
                                />
                                <span className="text-danger">{errors.mobile}</span>
                                <span className="text-danger">{errors.message}</span>
                            </div>
                           
                            <div className="col-md-3" style={{ display: (this.state.showResults ? 'block' : 'none') }}>
							
                                <button className="btn btn_man w110" type="submit"> Send OTP</button>
                            </div>
                        </div>
                   
                    <p class="mt-1 fs13">Please set your phone number with country code. Must be a mobile number!</p>
          
            </form>
            <span className="text-success">{this.state.message}</span>
            { this.state.modalIsLoadingOpen &&
                <Loading />
            }
          </>
        )
    }
}

VerifiPhone.propTypes = {
    kyc: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    kycdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    kycdata: state.kycdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { kyc }
)(withRouter(VerifiPhone));
