import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ReactGA from 'react-ga';
import Moment from 'moment';


import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class BankDetail extends Component {

    constructor(props) {
        super(props);
		
		
		
        this.state = {
            records: [],
            upiRecords:[],
            bankname:'',
			accountno:'',
			accountholdername:'',
			ifsccode:'',
			branchname:'',
			cancelledChequeImage:'',
            upiid:'',
			kycCompleteStatus:"pending",
			profileDataFound:false,
			recordProfile:'',
            currentReject:""
			
        };
        

        this.getData = this.getData.bind(this);
		
		this.columns = [
            {
                key: "bankName",
                text: "Bank Name",
                classNameName: "subject",
                align: "left",
                sortable: true,
            },
			{
                key: "accountHolderName",
                text: "Account Holder Name",
                classNameName: "subject",
                align: "left",
                sortable: true,
            },
			{
                key: "accountNo",
                text: "Account No",
                classNameName: "subject",
                align: "left",
                sortable: true,
            },{
                key: "ifscCode",
                text: "Ifsc Code",
                classNameName: "subject",
                align: "left",
                sortable: true,
            },{
                key: "branchName",
                text: "Branch Name",
                classNameName: "subject",
                align: "left",
                sortable: true,
            },{
                key: "cancelledChequeImage",
                text: "Cancelled Cheque Image",
                classNameName: "subjects",
                align: "left",
                sortable: true,
				cell: record => {
                    return (
                       
                        <img src={record.cancelledChequeImage} className="btn btn_man" width='100' />
                          
                    );
                }
            },
            {
                key: "status",
                text: "Status",
                className: "upcase",
                align: "left",
                sortable: true,
                cell: record => {
					if(record.status==="pending") {
						return (
							<Fragment>
							<span className="accepted">Pending</span>
							</Fragment>
						);
					}
                    else if(record.status==="accepted"){
                        return (
                        <Fragment>
                        <span className="accepted">Accepted</span>
                        </Fragment>);
                    }
                    else if(record.status==="rejected"){
                        var rejectReason = "";
                        if(record.rejectReason != undefined){
                            rejectReason = record.rejectReason;
                        }
                        return (<Fragment>
                            <span data-toggle="modal"
                                    data-target="#reject-reason-modal"
                                    className="rejected btn btn_man rejectedwithdrow" onClick={()=>this.setvalueReject(rejectReason)}>Rejected
                            </span>
                        </Fragment>);
                    }
                }               
            },
            {
                key: "date",
                text: "Date",
                className: "upcase",
                align: "left",
                sortable: true,
				cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            }
        ];

        this.columns1 = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
			{
                key: "upiId",
                text: "UPI ID",
                classNameName: "upiId",
                align: "left",
                sortable: true,
            },
            {
                key: "date",
                text: "Date",
                className: "upcase",
                align: "left",
                sortable: true,
				cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            }
        ];
    }
    
    getData() {
      const { user } = this.props.auth;
	   const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios
            .get(baseUrl+"api/user_bank_detail",config)
            .then(res => {				
                this.setState({ records: res.data.data})
            })
            .catch()
            axios
            .get(baseUrl+"api/geuserUPIList",config)
            .then(res => {
                this.setState({ upiRecords: res.data.data})
            })
            .catch()
    }
    componentDidMount() {
        ReactGA.initialize('UA-218461585-1');
        ReactGA.pageview('/bankdetail');
        this.getData()
        this.getProfileData()
    };
	
	
	
	getProfileData = () =>{
		
		const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
		axios
          .post(baseUrl+"api/profile-data",{},config)
          .then(res => {
            // if(res.data.data.videoStatus!="verify" && this.props.urlPath!=false){
            //    this.setState({modalIsOpen: true}); 
            // }
            console.log("res.data.data.kycCompleteStatus===",res.data.data.kycCompleteStatus);
            this.setState({profileDataFound:true,kycCompleteStatus:res.data.data.kycCompleteStatus,accountholdername:res.data.data.fname+" "+res.data.data.lname,accountholdernameReal:res.data.data.fname+" "+res.data.data.lname,recordProfile:res.data.data })
			
			})
		
	}
    componentWillReceiveProps(nextProps) {
        if (nextProps.prodata !== undefined
            && nextProps.prodata.pro !== undefined
            && nextProps.prodata.pro.data !== undefined
            && nextProps.prodata.pro.data.message !== undefined
            && nextProps.prodata.pro.data.status) {
                toast.success( nextProps.prodata.pro.data.message, toastStyles);
                
            
        }
        this.getData()
		if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
   
    setvalueReject=(rejectReason)=>{
        this.setState({ currentReject: rejectReason});
    }
   
     onChange = e => {
        this.setState({
            message: "",
            errors: {
                bankname:'',
                accountno:'',
                accountholdername:'',
                ifsccode:'',
                branchname:'',
                upiid:''
            }
        })
      
        if (e.target.id === 'bankname') {
            this.setState({ bankname: e.target.value });
        }
          if (e.target.id === 'accountno') {
            if(e.target.value.length>18){
                
            }else{
                this.setState({ accountno: e.target.value});
            }           
        }
        if (e.target.id === 'accountholdername') {
            this.setState({ accountholdername: e.target.value });
        }
       
        if (e.target.id === 'ifsccode' && e.target.value.length<12) {
            this.setState({ ifsccode: e.target.value });
        }
        if (e.target.id === 'branchname') {            
            if(e.target.value.match(/^\s/)){
                toast.dismiss();
                toast.error('First space not allowed',toastStyles);
                return false;
            }else{
                this.setState({ branchname: e.target.value });
            }
            // const result = e.target.value.replace(/[^a-zA-Z]/, '');
            // const keyCode = e.keyCode || e.charCode;
            // // Allow only alphabetic characters (A-Z, a-z)
            // if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 122)) {
            //     e.preventDefault();
            // }else{
            //     if(result){
            //         this.setState({ branchname: e.target.value });
            //     } 
            // }                       
        }

        if (e.target.id === 'upiid') {
            this.setState({ upiid: e.target.value });
        }
		
		if (e.target.name === 'cancelledChequeImage') {
            var cancelledChequeImage = e.target.files[0];
            
            var fileSize = e.target.files[0].size;
            if (fileSize > 10000000) {
                toast.dismiss();
				toast.error('Maximum 10 MB Image allowed',toastStyles);
               // this.setState({ invalidImage: "Maximum 1 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
            
            if (!cancelledChequeImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                toast.dismiss();
				toast.error('Please select valid Front image jpeg,png,gif.',toastStyles);
                //this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
				
                return false;
            }

            this.setState({
                cancelledChequeImage: cancelledChequeImage,

            });
        }

       /*  this.setState({
            showResults: this.state.name===null ? false : true
        }) */
        

    };



    onDataSubmit = e => {
 
        e.preventDefault();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
		
		if(this.state.bankname==""){
			toast.error('Bank name is required',toastStyles);
			return false;
		}
		
		if(this.state.accountno==""){
			toast.error('Account number is required',toastStyles);
			return false;
		}
        if(this.state.accountno.length<6){
            toast.error('Please enter valid account number',toastStyles);
			return false;
        }

        if(this.state.accountno<=0){
			toast.error('Please enter valid account number',toastStyles);
			return false;
		}
		
		if(this.state.accountholdername==""){
			toast.error('Account holder name is required',toastStyles);
			return false;
		}
        if(this.state.ifsccode==""){
			toast.error('IFSC code is required',toastStyles);
			return false;
		}
        if(this.state.ifsccode<=0){
			toast.error('Please enter valid IFSC code',toastStyles);
			return false;
		}
        var corectIFSC = /^[A-Za-z]{4}\d{7}$/;
        if (!corectIFSC.test((this.state.ifsccode))) {
            toast.error('Please enter valid IFSC code',toastStyles);
			return false;
            // IFSCMsg = "Enter valid IFSC code";
            // this.setState({ IFSCMsgErr: IFSCMsg, IFSC: IFSC });
            // return false;
        }
		if(this.state.branchname==""){
			toast.error('Branch name is required',toastStyles);
			return false;
		}
		if(this.state.cancelledChequeImage==""){
			toast.error('Cancelled cheque image is required',toastStyles);
			return false;
		}
      
        const formData = new FormData();
        formData.append('bankName', this.state.bankname);
        formData.append('accountNo', this.state.accountno);
        formData.append('accountHolderName', this.state.accountholdernameReal);
        formData.append('ifscCode', this.state.ifsccode);
        formData.append('branchName', this.state.branchname);
        formData.append('cancelledChequeImage', this.state.cancelledChequeImage);
        
    //    console.log("this.state.branchname==",this.state.branchname);

		axios.post(baseUrl+"api/add_bank_detail", formData,config).then((respData) => {
			var apiResp = respData.data;
			if(apiResp.status===true){                
                toast.success(apiResp.message,toastStyles);
				this.setState({
					bankname:'',
					accountno:'',
					accountholdername:'',
					ifsccode:'',
					branchname:'',
					cancelledChequeImage:''
				})
				this.getData();
			}else{
                toast.error(apiResp.message,toastStyles);
            }
            setTimeout(function () {
                window.location.href = "/bankdetail";
            },1000);
		});
			
       
    };
    
    onDataUPISubmit = e => {
        e.preventDefault();
		if(this.state.upiid===""){
			toast.error('UPI ID is required',toastStyles);
			return false;
		}else{
            if(this.state.upiid.indexOf("@") == -1){
                toast.error('Please enter valid UPI ID',toastStyles);
			    return false;
            }
        }
		axios.post(baseUrl+"api/add_upi_detail", {'upiid':this.state.upiid}).then((respData) => {
			var apiResp = respData.data;			
			if(apiResp.status===true){                
                toast.success(apiResp.message,toastStyles);
				this.setState({
					bankname:'',
					accountno:'',
					accountholdername:'',
					ifsccode:'',
					branchname:'',
					cancelledChequeImage:'',
                    upiid:''
				})
				this.getData();
			}else{
                toast.error(apiResp.message,toastStyles);
            }
		});
			
       
    };


 showBankFormHtml=()=>{
	 if(this.state.profileDataFound && this.state.kycCompleteStatus==='complete'){
		 
		  return  <div className="in_page " >
                        <ul className="nav contatcs-tab pb-3" id="crypt-tab">
                            <li className="nav-item">
                                <a className="active" data-toggle="tab" href="#AddBank">Add Bank</a>
                                </li>
                            <li className="nav-item">
                                <a data-toggle="tab" href="#AddUPI">Add UPI</a>
                            </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active padding_d" id="AddBank">
                                    <form noValidate onSubmit={this.onDataSubmit} id="update-user">
                                        <div className="white_box">
                                            <h6>Bank Detail</h6>
                                            <span className="text-success">{this.state.message}</span>
                                            <div className="row">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.id}
                                                    id="user-update-id"
                                                    type="text"
                                                    className="d-none" />
                                                    <div className="form-group col-md-6 col-12">
                                                        <label>Bank Name<span className="text-danger"> *</span></label>
                                                    
                                                        
                                                        <select onChange={this.onChange}
                                                            className="form-control"
                                                            value={this.state.bankname}
                                                            id="bankname"
                                                            type="text"
                                                            placeholder="Bank Name" > 
                                                            <option value="">Please Select</option>
                                                            <option value="Aditya Birla Idea Payments Bank Ltd">Aditya Birla Idea Payments Bank Ltd</option>
                                                            <option value="Airtel Payments Bank Ltd">Airtel Payments Bank Ltd</option>
                                                            <option value="Andhra Pradesh GVB">Andhra Pradesh GVB</option>
                                                            <option value="Andhra Pragathi Grameena Bank">Andhra Pragathi Grameena Bank</option>
                                                            <option value="Arunachal Pradesh Rural Bank">Arunachal Pradesh Rural Bank</option>
                                                            <option value="Aryavart Bank">Aryavart Bank</option>
                                                            <option value="Assam Gramin Vikash Bank">Assam Gramin Vikash Bank</option>
                                                            <option value="Au Small Finance Bank Ltd">Au Small Finance Bank Ltd</option>
                                                            <option value="Axis Bank Ltd">Axis Bank Ltd</option>
                                                            <option value="Bandhan Bank Ltd">Bandhan Bank Ltd</option>
                                                            <option value="Bangiya Gramin Vikash Bank">Bangiya Gramin Vikash Bank</option>
                                                            <option value="Bank of Baroda">Bank of Baroda</option>
                                                            <option value="Bank of India">Bank of India</option>
                                                            <option value="Bank of Maharashtra">Bank of Maharashtra</option>
                                                            <option value="Baroda Gujarat Gramin Bank">Baroda Gujarat Gramin Bank</option>
                                                            <option value="Baroda Rajasthan Kshetriya Gramin Bank">Baroda Rajasthan Kshetriya Gramin Bank</option>
                                                            <option value="Baroda Uttar Pradesh Gramin Bank">Baroda Uttar Pradesh Gramin Bank</option>
                                                            <option value="Canara Bank">Canara Bank</option>
                                                            <option value="Capital Small Finance Bank Ltd">Capital Small Finance Bank Ltd</option>
                                                            <option value="Central Bank of India">Central Bank of India</option>
                                                            <option value="Chaitanya Godavari GB">Chaitanya Godavari GB</option>
                                                            <option value="Chhattisgarh Rajya Gramin Bank">Chhattisgarh Rajya Gramin Bank</option>
                                                            <option value="City Union Bank Ltd">City Union Bank Ltd</option>
                                                            <option value="Coastal Local Area Bank Ltd">Coastal Local Area Bank Ltd</option>
                                                            <option value="CSB Bank Limited">CSB Bank Limited</option>
                                                            <option value="Dakshin Bihar Gramin Bank">Dakshin Bihar Gramin Bank</option>
                                                            <option value="DCB Bank Ltd">DCB Bank Ltd</option>
                                                            <option value="Dhanlaxmi Bank Ltd">Dhanlaxmi Bank Ltd</option>
                                                            <option value="Ellaquai Dehati Bank">Ellaquai Dehati Bank</option>
                                                            <option value="Equitas Small Finance Bank Ltd">Equitas Small Finance Bank Ltd</option>
                                                            <option value="ESAF Small Finance Bank Ltd">ESAF Small Finance Bank Ltd</option>
                                                            <option value="Export-Import Bank of India">Export-Import Bank of India</option>
                                                            <option value="Federal Bank Ltd">Federal Bank Ltd</option>
                                                            <option value="Fincare Small Finance Bank Ltd">Fincare Small Finance Bank Ltd</option>
                                                            <option value="Fino Payments Bank Ltd">Fino Payments Bank Ltd</option>
                                                            <option value="HDFC Bank Ltd">HDFC Bank Ltd</option>
                                                            <option value="Himachal Pradesh Gramin Bank">Himachal Pradesh Gramin Bank</option>
                                                            <option value="ICICI Bank Ltd">ICICI Bank Ltd</option>
                                                            <option value="IDBI Bank Limited">IDBI Bank Limited</option>
                                                            <option value="IDFC FIRST Bank Limited<">IDFC FIRST Bank Limited</option>
                                                            <option value="India Post Payments Bank Ltd">India Post Payments Bank Ltd</option>
                                                            <option value="Indian Bank">Indian Bank</option>
                                                            <option value="Indian Overseas Bank">Indian Overseas Bank</option>
                                                            <option value="IndusInd Bank Ltd">IndusInd Bank Ltd</option>
                                                            <option value="J&K Grameen Bank">J&K Grameen Bank</option>
                                                            <option value="Jammu & Kashmir Bank Ltd">Jammu & Kashmir Bank Ltd</option>
                                                            <option value="Jana Small Finance Bank Ltd">Jana Small Finance Bank Ltd</option>
                                                            <option value="Jharkhand Rajya Gramin Bank">Jharkhand Rajya Gramin Bank</option>
                                                            <option value="Jio Payments Bank Ltd">Jio Payments Bank Ltd</option>
                                                            <option value="Karnataka Bank Ltd">Karnataka Bank Ltd</option>
                                                            <option value="Karnataka Gramin Bank">Karnataka Gramin Bank</option>
                                                            <option value="Karnataka Vikas Gramin Bank">Karnataka Vikas Gramin Bank</option>
                                                            <option value="Karur Vysya Bank Ltd">Karur Vysya Bank Ltd</option>
                                                            <option value="Kashi Gomti Samyut Gramin Bank">Kashi Gomti Samyut Gramin Bank</option>
                                                            <option value="Kerala Gramin Bank">Kerala Gramin Bank</option>
                                                            <option value="Kotak Mahindra Bank Ltd">Kotak Mahindra Bank Ltd</option>
                                                            <option value="Krishna Bhima Samruddhi LAB Ltd">Krishna Bhima Samruddhi LAB Ltd</option>
                                                            <option value="Lakshmi Vilas Bank Ltd">Lakshmi Vilas Bank Ltd</option>
                                                            <option value="Madhya Pradesh Gramin Bank">Madhya Pradesh Gramin Bank</option>
                                                            <option value="Madhyanchal Gramin Bank">Madhyanchal Gramin Bank</option>
                                                            <option value="Maharashtra GB">Maharashtra GB</option>
                                                            <option value="Manipur Rural Bank">Manipur Rural Bank</option>
                                                            <option value="Meghalaya Rural Bank">Meghalaya Rural Bank</option>
                                                            <option value="Mizoram Rural Bank">Mizoram Rural Bank</option>
                                                            <option value="Nagaland Rural Bank">Nagaland Rural Bank</option>
                                                            <option value="Nainital Bank Ltd">Nainital Bank Ltd</option>
                                                            <option value="National Bank for Agriculture and Rural Development">National Bank for Agriculture and Rural Development</option>
                                                            <option value="National Housing Bank">National Housing Bank</option>
                                                            <option value="North East Small Finance Bank Ltd">North East Small Finance Bank Ltd</option>
                                                            <option value="NSDL Payments Bank Limited">NSDL Payments Bank Limited</option>
                                                            <option value="Odisha Gramya Bank">Odisha Gramya Bank</option>
                                                            <option value="Paschim Banga Gramin Bank">Paschim Banga Gramin Bank</option>
                                                            <option value="Paytm Payments Bank Ltd">Paytm Payments Bank Ltd</option>
                                                            <option value="Prathama U.P. Gramin Bank">Prathama U.P. Gramin Bank</option>
                                                            <option value="Puduvai Bharathiar Grama Bank">Puduvai Bharathiar Grama Bank</option>
                                                            <option value="Punjab & Sind Bank">Punjab & Sind Bank</option>
                                                            <option value="Punjab Gramin Bank">Punjab Gramin Bank</option>
                                                            <option value="Punjab National Bank">Punjab National Bank</option>
                                                            <option value="Purvanchal Bank">Purvanchal Bank</option>
                                                            <option value="Rajasthan Marudhara Gramin Bank">Rajasthan Marudhara Gramin Bank</option>
                                                            <option value="RBL Bank Ltd">RBL Bank Ltd</option>
                                                            <option value="Saptagiri Grameena Bank">Saptagiri Grameena Bank</option>
                                                            <option value="Sarva Haryana Gramin Bank">Sarva Haryana Gramin Bank</option>
                                                            <option value="Saurashtra Gramin Bank">Saurashtra Gramin Bank</option>
                                                            <option value="Small Industries Development Bank of India">Small Industries Development Bank of India</option>
                                                            <option value="South Indian Bank Ltd">South Indian Bank Ltd</option>
                                                            <option value="State Bank of India">State Bank of India</option>
                                                            <option value="Subhadra Local Bank Ltd">Subhadra Local Bank Ltd</option>
                                                            <option value="Suryoday Small Finance Bank Ltd">Suryoday Small Finance Bank Ltd</option>
                                                            <option value="Tamil Nadu Grama Bank">Tamil Nadu Grama Bank</option>
                                                            <option value="Tamilnad Mercantile Bank Ltd">Tamilnad Mercantile Bank Ltd</option>
                                                            <option value="Telangana Grameena Bank">Telangana Grameena Bank</option>
                                                            <option value="Tripura Gramin Bank">Tripura Gramin Bank</option>
                                                            <option value="UCO Bank">UCO Bank</option>
                                                            <option value="Ujjivan Small Finance Bank Ltd">Ujjivan Small Finance Bank Ltd</option>
                                                            <option value="Union Bank of India">Union Bank of India</option>
                                                            <option value="Utkal Grameen Bank">Utkal Grameen Bank</option>
                                                            <option value="Utkarsh Small Finance Bank Ltd">Utkarsh Small Finance Bank Ltd</option>
                                                            <option value="Uttar Bihar Gramin Bank">Uttar Bihar Gramin Bank</option>
                                                            <option value="Uttarakhand Gramin Bank">Uttarakhand Gramin Bank</option>
                                                            <option value="Uttar Banga Kshetriya Gramin Bank">Uttar Banga Kshetriya Gramin Bank</option>
                                                            <option value="Vidharbha Konkan Gramin Bank">Vidharbha Konkan Gramin Bank</option>
                                                            <option value="YES Bank Ltd">YES Bank Ltd</option>
                                                            </select>
                                                    
                                                    </div>
                                                    <div className="form-group col-md-6 col-12">
                                                        <label>Account No<span className="text-danger"> *</span></label>
                                                        <input
                                                            onChange={this.onChange}
                                                            className="form-control"
                                                            value={this.state.accountno}
                                                            id="accountno"
                                                            type="number"
                                                            placeholder="Account No"
                                                            onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}}  
                                                                                                                     
                                                        />
                                                    
                                                    </div>
                                                    <div className="form-group col-md-6 col-12">
                                                        <label>Account Holder Name<span className="text-danger"> *</span></label>
                                                        <input
                                                            onChange={this.onChange}
                                                            className="form-control"
                                                            value={this.state.accountholdername}
                                                            id="accountholdername"
                                                            type="text"
                                                            disabled={true}
                                                            placeholder="Account Holder Name"
                                                            
                                                        />
                                                        
                                                    </div>
                                                    <div className="form-group col-md-6 col-12">
                                                        <label>IFSC Code<span className="text-danger"> *</span></label>
                                                        <input
                                                            onChange={this.onChange}
                                                            className="form-control"
                                                            value={this.state.ifsccode}
                                                            id="ifsccode"
                                                            type="text"
                                                            placeholder="IFSC Code"
                                                            
                                                        />
                                                        
                                                    </div>
                                                    <div className="form-group col-md-6 col-12">
                                                        <label>Branch Name<span className="text-danger"> *</span></label>
                                                        <input
                                                            onChange={this.onChange}
                                                            className="form-control"
                                                            value={this.state.branchname}
                                                            id="branchname"
                                                            type="text"
                                                            placeholder="Branch Name"
                                                            onKeyPress={(event) => {
                                                            if (!/[a-zA-Z ]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            }}
                                                        />
                                                        
                                                    </div>
                                                    
                                                    <div className="form-group col-md-6 col-12">
                                                        <label>Cancelled Cheque/Bank Passbook<span className="text-danger"> *</span> (<a href="javascript:void(0);" data-toggle="modal" data-target="#loginmodal"  target="_blank" style={{cursor:"pointer"}} >Sample</a>)</label>
                                                        <input className="form-control" id="file-upload" name="cancelledChequeImage" onChange={this.onChange} type="file" accept="image/*" />                                                    
                                                    </div>
                                                    
                                                
                                                <div className="form-group col-md-12 col-12" >
                                                    <button className="btn btn_man ">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form> 
                            
                                    <div className="crypt-market-status2 mt-4 p-2 p-sm-3">
                                        <h5 className="w_hadding mb-2 mb-md-3">List</h5>
                                        <div className=" table-striped mb-0">
                                            <div className="ccrool_table">        
                                                <ReactDatatable records={this.state.records} columns={this.columns}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="AddUPI" className="tab-pane">
                                    <form noValidate onSubmit={this.onDataUPISubmit} id="update-user1">
                                        <div className="white_box">
                                            <h6>UPI Detail</h6>
                                            <span className="text-success">{this.state.message}</span>
                                            <div className="row">        
                                                <div className="form-group col-md-6 col-12">
                                                    <label>UPI ID<span className="text-danger"> *</span></label>
                                                    <input
                                                        onChange={this.onChange}
                                                        className="form-control"
                                                        value={this.state.upiid}
                                                        id="upiid"
                                                        type="text"
                                                        placeholder="UPI Id"
                                                        
                                                    />
                                                    <span className="text-danger">ex: xxxx@xxxx</span>
                                                </div>
                                                <div className="form-group col-md-12 col-12" >
                                                    <button className="btn btn_man ">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="crypt-market-status2 mt-4 p-2 p-sm-3">
                                        <h5 className="w_hadding mb-2 mb-md-3">List</h5>
                                        <div className=" table-striped mb-0">
                                            <div className="ccrool_table">        
                                                <ReactDatatable records={this.state.upiRecords} columns={this.columns1}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                </div>

		 
	 }else if(this.state.recordProfile.bImage_status==="reject" || this.state.recordProfile.addressStatus==="reject" || this.state.recordProfile.taxStatus==="reject" || this.state.recordProfile.videoStatus === "reject"){
        return <div className="in_page " ><div className="white_box">
                     <a className="btn btn-danger w100" href="/kyc">Please update KYC documents to add bank details</a> </div><div className="crypt-market-status2 mt-4 p-2 p-sm-3">
                                        <h5 className="w_hadding mb-2 mb-md-3">List</h5>
                                        <div className=" table-striped mb-0">
                                            <div className="ccrool_table">        
                                                <ReactDatatable records={this.state.records} columns={this.columns}/>
                                            </div>
                                        </div>
                                    </div></div>
     }else if(this.state.recordProfile.bImage_status===undefined || this.state.recordProfile.addressStatus===undefined || this.state.recordProfile.taxStatus===undefined || this.state.recordProfile.videoStatus === undefined){
        return <div className="in_page " ><div className="white_box">
                     <a className="btn btn-danger w100" href="/kyc">Please complete your KYC first</a> </div><div className="crypt-market-status2 mt-4 p-2 p-sm-3">
                                        <h5 className="w_hadding mb-2 mb-md-3">List</h5>
                                        <div className=" table-striped mb-0">
                                            <div className="ccrool_table">        
                                                <ReactDatatable records={this.state.records} columns={this.columns}/>
                                            </div>
                                        </div>
                                    </div></div>
    }else {
		 return <div className="in_page " ><div className="white_box">
                     <a className="btn btn-danger w100" href="/kyc">Please wait for KYC verification</a> </div>
                     <div className="crypt-market-status2 mt-4 p-2 p-sm-3">
                                        <h5 className="w_hadding mb-2 mb-md-3">List</h5>
                                        <div className=" table-striped mb-0">
                                            <div className="ccrool_table">        
                                                <ReactDatatable records={this.state.records} columns={this.columns}/>
                                            </div>
                                        </div>
                                    </div>
                     </div>
					
	 }
 }
 
 
    render() {
       const { errors } = this.state;
        return (
            <>
<Navbar urlPath={false} symbol={false}/>
<Sidebar />
<div className="container container2">
        {this.showBankFormHtml()}
    	
	<div className="modal fade" id="loginmodal">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-body login_modal"> <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>

                    <div className="tab-content pt-4">
                    <img src={process.env.PUBLIC_URL + 'img/demo_cheque.jpg'} style={{width:"100%"}} />
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    {/* show reject reason */}
    <div className="modal fade" id="reject-reason-modal">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Bank Detail Reject Reason</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    <span>{this.state.currentReject}</span>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>                                
                </div>
            </div>
        </div>
    </div>
    {/*  */}
</div>
<Footer />
<Toaster />
</>
             
        );
    }

}

BankDetail.propTypes = {
    auth: PropTypes.object.isRequired,
    prodata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    prodata: state.prodata,
    records: state.records,
	errors: state.errors
});

export default connect(
    mapStateToProps,
)(BankDetail);
