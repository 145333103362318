import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requery } from "../../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import Moment from 'moment';

class TimerContest extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
          //  records:[],
			days:[],
			hours:[],
			minutes:[],
			seconds:[],
            startDate:'',
            endDate:'',
            serverTime:this.props.serverTime,
            endDateTime:this.props.endDateTime,
            startDateTime:this.props.startDateTime,
            records:this.props.item,
            second1:0,
            days1:0,
            hours1:0,
            minute1:0,
            key:this.props.key
        };        
    }
    componentDidMount() {
      this.liveContestDataEnd(this.state.serverTime,this.state.endDateTime) 
     
        //this.liveContestDataEnd("2022-04-05 12:00:00","2022-04-06 09:10:55") 
         
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.endDateTime) {
                this.setState({
                    endDateTime: nextProps.endDateTime, 
                });           
        }

        if (nextProps.serverTime) {
            this.setState({
                serverTime: nextProps.serverTime,
            });
        }        
    }
	
	
	// showTimerCounterHtml = (expriryDateTime,getIndex) => {   
    //     console.log("dhfjhdjf");  
    //     var expriry = new Date(expriryDateTime);
    //     var dateformat = this.state.serverTime;
    //     var currentDate = new Date(dateformat);
    //     expriry = Date.parse(expriry);
    //     expriry = expriry/1000;
    //     currentDate = Date.parse(currentDate);
    //     currentDate = currentDate/1000;
    //     const countdown = moment(expriry-currentDate);
    //     var duration = moment.duration(countdown * 1000, 'milliseconds');
    //     var interval = 1000;
    //     duration = moment.duration(duration - interval, 'milliseconds');
    //     console.log(dateformat)
    //     const days = duration.days();
    //     const hours = duration.hours();
    //     const minutes = duration.minutes();
    //     const seconds = duration.seconds();
    //     const symbol1 =this.props.symbol;
    //     if(days>0 ||  hours>0 ||  minutes>0 || seconds>0){
    //         this.state.days[getIndex] = days;
    //         this.state.hours[getIndex] = hours;
    //         this.state.minutes[getIndex] = minutes;
    //         this.state.seconds[getIndex] = seconds;
    //         this.setState({aa : "done"});
    //         //location.reload();
    //     }

        			
    //   }
      liveContestDataEnd =(currentDateTime,endDateTime,intervalName) => {
 
        const endDateTime1 = endDateTime;
        const endDateTime2 = endDateTime1; 
        var endsendDateVal = Moment(Date.parse(endDateTime2) / 1000);
    
        const currentDateTime1 = currentDateTime;
        const currentDateTime2 = currentDateTime1; 
        var currentDate = Moment(Date.parse(currentDateTime2) / 1000);
       
        this.state.records.map( (value, j)=> {
            var intervalName = value.contestName;
            intervalName = setInterval(async () => {
                currentDate = currentDate + 1;
                this.secondsToHms(currentDate,endsendDateVal,intervalName)
            }, 1000);
        });
        
       
     
        return(() => {
          //  clearInterval(intervalId)
        })
       
      };
    
        secondsToHms = (currentDateTime, endsendDate,intervalName) => {
        const countdown =  Moment(endsendDate - currentDateTime);        
        var duration = Moment.duration(countdown * 1000, 'milliseconds');
        var interval = 1000; 
        duration = Moment.duration(duration - interval, 'milliseconds');     
        const endDays = duration.days();
        const endHours = duration.hours();
        const endMinutes = duration.minutes();
        const endSeconds = duration.seconds();    
        // setD(endDays);
        // setH(endHours);
        // setM(endMinutes);
        // setS(endSeconds);
        if(endSeconds<0){
            clearInterval(intervalName);
            // window.location.reload();
        }
        this.setState({
            days1:endDays,
            hours1:endHours,
            minute1:endMinutes,
            second1:endSeconds
        })
        
        //  console.log(duration)        
      };
    showChatHtml = () => {
        var month_name = function (dt) {
            var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return mlist[dt.getMonth()];
        };
        const { user } = this.props.auth;
        const html = []
        let classThis = this;
        return (<>                        
                <span className="timerdiv2 past_value">
                    <span>{this.state.days1}D</span>
                    <span>:</span>
                    <span>{this.state.hours1}H</span>
                    <span>:</span>
                    <span>{this.state.minute1}M</span>
                    <span>:</span>
                    <span>{this.state.second1}S</span>
                </span>                   
                </>
        )
        // if(this.state.endDateTime !== undefined){
        //         // end date time
        //         var endDateTime = new Date(this.state.endDateTime);
        //         return (<>                        
        //                 <span className="timerdiv2 past_value">
        //                     <span>{classThis.state.days[this.state.uniquKey]}D</span>
        //                     <span>:</span>
        //                     <span>{classThis.state.hours[this.state.uniquKey]}H</span>
        //                     <span>:</span>
        //                     <span>{classThis.state.minutes[this.state.uniquKey]}M</span>
        //                     <span>:</span>
        //                     <span className="timercounter">{classThis.state.seconds[this.state.uniquKey]}S </span>
        //                 </span>                   
        //             </>
        //         )
        // }
        
    }
    render() {
        const { errors } = this.state;
        const { user } = this.props.auth;
        return (
            <>
                <div className="past_value">
               
                    {this.showChatHtml()}
             
                </div>
            </>

        )
    }
}

TimerContest.propTypes = {
    requery: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    contestdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    contestdata: state.contestdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { requery }
)(withRouter(TimerContest));
