import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {toast,Toaster } from 'react-hot-toast'; 
import Moment from 'moment';
import socketIOClient from "socket.io-client";
import * as myConstList from '../../../BaseUrl';
const jwtToken = localStorage.getItem("jwtToken");
const baseUrl = myConstList.baseUrl;
const socket = socketIOClient(myConstList.baseUrlSocket);
const config = {
    headers: {
      Authorization: 'Bearer '+`${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
const isEmpty = require("is-empty");
class OrderHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            record: [],
            contestId:"",
            symbol:""
        };
    }
    
  
    componentWillReceiveProps(nextProps) {

        if (nextProps.record) {
            this.setState({
                record: nextProps.record
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.symbol) {
            this.setState({
                symbol: nextProps.symbol
            });
        }
        if (nextProps.contestId) {
            this.setState({
                contestId: nextProps.contestId
            });
        }
       
    }


    showHtml = () => {
        let counts= this.state.record.length;
        const html = []
        if(counts){
            var classThis=this;
        this.state.record.map(function (value, i) {
            if(value.status==="pending") {
				if (value.orderType == "Buy") {
					html.push(
						<>  
							<tr key={i}>
								<td>{Moment(value.date).format('lll')}</td>
								<td>{value.symbol.slice(0, -4)}</td>
								<td className="crypt-up">{value.orderType}</td>
								<td className="crypt-up">{value.price}</td>
								<td className="crypt-up">{value.amount}</td>
								<td className="crypt-up">{value.price*value.amount}</td>							  
								<td className="text-danger"><button className="btn e_btn_man"
								  onClick={() =>classThis.deleteOrder(value._id,value.orderType)}>
								   Cancel
								</button></td>
							</tr>
						</>
					);
				}
				if (value.orderType == "Sell") {
					html.push(
						<>
							<tr>
								<td>{Moment(value.date).format('lll')}</td>
								<td>{value.symbol.slice(0, -4)}</td>
								<td className="crypt-down">{value.orderType}</td>
								<td className="crypt-down">{-value.price}</td>
								<td className="crypt-down">{-value.amount}</td>
								<td className="crypt-down">{value.price*value.amount}</td>
						 
								<td className="text-danger">
								<button className="btn e_btn_man"
								   onClick={() =>classThis.deleteOrder(value._id,value.orderType)}>
								   Cancel
								</button>
								</td>
							</tr>
						</>
					);
				}
			}
        })
    }else{
        html.push(
     
        <div className="text-center">No Record Found</div>
        
        )
    }
        return html;
    }

    deleteOrder(record,orderType){
        const collectThis = this;
             axios
                 .post(baseUrl+"api/order-delete", {_id: record,orderType:orderType},config)
                 .then(res => {
                     if (res.status === 200) {
                        toast.error(res.data.message,toastStyles);
                        const socketEventorder = "order"+this.props.auth.user.id+this.state.contestId;
                        var dataVar = {'socketName':socketEventorder,'contestId':this.state.contestId,'userId':this.props.auth.user.id,'symbol':this.state.symbol};
                        socket.emit('exchangeOrderData',dataVar);  
                     }
                 })
                 .catch();
         }

    modifyOrder(record){
   
        // axios
        //     .post("/api/order-delete", {_id: record})
        //     .then(res => {
        //         if (res.status === 200) {
        //            toast(res.data.message, {
        //                position: toast.POSITION.TOP_CENTER,
        //            })
        //         }
        //     })
        //     .catch();
      
    }
    render() {
        return (
            <>
            <table className="table border_none">
                <tr>
                    <th scope="col">Time</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Buy/Sell</th>
                    <th scope="col">Price USDT</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Total</th>                    
                    <td></td>
                    <td></td>
                </tr>
                <tbody>
                {this.showHtml()}
                </tbody>
            </table>
            <Toaster />
            </>
        );
    }

}

OrderHistory.propTypes = {
    auth: PropTypes.object.isRequired,
    orderdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    orderdata: state.orderdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(OrderHistory);
