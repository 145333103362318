import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateKycProfile } from "../../../actions/updatePersionalDataKycActions";
import { withRouter } from "react-router-dom";
import {toast,Toaster } from 'react-hot-toast';
import moment from 'moment';
import $ from 'jquery';
import axios from "axios";
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: 'Bearer ' + `${localStorage.getItem("jwtToken")}`,
    },
  };
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class ProfileUpdateDetails extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            id: this.props.id,
            fname: "",
            lname: "",
            dob: "",
            gender: this.props.gender,
            profileImage: this.props.profileImage,
            profileImageTemp: "",
            message:'',
            invalidImage:'',
            showResults : false,
            errors: {},
        };
              
    }
  
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            if(nextProps.record.fname){
                this.setState({
        
                    fname: nextProps.record.fname,
             
                })
            }
            if(nextProps.record.lname){
                this.setState({
                
                    lname: nextProps.record.lname,
               
                })
            }
            if(nextProps.record.dob){
                this.setState({
            
                    dob: nextProps.record.dob,
               
                })
            }
            this.setState({
                id: nextProps.record.id,            
                gender: nextProps.record.gender,
                profileImage: nextProps.record.profileImage
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log("nextProps.prodata",nextProps.prodata);
        if (nextProps.prodata !== undefined
            && nextProps.prodata.proimage !== undefined
            && nextProps.prodata.proimage.data !== undefined
            && nextProps.prodata.proimage.data.message !== undefined
            && nextProps.prodata.proimage.data.status && nextProps.prodata.proimage.data.messageStatus=='1') {
               const textMsg = nextProps.prodata.proimage.data.message
               nextProps.prodata.proimage.data.message ="";
                this.setState({
                    message: nextProps.prodata.proimage.data.message,
                    errors: {
                   
                    }
                });
                if(textMsg !== ""){
                    toast.success(textMsg, toastStyles);  
                }
                              
        }else if (nextProps.prodata !== undefined
            && nextProps.prodata.pro !== undefined
            && nextProps.prodata.pro.data !== undefined){
            this.setState({
                errors: nextProps.prodata.pro.data
            });
        }
    }
  
    onChange = e => {
        this.setState({
            message: ""
        })
    if (e.target.id === 'user-update-lname') {
            this.setState({ lname: e.target.value });
        }
    if (e.target.id === 'user-update-fname') {
            this.setState({ fname: e.target.value });
        }
    if (e.target.id === 'user-update-dob') {
            this.setState({ dob: e.target.value });
        }
    if (e.target.id === 'user-update-gender') {
            this.setState({ gender: e.target.value });
        }
        if (e.target.name === 'profileImage') {
            var profileImage = e.target.files[0];
            this.setState({profileImageTemp:URL.createObjectURL(e.target.files[0])});
            var fileSize = e.target.files[0].size;
            if (fileSize > 10000000) {
                this.setState({ invalidImage: "Maximum 10 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
            console.log(this.state.invalidImage);
            if (!profileImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }

            this.setState({
                profileImage: profileImage,


            });
            e.preventDefault();
            const config = {headers: {'content-type': 'multipart/form-data'}}
            const formData = new FormData();
            formData.append('profileImage', profileImage);
            this.props.updateKycProfile(formData, config);
        }
        if(e.target.name === "profileRemove"){
            axios.post(baseUrl + "api/profile-image-empty", {}, configToken)
                .then(res => {
                    toast.success(res.data.message, toastStyles); 
                    setTimeout(function(){ window.location.href = "/profile-user"; },2000);  
                    
            })  
        }
        this.setState({
            showResults: this.state.name===null ? false : true
        })
    };

    // onUserUpdate = e => {   
    //     e.preventDefault();
    //     const config = {
    //         headers: {
    //             'content-type': 'multipart/form-data'
    //         }
    //     }
    //     const formData = new FormData();
    //     formData.append('id', this.state.id);
    //     formData.append('type', "pdetails");
    //     formData.append('fname', this.state.fname);
    //     formData.append('lname', this.state.lname);
    //     formData.append('dob', this.state.dob);
    //     formData.append('gender', this.state.gender);
    //     formData.append('profileImage', this.state.profileImage);
    //     this.props.updateKycProfile(formData, config);       
    // };
    
    render() {
        const { errors } = this.state;
        return (
            
            <>
            
                <div id="file-upload-form" className="uploader text-center">
                    <div className="file-inner position-relative">
                        <input id="file-upload" name="profileImage" onChange={this.onChange} type="file" accept="image/*" />
                        <label for="file-upload" id="file-drag"></label>
                        {
                        this.state.profileImage !== undefined && this.state.profileImage !== null && this.state.profileImage !== "" &&
                            <a className="profile-remove" name="profileRemove" onClick={this.onChange} href="javascript:void(0)"></a>
                        }
                         
                        <span className="text-danger">{this.state.invalidImage}</span>                    
                            <div id="start">
                                <a data-toggle="modal" className="profile-popup-link" data-target="#profile-popup"></a>
                                <span className="fasi">
                                {this.state.profileImageTemp === '' && this.state.profileImage !== undefined && this.state.profileImage !== ''&&
                                    <>
                                        <img src={this.state.profileImage!=undefined?baseUrl+"profile/"+this.state.profileImage:baseUrl+"profile/user.png"} ></img>
                                    </>
                                }
                                {this.state.profileImageTemp === '' && this.state.profileImage === undefined &&
                                    <>
                                        <img src={baseUrl+"profile/user.png"} ></img>
                                    </>
                                }
                                {this.state.profileImageTemp !== undefined && this.state.profileImageTemp !== ''&&
                                    <>
                                        <img src={this.state.profileImageTemp} ></img>
                                    </>
                                }                              

                                    
                                    {/* <i className="las la-user"></i> */}
                                </span>
                            </div>
                        <span className="change_text">Change Image</span>
                    </div>
                </div>
                <div className="white_box mt-3" >
                    <h6 className=" mb-3">Personal Data</h6>
                    <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                        <div className="" >
                            {/* <span className="text-success">{this.state.message}</span> */}
                            <div className="row" >
                                <div className="form-group col-md-6" >
								{/*<input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"
										
								/>*/}
                                    <label>First name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.fname}
                                        id="user-update-fname"
                                        type="text"
										disabled={true}
                                        error={errors.fname}
                                        className={classnames("form-control", {
                                            invalid: errors.fname
                                        })} />
                                    <span className="text-danger">{errors.fname}</span>
                                </div>
                                <div className="form-group col-md-6" >
                                    <label>Last name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.lname}
                                        id="user-update-lname"
                                        type="text"
										disabled={true}
                                        error={errors.lname}
                                        className={classnames("form-control", {
                                            invalid: errors.lname
                                        })} />
                                    <span className="text-danger">{errors.lname}</span>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="form-group col-md-6" >
                                    <label>Date of Birth</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.dob}
                                        id="user-update-dob"
                                        type="date"
										disabled={true}
                                        maxLength="8"
                                        error={errors.dob}
                                        className={classnames("form-control", {
                                            invalid: errors.dob
                                        })} />
                                    <span className="text-danger">{errors.dob}</span>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Gender</label>
                                    <select disabled={true} error={errors.gender} onChange={this.onChange} value={this.state.gender} className="form-control" id="user-update-gender">
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Eunuch">Eunuch</option>
                                    </select>
                                    <span className="text-danger">{errors.gender}</span>
                                </div>
                            </div>
							<button className="btn btn_man " style={{ display: (this.state.showResults ? 'block' : 'none') }}>Save</button>
                        </div>
                    </form>
                </div>
                {/* <!-- profile pop-up --> */}
                    <div class="modal fade" id="profile-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg justify-content-center" role="document">
                            <div class="modal-content">                        
                                <div class="modal-body p-0">
                                    <div class="profile-big"><img className="img-fluid" src={this.state.profileImage!=undefined?baseUrl+"profile/"+this.state.profileImage:baseUrl+"profile/user.png"}></img></div>
                                </div>                       
                            </div>
                        </div>
                    </div>
                   
            </>
        )
    }
}

ProfileUpdateDetails.propTypes = {
    updateKycProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    prodata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    prodata: state.prodata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateKycProfile }
)(withRouter(ProfileUpdateDetails));
