const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    contest: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case 'CONTEST_ADD':
            return {
                isAuthenticated: !isEmpty(action.payload),
                contest: action.payload
            };
        case 'CONTEST_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                contest: action.payload,
            };
        case 'SET_CURRENT_CONTEST':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                contest: action.payload
            };
         
        case 'CONTEST_LOADING':
            return {
                ...state,
                loading: true
            };
      
        default:
            return state;
    }

}
